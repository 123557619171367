import { Subject } from "rxjs";
import { IDataStore } from "../../models/common/IDataStore";
import { INotificationHub } from "../../models/common/INotificationHub";

export interface TextSearchData {
    filterValue: string;
}

export class TextSearchStore implements IDataStore, INotificationHub {

    searchSubject = new Subject();
    storageState: TextSearchData = { filterValue: "" } as TextSearchData;

    sendMessage(fieldName: string, fieldValue: string) {
        // Updates the temporary storage
        this.storageState = { ...this.storageState, filterValue: fieldValue };

        // Notifies everyone
        this.searchSubject.next(this.storageState);
    }

    subscribe(onMessageReceived: any) {
        return this.searchSubject.subscribe(onMessageReceived);
    }
}