import { TransferProgressEvent } from "@azure/core-http";
import { BlobServiceClient, BlockBlobParallelUploadOptions, ContainerClient } from "@azure/storage-blob";
import { UploadStore } from "./UploadStore";
import { AttachmentDataModel } from "../../models/data/AttachmentDataModel";
import { ConceptAttachmentStore } from "../stores/ConceptAttachment/ConceptAttachmentStore";
import { IHubSubcription, MessageHubContext, MessageHubHandler } from "../notification/MessageHubHandler";
import { MessageHubOwner } from "../messaging/MessageHubOwner";
import { MessageType } from "../../models/message/IServerMessage";
import { ConceptAttachmentSaveActionResponse } from "../../models/communication/actions/ConceptAttachmentSaveAction";
import { ConceptAttachmentUploadCompleteActionResponse } from "../../models/communication/actions/ConceptAttachmentUploadCompleteAction";
import { ConceptAttachmentNotifyResponse } from "../../models/communication/actions/ConceptAttachmentNotify";
import { LogCollector } from "../logger/LogCollector";

const containerName = "temp-upload";
const sasToken = process.env.REACT_APP_STORAGESASTOKEN;
const storageAccountName = process.env.REACT_APP_STORAGERESOURCENAME;

export interface UploadServiceProps {
    uploadStore: UploadStore;
    attachmentStore: ConceptAttachmentStore;
    maxFileSize: number;
    fileTypesAllowed?: string;

    disableNotification?: boolean;
}

export enum FailureReasons {
    NoneSpecified = 0,
    FileTooBig = 1,
    FileMustBeImage = 2,
    MaxUploadReached = 3,

    NotEnoughSpaceToUpload = 10
}

export class UploadService {
    props: UploadServiceProps;

    uploadModifier: number = 1;
    currentHandlers: MessageHubHandler[] = [];

    constructor(props: UploadServiceProps) {
        this.props = props;
    }

    isStorageConfigured() {
        return (!storageAccountName || !sasToken) ? false : true;
    }


    async uploadAttachment(attachment: AttachmentDataModel, uploadingFile: File) {

        return new Promise(async (resolve, reject) => {

            //  return;
            if (!uploadingFile) {
                reject(FailureReasons.NoneSpecified);
                return;
            }

            if (this.props.fileTypesAllowed) {
                if (!uploadingFile.type) {
                    reject(FailureReasons.FileMustBeImage);
                    return;
                }

                if (!(uploadingFile.type.toLowerCase().indexOf(this.props.fileTypesAllowed.toLowerCase()) > -1)) {
                    reject(FailureReasons.FileMustBeImage);
                    return;
                }
            }

            const updateFileSize = uploadingFile.size / 1024 / 1024;
            LogCollector.LogMessage(updateFileSize);
            const maxFileSize = this.props.maxFileSize;

            if (updateFileSize > maxFileSize) {
                reject(FailureReasons.FileTooBig);
                return;
            }

            LogCollector.LogMessage("...................... ValidationStep!");
            resolve(true);
        }).then(() => {
            LogCollector.LogMessage("...................... Calculating position");
            this.props.attachmentStore.calculateAttachmentOrder(attachment);
        }).then(() => {

            return new Promise(async (resolve, reject) => {
                LogCollector.LogMessage("...................... NotifyStep!");

                const notifyListener = MessageHubContext().ListenMessage(MessageHubOwner, {
                    MessageType: MessageType.ConceptAttachmentNotified, OnReceive: (response: ConceptAttachmentNotifyResponse) => {

                        notifyListener.Dispose();

                        if (response.attachmentID === attachment.attachmentID) {
                            if (response.hasEnoughSpace) {
                                resolve(true);
                            }
                            else {
                                reject(FailureReasons.NotEnoughSpaceToUpload);
                            }
                        }
                        else {
                            reject(FailureReasons.NoneSpecified);
                        }
                    }
                } as IHubSubcription);

                this.currentHandlers.push(notifyListener);

                await this.props.attachmentStore.notifyUpload(attachment);
            }).then(() => {
                LogCollector.LogMessage("...................... UploadToAzure!");
                return this.uploadToAzure(uploadingFile, attachment.uploadFileName);
            }).then(() => {

                return new Promise(async (resolve, reject) => {
                    LogCollector.LogMessage("...................... SaveAttachement!");

                    if (!this.props.attachmentStore.syncWithServer) {
                        LogCollector.LogMessage("...................... SkipSaveAttachement!");
                        resolve(true);
                        return;
                    }

                    const saveListener = MessageHubContext().ListenMessage(MessageHubOwner, {
                        MessageType: MessageType.ConceptAttachmentSaved, OnReceive: (response: ConceptAttachmentSaveActionResponse) => {

                            saveListener.Dispose();

                            if (response.attachmentID === attachment.attachmentID) {
                                resolve(true);
                            }
                            else {
                                reject(FailureReasons.NoneSpecified);
                            }
                        }
                    } as IHubSubcription);

                    this.currentHandlers.push(saveListener);

                    await this.props.attachmentStore.saveNewAttachement(attachment);
                });
            }).then(() => {
                return new Promise(async (resolve, reject) => {
                    LogCollector.LogMessage("...................... NotifyComplete!");

                    if (this.props.attachmentStore.syncWithServer) {
                        LogCollector.LogMessage("...................... SkipNotifyComplete!");
                        resolve(true);
                        return;
                    }

                    const completeListener = MessageHubContext().ListenMessage(MessageHubOwner, {
                        MessageType: MessageType.ConceptAttachmentUploadCompleted, OnReceive: (response: ConceptAttachmentUploadCompleteActionResponse) => {

                            completeListener.Dispose();

                            if (response.attachmentID === attachment.attachmentID) {
                                resolve(true);
                            }
                            else {
                                reject(FailureReasons.NoneSpecified);
                            }
                        }
                    } as IHubSubcription);

                    this.currentHandlers.push(completeListener);

                    await this.props.attachmentStore.completeUpload(attachment);
                });

            }).then(() => {
                LogCollector.LogMessage("...................... AddAttachement!");
                if (this.props.disableNotification) {
                    return;
                }

                this.props.attachmentStore.addNewAttachment(attachment);
            });

            //MessageHubContext().ListenMessage(MessageHubOwner, { MessageType: MessageType.ConceptAttachmentSaved, OnReceive: onConceptUpdate } as IHubSubcription)
            //await this.props.attachmentStore.addNewAttachment(attachment);
        });
    }

    async uploadToAzure(uploadingFile: File, fileName: string) {
        return new Promise(async (resolve, reject) => {

            // get BlobService = notice `?` is pulled out of sasToken - if created in Azure portal
            const blobService = new BlobServiceClient(
                `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`
            );

            try {
                // get Container - full public read access
                const containerClient: ContainerClient = blobService.getContainerClient(containerName);

                // await containerClient.createIfNotExists({
                //     access: "container",
                // });

                // upload file
                await this.createBlobInContainer(containerClient, uploadingFile, fileName);
                resolve(true);
            }
            catch (err: any) {

                LogCollector.LogError(err);
                reject(FailureReasons.NoneSpecified);
            }
        });
    }

    async createBlobInContainer(containerClient: ContainerClient, file: File, fileName: string) {

        // create blobClient for container
        const blobClient = containerClient.getBlockBlobClient(fileName);

        // set mimetype as determined from browser with file upload control
        const options = {
            onProgress: (progress: TransferProgressEvent) => {
                if (!this.props.uploadStore) {
                    return;
                }

                const percent = (progress.loadedBytes / file.size * 100.0) * this.uploadModifier;

                LogCollector.LogMessage(percent);
                this.props.uploadStore.updateProgress(percent);
            },
            blobHTTPHeaders: {
                blobContentType: file.type
            }
        } as BlockBlobParallelUploadOptions;

        // upload file
        return blobClient.uploadData(file, options);
    }


    dispose() {

        if (this.currentHandlers && this.currentHandlers.length > 0) {
            this.currentHandlers.forEach(handler => handler.Dispose());
            this.currentHandlers = [];
        }
    }
}