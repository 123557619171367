import { Subject } from "rxjs";
import { ConceptType } from "../../../models/common/CommonEnums";
import { IDataStore } from "../../../models/common/IDataStore";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { ConceptTagUpdateAction } from "../../../models/communication/actions/ConceptTagUpdateAction";
import { TagDataModel } from "../../../models/data/TagDataModel";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { MessageService } from "../../messaging/MessageService";
import { ConceptTagStoreModel } from "./ConceptTagStore";
import { ITagStoreNotification, TagInsightLoader, TagModel, TagProblemLoader, TagSolutionLoader, TagStoreData } from "./TagStore";

export class TagEditorStore implements IDataStore, INotificationHub, ITagStoreNotification {
    editorTagSubject = new Subject();
    storageState: TagStoreData = { tagList: [] } as TagStoreData;
    defaultSelectedTags: TagDataModel[] = [];
    conceptID: string = "";
    conceptType: ConceptType = ConceptType.Default;
    syncWithServer: boolean = true;

    subscribe(OnReceiveAction: any) {
        return this.editorTagSubject.subscribe(OnReceiveAction);
    }

    async sendMessage(fieldName: string, fieldValue: string) {
        const matchingTag = this.storageState.tagList.find(tag => tag.TagID === fieldName);
        if (matchingTag) {
            matchingTag.isSelected = fieldValue === "true";

            if (!this.syncWithServer) {
                this.editorTagSubject.next(this.storageState);
                return;
            }

            const messageType = matchingTag.isSelected
                ? MessageType.ConceptTagAdd
                : MessageType.ConceptTagDelete;
            return MessageService.sendMessage({
                messageType: messageType,
                requestData: {
                    conceptID: this.conceptID,
                    tagID: matchingTag.TagID
                } as ConceptTagUpdateAction
            } as IServerMessage).then(() => {
                this.editorTagSubject.next(this.storageState);
            });
        }
    }

    initialize(conceptTagModel: ConceptTagStoreModel) {

        if (this.conceptID) {
            return;
        }
        this.conceptID = conceptTagModel.conceptID;
        this.conceptType = conceptTagModel.conceptType;

        if (conceptTagModel.selectedTags) {
            this.defaultSelectedTags = conceptTagModel.selectedTags;
        }

        switch (conceptTagModel.conceptType) {
            case ConceptType.Insights:
                TagInsightLoader.LoadTags(this);
                break;
            case ConceptType.Problems:
                TagProblemLoader.LoadTags(this);
                break;
            case ConceptType.Solutions:
                TagSolutionLoader.LoadTags(this);
                break;
            default:
                this.defaultLoaded([]);
                break;
        }
    }

    defaultLoaded(defaultTagList: TagModel[]) {
        if (defaultTagList) {
            const copy = defaultTagList.map((tag) => {
                return { ...tag, isSelected: (this.defaultSelectedTags.filter(f => f.tagID === tag.TagID).length > 0) };
            });

            this.storageState = { tagList: copy };
        }
        this.editorTagSubject.next(this.storageState);
    }

    reload() {
        switch (this.conceptType) {
            case ConceptType.Insights:
                TagInsightLoader.LoadTags(this);
                break;
            case ConceptType.Problems:
                TagProblemLoader.LoadTags(this);
                break;
            case ConceptType.Solutions:
                TagSolutionLoader.LoadTags(this);
                break;
            default:
                this.defaultLoaded([]);
                break;
        }
    }
}