import React, { useState } from "react"
import { FieldType, TextBoxComponent } from "../elements/TextBoxComponent"
import { SaveButtonComponent, TempValidatorType } from "../elements/SaveButtonComponent"
import { WorkspaceTeamInviteStore } from "../../services/stores/Workspace/WorkspaceTeamInviteStore"

export const WorkspaceInvitationComponent = () => {

    const [store] = useState(new WorkspaceTeamInviteStore());

    const OnSendInvitation = async () => {
        await store.sendInvitation()
            .then(() => {

            });
    }

    return (
        <React.Fragment>
            <div className="modal-header bg-header text-white">
                <div className="modal-title fw-large-500 ps-2">Invite to workspace</div>
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body bg-white vc-editor m-2 mb-0 mx-1">
                <div>
                    {/* <div className="fw-reg-500 mb-1">Member email address</div> */}
                    <TextBoxComponent
                        fieldName="emailAddress"
                        dataStore={store}
                        maxLength={300}
                        placeholder="Email address"
                        details={{
                            fieldType: FieldType.Email,
                            numberOfRows: 1
                        }}

                        autoFocus={true}
                        autoFocusDelayed={true}
                    />
                </div>
            </div>
            <div className="modal-footer d-flex justify-content-between pt-3 px-3" style={{ border: "0px" }}>
                <SaveButtonComponent displayText="Invite" validatorType={TempValidatorType.MemberInvitation} notificationHub={store} ButtonClick={OnSendInvitation} />
                <button type="button" className="btn btn-outline-primary btn-concept-outline btn-medium" data-bs-dismiss="modal">Skip for now</button>
            </div>
        </React.Fragment>
    )
}