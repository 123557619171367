import { $isAtNodeEnd } from "@lexical/selection";
import { RangeSelection } from "lexical";
import { CommonElements } from "../../../../../models/CommonElements";

const SUPPORTED_URL_PROTOCOLS = new Set([
    'http:',
    'https:',
    'mailto:',
    'sms:',
    'tel:',
]);

const VERTICAL_GAP = 32;

export const ToolbarPlugginHelpers = {

    getSelectedNode(selection: RangeSelection) {
        const anchor = selection.anchor;
        const focus = selection.focus;
        const anchorNode = selection.anchor.getNode();
        const focusNode = selection.focus.getNode();
        if (anchorNode === focusNode) {
            return anchorNode;
        }
        const isBackward = selection.isBackward();
        if (isBackward) {
            return $isAtNodeEnd(focus) ? anchorNode : focusNode;
        } else {
            return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
        }
    },

    positionEditorElement(floatingEditor: HTMLDivElement, targetRect: DOMRect | undefined) {
        if (!targetRect) {
            floatingEditor.style.opacity = "0";
            floatingEditor.style.transform = 'translate(-10000px, -10000px)';
        }
        else {
            var anchorElem = document.getElementById(CommonElements.ModalElement)?.firstElementChild;
            if (!anchorElem) {
                floatingEditor.style.opacity = "0";
                floatingEditor.style.transform = 'translate(-10000px, -10000px)';
                return;
            }

            const anchorRect = anchorElem.getBoundingClientRect();            

            let calculatedTop = targetRect.top + VERTICAL_GAP;
            let calculatedLeft = targetRect.left;

            const floatingElemRect = floatingEditor.parentElement?.getBoundingClientRect();
            if (floatingElemRect) {
                calculatedTop -= floatingElemRect.top;
                calculatedLeft -= floatingElemRect.left;
            }

            floatingEditor.style.opacity = "1";
            floatingEditor.style.top = `${calculatedTop}px`;

            if (anchorRect) {
                const floatingWidth = floatingEditor.getBoundingClientRect().width;
                const xGap = 120;
                // console.warn(calculatedLeft);
                // console.warn(floatingWidth);
                // console.warn(anchorRect.width);

                if ((calculatedLeft + floatingWidth + xGap) > anchorRect.width) {
                    calculatedLeft -= (floatingWidth - targetRect.width);
                }

                floatingEditor.style.left = `${calculatedLeft}px`;
            }
        }
    },

    sanitizeUrl(url: string): string {
        try {
            const parsedUrl = new URL(url);
            // eslint-disable-next-line no-script-url
            if (!SUPPORTED_URL_PROTOCOLS.has(parsedUrl.protocol)) {
                return 'about:blank';
            }
        } catch {
            return url;
        }
        return url;
    }
}