import { ConceptViewerNotificationModel } from "../../../models/communication/internal/ConceptViewerNotificationModel";
import { ConceptViewerComponent } from "../../concept/ConceptViewerComponent";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";

interface ViewConceptHandlerProps {
    notification: ConceptViewerNotificationModel;
}

export const ConceptViewHandler = (props: ViewConceptHandlerProps) => {

    return {
        modalSize: ModalSize.Xlarge,
        modalContent: (
            <ConceptViewerComponent
                conceptID={props.notification.conceptID} />
        )
    } as ModalDefinitionModel;
}