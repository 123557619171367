import { Subject } from "rxjs";
import { ConceptType, GlobalNotifications } from "../../../models/common/CommonEnums";
import { IDataStore } from "../../../models/common/IDataStore";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { ConceptFolderMoveAction } from "../../../models/communication/actions/ConceptFolderMoveAction";
import { FolderLoadAction, FolderLoadActionResponse } from "../../../models/communication/actions/FolderLoadAction";
import { ConceptDataModel } from "../../../models/ConceptDataModel";
import { FolderDataModel, FolderType } from "../../../models/data/FolderDataModel";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { GlobalNotificationHub } from "../../GlobalMessageHub";
import { MessageHubOwner } from "../../messaging/MessageHubOwner";
import { MessageService } from "../../messaging/MessageService";
import { IHubSubcription, MessageHubContext, MessageHubHandler } from "../../notification/MessageHubHandler";

interface FolderStoreState {
    folderList: FolderDataModel[];
}

const initialState = {
    folderList: [
        // { folderID: crypto.randomUUID(), folderName: "All", conceptCount: 241, folderType: FolderType.SystemAllConcepts, displayOrder: 1 } as FolderDataModel,
        // { folderID: crypto.randomUUID(), folderName: "Not in a folder", conceptCount: 9, folderType: FolderType.SystemNotSorted, displayOrder: 1 } as FolderDataModel,
        // { folderID: crypto.randomUUID(), folderName: "Feedbackform", conceptCount: 15, folderType: FolderType.SystemQikFeedbackForm, displayOrder: 1 } as FolderDataModel,

        // { folderID: crypto.randomUUID(), folderName: "UX Issues", conceptCount: 24, folderType: FolderType.CustomFolder, displayOrder: 1 } as FolderDataModel,
        // { folderID: crypto.randomUUID(), folderName: "Mobile site", conceptCount: 12, folderType: FolderType.CustomFolder, displayOrder: 1 } as FolderDataModel,
        // { folderID: crypto.randomUUID(), folderName: "To Action", conceptCount: 4, folderType: FolderType.CustomFolder, displayOrder: 1 } as FolderDataModel,
    ]

} as FolderStoreState;

class FolderStoreLoaderImplementation {
    hubContext: MessageHubHandler;
    currentStore?: FolderStore;

    constructor() {
        this.OnFolderLoaded = this.OnFolderLoaded.bind(this);

        this.hubContext = MessageHubContext()
            .ListenMessage(MessageHubOwner, { MessageType: MessageType.FolderLoaded, OnReceive: this.OnFolderLoaded } as IHubSubcription);
    }

    OnFolderLoaded(response: FolderLoadActionResponse) {

        if (this.currentStore == null) {
            return;
        }

        if (response.conceptType !== this.currentStore?.conceptType) {
            return;
        }

        this.currentStore.OnFolderLoaded(response.folderList);
    }

    LoadFolders(folderStore: FolderStore) {

        this.currentStore = folderStore;

        MessageService.sendMessage({
            messageType: MessageType.FolderLoad,
            requestData: { conceptType: folderStore.conceptType } as FolderLoadAction
        } as IServerMessage);
    }

}

const FolderStoreLoader = new FolderStoreLoaderImplementation();

export class FolderStore implements IDataStore, INotificationHub {
    folderSubject = new Subject();
    storageState = initialState;

    conceptType: ConceptType = ConceptType.Default;

    OnFolderLoaded(folderList: FolderDataModel[]) {

        this.storageState.folderList = folderList;
        this.folderSubject.next(this.storageState.folderList);

    }

    initialize(conceptTypeValue: ConceptType) {

        this.storageState = { ...initialState };

        this.conceptType = conceptTypeValue;

        if (this.storageState.folderList.length === 0) {

            FolderStoreLoader.LoadFolders(this);
        }
        else {
            this.folderSubject.next(this.storageState.folderList);
        }
    }

    subscribe(onMessageReceived: any) {
        return this.folderSubject.subscribe(onMessageReceived);
    }

    async sendMessage(conceptIDValue: string, folderIDValue: string) {
        throw new Error("Method not implemented.");
    }

    async sendFolderChange(concept: ConceptDataModel, folderIDValue: string) {
        return MessageService.sendMessage({
            messageType: MessageType.ConceptFolderMove,
            requestData: {
                conceptID: concept.conceptID,
                conceptType: concept.conceptType,
                folderID: folderIDValue
            } as ConceptFolderMoveAction
        } as IServerMessage);
    }


    selectAllConceptFolder() {
        const systemAllFolder = this.storageState.folderList.find(folder => folder.folderType === FolderType.SystemAllConcepts);
        if (systemAllFolder) {
            GlobalNotificationHub.sendMessageWithData(GlobalNotifications.FolderSelectChange, systemAllFolder);
        }
    }
}