import React from "react";
import { AttachmentDataModel } from "../../../models/data/AttachmentDataModel";
import { SecureImageDisplayFullscreen } from "./SecureImageDisplay";
import { KeyboardContext, ModalOpenCloseWatcher } from "../../../services/shortcut/KeyboardService";

interface ScreenshotFullScreenViewerComponentProps {
    currentScreenshot: AttachmentDataModel;
}

export const ScreenshotFullScreenViewerComponent = (props: ScreenshotFullScreenViewerComponentProps) => {

    return (
        <React.Fragment>
            <SecureImageDisplayFullscreen imagePartialUrl={props.currentScreenshot.uploadFileName} />
            <ModalOpenCloseWatcher keyboardContext={KeyboardContext.ScreenshotViewer} />
        </React.Fragment>
    )
}