import { memo, useEffect, useMemo } from "react";
import { GlobalNotifications } from "../../../models/common/CommonEnums";
import { UserDismissibleStore, UserDismissibleType } from "../../../services/stores/UserDismissibleStore";
import { debounce } from "lodash";
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub";
import { CommonElements } from "../../../models/CommonElements";
import React from "react";

interface OneOffNotificationComponentProps {
    alertType: UserDismissibleType;
    notificationType: GlobalNotifications;

}

export const OneOffNotificationComponent: React.FunctionComponent<OneOffNotificationComponentProps>
    = memo(function ({ alertType, notificationType }) {

        const debouncedChangeHandler = useMemo(
            () => debounce(async () => {

                GlobalNotificationHub.sendMessage(notificationType);
                window.openModal(CommonElements.ModalElement);
            }, 200)
            , []);

        useEffect(() => {
            return () => debouncedChangeHandler.cancel();
        }, [debouncedChangeHandler]);

        useEffect(() => {
            if (UserDismissibleStore.shouldDisplay(alertType)) {
                debouncedChangeHandler();
            }
        }, []);

        return (
            <React.Fragment></React.Fragment>
        )
    })