import React from "react";
import { WorkspaceEditorStore } from "../../services/stores/Workspace/WorkspaceEditorStore";
import { WorkspaceStore } from "../../services/stores/Workspace/WorkspaceStore";
import { SaveButtonComponent, TempValidatorType } from "../elements/SaveButtonComponent";
import { TextBoxComponent } from "../elements/TextBoxComponent";

interface WorkspaceEditorProps {
    workspaceStore: WorkspaceStore;
}

export const WorkspaceEditor = (props: WorkspaceEditorProps) => {
    const editorDataStore = new WorkspaceEditorStore();

    const SaveConcept = async () => {
        await editorDataStore.saveWorkspace(props.workspaceStore);
    }

    return (
        // <div className="modal fade" id={CommonElements.ModalElement}>
        // <div className="modal-dialog modal-dialog-centered modal-sm">
        // <div className="modal-content modal-border">
        <React.Fragment>
            <div className="modal-header bg-header text-white">
                <h1 className="modal-title fs-5 mx-2" id="staticBackdropLabel">Add a new workspace</h1>
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body bg-white vc-editor">
                <div className="mb-3 mx-2">
                    <div className="form-text fw-bold">Name of workspace</div>
                    <TextBoxComponent fieldName="workspaceName" dataStore={editorDataStore} maxLength={300} autoFocus={true} autoFocusDelayed={true} />
                </div>
            </div>
            <div className="modal-footer bg-light">
                <div className="d-flex w-100">
                    <div className="w-50 me-3 ms-2">
                        {/* <button type="button" className="btn btn-primary btn-concept w-100" disabled data-bs-dismiss="modal">Save</button> */}
                        <SaveButtonComponent className={"w-100"} validatorType={TempValidatorType.Workspace} notificationHub={editorDataStore} ButtonClick={SaveConcept} />
                    </div>
                    <div className="w-50 mx-3 me-2">
                        <button type="button" className="btn btn-outline-primary btn-concept-outline w-100" data-bs-dismiss="modal">Cancel</button>
                    </div>
                </div>

            </div>
        </React.Fragment>        
    );
}