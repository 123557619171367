
class CommonElementsImplementation {
    ModalElement: string = "conceptCreatorModal";
    ModalElementTarget: string = "#conceptCreatorModal";

    CustomModalElement: string = "screenshotDisplayModal";

    ConceptKeywordSearch: string = "conceptKeywordSearch";

    InsightTab: string = "navigate-to-insights";
    ProblemTab: string = "navigate-to-problems";
    SolutionTab: string = "navigate-to-solutions";
    DashboardTab: string = "navigate-to-dashboard";


    CreateConcept: string = "create-concept-button";
    DeleteConcept: string = "delete-concept-button";
    EditConcept: string = "edit-concept-button";
}

export const CommonElements = new CommonElementsImplementation();