
interface AreYouSureComponentProps {
    OnConfirmAction: () => Promise<void>;
    deleteElementID?: string;
}

export const AreYouSureComponent = (props: AreYouSureComponentProps) => {

    const dropdownUID = props.deleteElementID ?? crypto.randomUUID();

    const OnConfirm = async () => {
        await props.OnConfirmAction();
    }

    return (
        <div className="btn-group">
            <button id={dropdownUID} type="button" className={"btn btn-primary btn-concept-outline btn-mod w-100 "}
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false">
                Delete
            </button>
            <div className="dropdown-menu dropdown-menu-end shadow drop-container" style={{ height: "150px", width: "390px" }}>
                <div className="bg-header text-white p-3 ps-4 rounded-top d-flex align-items-center">
                    <div>
                        <div className="fw-large-500 ">
                            Are you sure?
                        </div>
                    </div>
                    <div className="m-auto"></div>
                    <div><button type="button" className="btn-close btn-close-white" onClick={() => { global.window.closeCustomDropdown(dropdownUID); }}></button></div>
                </div>
                <div className="vc-editor d-flex justify-content-between p-3" >
                    <div className="flex-fill m-2">
                        <button type="button" className="btn btn-primary btn-concept w-100" data-bs-dismiss="modal" aria-label="Close" onClick={OnConfirm}>Yes</button>
                    </div>
                    <div className="flex-fill m-2">
                        <button className="btn btn-outline-primary btn-concept-outline w-100" onClick={() => { global.window.closeCustomDropdown(dropdownUID); }}>No</button>
                    </div>
                </div>
            </div>
        </div>
    )
}