import { useEffect, useState } from "react";
import { FeedbackStatisticStore, FeedbackStatisticStoreComponent, FeedbackStatisticsState } from "../../../services/stores/Feedback/FeedbackStatisticStore";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { LoadingComponent } from "../../../pages/loading/LoadingPage";

export const FeedbackGraphComponent = () => {

    const [statisticStore, setStatisticStore] = useState<FeedbackStatisticStore>();
    const [feedbackStatistics, setFeedbackStatistics] = useState<FeedbackStatisticsState>();

    useEffect(() => {

        if (!statisticStore) {
            return;
        }

        const hubContext = MessageHubContext()
            .Subscribe(statisticStore, (data: FeedbackStatisticsState) => {
                setFeedbackStatistics(data);
            });

        return () => hubContext.Dispose();

    }, [statisticStore]);

    return (
        <div className="feedback-graph-section mt-3">
            {
                (feedbackStatistics) &&
                <FeedbackSummaryDisplay feedbackStatistics={feedbackStatistics} />
            }
            {
                (!feedbackStatistics) &&
                <div className="justify-content-center my-3 small-loader" style={{ gridColumn: "span 3" }}>
                    <LoadingComponent />
                </div>
            }

            <FeedbackStatisticStoreComponent onStoreCreated={(store: FeedbackStatisticStore) => {
                setStatisticStore(store);
            }} />
        </div>
    )
}

interface FeedbackSummaryDisplayProps {
    feedbackStatistics: FeedbackStatisticsState;
}
const FeedbackSummaryDisplay = (props: FeedbackSummaryDisplayProps) => {
    const feedbackTotalCount = props.feedbackStatistics.counter.totalCount;

    //console.error(props.feedbackStatistics);

    const positivePerc = feedbackTotalCount > 0
        ? (props.feedbackStatistics.counter.positiveCount / feedbackTotalCount * 100.0)
        : 50;
    const negativePerc = feedbackTotalCount > 0
        ? (props.feedbackStatistics.counter.negativeCount / feedbackTotalCount * 100.0)
        : 50;

    return (
        <>
            <div className="fw-xlarge-700 mx-3">{feedbackTotalCount}</div>
            <div className="my-3 fw-xsmall-400">{feedbackTotalCount === 1 ? "response" : "responses"} in the last {props.feedbackStatistics.dayCount} days</div>
            <div className="feedback-bar p-2">
                <div className="feedback-container">
                    <div className="feedback-positive" style={{ width: String(positivePerc) + "%" }}>
                        {
                            (positivePerc > 5) &&
                            <>👍️</>
                        }
                    </div>
                    <div className="feedback-negative" style={{ width: String(negativePerc) + "%" }}>
                        {
                            (negativePerc > 5) &&
                            <>👎</>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}