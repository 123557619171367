import React, { memo, useEffect, useState } from "react";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { TagSearchOrCreateComponent } from "./TagSearchOrCreateComponent";
import { TagGroupComponent } from "./TagGroupComponent";
import { ConceptTagStore, ConceptTagStoreModel } from "../../services/stores/Tag/ConceptTagStore";
import { TagDataModel } from "../../models/data/TagDataModel";

interface TagComponentProps {
    conceptTagStore: ConceptTagStore;
    selectionOnly?: boolean;
    disableDelete?: boolean;
}

export const TagComponent: React.FunctionComponent<TagComponentProps>
    = memo(function TagComponentImplementation({ conceptTagStore, selectionOnly, disableDelete }) {

        const [selectedTags, setSelectedTags] = useState<TagDataModel[]>([]);
        const [dropdownUID] = useState(Math.random().toString(36));

        useEffect(() => {
            const hubContext = MessageHubContext()
                .Subscribe(conceptTagStore, (concept: ConceptTagStoreModel) => {
                    setSelectedTags([
                        ...concept.selectedTags
                    ]);
                });

            return () => hubContext.Dispose();
        }, []);

        return (
            <React.Fragment>
                <div className="d-flex">
                    <div className="me-2">
                        <svg className="pe-none icon-color" width="16" height="17" role="img" aria-label="Tag" ><use xlinkHref="#tags-icon"></use></svg>
                    </div>
                    <div className="concept-tag-container">
                        {
                            selectedTags.slice(0, 3).map(tag => {
                                return (
                                    <div key={tag.tagID} className="tag-group">
                                        <TagSearchOrCreateComponent
                                            tagName={tag.tagDescription}
                                            tagConceptStore={conceptTagStore} selectionOnly={selectionOnly ?? false} disableDelete={disableDelete ?? false}
                                        />
                                    </div>
                                )
                            })
                        }
                        <div className="tag-group">
                            <TagGroupComponent selectedTags={selectedTags} maxItems={3} />
                        </div>
                        <TagSearchOrCreateComponent tagName="+" tagConceptStore={conceptTagStore} selectionOnly={selectionOnly ?? false} disableDelete={disableDelete ?? false} />
                    </div>
                </div>
            </React.Fragment >
        )
    })