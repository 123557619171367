import React, { memo } from 'react';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { RichTextBoxTheme } from './theme/RichTextBoxTheme';
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { DetectLinkPlugin } from './plugins/DetectLinkPlugin';
import { ListItemNode, ListNode, } from "@lexical/list";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { MarkdownShortcutPlugin } from "@lexical/react/LexicalMarkdownShortcutPlugin";
import { TRANSFORMERS } from "@lexical/markdown";
import { CodeNode } from "@lexical/code";
import { IDataStore } from "../../../models/common/IDataStore";
import { EditorLoaderPlugin } from "./plugins/EditorLoaderPlugin";
import { FocusWatchPlugin } from "./plugins/FocusWatchPlugin";
//import ToolbarPlugin from "./plugins/ToolbarPlugin";
import { ToolbarPluginV2 } from './plugins/Toolbar/ToolbarPlugin';


interface TextBoxRichTextComponentProps {
    fieldName: string;
    dataStore: IDataStore;
    maxLength: number;
}

export const TextBoxRichTextComponent: React.FunctionComponent<TextBoxRichTextComponentProps>
    = memo(function TextBoxRichTextComponent({ fieldName, dataStore }) {

        const onError = (error: any) => {
            console.error(error);
        }

        const initialConfig = {
            namespace: "VoyceEditor",
            theme: RichTextBoxTheme,
            onError,

            nodes: [
                HeadingNode,
                ListNode,
                ListItemNode,

                QuoteNode,
                CodeNode,
                LinkNode,
                AutoLinkNode
            ]
        };

        return (
            <LexicalComposer initialConfig={initialConfig}>
                <div className="editor-container">
                    {/* <ToolbarPlugin /> */}
                    <ToolbarPluginV2 />
                    <div className="editor-inner">
                        <RichTextPlugin
                            contentEditable={<ContentEditable className="editor-input editor-default" />}
                            placeholder={<Placeholder />}
                            ErrorBoundary={LexicalErrorBoundary}
                        />
                    </div>

                    {/* <OnChangePlugin onChange={onChange} /> */}
                    <HistoryPlugin />
                    {/* <TreeViewPlugin /> */}
                    <ListPlugin />
                    <LinkPlugin />
                    <CheckListPlugin />

                    <DetectLinkPlugin />
                    <FocusWatchPlugin />
                    <MarkdownShortcutPlugin transformers={TRANSFORMERS} />

                    <EditorLoaderPlugin fieldName={fieldName} dataStore={dataStore} />
                </div>

            </LexicalComposer>

        )
    });


const Placeholder = () => {
    return <div className="editor-placeholder"></div>;
}

