import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection, COMMAND_PRIORITY_LOW, SELECTION_CHANGE_COMMAND } from "lexical";
import { useCallback, useEffect, useRef, useState } from "react";
import { $isLinkNode, TOGGLE_LINK_COMMAND } from "@lexical/link";
import { ToolbarPlugginHelpers } from "./ToolbarPlugginHelpers";
import { mergeRegister } from "@lexical/utils";
import React from "react";
import { KeyboardService } from "../../../../../services/shortcut/KeyboardService";

interface ToolbarLinkEditorComponentProps {
    setIsLink: (isLink: boolean) => void;
}

export const ToolbarLinkEditorComponent = (props: ToolbarLinkEditorComponentProps) => {

    const [elementKey] = useState(crypto.randomUUID());
    const [editor] = useLexicalComposerContext();

    const editorRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [linkUrl, setEditedLinkUrl] = useState("");
    const [isEditMode, setIsLinkEditMode] = useState(true);
    const [lastSelection, setLastSelection] = useState<any>(null);

    const updateLinkEditor = useCallback(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const node = ToolbarPlugginHelpers.getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent)) {
                setEditedLinkUrl(parent.getURL());
            } else if ($isLinkNode(node)) {
                setEditedLinkUrl(node.getURL());
            } else {
                setEditedLinkUrl("");
            }
        }

        const editorElem = editorRef.current;
        const nativeSelection = window.getSelection();
        const activeElement = document.activeElement;

        if (editorElem === null) {
            return;
        }

        const rootElement = editor.getRootElement();

        if (selection !== null &&
            nativeSelection !== null &&
            rootElement !== null &&
            rootElement.contains(nativeSelection.anchorNode) &&
            editor.isEditable()) {
            const domRect: DOMRect | undefined =
                nativeSelection.focusNode?.parentElement?.getBoundingClientRect();
            if (domRect) {
                ToolbarPlugginHelpers.positionEditorElement(editorElem, domRect);
            }

            setLastSelection(selection);
        }
        else if (!activeElement || activeElement.className !== "link-input") {
            ToolbarPlugginHelpers.positionEditorElement(editorElem, undefined);
            setLastSelection(null);
            //setIsLinkEditMode(false);
            setEditedLinkUrl("");
        }

        return true;
    }, [editor]);

    useEffect(() => {
        return mergeRegister(
            editor.registerUpdateListener(({ editorState }) => {
                editorState.read(() => {
                    updateLinkEditor();
                });
            }),

            editor.registerCommand(
                SELECTION_CHANGE_COMMAND,
                () => {
                    updateLinkEditor();
                    return true;
                },
                COMMAND_PRIORITY_LOW
            ),

        );
    }, [editor, updateLinkEditor]);

    useEffect(() => {
        editor.getEditorState().read(() => {
            updateLinkEditor();
        });
    }, [editor, updateLinkEditor]);

    // useEffect(() => {
    //     if (inputRef.current) {
    //         inputRef.current.focus();
    //     }
    // }, [inputRef]);

    const monitorInputInteraction = (
        event: React.KeyboardEvent<HTMLInputElement>,
    ) => {
        if (event.key === "Enter") {
            event.preventDefault();
            handleLinkSubmission();
        }
        else if (event.key === "Escape") {
            event.preventDefault();
            event.stopPropagation();
            //setIsLinkEditMode(false);
            handleClosing();
        }
    };

    const handleLinkSubmission = () => {
        if (lastSelection !== null) {
            if (linkUrl !== "") {
                editor.dispatchCommand(TOGGLE_LINK_COMMAND,
                    { url: ToolbarPlugginHelpers.sanitizeUrl(linkUrl), target: "_blank", rel: "noopener noreferrer" });
            }
            //setEditedLinkUrl('https://');
            //setIsLinkEditMode(false);
            setTimeout(() => {
                props.setIsLink(false);
            }, 50);
        }
    };

    const handleClosing = () => {
        if (linkUrl !== "")
            editor.dispatchCommand(TOGGLE_LINK_COMMAND,
                { url: ToolbarPlugginHelpers.sanitizeUrl(linkUrl), target: "_blank", rel: "noopener noreferrer" });

        setTimeout(() => {
            props.setIsLink(false);
        }, 50);
    }

    useEffect(() => {
        if (!isEditMode) {
            props.setIsLink(false);
            //$getSelection()?.getNodes()[0].
            // if (editor._rootElement)
            //     editor._rootElement.focus();
        }
    }, [isEditMode]);

    useEffect(() => {
        return () => { KeyboardService.lostFocus(elementKey); }
    }, []);

    // useEffect(() => {
    //     if (!isEditMode) {
    //         KeyboardService.lostFocus(editorRef);
    //         return;
    //     }

    //     KeyboardService.hasFocus(editorRef);
    //     return () => { KeyboardService.lostFocus(editorRef); }
    // }, [editorRef, isEditMode]);    

    return (
        <div ref={editorRef} className="link-editor"
            onClick={() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                    inputRef.current.select();
                }
            }}            >
            {/* {isEditMode ? ( */}
            <React.Fragment>
                <input
                    ref={inputRef}
                    className="link-input"
                    value={linkUrl}
                    onChange={(event) => {
                        setEditedLinkUrl(event.target.value);
                    }}
                    onKeyDown={monitorInputInteraction}

                    onFocus={() => {
                        KeyboardService.hasFocus(elementKey);
                    }}
                    onBlur={() => {
                        KeyboardService.lostFocus(elementKey);
                    }}
                />
                <div>
                    <div
                        className="link-cancel"
                        role="button"
                        tabIndex={0}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={() => {
                            //props
                            //setIsLinkEditMode(false);
                            //if (linkUrl !== "") {
                            //editor.dispatchCommand(TOGGLE_LINK_COMMAND, ToolbarPlugginHelpers.sanitizeUrl(linkUrl));
                            //}
                            handleClosing();
                        }}
                    />
                    <div
                        className="link-confirm"
                        role="button"
                        tabIndex={0}
                        onMouseDown={(event) => event.preventDefault()}
                        onClick={handleLinkSubmission}
                    />
                </div>
            </React.Fragment>
            {/* // )
            // : (
            //     <>
            //         <div className="link-view">
            //             <a href={linkUrl} className="concept-link" target="_blank" rel="noopener noreferrer">
            //                 {linkUrl}
            //             </a>
            //             <div
            //                 className="link-edit"
            //                 role="button"
            //                 tabIndex={0}
            //                 onMouseDown={(event) => event.preventDefault()}
            //                 onClick={() => {
            //                     setIsLinkEditMode(true);
            //                 }}
            //             />
            //         </div>
            //     </>
            // )} */}
        </div>
    );
}