import { FeedbackFeatureModalComponent } from "../../notification/oneoffalert/FeedbackFeatureModalComponent";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";

export const VoyceFeedbackHandler = () => {
    return {
        modalSize: ModalSize.Xlarge,
        modalPosition: "modal-dialog-centered",
        modalContent: (
            <FeedbackFeatureModalComponent />
        )
    } as ModalDefinitionModel;
}