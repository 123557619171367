import VoyceFeedbackImg from "../../../../assets/images/app/voyce-feedback.png"

export const VoyceFeedbackSoonContent = () => {

    return (
        <div>
            <div className="d-flex flex-column justify-content-center align-items-center w-100 px-5" style={{ border: "0px solid black" }}>
                <div className="pt-2 fw-xlarge-400 text-center" style={{ border: "0px solid black" }}>
                    <div>COMING SOON</div>
                    <div className="fw-xxlarge-900">Your own private customer feedback channel.</div>
                </div>
                <div className="pt-3">
                    <img src={VoyceFeedbackImg} style={{ width: "100%", height: "auto" }} alt="Voyce Feedback coming soon." />
                </div>
                <div className="pt-5 fw-med-400 px-5" style={{ border: "0px solid black" }}>
                    <div className="text-center" style={{ maxWidth: "910px" }}>
                        <div>Start collecting collect meaningful customer feedback about your product or service.</div>
                        <div>All feedback arrives neatly into Voyce Insights where you can, discuss, organize, & triage.</div>
                    </div>
                </div>
                <div className="pt-4 px-5" style={{ maxWidth: "650px", border: "0px solid black" }}>
                    <div className="pt-3">
                        <div className="fw-reg-500">Zero Setup</div>
                        <div>Activating your online Voyce Feedback Form is as simple as flicking a switch to activate it. Link to it from anywhere you want - end of checkouts, emails, social media... anywhere!</div>
                    </div>
                    <div className="pt-3">
                        <div className="fw-reg-500">Get valuable insights on what your customers love.</div>
                        <div>Understand what things your customers love about your product or service so you can double down on those things.</div>
                    </div>
                    <div className="pt-3">
                        <div className="fw-reg-500">Discover new opportunities..</div>
                        <div>Start to discover patterns on what customers want so you can convert these to problems, and then solutions.</div>
                    </div>
                    <div className="pt-3">
                        <div className="fw-reg-500">React to customer pain points... fast.</div>
                        <div>Quickly view and manage your customer feedback so you can react early and before things escalate to other online review platforms and keep them happy!</div>
                    </div>
                    <div className="pt-3">
                        <div className="fw-reg-500">The ‘Magic Question’.</div>
                        <div>Through years of performing customer research ourselves, we have discovered one question provides A) the most number of responses, and B) an answer that is meaningful and provides a real insight into how your business can improve.</div>
                    </div>
                </div>
            </div>
        </div >
    )
}