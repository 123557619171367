import { useEffect, useRef, useState } from "react";
import { ConceptAttachmentStore, ConceptAttachmentStoreData } from "../../../services/stores/ConceptAttachment/ConceptAttachmentStore";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { AttachmentDataModel, AttachmentType } from "../../../models/data/AttachmentDataModel";
import { AreYouSureLinkComponent } from "../../notification/AreYouSureLinkComponent";
import { AttachmentIconComponent } from "./AttachmentIconComponent";
import { SecureImagePreview } from "./SecureImagePreview";
import { SecureDownloaderHandler } from "./SecureDownloader";
import { useAuth } from "react-oidc-context";

interface AttachmentDisplayComponentProps {
    attachmentStore: ConceptAttachmentStore;
}

export const AttachmentDisplayComponent = (props: AttachmentDisplayComponentProps) => {

    const [attachmentList, setAttachmentList] = useState([] as AttachmentDataModel[]);
    const [downloaderHandler, setDownloaderHandler] = useState(new SecureDownloaderHandler());
    const auth = useAuth();

    const downloadSaveRef = useRef<HTMLAnchorElement>(null);

    useEffect(() => {

        const newDownloader = new SecureDownloaderHandler();
        downloaderHandler.dispose();

        setDownloaderHandler(newDownloader);

        return () => {
            if (newDownloader) {
                newDownloader.dispose();
            }
        }

    }, []);

    useEffect(() => {

        const hubContext = MessageHubContext()
            .Subscribe(props.attachmentStore, (attachmentData: ConceptAttachmentStoreData) => {
                const attList = attachmentData.attachmentList.filter(a => a.attachmentType === AttachmentType.Attachment);
                setAttachmentList([...attList]);
            });

        return () => hubContext.Dispose();

    }, [props.attachmentStore]);

    const OnDeleteAttachment = async (attachment: AttachmentDataModel) => {

        props.attachmentStore.deleteAttachment(attachment);
    }

    const OnAttachmentDownload = async (attachment: AttachmentDataModel) => {
        if (downloadSaveRef.current) {
            const blobUrl = await downloaderHandler.secureDownload(attachment.uploadFileName, auth.user?.access_token ?? "");

            if (blobUrl) {
                downloadSaveRef.current.href = blobUrl;
                downloadSaveRef.current.download = attachment.displayName

                downloadSaveRef.current.click();

                const timeout = setTimeout(function () {
                    if (downloadSaveRef.current && blobUrl) {
                        URL.revokeObjectURL(blobUrl);
                        downloadSaveRef.current.href = "";

                        if (timeout) {
                            clearTimeout(timeout);
                        }
                    }
                }, 10);
            }
        }
    }


    return (
        <div className="attachment-section">
            {
                attachmentList.map((attachment: AttachmentDataModel) => {

                    const isImageAttachment = ["jpg", "png", "webp"].indexOf(attachment.fileExtension.toLowerCase()) > -1;

                    return (
                        <div key={attachment.attachmentID} className="fw-small-400 mt-1 attachment-item">

                            <div role="button" className="me-2" onClick={async () => { await OnAttachmentDownload(attachment) }}>
                                {
                                    (isImageAttachment) &&
                                    <SecureImagePreview imagePartialUrl={attachment.uploadFileName} savingFileName={attachment.displayName} />
                                }
                                {
                                    (!isImageAttachment) &&
                                    <div className="attachment-icon">
                                        <AttachmentIconComponent fileExtension={attachment.fileExtension} />
                                    </div>
                                }
                            </div>

                            <div>
                                <div role="button" onClick={async () => { await OnAttachmentDownload(attachment) }} className="concept-link fw-dark fw-small-500">{attachment.displayName}</div>
                                <div className="link-highlight">
                                    <AreYouSureLinkComponent
                                        OnConfirmAction={async () => { await OnDeleteAttachment(attachment); }} />
                                </div>
                            </div>
                        </div>
                    )
                })
            }

            <a ref={downloadSaveRef} href="" target="_blank" rel="noreferrer" style={{ display: "none" }}>Download Placeholder</a>
        </div >
    )
}