import { ConceptType } from "../../../models/common/CommonEnums";
import { FolderDeleteAction } from "../../../models/communication/actions/FolderDeleteAction";
import { IConfirmationService } from "../../../models/communication/internal/ConfirmNotificationModel";
import { FolderDataModel } from "../../../models/data/FolderDataModel";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { MessageService } from "../../messaging/MessageService";
import { FolderStore } from "./FolderStore";

export class FolderDeleteConfirmService implements IConfirmationService {
    folder: FolderDataModel;
    conceptTypeToReload: ConceptType;
    folderStore: FolderStore;

    constructor(folder: FolderDataModel, folderStore: FolderStore, conceptTypeToReload: ConceptType) {

        this.folder = folder;
        this.folderStore = folderStore;
        this.conceptTypeToReload = conceptTypeToReload;
    }

    GetConfirmHeader() {
        return "Are you sure?"
    }
    GetDescription() {
        return this.folder.folderName;        
    }

    GetDescriptionDetail() {
        return "Please confirm you want to delete this folder."
    }

    GetMessage() {

        if ((this.folder.conceptCount) && this.folder.conceptCount > 0) {
            return "This won't delete any cards in this folder - we'll just move them to 'Not in a folder'.";
        }

        return "";
    }

    async CompleteChanges() {
        await MessageService.sendMessage({
            messageType: MessageType.FolderDelete,
            requestData: {
                folderToBeDeleted: {
                    ...this.folder
                },
                conceptType: this.conceptTypeToReload
            } as FolderDeleteAction
        } as IServerMessage).then(() => {
            this.folderStore.selectAllConceptFolder();
        });
    }
}