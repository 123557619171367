import { memo, useEffect, useMemo, useRef } from "react";
import { LoadingComponent } from "../../pages/loading/LoadingPage";
import { IHubSubcription, MessageHubContext } from "../../services/notification/MessageHubHandler";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { MessageType } from "../../models/message/IServerMessage";
import { GlobalNotification } from "../../services/GlobalMessageHub";
import { debounce } from "lodash";
import { MessageHubOwner } from "../../services/messaging/MessageHubOwner";
import { CommonElements } from "../../models/CommonElements";

// export interface ModalLoadingNotification {
//     waitForMessage: MessageType;
// }

export const ModalLoadingComponent: React.FunctionComponent
    = memo(function ModalLoadingComponentImp() {

        const loadingRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const hubContext = MessageHubContext()
                .ListenGlobalNotification([GlobalNotifications.ModalLoading], (notification: GlobalNotification) => {                    

                    if (loadingRef.current) {
                        loadingRef.current.classList.remove("d-none");
                    }
                })
                .ListenMessage(MessageHubOwner, {
                    MessageType: MessageType.ConceptCreated,
                    OnReceive: () => {

                        debouncedWaitForHandler();
                    }
                } as IHubSubcription);
            ;


            return () => hubContext.Dispose();

        }, [loadingRef.current]);

        const debouncedWaitForHandler = useMemo(
            () => debounce(async () => {
                if (loadingRef.current) {
                    loadingRef.current.classList.add("d-none");
                }

                window.closeModal(CommonElements.ModalElement);
            }, 500)
            , [loadingRef.current]);

        useEffect(() => {
            return () => debouncedWaitForHandler.cancel();
        }, [debouncedWaitForHandler]);

        return (
            <div ref={loadingRef} className="processing-overlay d-none">
                <div className="d-flex justify-content-center h-100">
                    <LoadingComponent />
                </div>
            </div>
        )
    })