import { debounce } from "lodash";
import React, { useEffect, useMemo } from "react"
import { CommonElements } from "../../models/CommonElements";
import { GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { UserDismissibleStore, UserDismissibleType } from "../../services/stores/UserDismissibleStore";


export const PreReleaseComponent = () => {

    const debouncedChangeHandler = useMemo(
        () => debounce(async () => {

            GlobalNotificationHub.sendMessage(GlobalNotifications.PreRelease);
            window.openModal(CommonElements.ModalElement);
        }, 200)
        , []);

    useEffect(() => {
        return () => debouncedChangeHandler.cancel();
    }, [debouncedChangeHandler]);

    useEffect(() => {
        if (UserDismissibleStore.shouldDisplay(UserDismissibleType.PreRelease)) {
            debouncedChangeHandler();
        }
    }, []);


    return (
        <React.Fragment></React.Fragment>
    )
}

export const PreReleaseModalComponent = () => {

    const CloseNotification = () => {
        UserDismissibleStore.dismiss(UserDismissibleType.PreRelease);
    }

    return (
        <React.Fragment>
            <div className="modal-header border-0">
                <div className="d-flex w-100 px-3 pt-3">
                    <div className="flex-fill ps-1 fw-xlarge-700">
                        Voyce is in a pre-release state.
                    </div>
                    <div className="pt-2 ms-2 d-flex">
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                </div>
            </div>
            <div className="modal-body p-0 px-3 pb-3">
                <div className="p-3 d-flex flex-column">
                    <div>
                        Thanks for being one of the earliest users to get a peek into what we're building! 🕵️
                    </div>

                    <div className="pt-3">
                        <span className="fw-reg-700">Things are not 100% complete</span>, but they should be good enough to give you an idea of where we are heading. While we finish building out initial features, we hope you'll have a poke around and let us know where we can improve.
                    </div>
                    <div className="pt-3">
                        Basic screens for adding insights, defining problems, and documenting solutions are up, so those might be good to check out first. There are still a ton of things missing though (we're working on it!).
                    </div>
                    <div className="pt-3">
                        Key features coming soon:
                        <ul className="m-0">
                            <li>Card sorting</li>
                            <li>Adding teammates, sharing workspaces, and collaboration tools</li>
                            <li>Voyce feedback form</li>
                            <li>Image scribble</li>
                            <li>Sub folders, and tagging improvements</li>
                            <li>Image previews on cards</li>
                            <li>Account and profile management options</li>
                            <li>Keyboard shortcuts, and plenty of other UI improvements</li>
                        </ul>
                    </div>
                    <div className="pt-3">
                        We hope you enjoy using this early version of Voyce. Please email any feedback to <a href="mailto:hello@simplyvoyce.com?subject=Feedback+from+the+Voyce+app" target="_blank"  rel="noreferrer" className="concept-link">hello@simplyvoyce.com</a> - we'd love to hear from you.
                    </div>
                    <div className="pt-3">
                        <button type="button" className="btn btn-primary btn-concept btn-medium" data-bs-dismiss="modal" onClick={CloseNotification}>Got it - Let's go!</button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}