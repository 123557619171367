import React, { memo, useEffect, useRef, useState } from "react";
import { KeyboardCodes } from "../../models/common/CommonEnums"
import { IDataStore } from "../../models/common/IDataStore";
import { INotificationHub } from "../../models/common/INotificationHub";
import { HelperExtension } from "../../services/HelperExtension";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { KeyboardService } from "../../services/shortcut/KeyboardService";

export enum FieldType {
    Text,
    Email
}

export interface TextBoxComponentCustomization {
    fieldType: FieldType;
    numberOfRows: number;
}

export interface TextBoxComponentProps {
    fieldName: string;
    dataStore: IDataStore;

    maxLength: number;
    details?: TextBoxComponentCustomization;
    placeholder?: string;
    cssClass?: string;

    onCommit?: () => void;
    onCancel?: () => void;
    onBlur?: () => void;

    autoFocus?: boolean;
    autoFocusDelayed?: boolean;
}

// interface TextBoxComponentPropsState {
//     currentValue: string;
// }


export const TextBoxComponent: React.FunctionComponent<TextBoxComponentProps>
    = memo(function TextBoxImplementation({ fieldName, dataStore, maxLength, details, placeholder, cssClass, onCommit, onCancel, onBlur, autoFocus, autoFocusDelayed }) {

        const [elementKey] = useState(crypto.randomUUID());
        const [currentValue, setCurrentValue] = useState("");
        const textBoxRef = useRef<HTMLInputElement>(null);
        const textAreaRef = useRef<HTMLTextAreaElement>(null);
        const divTextAreaWrapper = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const hubContext = MessageHubContext()
                .Subscribe((dataStore as any) as INotificationHub, (conceptModel: any) => {

                    hubContext.Dispose();

                    const loadedValue = HelperExtension.GetPropertyValue(fieldName, conceptModel);
                    setCurrentValue(loadedValue);
                });


            return () => hubContext.Dispose();
        }, [])

        const handleKeyDown = (event: any) => {

            // Enter - they probably wanted to commit it.
            if (event.keyCode === KeyboardCodes.ENTER && !event.shiftKey) {
                if (onCommit) {
                    event.preventDefault();
                    event.stopPropagation();

                    onCommit();
                    setCurrentValue("");
                    return;
                }
            }

            if (event.keyCode === KeyboardCodes.ESCAPE) {
                event.preventDefault();
                event.stopPropagation();

                if (onCancel) {
                    onCancel();
                }
                else {
                    KeyboardService.delayedLostFocus(textBoxRef.current ?? textAreaRef.current);
                }

                return;
            }

            // Enforce max length
            if (event.target.value.length >= (maxLength || Infinity)) {

                // Allow CTRL+? keypresses, e.g. CTRL+A, CTRL+C, CTRL+V, CTRL+X
                const ctrlDown = event.ctrlKey || event.metaKey;

                if (ctrlDown) {
                    // Truncate (e.g. if pasting)
                    event.target.value = event.target.value.substring(0, maxLength);

                    return;
                }

                // If not delete, backspace, home, end or arrow keys then prevent keypress
                if (event.keyCode !== KeyboardCodes.DELETE &&
                    event.keyCode !== KeyboardCodes.BACKSPACE &&
                    event.keyCode !== KeyboardCodes.HOME &&
                    event.keyCode !== KeyboardCodes.END &&
                    event.keyCode !== KeyboardCodes.UP_ARROW &&
                    event.keyCode !== KeyboardCodes.DOWN_ARROW &&
                    event.keyCode !== KeyboardCodes.UP_ARROW &&
                    event.keyCode !== KeyboardCodes.LEFT_ARROW &&
                    event.keyCode !== KeyboardCodes.RIGHT_ARROW) {
                    event.preventDefault();
                    event.stopPropagation();

                    return;
                }
            }
        }

        useEffect(() => {

            if (!textAreaRef?.current || !divTextAreaWrapper.current) {
                return;
            }

            if (!textAreaRef || !textAreaRef.current) {
                return;
            }

            const cut = (36 * (details?.numberOfRows ?? 1) - 36);

            if (textAreaRef.current.scrollHeight <= cut) {
                return;
            }

            textAreaRef.current.style.height = "auto";
            const newValue = textAreaRef.current.scrollHeight;
            textAreaRef.current.style.height = (newValue) + "px";

            const wrapperSize = (newValue + 2) + "px";
            if (divTextAreaWrapper.current.style.height === wrapperSize) {
                return;
            }

            divTextAreaWrapper.current.style.height = wrapperSize;
        }, [currentValue]);

        useEffect(() => {
            return () => {
                KeyboardService.lostFocus(elementKey);
            }
        }, []);
        // useEffect(() => {

        //     if (textAreaRef?.current) {
        //         textAreaRef.current.focus();
        //         KeyboardService.hasFocus(textAreaRef);
        //     }
        //     else if (textBoxRef.current) {
        //         textBoxRef.current.focus();
        //         KeyboardService.hasFocus(textBoxRef);
        //     }

        //     return () => {
        //         KeyboardService.lostFocus(textAreaRef);
        //         KeyboardService.lostFocus(textBoxRef);
        //     }

        // }, []);

        useEffect(() => {
            if (autoFocus) {

                // need to wait for bootstrap modal opening transition
                const timeoutValue = autoFocusDelayed ? 500 : 50;
                const timeout = setTimeout(() => {

                    if (textAreaRef.current) {
                        textAreaRef.current.focus();
                    }

                    if (textBoxRef.current) {
                        textBoxRef.current.focus();
                    }
                }, timeoutValue);

                return () => clearTimeout(timeout);
            }

        }, [textBoxRef, textAreaRef, autoFocus, autoFocusDelayed]);

        const handleChangeTextArea = (event: React.FormEvent<HTMLTextAreaElement>) => {
            // Enforce max length
            if (event.currentTarget.value.length >= (maxLength || Infinity)) {
                // Truncate (e.g. if pasting)
                event.currentTarget.value = event.currentTarget.value.substring(0, maxLength);
            }

            if (currentValue === event.currentTarget.value) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }

            setCurrentValue(event.currentTarget.value);
            // this.setState({
            //     currentValue: event.currentTarget.value
            // });

            if (dataStore != null) {
                dataStore.sendMessage(fieldName, event.currentTarget.value);
            }
        }

        const handleChange = (event: React.FormEvent<HTMLInputElement>) => {
            // Enforce max length
            if (event.currentTarget.value.length >= (maxLength || Infinity)) {
                // Truncate (e.g. if pasting)
                event.currentTarget.value = event.currentTarget.value.substring(0, maxLength);
            }

            if (currentValue === event.currentTarget.value) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }

            setCurrentValue(event.currentTarget.value);
            // this.setState({
            //     currentValue: event.currentTarget.value
            // });

            if (dataStore != null) {
                dataStore.sendMessage(fieldName, event.currentTarget.value);
            }
        }

        if (details?.fieldType === FieldType.Text) {
            return (
                <div ref={divTextAreaWrapper}>
                    <textarea
                        ref={textAreaRef}
                        id={fieldName.replace(".", "")}
                        className="form-control text-effect no-resize"
                        onKeyDown={handleKeyDown}
                        //onChange={this.handleChange}
                        onChange={handleChangeTextArea}
                        value={currentValue}
                        spellCheck={true}
                        rows={details.numberOfRows}
                        placeholder={placeholder}
                        required
                        onFocus={() => {
                            KeyboardService.hasFocus(elementKey)
                        }}

                        onBlur={() => {
                            KeyboardService.lostFocus(elementKey);
                            if (onBlur) {
                                onBlur();
                            }
                        }}
                    />
                </div>
            )
        }

        const fieldType = details?.fieldType === FieldType.Email ? "email" : "text";

        return (
            // <input type="text" className="form-control" id="conceptTitle" aria-describedby="Title" value={this.state.currentValue} onChange={(e) => this.update(this.props.fieldName, e.target.value)} />            
            <React.Fragment>
                <input type={fieldType}
                    id={fieldName.replace(".", "")}
                    className={"form-control text-effect " + (cssClass ?? "")}

                    onKeyDown={handleKeyDown}


                    onChange={handleChange}
                    value={currentValue}
                    spellCheck={true}
                    placeholder={placeholder}
                    required
                    ref={textBoxRef}

                    onFocus={() => {
                        KeyboardService.hasFocus(elementKey);
                    }}

                    onBlur={() => {
                        KeyboardService.lostFocus(elementKey);

                        if (onBlur) {
                            onBlur();
                        }
                    }}
                />
                {/* <div className="invalid-feedback">
                    Please provide a valid city.
                </div> */}
            </React.Fragment>
        );

    });