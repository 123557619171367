import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $getSelection, $isRangeSelection } from "lexical";
import { $setBlocksType } from "@lexical/selection";
import { $createQuoteNode, } from "@lexical/rich-text";
import { $createCodeNode } from "@lexical/code";

interface ToolbarCustomComponentProps {
    blockType: string;
    desiredBlockType: string;
}
export const ToolbarCustomComponent = (props: ToolbarCustomComponentProps) => {
    const [editor] = useLexicalComposerContext();

    const formatQuote = () => {
        if (props.blockType !== "quote") {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createQuoteNode());
                }
            });
        }
    }

    const formatCode = () => {
        if (props.blockType !== "code") {
            editor.update(() => {
                const selection = $getSelection();

                if ($isRangeSelection(selection)) {
                    $setBlocksType(selection, () => $createCodeNode());
                }
            });
        }
    };

    const isActive = props.blockType === props.desiredBlockType;

    return (
        <button tabIndex={-1} className={"toolbar-item spaced " + (isActive ? "active" : "")} aria-label={"Format " + props.desiredBlockType}
            onClick={() => {

                if (props.desiredBlockType === "code") {
                    formatCode();
                }
                else if (props.desiredBlockType === "quote") {
                    formatQuote();
                }
            }}>
            <i className={"format " + props.desiredBlockType} />
        </button>
    )
}