interface FeedbackActionComponentProps {
    description: string;

    icon: string;
    iconWidth: string;
    iconHeight: string;

    onClick?: () => void;
}
export const FeedbackActionComponent = (props: FeedbackActionComponentProps) => {

    return (
        <div className="feedback-action p-3 mx-1 mt-2 justify-content-end" role="button" onClick={props.onClick}>
            <div>
                <svg className="p-none" width={props.iconWidth} height={props.iconHeight} role="img"><use xlinkHref={props.icon}></use></svg>
            </div>
            <div className="fw-small-700 fw-concept pt-1">
                {props.description}
            </div>
        </div>
    )
}