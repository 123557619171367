import { Link } from "react-router-dom";
import { ConceptType, GlobalNotifications } from "../../../models/common/CommonEnums";
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub";
import { GlobalStore } from "../../../services/stores/GlobalStore";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../../elements/SectionHeaderComponent";
import { HelperExtension } from "../../../services/HelperExtension";

interface ConceptShareComponentProps {
    conceptID: string;
    conceptType: ConceptType;
}

export const ConceptShareComponent = (props: ConceptShareComponentProps) => {
    const dropdownUID = crypto.randomUUID();
    const workspaceCode = GlobalStore.getWorkspaceCodeIfAvailable();

    let shareUrl =
        window.location.protocol + "//" + window.location.host + "/w/" + workspaceCode;
    switch (props.conceptType) {
        case ConceptType.Solutions:
            shareUrl += "/solutions/" + props.conceptID + "/";
            break
        case ConceptType.Problems:
            shareUrl += "/problems/" + props.conceptID + "/";
            break
        default:
            shareUrl += "/insights/" + props.conceptID + "/";
            break;
    }

    return (
        <div className="btn-group">
            <button id={dropdownUID} type="button" className={"btn btn-primary btn-concept-outline btn-mod w-100 "}
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false">
                <svg className="pe-none me-1" width="18" height="15" role="img" aria-label="Link"><use xlinkHref="#share-icon"></use></svg>
                Share
            </button>
            <div className="dropdown-menu bg-white dropdown-menu-end shadow drop-container" style={{ height: "270px", width: "550px" }}>
                <div className="bg-header text-white p-3 ps-4 rounded-top d-flex align-items-center">
                    <div>
                        <SectionHeaderComponent iconName="#share-icon"
                            header="Share" fontSizeStyle="fw-large-500"
                            iconSize={SectionHeaderIconSize.Regular} headerStyle="icon-bright" />

                    </div>
                    <div className="m-auto"></div>
                    <div><button type="button" className="btn-close btn-close-white" onClick={() => { global.window.closeCustomDropdown(dropdownUID); }}></button></div>
                </div>
                <div className="vc-editor d-flex flex-column justify-content-between p-3" >
                    <div className="fw-reg-700" style={{ paddingLeft: "2px" }}>
                        Share this {HelperExtension.GetConceptName(props.conceptType, 1).toLowerCase()}
                    </div>
                    <div className="d-flex pt-1">
                        <input type="text" className="form-control" readOnly={true} value={shareUrl} />
                        <button className="btn btn-outline-primary btn-concept-outline text-nowrap ms-2"
                            onClick={() => {
                                navigator.clipboard.writeText(shareUrl);
                                GlobalNotificationHub.sendMessage(GlobalNotifications.FeedbackUrlCopied);
                            }}
                        >Copy Link</button>
                    </div>
                    <div className="fw-small-500 pt-4" style={{ paddingLeft: "2px" }}>
                        Note: Only team members in this workspace can access this page.
                    </div>
                    <div className="pt-4 rounded-bottom">
                        <div>
                            <Link to={"/w/" + workspaceCode + "/team/"} target="_blank" rel="noreferrer"
                                className="btn btn-primary btn-concept">Invite new team members</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}