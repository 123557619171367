import { GlobalNotifications } from "../../../models/common/CommonEnums"
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub"
import problems from "../../../../assets/images/app/problems.svg"
import { CommonElements } from "../../../models/CommonElements"


export const ProblemEmptyContent = () => {

    const OnLinkClick = () => {
        GlobalNotificationHub.sendMessage(GlobalNotifications.AddNewConcept);
    }

    return (
        <div className="text-center w-100 pt-4 px-5">
            <div style={{ height: "280px" }}>
                <img src={problems} alt="Add a new problem" />
            </div>
            <div className="pt-4 px-5">
                Creating well-defined problem statements helps you deliver smarter ideas that offer real customer value.
                <span role="button" onClick={OnLinkClick} className="concept-link" data-bs-toggle="modal" data-bs-target={CommonElements.ModalElementTarget}> Add a new problem</span> and validate it by linking one or more insights, and then use it to create any number of ideas/solutions.
            </div>
        </div>

    )
}