import React from "react"
import { Link } from "react-router-dom";
import VoyceNotFound from "../../assets/images/app/voyce-not-found.svg"

function NotFoundPage() {
    return (
        <div className="d-flex flex-column justify-content-center main-content align-items-center" style={{marginLeft:"-80px", overflow: "hidden"}}>
            <div className="fw-xxlarge-900">Page not found</div>
            <p>
                <Link className="concept-link" to="/">Go back to the home page</Link>
            </p>
            <div className="pt-5">
                <img src={VoyceNotFound} style={{maxWidth:"464px", maxHeight:"426px"}} alt="Not found" />
            </div>

        </div>
    );
}

export default NotFoundPage;