import { memo } from "react";

export const LoadingComponent: React.FunctionComponent
    = memo(function LoadingComponentImp() {
        return (
            <div className="align-self-center d-flex flex-column">
                <span className="loader"></span>
            </div>
        )
    })

export const LoadingPage: React.FunctionComponent
    = memo(function LoadingPageImp() {
        return (
            <div className="my-auto d-flex loading-content justify-content-center">
                <LoadingComponent />
            </div>
        )
    })