import { ConceptType } from "../../models/common/CommonEnums";
import { ConceptDataUpdatedActionResponse } from "../../models/communication/actions/ConceptDataUpdatedAction";
import { ConceptDetailsLoadAction, ConceptDetailsLoadActionResponse } from "../../models/communication/actions/ConceptDetailsLoadAction";
import { ConceptDataModel, ConceptLinksSummaryModel, SortDataModel } from "../../models/ConceptDataModel";
import { ConceptLinkDataModel } from "../../models/data/ConceptLinkDataModel";
import { IServerMessage, MessageType } from "../../models/message/IServerMessage";
import { ConceptService } from "../ConceptService";
import { AppContextData } from "../context/AppContext";
import { LogCollector } from "../logger/LogCollector";
import { MessageHubOwner } from "../messaging/MessageHubOwner";
import { MessageService } from "../messaging/MessageService";
import { IHubSubcription, MessageHubContext, MessageHubHandler } from "../notification/MessageHubHandler";
import { ConceptAttachmentStore } from "../stores/ConceptAttachment/ConceptAttachmentStore";
import { ConceptEditorStore } from "../stores/ConceptEditorStore";
import { ConceptLinkStore } from "../stores/ConceptLink/ConceptLinkStore";
import { ConceptTagStore } from "../stores/Tag/ConceptTagStore";

export class ConceptEditorService {
    conceptEditorStore: ConceptEditorStore;
    tagStore: ConceptTagStore;
    messageHubContext?: MessageHubHandler;
    conceptLinkStore: ConceptLinkStore;
    conceptAttachmentStore: ConceptAttachmentStore;
    loadedConceptId?: string;

    constructor() {
        this.conceptEditorStore = new ConceptEditorStore();
        this.tagStore = new ConceptTagStore();
        this.conceptLinkStore = new ConceptLinkStore();
        this.conceptAttachmentStore = new ConceptAttachmentStore();

        this.onConceptDetailLoaded = this.onConceptDetailLoaded.bind(this);
        this.processPartialUpdate = this.processPartialUpdate.bind(this);

        this.messageHubContext = MessageHubContext()
            .ListenMessage(MessageHubOwner, { MessageType: MessageType.ConceptDetailLoaded, OnReceive: this.onConceptDetailLoaded } as IHubSubcription)
            .ListenMessage(MessageHubOwner, { MessageType: MessageType.ConceptDataUpdated, OnReceive: this.processPartialUpdate } as IHubSubcription);
    }

    processPartialUpdate(conceptValueUpdated: ConceptDataUpdatedActionResponse) {

        if (!conceptValueUpdated?.partialConceptData) {
            return;
        }

        this.conceptEditorStore.processPartialUpdate(conceptValueUpdated.partialConceptData);
    }

    initialize(conceptContext: AppContextData) {
        this.conceptEditorStore.initialize(conceptContext);

        this.tagStore.initNew(conceptContext.conceptType);
        this.tagStore.syncWithServer = false;

        this.conceptAttachmentStore.loadAttachments(this.conceptEditorStore.storageState.conceptID, []);
        this.conceptAttachmentStore.syncWithServer = false;
    }

    loadConcept(conceptID: string, useOnlineTagStore: boolean) {

        this.loadedConceptId = conceptID;

        if (useOnlineTagStore) {
            this.tagStore.syncWithServer = true;
            this.conceptAttachmentStore.syncWithServer = true;
        }
        else {
            this.tagStore.syncWithServer = false;
            this.conceptAttachmentStore.syncWithServer = false;
        }

        LogCollector.LogWarn("loadConcept");

        return MessageService.sendMessage({
            messageType: MessageType.ConceptDetailLoad,
            requestData: { conceptID } as ConceptDetailsLoadAction
        } as IServerMessage);

    }

    async onConceptDetailLoaded(conceptDetailLoadResponse: ConceptDetailsLoadActionResponse) {

        const concept = conceptDetailLoadResponse.conceptDetail;
        if (!concept) {
            return;
        }

        if (concept.conceptID !== this.loadedConceptId) {
            return;
        }

        //Loads the concept data first
        this.conceptEditorStore.loadConcept(concept);

        //const newTagStore = this.tagStore as ConceptTagStore;        
        this.tagStore.initialize(concept);
        this.conceptLinkStore.loadLinks(concept.conceptID, concept.conceptLinkList);

        this.conceptAttachmentStore.loadAttachments(concept.conceptID, concept.conceptAttachmentList ?? []);
        // this.conceptAttachmentStore.loadAttachments(concept.conceptID, [
        //      { attachmentID: "0def8b4b-5e3f-4b94-bd72-4f65603a4357", fileName: "test.jpg", fileContentType: "image/png", attachmentType: AttachmentType.Screenshot },
        //      { attachmentID: "442a382f-f261-4e5d-8487-657ea138ae02", fileName: "test.jpg", fileContentType: "image/png", attachmentType: AttachmentType.Screenshot },
        //      { attachmentID: "552c8715-dce2-4701-a253-097ba0037ffd", fileName: "test.jpg", fileContentType: "image/png", attachmentType: AttachmentType.Screenshot },
        // ] as AttachmentDataModel[])

        //this.conceptLinkStore.initialize(concept.conceptID);

    }

    // loadConcept2(context: AppContextData, conceptID: string, concept: ConceptDataModel) {

    //     if (this.messageHubContext) {
    //         this.messageHubContext.Dispose();
    //     }

    //     this.messageHubContext = MessageHubContext()
    //         .ListenMessage(MessageHubOwner, { MessageType: MessageType.ConceptLoaded, OnReceive: this.onConceptDetailLoaded } as IHubSubcription)

    //     const x = new ConceptTagStore({ conceptID: concept.conceptID });
    //     this.tagStore = x;
    //     this.conceptEditorStore.initialize(context);

    //     setTimeout(() => {
    //         // const x = new ConceptTagStore({ conceptID: concept.conceptID });
    //         x.initialize(concept.conceptType, concept.tagList);

    //         this.tagStore = x;

    //         this.conceptEditorStore.loadConcept(concept);
    //     }, 100);

    // }

    createSortPosition(currentContext: AppContextData) {

        if (ConceptService.storageState.data.length > 0) {

            const latestItem = ConceptService.storageState.data.reduce(function (prev, current) {
                return (prev.sortData.sortIndex > current.sortData.sortIndex) ? prev : current
            });

            // console.error(latestItem);
            // console.error(ConceptService.storageState.data);
            // .sort((a, b) => {
            //     if (a.displayIndex < b.displayIndex) {
            //         return Math.abs(a.displayIndex);
            //     }
            //     return -1;
            // })


            return {
                folderID: latestItem.sortData.folderID,
                sortIndex: Math.round(latestItem.sortData.sortIndex ?? 0) + 100
            } as SortDataModel;
        }

        if (currentContext.state.selectedFolder.folderID) {
            return {
                folderID: currentContext.state.selectedFolder.folderID,
                sortIndex: 0
            } as SortDataModel
        }

        return {
            sortIndex: 0
        } as SortDataModel;
    }

    async saveConcept(currentContext: AppContextData) {

        const tags = [...this.tagStore.storageState.selectedTags];

        const conceptSortData = this.createSortPosition(currentContext);

        //console.error(conceptSortData);

        const conceptLinks = this.conceptLinkStore.storageState
            .conceptLinkList
            .map((concept) => {
                return {
                    toConceptID: concept.conceptID,
                    toConceptType: concept.conceptType
                } as ConceptLinkDataModel
            });

        const attachmentList = [...this.conceptAttachmentStore.storageState.attachmentList, ...this.conceptAttachmentStore.storageState.deletedAttachmentList];

        const conceptToBeSaved = {
            ...this.conceptEditorStore.storageState,
            tagList: tags,
            conceptLinkList: conceptLinks,
            conceptAttachmentList: attachmentList,
            conceptLinkSummary: {
                insightsCount: conceptLinks.filter(x => x.toConceptType === ConceptType.Insights).length,
                problemsCount: conceptLinks.filter(x => x.toConceptType === ConceptType.Problems).length,
                solutionsCount: conceptLinks.filter(x => x.toConceptType === ConceptType.Solutions).length,
            } as ConceptLinksSummaryModel,

            sortData: conceptSortData
        } as ConceptDataModel;

        LogCollector.LogMessage(conceptToBeSaved);

        return ConceptService.createConcept(conceptToBeSaved);
    }



    async deleteConcept(conceptID: string) {

        const conceptToBeDeleted = this.conceptEditorStore.storageState;

        if (conceptToBeDeleted?.conceptID !== conceptID) {
            return;
        }

        return ConceptService.deleteConcept(conceptToBeDeleted);

    }

    async Dispose() {
        this.messageHubContext?.Dispose();
    }
}