import React from "react";
import { memo } from "react";
import { ConceptType } from "../../models/common/CommonEnums";
import { ConceptEditorService } from "../../services/concepts/ConceptEditorService";
import { useAppContext } from "../../services/context/AppContext";
import { TagRenameHandler } from "../../services/stores/Tag/TagRenameHandler";
import { InsightEditorComponent } from "./insights/InsightEditorComponent";
import { ProblemEditorComponent } from "./problems/ProblemEditorComponent";
import { SolutionEditorComponent } from "./solutions/SolutionEditorComponent";

export interface ConceptHandlerComponentV2Props {
    conceptEditorService: ConceptEditorService;
    isEditing: boolean;
}

export const ConceptEditorComponent: React.FunctionComponent<ConceptHandlerComponentV2Props>
    = memo(
        function ConceptEditor({ conceptEditorService, isEditing }) {

            const context = useAppContext();

            const RenderEditor = () => {
                if (context.conceptType === ConceptType.Problems) {

                    return <ProblemEditorComponent conceptEditorService={conceptEditorService} isEditing={isEditing} />
                }
                else if (context.conceptType === ConceptType.Solutions) {
                    return <SolutionEditorComponent conceptEditorService={conceptEditorService} isEditing={isEditing} />
                }

                return <InsightEditorComponent conceptEditorService={conceptEditorService} isEditing={isEditing} />
            }

            return (
                <React.Fragment>
                    <RenderEditor />
                    <TagRenameHandler tagStore={conceptEditorService.tagStore} />
                </React.Fragment>
            )
        }
    )