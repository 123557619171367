import { useEffect, useRef } from "react";
import React from "react";
import { SecureDownloader } from "./SecureDownloader";
import { LoadingComponent } from "../../../pages/loading/LoadingPage";

export interface SecureImageDisplayProps {
    imagePartialUrl: string;

    maxHeight?: string;
    maxWidth?: string;
    useSmallLoader?: boolean;
}

export const SecureImageDisplay = (props: SecureImageDisplayProps) => {

    const imageRef = useRef<HTMLImageElement>(null);
    const imageLoading = useRef<HTMLDivElement>(null);

    const OnImageDownloaded = (blobUrl: string) => {
        if (imageRef.current) {
            imageRef.current.src = blobUrl;
            imageRef.current.style.display = "";
        }

        if (imageLoading.current) {
            imageLoading.current.style.display = "none";
        }
    }

    useEffect(() => {
        if (imageRef.current) {
            imageRef.current.style.display = "none";
        }

        if (imageLoading.current) {
            imageLoading.current.style.display = "";
        }
    }, [props.imagePartialUrl]);

    return (
        <React.Fragment>
            <div ref={imageLoading} className={props.useSmallLoader ? "small-loader" : ""} >
                <LoadingComponent />
            </div>
            <img ref={imageRef} src="" alt="" style={{ display: "none", maxHeight: props.maxHeight, maxWidth: props.maxWidth }} />
            <SecureDownloader partialUrl={props.imagePartialUrl} OnDocumentLoaded={OnImageDownloaded} />
        </React.Fragment>
    )
}

export const SecureImageDisplayFullscreen = (props: SecureImageDisplayProps) => {

    return (
        <SecureImageDisplay imagePartialUrl={props.imagePartialUrl} maxWidth="100%" maxHeight="100%" />
    )
}