import React, { useRef } from "react";
import { useState } from "react";

interface AreYouSureLinkComponentProps {
    OnConfirmAction: () => Promise<void>;

    customDisplay?: JSX.Element;
}

export const AreYouSureLinkComponent = (props: AreYouSureLinkComponentProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownUID = crypto.randomUUID();
    const dropdownRef = useRef<HTMLDivElement>(null);

    const OnOpenDelete = () => {
        if (isOpen) {
            return;
        }

        if (!dropdownRef?.current) {
            return;
        }

        dropdownRef.current.addEventListener("hide.bs.dropdown", OnClosingDrop);
        setIsOpen(true);
    }

    const OnClosingDrop = () => {
        if (!dropdownRef?.current) {
            return;
        }
        dropdownRef.current.removeEventListener("hide.bs.dropdown", OnClosingDrop);
        setIsOpen(false);
    }


    const OnConfirm = async () => {
        global.window.closeCustomDropdown(dropdownUID);
        await props.OnConfirmAction();

    }

    const GetContent = () => {

        if (props.customDisplay) {
            return (
                <React.Fragment>
                    {props.customDisplay}
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                Delete
            </React.Fragment>
        );
    }


    return (
        <div ref={dropdownRef} className="btn-group">
            <div id={dropdownUID}
                tabIndex={-1}
                className="concept-link"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-expanded="false"
                onClick={OnOpenDelete}
                style={{ outline: "none" }}>
                <GetContent />
            </div>

            <div className="dropdown-menu shadow drop-container" style={{ height: "150px", width: "390px" }}>
                {
                    (isOpen) &&
                    <React.Fragment>
                        <div className="bg-header text-white p-3 ps-4 rounded-top d-flex align-items-center">
                            <div>
                                <div className="fw-large-500 ">
                                    Are you sure?
                                </div>
                            </div>
                            <div className="m-auto"></div>
                            <div><button type="button" className="btn-close btn-close-white" onClick={() => { global.window.closeCustomDropdown(dropdownUID); }}></button></div>
                        </div>
                        <div className="vc-editor d-flex justify-content-between p-3" >
                            <div className="flex-fill m-2">
                                <button type="button" className="btn btn-primary btn-concept w-100" onClick={OnConfirm}>Yes</button>
                            </div>
                            <div className="flex-fill m-2">
                                <button className="btn btn-outline-primary btn-concept-outline w-100" onClick={() => { global.window.closeCustomDropdown(dropdownUID); }}>No</button>
                            </div>
                        </div>
                    </React.Fragment>
                }
            </div>
        </div>
    )
}