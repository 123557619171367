import { Subject } from "rxjs";
import { GlobalNotifications } from "../models/common/CommonEnums";
import { INotificationHub } from "../models/common/INotificationHub";

export interface GlobalNotification {
    notificationType: GlobalNotifications;

    notificationData: any;
}

class GlobalMessageHub implements INotificationHub {
    conceptEditorSubject = new Subject();
    storageState: number = 1;

    // initialize() {
    //     this.storageState = 1;
    //     this.conceptEditorSubject.next(this.storageState);
    // }
    // createContext() {
    //     return new MessageHubHandler();
    // {
    //     componentSubscriptionList: []
    // } as IMessageHubHandler;
    //}

    subscribe(setState: any) {
        return this.conceptEditorSubject.subscribe(setState);
    };

    sendMessage(notificationType: GlobalNotifications) {
        this.sendMessageWithData(notificationType, null);
    }

    sendMessageWithData(notificationType: GlobalNotifications, notificationData: any) {

        this.storageState++;
        // Notifies everyone
        this.conceptEditorSubject.next({ notificationType: notificationType, notificationData: notificationData } as GlobalNotification);
    }

    queueDelayedAction(action: () => void) {
        const timeout = setTimeout(() => {
            action();

            clearTimeout(timeout);
        }, 500);
    }
}

export const GlobalNotificationHub: GlobalMessageHub = new GlobalMessageHub();