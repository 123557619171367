import React, { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { CommentDataModel } from "../../models/data/CommentDataModel";
import { CommentDataStoreComponent } from "../../services/comments/CommentDataStoreComponent";
import { CommentsDataStore } from "../../services/comments/CommentsDataStore";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../elements/SectionHeaderComponent";
import { FieldType, TextBoxComponent } from "../elements/TextBoxComponent"
import { CommentDisplayComponent } from "./CommentDisplayComponent";
import { CommonElements } from "../../models/CommonElements";
import { KeyboardContext, KeyboardService } from "../../services/shortcut/KeyboardService";

interface CommentComponentProps {
    conceptID: string;
}

export const CommentComponent = (props: CommentComponentProps) => {

    // const [messageList, setMessageList] = useState<CommentDataModel[]>([
    //     { byUserName: "Bob Jefferies", displayTime: "3 October 2021, 10:03PM", message: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." } as CommentDataModel,
    //     { byUserName: "Bob Jefferies", displayTime: "3 October 2021, 10:03PM", message: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." } as CommentDataModel,
    //     { byUserName: "Bob Jefferies", displayTime: "3 October 2021, 10:03PM", message: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." } as CommentDataModel,
    //     { byUserName: "Bob Jefferies", displayTime: "3 October 2021, 10:03PM", message: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." } as CommentDataModel,
    //     { byUserName: "Bob Jefferies", displayTime: "3 October 2021, 10:03PM", message: "Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum." } as CommentDataModel
    // ]);
    const auth = useAuth();
    const [messageList, setMessageList] = useState<CommentDataModel[]>([]);
    const [commentsStore, setCommentStore] = useState<CommentsDataStore>();

    const OnCommitMessage = () => {

        if (!auth.user?.profile.name) {
            return;
        }

        commentsStore?.Commit(props.conceptID, auth.user?.profile.sub.toLowerCase(), auth.user?.profile.name);
    }

    useEffect(() => {

        if (!commentsStore) {
            return;
        }

        const messageHubContext =
            MessageHubContext()
                .Subscribe(commentsStore, (commentList: CommentDataModel[]) => {
                    setMessageList(commentList);
                });

        return () => messageHubContext.Dispose();

    }, [commentsStore]);

    return (
        // <div></div>
        <div className="p-3" >
            <CommentDataStoreComponent key={props.conceptID} conceptID={props.conceptID} setConceptStore={setCommentStore} />
            <div>
                <SectionHeaderComponent iconName="#comment-icon" header={messageList.length + " Comments"} iconSize={SectionHeaderIconSize.Medium} />
            </div>
            {
                (commentsStore) && (
                    <React.Fragment>
                        <div className="pt-3">
                            <TextBoxComponent
                                fieldName="newcomment"
                                dataStore={commentsStore} maxLength={500} placeholder="Add a comment"
                                onCommit={OnCommitMessage}
                                onCancel={() => {
                                    if (KeyboardService.currentContext === KeyboardContext.DefaultView) {
                                        document.getElementById("newcomment")?.blur();
                                    }
                                    else {
                                        document.getElementById(CommonElements.ModalElement)?.focus();
                                    }
                                }}

                                details={{
                                    fieldType: FieldType.Text,
                                    numberOfRows: 1
                                }}
                            />
                        </div>

                        <div>
                            {
                                messageList.map(comment => {
                                    return <CommentDisplayComponent
                                        key={comment.conceptCommentID}
                                        currentUserID={auth.user?.profile.sub.toLowerCase() ?? ""}
                                        comment={comment} />
                                })
                            }
                        </div>
                    </React.Fragment>
                )
            }
        </div>
    )
}