import { Subject } from "rxjs";
import { ConceptType } from "../../models/common/CommonEnums";
import { IDataStore } from "../../models/common/IDataStore";
import { INotificationHub } from "../../models/common/INotificationHub";
import { ConceptAffect, ConceptComplexity, ConceptDataModel, ProblemDataModel, SolutionDataModel } from "../../models/ConceptDataModel";
import { FolderType } from "../../models/data/FolderDataModel";
import { AppContextData } from "../context/AppContext";
import { HelperExtension } from "../HelperExtension";

const conceptEditorInitialState = {
    savePending: true,

    title: "",
    description: ""

} as ConceptDataModel;

const conceptEditorInitialStateForSolution = {

    conceptEffort: ConceptComplexity.Low,
    conceptImpact: ConceptComplexity.NOTDEFINED

} as SolutionDataModel;

const conceptEditorInitialStateForProblems = {

    conceptSeverity: ConceptComplexity.Low,
    conceptPeopleAffected: ConceptAffect.Few

} as ProblemDataModel;

export class ConceptEditorStore implements IDataStore, INotificationHub {
    conceptEditorSubject = new Subject();
    storageState = conceptEditorInitialState;

    initialize(conceptContext: AppContextData) {
        this.storageState = {
            ...conceptEditorInitialState,
            conceptID: crypto.randomUUID(),
            conceptType: conceptContext.conceptType,
        };

        switch (conceptContext.conceptType) {
            case ConceptType.Solutions:
                this.storageState.solutionData = { ...conceptEditorInitialStateForSolution };
                break;
            case ConceptType.Problems:
                this.storageState.problemData = { ...conceptEditorInitialStateForProblems };
                break;
        }

        if (conceptContext.state.selectedFolder?.folderType === FolderType.CustomFolder) {
            this.storageState.folderList = [conceptContext.state.selectedFolder];
        }

        this.conceptEditorSubject.next(this.storageState);
    }

    loadConcept(concept: ConceptDataModel) {
        this.storageState = { ...concept };

        this.conceptEditorSubject.next(this.storageState);
    }

    subscribe(onMessageReceived: any) {
        return this.conceptEditorSubject.subscribe(onMessageReceived);
    };

    sendMessage(fieldName: string, fieldValue: string) {

        const fieldNames = fieldName.split(".");

        if (fieldNames.length === 1) {
            // Updates the temporary storage
            this.storageState = { ...this.storageState, [fieldName]: fieldValue };
        }
        else {
            // Updates the temporary storage
            const parent = fieldNames[0];
            const field = fieldNames[1];

            this.storageState = {
                ...this.storageState,
                [parent]: {
                    ...(this.storageState as any)[parent],
                    [field]: fieldValue
                }
            };
        }

        // Notifies everyone
        this.conceptEditorSubject.next(this.storageState);
    }

    sendMessageCustom(fieldName: string, fieldValue: any) {
        const fieldNames = fieldName.split(".");

        if (fieldNames.length === 1) {
            // Updates the temporary storage
            this.storageState = { ...this.storageState, [fieldName]: fieldValue };
        }
        else {
            // Updates the temporary storage
            const parent = fieldNames[0];
            const field = fieldNames[1];

            this.storageState = {
                ...this.storageState,
                [parent]: {
                    ...(this.storageState as any)[parent],
                    [field]: fieldValue
                }
            };
        }

        // Notifies everyone
        this.conceptEditorSubject.next(this.storageState);
    }

    //#partial-update
    processPartialUpdate(partialConceptData: ConceptDataModel) {

        if (this.storageState.conceptID === partialConceptData.conceptID) {

            this.storageState = HelperExtension.ProcessConceptPartialUpdate(this.storageState, partialConceptData);

            // Notifies everyone
            this.conceptEditorSubject.next(this.storageState);
        }
    }
}