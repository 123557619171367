import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import React, { useEffect, useState } from "react"
import { MessageHubContext } from "../../../../services/notification/MessageHubHandler";
import { IDataStore } from "../../../../models/common/IDataStore";
import { HelperExtension } from "../../../../services/HelperExtension";
import { INotificationHub } from "../../../../models/common/INotificationHub";
import { ConceptDataModel } from "../../../../models/ConceptDataModel";
import { $getRoot } from "lexical";

interface EditorLoaderPluginProps {
    fieldName: string;
    dataStore: IDataStore;
}

export const EditorLoaderPlugin = (props: EditorLoaderPluginProps) => {
    const [editor] = useLexicalComposerContext();
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {

        if (isLoaded) {
            return;
        }

        const hubContext = MessageHubContext()
            .Subscribe((props.dataStore as any) as INotificationHub, (concept: ConceptDataModel) => {

                const editorStateJSONString = HelperExtension.GetPropertyValue(props.fieldName, concept);
                hubContext.Dispose();

                if (editorStateJSONString) {
                    const editorState = editor.parseEditorState(editorStateJSONString);
                    editor.setEditorState(editorState);

                    editorState.read(() => {
                        const root = $getRoot();
                        const textContent = root.getTextContent();

                        props.dataStore.sendMessage(props.fieldName + "_unformatted", textContent);
                    })
                }

                setIsLoaded(true);
            });

        return () => hubContext.Dispose();
    }, [editor, isLoaded]);


    useEffect(() => {
        if (!isLoaded) {
            return;
        }
        // most listeners return a teardown function that can be called to clean them up.
        return editor.registerUpdateListener(({ editorState }) => {
            // call onChange here to pass the latest state up to the parent.
            //onChange(editorState);            

            const editorStateJSON = editorState.toJSON();
            const json = JSON.stringify(editorStateJSON);

            editorState.read(() => {
                const root = $getRoot();
                const textContent = root.getTextContent();

                props.dataStore.sendMessage(props.fieldName + "_unformatted", textContent);
            });
            //console.warn(editorState._nodeMap.get('root')?.__cachedText);
            //editor._nodes.get("").            
            props.dataStore.sendMessage(props.fieldName, json);
        });
    }, [editor, isLoaded]);


    return <React.Fragment></React.Fragment>
}