import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConceptType, PageType, VoyceQueryString } from "../../models/common/CommonEnums";
import { WorkspaceDataModel } from "../../models/data/WorkspaceDataModel";
import { MessageType } from "../../models/message/IServerMessage";
import { HelperExtension } from "../../services/HelperExtension";
import { MessageHubOwner } from "../../services/messaging/MessageHubOwner";
import { IHubSubcription, MessageHubContext } from "../../services/notification/MessageHubHandler";
import { GlobalStore } from "../../services/stores/GlobalStore";
import { TagCreateService } from "../../services/stores/Tag/TagCreateStore";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../elements/SectionHeaderComponent";
import { LoadingComponent } from "../../pages/loading/LoadingPage";
import { WorkspaceDisplayActionComponent } from "./WorkspaceDisplayActionComponent";
import { WorkspaceStore } from "../../services/stores/Workspace/WorkspaceStore";

interface WorkspaceDisplayProps {
    workspace: WorkspaceDataModel;
    workspaceStore?: WorkspaceStore;
    isSharedWorkspace: boolean;
}

export const WorkspaceDisplay = (props: WorkspaceDisplayProps) => {

    const navigate = useNavigate();
    const [isSaving, setIsSaving] = useState(props.workspace.savePending);
    const [workspaceCode, setWorkspaceCode] = useState(props.workspace.workspaceCode);

    // const NavigateToWorkspace = (workspace: WorkspaceDataModel, navigateTo: ConceptType) => {
    //     NavigateToWorkspaceInternal(workspace, navigateTo, undefined);
    // }

    const NavigateToWorkspace = (workspace: WorkspaceDataModel, navigateTo: PageType) => {
        NavigateToWorkspaceInternal(workspace, navigateTo, undefined);
    }

    const NavigateToWorkspaceInternal = (workspace: WorkspaceDataModel, navigateTo: PageType, queryString: string | undefined) => {

        new TagCreateService().clearTags();
        GlobalStore.setCurrentWorkspace(workspace);
        GlobalStore.workspaceLoaderStore.forceReload();

        switch (navigateTo) {
            case PageType.Insights:
                navigate("/w/" + workspaceCode + "/insights/" + (queryString ?? ""));
                break;
            case PageType.Problems:
                navigate("/w/" + workspaceCode + "/problems/" + (queryString ?? ""));
                break;
            case PageType.Solutions:
                navigate("/w/" + workspaceCode + "/solutions/" + (queryString ?? ""));
                break;
            case PageType.Dashboard:
                navigate("/w/" + workspaceCode + "/" + (queryString ?? ""));
                break;
        }
    }

    const NativateToWorkspaceTeam = (workspace: WorkspaceDataModel) => {
        new TagCreateService().clearTags();
        GlobalStore.setCurrentWorkspace(workspace);
        GlobalStore.workspaceLoaderStore.forceReload();

        navigate("/workspace/" + props.workspace.workspaceCode + "/team/");
    }


    useEffect(() => {

        const hubContext = MessageHubContext()
            .ListenMessage(MessageHubOwner, { MessageType: MessageType.WorkspaceSaved, OnReceive: OnWorkspaceUpdate } as IHubSubcription);


        return () => hubContext.Dispose();
    });

    const OnWorkspaceUpdate = (messageData: WorkspaceDataModel) => {

        if (messageData.workspaceID === props.workspace.workspaceID) {

            props.workspace.savePending = false;
            props.workspace.workspaceCode = messageData.workspaceCode;

            setIsSaving(false);
            setWorkspaceCode(messageData.workspaceCode);
        }
    };

    const GetWorkspaceConceptCount = (conceptCount: number) => {
        if (!conceptCount || conceptCount === 0) {
            return "";
        }

        return String(conceptCount) + " ";
    }


    return (
        <div className={"workspace-display shadow-sm m-2 " + (isSaving && " concept-saving")} style={{ position: "relative" }}>
            {
                (isSaving) &&
                <div className="workspace-saving processing-overlay">
                    <div className="d-flex align-items-center justify-content-center w-100 h-100">
                        <LoadingComponent />
                    </div>
                </div>
            }
            <div>
                <div className="d-flex justify-content-between align-items-start">
                    <div className="w-100">

                        <WorkspaceDisplayActionComponent
                            workspace={props.workspace}
                            workspaceStore={props.workspaceStore}
                            onNavigateToWorkspace={NavigateToWorkspace}
                            isSharedWorkspace={props.isSharedWorkspace} />

                        <div className="pt-2">
                            <div className="counter-section fw-small-400">
                                <div className="fw-insight">
                                    <div className="sz-fit" role="button" onClick={() => { NavigateToWorkspaceInternal(props.workspace, PageType.Insights, `?${VoyceQueryString.SpecialFolder}=${VoyceQueryString.FeedbackFolder}`); }}>
                                        <span className="fw-small-500">{GetWorkspaceConceptCount(props.workspace.feedbackCount)}</span>
                                        <span>Voyce Feedback</span>
                                    </div>
                                </div>
                                <div className="fw-insight" role="button" onClick={() => NavigateToWorkspace(props.workspace, PageType.Insights)}>
                                    <span className="fw-small-500">{GetWorkspaceConceptCount(props.workspace.insightCount)}</span>
                                    <span>{HelperExtension.GetConceptName(ConceptType.Insights, props.workspace.insightCount)}</span>
                                </div>
                                <div className="fw-problem" role="button" onClick={() => NavigateToWorkspace(props.workspace, PageType.Problems)}>
                                    <span className="fw-small-500">{GetWorkspaceConceptCount(props.workspace.problemCount)}</span>
                                    <span>{HelperExtension.GetConceptName(ConceptType.Problems, props.workspace.problemCount)}</span>
                                </div>
                                <div className="fw-solution" role="button" onClick={() => NavigateToWorkspace(props.workspace, PageType.Solutions)}>
                                    <span className="fw-small-500">{GetWorkspaceConceptCount(props.workspace.solutionCount)}</span>
                                    <span>{HelperExtension.GetConceptName(ConceptType.Solutions, props.workspace.solutionCount)}</span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="d-flex row px-1 pt-3">
                            <div className="col wd-group wd-insight" role="button"
                                onClick={() => NavigateToWorkspace(props.workspace, PageType.Insights)}>
                                <SectionHeaderComponent
                                    header={GetWorkspaceConceptCount(props.workspace.insightCount) + HelperExtension.GetConceptName(ConceptType.Insights, props.workspace.insightCount)}
                                    iconName={HelperExtension.GetConceptIcon(ConceptType.Insights)}
                                    headerStyle="fw-insight insight-icon"
                                    fontSizeStyle="fw-med-500"
                                    iconSize={SectionHeaderIconSize.Medium} />
                            </div>
                            <div className="col wd-group wd-problem" role="button"
                                onClick={() => NavigateToWorkspace(props.workspace, PageType.Problems)}>
                                <SectionHeaderComponent
                                    header={GetWorkspaceConceptCount(props.workspace.problemCount) + HelperExtension.GetConceptName(ConceptType.Problems, props.workspace.problemCount)}
                                    iconName={HelperExtension.GetConceptIcon(ConceptType.Problems)}
                                    headerStyle="fw-problem problem-icon"
                                    fontSizeStyle="fw-med-500"
                                    iconSize={SectionHeaderIconSize.Medium} />
                            </div>
                            <div className="col wd-group wd-solution" role="button"
                                onClick={() => NavigateToWorkspace(props.workspace, PageType.Solutions)}>
                                <SectionHeaderComponent
                                    header={GetWorkspaceConceptCount(props.workspace.solutionCount) + HelperExtension.GetConceptName(ConceptType.Solutions, props.workspace.solutionCount)}
                                    iconName={HelperExtension.GetConceptIcon(ConceptType.Solutions)}
                                    headerStyle="fw-solution solution-icon"
                                    fontSizeStyle="fw-med-500"
                                    iconSize={SectionHeaderIconSize.Medium} />
                            </div>
                        </div> */}
                        <div className="mt-2 wk-team-section d-flex align-items-center">
                            <div className="ps-2 py-1 flex-fill"
                                role="button" onClick={() => {
                                    NativateToWorkspaceTeam(props.workspace);
                                }}>
                                <SectionHeaderComponent
                                    header={HelperExtension.GetTeamSize(props.workspace.workspaceTeamSize)}
                                    iconName="#team-icon"
                                    headerStyle="fw-secondary grey-icon"
                                    fontSizeStyle="fw-small-500"
                                    iconSize={SectionHeaderIconSize.Medium} />
                            </div>
                            <div className="pe-2 py-1">
                                <button className="btn btn-xsmall btn-custom-light"
                                    onClick={() => {
                                        NativateToWorkspaceTeam(props.workspace);
                                    }}>
                                    Invite team members
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}