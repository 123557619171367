import { FeedbackSettingsNotificationModel } from "../../../models/communication/internal/FeedbackSettingsNotificationModel";
import { FeedbackEditorComponent } from "../../feedback/FeedbackEditorComponent";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";

export const FeedbackEditorHandler = (notification: FeedbackSettingsNotificationModel) => {
    return {
        modalSize: ModalSize.Xlarge,
        modalPosition: "modal-dialog-centered",
        modalContent: (
            <FeedbackEditorComponent feedbackStore={notification.feedbackStore} />
        )
    } as ModalDefinitionModel;

}