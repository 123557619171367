import { useEffect, useState } from "react"
import { CardDisplaySmallComponent } from "../../../components/card/CardDisplaySmallComponent"
import { ConceptDataModel } from "../../../models/ConceptDataModel"
import { ConceptType, GlobalNotifications } from "../../../models/common/CommonEnums"
import { DashboardStore, DashboardStoreComponent, DashboardStoreState, DashboardStoreType } from "../../../services/stores/Dashboard/DashboardStore"
import { MessageHubContext } from "../../../services/notification/MessageHubHandler"
import { LoadingComponent } from "../../loading/LoadingPage"
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub"
import { ConceptViewerNotificationModel } from "../../../models/communication/internal/ConceptViewerNotificationModel"

export const RecentEventsConcept = () => {

    const [recentDashboardStore, setRecentDashboardStore] = useState<DashboardStore>();
    const [recentConceptList, setRecentConceptList] = useState<ConceptDataModel[]>();


    useEffect(() => {
        if (!recentDashboardStore) {
            return;
        }

        const hubContext = MessageHubContext()
            .Subscribe(recentDashboardStore, (storeState: DashboardStoreState) => {

                if (!storeState.recentConceptList) {
                    return;
                }
                setRecentConceptList(storeState.recentConceptList);
            });


        return () => hubContext.Dispose();

    }, [recentDashboardStore])

    return (
        <div>

            {
                (!recentConceptList) &&
                <div className="d-flex w-100 justify-content-center my-3">
                    <LoadingComponent />
                </div>
            }
            {
                (recentConceptList && recentConceptList.length === 0) &&
                <div className="fw-secondary fw-i">
                    When insights, problems or solutions are added, we'll email you a notification and display them here.
                </div>
            }
            {
                (recentConceptList) &&
                recentConceptList.map((concept: ConceptDataModel) => {
                    let theme = "";
                    if (concept.conceptType === ConceptType.Problems) {
                        theme = "problem";
                    }
                    else if (concept.conceptType === ConceptType.Solutions) {
                        theme = "solution";
                    }
                    return (
                        <div key={concept.conceptID} data-theme={theme} className="dashboard-card-override">
                            <CardDisplaySmallComponent
                                conceptModel={concept}
                                onLinkClick={async () => { }}
                                isLinked={false}
                                isEditing={false}
                                enableTitleClick={true}
                                onTitleClick={async (conceptData: ConceptDataModel) => {
                                    //window.open(HelperExtension.GetConceptUrl(conceptData), "_blank")
                                    GlobalNotificationHub.sendMessageWithData(GlobalNotifications.ViewConcept, {
                                        conceptID: conceptData.conceptID,
                                        conceptType: conceptData.conceptType
                                    } as ConceptViewerNotificationModel);

                                    // setTimeout(() => {
                                    //     global.window.openModal(CommonElements.ModalElement);
                                    // }, 100);
                                }}
                            />
                        </div>
                    )
                })
            }

            <DashboardStoreComponent storeType={DashboardStoreType.RecentConcepts} onStoreCreated={(store) => { setRecentDashboardStore(store); }} />
        </div>
    )
}