import React from "react";
import { memo, useEffect, useState } from "react";
import { ConceptType } from "../../models/common/CommonEnums";
import { ConceptDataModel } from "../../models/ConceptDataModel";
import { HelperExtension } from "../../services/HelperExtension";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { ConceptLinkStore, ConceptLinkStoreData } from "../../services/stores/ConceptLink/ConceptLinkStore";
import { GlobalStore } from "../../services/stores/GlobalStore";
import { CardDisplayLoaderComponent } from "../card/CardDisplayLoaderComponent";
import { CardDisplaySmallComponent } from "../card/CardDisplaySmallComponent";

interface ConceptLinkViewerComponentProps {
    conceptLinkStore: ConceptLinkStore;
    conceptType: ConceptType;
    isEditing: boolean;
    useNormalCard?: boolean;
    displayAttachedMessage?: boolean;
}

interface WrapConceptModel {
    conceptItem: ConceptDataModel;
}

export const ConceptLinkViewerComponent: React.FunctionComponent<ConceptLinkViewerComponentProps>
    = memo(function ConceptLinkViewer({ conceptLinkStore, conceptType, isEditing, useNormalCard, displayAttachedMessage }) {

        const [linkedConcepts, setLinkedConcepts] = useState([] as ConceptDataModel[]);

        useEffect(() => {
            const messageHubContext = MessageHubContext()
                .Subscribe(conceptLinkStore, (conceptLink: ConceptLinkStoreData) => {
                    setLinkedConcepts(conceptLink.conceptLinkList);
                });

            conceptLinkStore.initialize();

            return () => messageHubContext.Dispose();
        }, []);

        const ConceptLinkSmall = (concept: WrapConceptModel) => {
            return (
                <div>
                    <CardDisplaySmallComponent
                        conceptModel={concept.conceptItem}
                        isLinked={true}
                        isEditing={isEditing}
                        onLinkClick={async () => { await conceptLinkStore.toggleLink(concept.conceptItem) }}

                        enableTitleClick={true}
                        onTitleClick={async (concept: ConceptDataModel) => {

                            switch (concept.conceptType) {
                                case ConceptType.Insights:
                                    window.open("/w/" + GlobalStore.getWorkspaceCodeIfAvailable() + "/insights/" + concept.conceptID + "/", "_blank");
                                    break;
                                case ConceptType.Problems:
                                    window.open("/w/" + GlobalStore.getWorkspaceCodeIfAvailable() + "/problems/" + concept.conceptID + "/", "_blank");
                                    break;
                                case ConceptType.Solutions:
                                    window.open("/w/" + GlobalStore.getWorkspaceCodeIfAvailable() + "/solutions/" + concept.conceptID + "/", "_blank");
                                    break;
                            }
                        }}

                    />
                </div>)
        }

        const ConceptLinkNormal = (concept: WrapConceptModel) => {
            const onUnlinkCLick = isEditing ? async () => {
                await conceptLinkStore.toggleLink(concept.conceptItem)
            } : undefined;

            return (
                <div className="concept-link-item-expand w-100 concept-link-viewer pt-2 voyce-display-mod">
                    <CardDisplayLoaderComponent
                        conceptPartialModel={concept.conceptItem}
                        onLinkClick={onUnlinkCLick}
                        disableDefaultAction={true}
                    />
                </div>)
        }

        const RenderAttachmentHeader = (links: ConceptDataModel[]) => {
            if (links.length === 0) {
                return <React.Fragment></React.Fragment>
            }

            return displayAttachedMessage
                ? (<div className="w-100 py-1 fw-small-500">Attached {HelperExtension.GetConceptName(conceptType, links.length).toLocaleLowerCase()}</div>)
                : (<div className="w-100 pt-2"></div>);
        }


        const conceptsToRender = linkedConcepts.filter(link => link.conceptType === conceptType);

        return (
            <div className="d-flex flex-wrap justify-content-between">
                {
                    RenderAttachmentHeader(conceptsToRender)
                }
                <div className="concept-link-container">
                    {
                        conceptsToRender.filter(link => link.conceptType === conceptType).map((conceptItem) => {
                            return (
                                useNormalCard
                                    ? <ConceptLinkNormal key={conceptItem.conceptID} conceptItem={conceptItem} />
                                    : <ConceptLinkSmall key={conceptItem.conceptID} conceptItem={conceptItem} />
                            );
                        })
                    }
                </div>
            </div>
        );
    })