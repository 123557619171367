import React, { memo, useEffect, useState } from "react";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { ConceptTagStore, ConceptTagStoreModel } from "../../../services/stores/Tag/ConceptTagStore";
import { TagFilterComponentV2 } from "../Tags/TagFilterComponent";

interface ConceptFilterComponentPropsV2 {
    searchTagStore: ConceptTagStore;
}


export const ConceptFilterComponentV2: React.FunctionComponent<ConceptFilterComponentPropsV2>
    = memo(function ({ searchTagStore }) {

        const [isResetVisible, setIsResetVisible] = useState(false);

        useEffect(() => {

            const messageHubContext = MessageHubContext()
                .Subscribe(searchTagStore, OnConceptTagsUpdate);

            return () => messageHubContext.Dispose();
        }, [searchTagStore]);

        const OnConceptTagsUpdate = (tagUpdate: ConceptTagStoreModel) => {
            const selectedTags = tagUpdate.selectedTags;
            setIsResetVisible(selectedTags.length > 0)
        }

        const OnReset = () => {
            searchTagStore.resetSelection();
        }

        return (
            <React.Fragment>
                <TagFilterComponentV2 searchTagStore={searchTagStore} />
                {
                    (isResetVisible) &&
                    (
                        <div>
                            <button type="button" className="btn btn-concept-outline btn-xsmall ms-3" onClick={OnReset}>Reset</button>
                        </div>
                    )
                }
            </React.Fragment>
        );
    });