import { IConfirmationService } from "../../../models/communication/internal/ConfirmNotificationModel";
import { WorkspaceDataModel } from "../../../models/data/WorkspaceDataModel";
import { WorkspaceStore } from "./WorkspaceStore";

export class WorkspaceDeleteService implements IConfirmationService {
    workspaceStore: WorkspaceStore;
    workspace: WorkspaceDataModel;

    constructor(workspaceStore: WorkspaceStore, workspace: WorkspaceDataModel) {

        this.workspaceStore = workspaceStore;
        this.workspace = workspace;
    }

    GetConfirmHeader() {
        return "Are you sure?";
    };

    GetDescription() {
        return this.workspace.workspaceName;
    }

    GetDescriptionDetail() {
        return "Please confirm you want to delete this workspace."
    }

    GetMessage() {
        if (!this.workspace.workspaceTeamSize) {
            return ""
        }

        if (this.workspace.workspaceTeamSize === 1) {
            return "";
        }

        if (this.workspace.workspaceTeamSize === 2) {
            return "1 team member who shares this workspace will also lose access.";
        }

        return (this.workspace.workspaceTeamSize - 1) +" team members who share this workspace will also lose access.";
    }

    async CompleteChanges() {
        return this.workspaceStore.deleteWorkspace(this.workspace.workspaceID);
    }
}