import React, { useEffect, useState } from "react";
import { ConceptDataModel } from "../../models/ConceptDataModel";
import { FolderDataModel } from "../../models/data/FolderDataModel";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { ConceptEditorStore } from "../../services/stores/ConceptEditorStore";

export interface FolderSelectionComponentProps {
    dataStore: ConceptEditorStore;
}

export const FolderSelectionComponent = (props: FolderSelectionComponentProps) => {

    const [folderList, setFolderList] = useState<FolderDataModel[]>();
    //const defaultFolderList = props.dataStore.storageState.folderList;

    useEffect(() => {

        const hubContext = MessageHubContext()
            .Subscribe(props.dataStore, (concept: ConceptDataModel) => {
                hubContext.Dispose();
                setFolderList(concept.folderList);
            });

        return () => hubContext.Dispose();
    }, [])

    const RenderFolderList = () => {

        if (folderList && folderList.length > 0) {

            return (<React.Fragment>
                {
                    folderList.map(folder => {
                        return (
                            <div key={folder.folderID} className="btn btn-light btn-xsmall btn-folder me-1" >{folder.folderName}</div>
                        )

                    })
                }
            </React.Fragment>
            )
        }


        return (<div className="btn btn-light btn-xsmall btn-folder">Folder not selected</div>);

    }


    return (
        <div className="vc-actions d-flex align-items-center">
            <div className="me-2 align-items-start">
                <svg className="pe-none icon-color" width="16" height="17" role="img" aria-label="Home"><use xlinkHref="#folder-icon"></use></svg>
            </div>

            <RenderFolderList />
        </div>
    );
}