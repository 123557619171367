import React, { RefObject, useEffect } from "react";
import { PropsWithChildren } from "react";
import { CommonElements } from "../../models/CommonElements";
import { LogCollector } from "../logger/LogCollector";

export enum KeyboardContext {
    DefaultView = 0,
    ModalViewer = 1,
    ConceptFullscreen = 2,
    ScreenshotViewer = 3,
    ImageScribbleViewer = 4
}

class KeyboardServiceImplementation {
    currentContext: KeyboardContext = KeyboardContext.DefaultView;
    //elementWithFocus: RefObject<HTMLElement>[] = [];
    elementWithFocusList: string[] = [];

    constructor() {
        this.handleDropClosing = this.handleDropClosing.bind(this);
    }    

    lostFocus(elementKey: string) {
        //const c = o as RefObject<HTMLElement>;
        var index = this.elementWithFocusList.indexOf(elementKey);
        if (index > -1) {
            this.elementWithFocusList.splice(index, 1);
        }
    }

    hasFocus(elementKey: string) {
        // const c = o as RefObject<HTMLElement>;
        // if (!c.current) {
        //     return;
        // }

        var index = this.elementWithFocusList.indexOf(elementKey);
        if (index === -1) {
            this.elementWithFocusList.push(elementKey);
        }
    }

    attachAutoClose(element: RefObject<HTMLButtonElement>) {

        if (!element.current) {
            return;
        }

        element.current.addEventListener("hide.bs.dropdown", this.handleDropClosing);
    }

    attachAutoCloseForModal(element: React.RefObject<HTMLDivElement>) {
        if (!element.current) {
            return;
        }

        element.current.addEventListener("hide.bs.modal", this.handleDropClosing);
    }

    release(element: React.RefObject<HTMLButtonElement>) {
        if (!element.current) {
            return;
        }

        element.current.removeEventListener("hide.bs.dropdown", this.handleDropClosing);
        element.current.removeEventListener("hide.bs.modal", this.handleDropClosing);
    }

    releaseModal(element: React.RefObject<HTMLDivElement>) {
        if (!element.current) {
            return;
        }

        element.current.removeEventListener("hide.bs.dropdown", this.handleDropClosing);
        element.current.removeEventListener("hide.bs.modal", this.handleDropClosing);
    }

    delayedLostFocus(element: HTMLInputElement | HTMLTextAreaElement | HTMLDivElement | null) {

        if (this.currentContext === KeyboardContext.DefaultView) {
            if (element) {
                element.blur();
            }
        }
        else {
            //TODO: testing without delay            
            document.getElementById(CommonElements.ModalElement)?.focus();
        }

    }

    canClose() {
        return (this.elementWithFocusList.length === 0);
    }

    handleDropClosing(event: any) {
        LogCollector.LogWarn("handleDropClosing: " + this.elementWithFocusList.length);
        if (!this.canClose()) {
            event.stopPropagation();
            event.preventDefault();
        }
    }


    currentPasteHandlerList: string[] = [];

    attachPasteHandler(componentKey: string) {
        this.currentPasteHandlerList.push(componentKey);
    }

    isCurrentHandler(componentKey: string) {
        if (this.currentPasteHandlerList.length === 0) {
            return false;
        }
        return this.currentPasteHandlerList[this.currentPasteHandlerList.length - 1] === componentKey;
    }

    releasePasteHandler(componentKey: string) {
        this.currentPasteHandlerList = this.currentPasteHandlerList.filter(k => k !== componentKey);
    }
}

interface KeyboardAction {
    shortCut: string;
    action: () => void;
}

interface KeyboardActionGroup {
    context: KeyboardContext;

    altKey: boolean;
    ctrlKey: boolean;
    actionList: KeyboardAction[];
}

const HotKeyConfiguration = () => {

    const ElementSelectionHandler = (elementID: string) => {
        const elementClick = document.getElementById(elementID);
        if (elementClick) {
            elementClick.click();
        }
    }

    const KeywordSearch = () => {
        const elementClick = document.getElementById(CommonElements.ConceptKeywordSearch);
        if (elementClick) {
            elementClick.focus();
            elementClick.click();
        }
    }

    const keyboardShortcutConfig = [
        {
            context: KeyboardContext.DefaultView, altKey: false, ctrlKey: true,
            actionList: [
                { shortCut: "i", action: () => { ElementSelectionHandler(CommonElements.InsightTab); } }, { shortCut: "I", action: () => { ElementSelectionHandler(CommonElements.InsightTab); } },
                { shortCut: "p", action: () => { ElementSelectionHandler(CommonElements.ProblemTab); } }, { shortCut: "P", action: () => { ElementSelectionHandler(CommonElements.ProblemTab); } },
                { shortCut: "s", action: () => { ElementSelectionHandler(CommonElements.SolutionTab); } }, { shortCut: "S", action: () => { ElementSelectionHandler(CommonElements.SolutionTab); } },
            ] as KeyboardAction[]
        },

        { context: KeyboardContext.DefaultView, altKey: false, ctrlKey: false, actionList: [{ shortCut: "n", action: () => ElementSelectionHandler(CommonElements.CreateConcept) }, { shortCut: "N", action: () => ElementSelectionHandler(CommonElements.CreateConcept) }] },
        { context: KeyboardContext.DefaultView, altKey: false, ctrlKey: false, actionList: [{ shortCut: "s", action: KeywordSearch }, { shortCut: "S", action: KeywordSearch }] },

        //Modal viewer
        { context: KeyboardContext.ModalViewer, altKey: false, ctrlKey: false, actionList: [{ shortCut: "e", action: () => ElementSelectionHandler(CommonElements.EditConcept) }, { shortCut: "E", action: () => ElementSelectionHandler(CommonElements.EditConcept) }] },
        { context: KeyboardContext.ModalViewer, altKey: false, ctrlKey: true, actionList: [{ shortCut: "x", action: () => ElementSelectionHandler(CommonElements.DeleteConcept) }, { shortCut: "X", action: () => ElementSelectionHandler(CommonElements.DeleteConcept) }] },

        {
            context: KeyboardContext.DefaultView, altKey: true, ctrlKey: true,
            actionList: [
                { shortCut: "d", action: () => document.body.setAttribute("data-theme", "dark") }, { shortCut: "D", action: () => document.body.setAttribute("data-theme", "dark") },
                { shortCut: "l", action: () => document.body.removeAttribute("data-theme") }, { shortCut: "L", action: () => document.body.removeAttribute("data-theme") }
            ]
        },

        {
            context: KeyboardContext.ScreenshotViewer, altKey: false, ctrlKey: false,
            actionList: [{ shortCut: "Escape", action: () => { ElementSelectionHandler(CommonElements.CustomModalElement) } }]
        },
        {
            context: KeyboardContext.ImageScribbleViewer, altKey: false, ctrlKey: false,
            actionList: [{ shortCut: "Escape", action: () => { ElementSelectionHandler(CommonElements.CustomModalElement) } }]
        }




    ] as KeyboardActionGroup[];


    return keyboardShortcutConfig;
}

export const KeyboardServiceHandler = (props: PropsWithChildren) => {


    const config = HotKeyConfiguration()


    const HandleKeyboardEvent = (event: KeyboardEvent) => {

        if (KeyboardService.currentContext !== KeyboardContext.ScreenshotViewer &&
            KeyboardService.currentContext !== KeyboardContext.ImageScribbleViewer) {
            if (KeyboardService.elementWithFocusList.length > 0) {
                return;
            }
        }

        const matchingList = config.filter(keyboard =>
            keyboard.context === KeyboardService.currentContext &&
            keyboard.altKey === event.altKey &&
            keyboard.ctrlKey === event.ctrlKey);

        if (matchingList.length === 0) {
            return;
        }

        matchingList.forEach(keyboard => {
            const foundAction = keyboard.actionList.filter(action => action.shortCut === event.key);
            if (foundAction.length > 0) {
                foundAction[0].action();
                event.stopPropagation();
                event.preventDefault();
                return;
            }
        });
    }

    useEffect(() => {
        document.addEventListener("keydown", HandleKeyboardEvent);

        return () => document.removeEventListener("keydown", HandleKeyboardEvent);
    }, []);


    return (
        <React.Fragment>
            {props.children}
        </React.Fragment>
    )
}

export const KeyboardService = new KeyboardServiceImplementation();

interface ModalOpenCloseWatcherProps {
    keyboardContext?: KeyboardContext;
}

export const ModalOpenCloseWatcher = (props: ModalOpenCloseWatcherProps) => {

    useEffect(() => {

        const previousContext = KeyboardService.currentContext;
        KeyboardService.currentContext = props.keyboardContext ?? KeyboardContext.ModalViewer;

        return () => { KeyboardService.currentContext = previousContext; }
    }, [])

    return <React.Fragment></React.Fragment>
}