import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import React, { useEffect } from "react";
import { ToolbarLinkEditorComponent } from "./ToolbarLinkEditorComponent";
import { mergeRegister } from "@lexical/utils";
import { COMMAND_PRIORITY_HIGH, KEY_ESCAPE_COMMAND } from "lexical";

interface ToolbarLinkComponentProps {
    isActive: boolean;
    setIsLink: (isLink: boolean) => void;
}

export const ToolbarLinkComponent = (props: ToolbarLinkComponentProps) => {
    const [editor] = useLexicalComposerContext();

    useEffect(() => {
        return mergeRegister(
            editor.registerCommand(
                KEY_ESCAPE_COMMAND,
                () => {
                    if (props.isActive) {
                        props.setIsLink(false);
                        return true;
                    }
                    return false;
                },
                COMMAND_PRIORITY_HIGH,
            ),

        );
    }, [editor, props.isActive]);

    return (
        <React.Fragment>
            <button tabIndex={-1} className={"toolbar-item spaced " + (props.isActive ? "active" : "")} aria-label={"Format Bold"}
                onClick={() => {
                    if (!props.isActive) {
                        editor.dispatchCommand(TOGGLE_LINK_COMMAND, {
                            url: "https://", target: "_blank", rel: "noopener noreferrer",
                        });
                    }
                    else {
                        editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
                    }
                }}>
                <i className={"format link"} />
            </button>
            {
                (props.isActive) &&
                <ToolbarLinkEditorComponent setIsLink={props.setIsLink} />
            }
        </React.Fragment>
    )
}