import { LoadingComponent } from "../loading/LoadingPage"
import { AuthLoader } from "./AuthLoader";

export const SignInPage = () => {

    return (
        <AuthLoader extraQueryParamenters={{ signin: "true" }}>
            <LoadingComponent />
        </AuthLoader>
    )
}