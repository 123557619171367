import { useEffect } from "react";
import { useAuth } from "react-oidc-context"
import { WorkspaceEditorStore } from "../../services/stores/Workspace/WorkspaceEditorStore";
import { ModalComponent } from "../../components/modal/ModalComponent";

interface RenderSectionProps {
    title: string;
    content: string;

    navigateTo: string;
}

export const GetStartedPage = () => {
    const auth = useAuth();

    useEffect(() => {

        new WorkspaceEditorStore().generateDefaultWorkspace();

    }, []);


    const RenderSection = (props: RenderSectionProps) => {

        return (
            <a className="getstarted-display mt-3 d-flex flex-column align-items-center" role="button" href={props.navigateTo}>
                <div className="fw-med-700">{props.title}</div>
                <div className="pt-2 px-3 fw-small-400 fw-secondary text-center">{props.content}</div>
            </a>
        )
    }

    return (
        <div className="getstarted-content p-3" >
            <div className="px-5">
                <div className="fw-xxlarge-900 fw-concept text-center">
                    {auth.user?.profile.given_name}, what would you like to do first?
                </div>
                <RenderSection title="💬 Collect customer feedback" content="Receive meaningful insights on how you can improve your product offering." navigateTo="/onboarding/feedback" />
                <RenderSection title="🔬 Start adding insights" content="Add & categorize your customer insights." navigateTo="/onboarding/insights/" />
                <RenderSection title="⚡ Define a problem" content="Discover the real impact of a problem." navigateTo="/onboarding/problems/" />
                <RenderSection title="😊 Add an idea or solution" content="Jump straight in and add an idea!" navigateTo="/onboarding/solutions/" />
                <div className="pt-4 text-center">
                    <a href="/workspace/" className="concept-link">Just let me explore</a>
                </div>
            </div>
            <ModalComponent />
            {/* <PreReleaseComponent /> */}
        </div>
    )
}