
export enum ConceptType {
    Default = 0,
    Insights = 1,
    Problems = 2,
    Solutions = 3,

    Error = 500
}

export enum PageType {
    Insights = 1,
    Problems = 2,
    Solutions = 3,

    Dashboard = 4,
    Feedback = 5
}

export const ConceptTypeEnumLabel = new Map<ConceptType, string>([
    [ConceptType.Insights, "Insight"],
    [ConceptType.Problems, "Problem"],
    [ConceptType.Solutions, "Solution"],
]);

export const KeyboardCodes = {
    BACKSPACE: 8,
    ENTER: 13,
    ESCAPE: 27,
    SPACE: 32,
    END: 35,
    HOME: 36,
    LEFT_ARROW: 37,
    UP_ARROW: 38,
    RIGHT_ARROW: 39,
    DOWN_ARROW: 40,
    DELETE: 46
};

export enum GlobalNotifications {
    None = 0,
    AddNewConcept = 1,
    AddNewFolder = 2,

    ViewConcept = 3,    
    EditConcept = 4,
    ConceptSavePending = 5,
    DeleteConcept = 6,
    LinkToNewConcept = 7,

    FolderSelectChange = 10,
    FolderDefaultSelection = 11,
    RemoveConceptFromView = 12,

    DeleteComment = 13,

    AddNewTag = 14,
    TagStoreUpdated = 15,
    TagRenamed = 16,

    ConfirmNotification = 17,

    AlertError = 18,
    AlertClose = 19,
    AlertTimerError = 20,


    FeedbackLogoEditor = 30,
    FeedbackUrlCopied = 31,

    WorkspaceEditor = 40,
    WorkspaceInvitation = 41,
    WorspaceMemberInvited = 42,

    PreRelease = 100,
    VoyceFeedback = 101,

    ModalLoading = 500,


    //Custom modals
    DisplayScreenshotFullscreen = 150,
    DisplayImageScribble = 151,
    ImageScribbleUpdated = 152
}


export enum DraggableElement {
    Concept = "concept.card"
}

export enum VoyceQueryString {
    SpecialFolder = "f",
    FeedbackFolder = "vf"
}