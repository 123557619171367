import insight from "../../../../assets/images/app/insights.svg";

export const InsightEmptyContent = () => {

    return (
        <div className="text-center w-100 pt-4">
            <div style={{ height: "280px" }}>
                <img src={insight} alt="Add a new insight" />
            </div>
            <div className="pt-3">
                Here are some examples of insights you can add:
            </div>
            <div className="pt-3 fw-reg-500">
                <div>Customer feedback</div>
                <div>User interviews</div>
                <div>User testing recording</div>
                <div>Links to Google docs</div>
                <div>Spreadsheets and documents</div>
                <div>A/B test results</div>
            </div>
        </div>

    )
}