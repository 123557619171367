import React, { useEffect, useState } from "react";
import { ConceptEditorStore } from "../../../../services/stores/ConceptEditorStore";
import { MessageHubContext } from "../../../../services/notification/MessageHubHandler";
import { ConceptDataModel } from "../../../../models/ConceptDataModel";
import { ProblemDefinitionModel } from "../../../../models/concept/ProblemDefinitionModel";
import { HelperExtension } from "../../../../services/HelperExtension";

interface ProblemDefinitionDisplayComponentProps {
    dataStore: ConceptEditorStore;
}

export const ProblemDefinitionDisplayComponent = (props: ProblemDefinitionDisplayComponentProps) => {

    const [problemDefinition, setProblemDefinition] = useState<ProblemDefinitionModel>();

    useEffect(() => {

        const hubContext = MessageHubContext()
            .Subscribe(props.dataStore, (conceptModel: ConceptDataModel) => {
                const problem = HelperExtension.GetProblemDefinition(conceptModel);
                setProblemDefinition(problem);
            });


        return () => hubContext.Dispose();
    }, []);

    if (!problemDefinition) {

        return (<React.Fragment></React.Fragment>)
    }

    return (

        <ul className="m-0 pt-1">
            {
                (problemDefinition.g) &&
                <li><span className="fw-reg-700 me-1">Goal:</span>{problemDefinition.g}</li>
            }
            {
                (problemDefinition.v) &&
                <li className="pt-1"><span className="fw-reg-700 me-1">Value:</span>{problemDefinition.v}</li>
            }
            {
                (problemDefinition.o) &&
                <li className="pt-1"><span className="fw-reg-700 me-1">Obstacle:</span>{problemDefinition.o}</li>
            }
        </ul>
    )
}