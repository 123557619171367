
export interface AttachmentDataModel {
    attachmentID: string;

    attachmentType: AttachmentType;

    displayName: string;
    fileName: string;
    fileContentType: string;
    fileSize: number;
    fileExtension: string;
    uploadFileName: string;

    attachmentOrder: number;

    shouldDelete: boolean;

    //FED
    isUploading: boolean;
    isNew: boolean;
}

export enum AttachmentType {
    Attachment = 1,
    Screenshot = 2,


    FeedbackLogo = 50
}