import _ from "lodash";
import { memo, useEffect, useState } from "react";
import { ConceptType } from "../../../../models/common/CommonEnums";
import { ConceptAffect, ConceptComplexity, ConceptDataModel } from "../../../../models/ConceptDataModel";
import { MessageHubContext } from "../../../../services/notification/MessageHubHandler";
import { ConceptEditorStore } from "../../../../services/stores/ConceptEditorStore";
import { ComplexityViewerComponent } from "../../../elements/ComplexityViewerComponent";
import { ImplementationLevelComponent } from "../../../elements/ImplementationLevelComponent";
import { SelectorComponent } from "../../../elements/SelectorComponent";

interface ProblemImpactComponentProps {
    conceptStore: ConceptEditorStore;
}

interface ImpactState {
    conceptComplexity: ConceptComplexity;
    currentAffect: ConceptAffect;
    problemImpact: number;
}

export const ProblemImpactComponent: React.FunctionComponent<ProblemImpactComponentProps>
    = memo(
        function ProblemImpact({ conceptStore }) {
            const [currentImpact, setCurrentImpact] = useState<ImpactState>();


            useEffect(() => {
                const hubContext = MessageHubContext()
                    .Subscribe(conceptStore, (conceptModel: ConceptDataModel) => {

                        if (!conceptModel.problemData) {
                            return;
                        }

                        const newValue = {
                            conceptComplexity: conceptModel.problemData.conceptSeverity,
                            currentAffect: conceptModel.problemData.conceptPeopleAffected,
                            problemImpact: conceptModel.conceptValue
                        } as ImpactState;

                        if (_.isEqual(currentImpact, newValue)) {
                            return;
                        }

                        setCurrentImpact(newValue);
                    });


                return () => hubContext.Dispose();
            }, [currentImpact]);

            const GetImplementationValue = () => {
                if (currentImpact) {
                    return currentImpact.problemImpact;
                }
                return 0;
            }

            return (
                <div className="concept-bg-lighter rounded-2">
                    <div className="pt-3 d-flex flex-column align-items-center">
                        <div className="fw-concept fw-med-500">Problem Impact</div>
                        <div className="pt-2">
                            <ImplementationLevelComponent currentLevel={GetImplementationValue()} conceptType={ConceptType.Problems} />
                        </div>
                    </div>

                    <div className="p-3">
                        <ComplexityViewerComponent
                            conceptType={ConceptType.Problems}
                            complexity={currentImpact?.conceptComplexity}
                            affect={currentImpact?.currentAffect}

                            xDef={{
                                axisDescription: "Affects",
                                minDescription: "Few",
                                maxDescription: "All"
                            }}
                            yDef={{
                                axisDescription: "Severity",
                                minDescription: "Small",
                                maxDescription: "Big"
                            }}
                        />

                        <div className="pt-3">
                            <SelectorComponent
                                conceptStore={conceptStore}
                                fieldName="problemData.conceptSeverity"
                                description="Severity: "
                                currentValue={currentImpact?.conceptComplexity}
                                selectionOptions={[
                                    { selectorID: ConceptComplexity.Low, selectorText: "Low" },
                                    { selectorID: ConceptComplexity.LowMedium, selectorText: "Medium/Low" },
                                    { selectorID: ConceptComplexity.Medium, selectorText: "Medium" },
                                    { selectorID: ConceptComplexity.MediumHigh, selectorText: "Medium/High" },
                                    { selectorID: ConceptComplexity.High, selectorText: "High" },
                                ]}
                            />
                        </div>
                        <div className="pt-3">
                            <SelectorComponent
                                conceptStore={conceptStore}
                                fieldName="problemData.conceptPeopleAffected"
                                description="Affects: "
                                currentValue={currentImpact?.currentAffect}
                                selectionOptions={[
                                    { selectorID: ConceptAffect.Few, selectorText: "Few" },
                                    { selectorID: ConceptAffect.Some, selectorText: "Some" },
                                    { selectorID: ConceptAffect.Many, selectorText: "Many" },
                                    { selectorID: ConceptAffect.Most, selectorText: "Most" },
                                    { selectorID: ConceptAffect.All, selectorText: "All" },
                                ]}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    )