import { useEffect, useRef } from "react";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { ErrorNotificationModel } from "../../models/communication/internal/ErrorNotificationModel";
import { GlobalNotification } from "../../services/GlobalMessageHub";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { LogCollector } from "../../services/logger/LogCollector";

interface ErrorDisplayComponentProps {
    errorComponentKey: string;

}

export const ErrorDisplayComponent = (props: ErrorDisplayComponentProps) => {

    const alertRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const hubContext = MessageHubContext()
            .ListenGlobalNotification([GlobalNotifications.AlertError], (notification: GlobalNotification) => {

                LogCollector.LogMessage("ErrorDisplayComponent.Notification");

                const errorNotification = notification.notificationData as ErrorNotificationModel;
                if (errorNotification?.errorComponentKey === props.errorComponentKey) {

                    const htmlDiv = alertRef.current?.firstChild as HTMLDivElement;
                    if (alertRef.current && htmlDiv) {
                        let errorMessageHtml = errorNotification.errorMessage;
                        //htmlDiv.innerHTML = errorNotification.errorMessage;

                        if (errorNotification.errorDetails) {
                            const errorDetailHtml = errorNotification.errorDetails.map(e => "<li>" + e + "</li>").join("");
                            errorMessageHtml += "<ul class='m-0 fw-small-400'>" + errorDetailHtml + "</ul>";
                        }

                        htmlDiv.innerHTML = errorMessageHtml;
                        alertRef.current.classList.add("show");
                    }
                }
            })
            .ListenGlobalNotification([GlobalNotifications.AlertClose], (notification: GlobalNotification) => {
                if (alertRef.current) {
                    const errorNotification = notification.notificationData as ErrorNotificationModel;
                    if (errorNotification?.errorComponentKey === props.errorComponentKey) {
                        alertRef.current.classList.remove("show");
                    }
                }
            });


        return () => hubContext.Dispose();

    }, [alertRef]);

    const CloseAlert = () => {
        if (alertRef.current) {
            alertRef.current.classList.remove("show");
        }
    }

    return (
        <div ref={alertRef} className="alert alert-danger alert-dismissible collapse" role="alert">
            <div></div>
            <button type="button" className="btn-close" aria-label="Close" onClick={CloseAlert}></button>
        </div>
    )
}