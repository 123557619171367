import { useCallback, useEffect, useState } from "react";
import { KeyboardService } from "../../../services/shortcut/KeyboardService";
import React from "react";
import { LogCollector } from "../../../services/logger/LogCollector";

interface ImagePasteUploadComponentProps {

    OnPaste: (uploadingImages: File[]) => void;

}

export const ImagePasteUploadComponent = (props: ImagePasteUploadComponentProps) => {

    const [componentKey] = useState(crypto.randomUUID());

    const OnPaste = useCallback((event: ClipboardEvent) => {
        LogCollector.LogMessage("OnPaste");

        if (!KeyboardService.canClose()) {
            //Means something has focus
            return;
        }

        if (!KeyboardService.isCurrentHandler(componentKey)) {
            return;
        }

        if (!event.clipboardData) {
            return;
        }

        // Get the data of clipboard
        const clipboardItems = event.clipboardData.items;
        const items = [].slice.call(clipboardItems).filter(function (item: DataTransferItem) {
            // Filter the image items only
            if (!item?.type) {
                return;
            }

            return item.type.indexOf("image") !== -1;
        }) as DataTransferItem[];
        if (items.length === 0) {
            return;
        }

        const uploadList: File[] = [];
        items.forEach(item => {
            const fileData = item.getAsFile();
            if (fileData) {
                uploadList.push(fileData);
            }
        })

        props.OnPaste(uploadList);

    }, []);

    useEffect(() => {

        KeyboardService.attachPasteHandler(componentKey);

        document.addEventListener("paste", OnPaste);

        return () => {
            document.removeEventListener("paste", OnPaste);
            KeyboardService.releasePasteHandler(componentKey);
        }
    }, [componentKey]);

    return (
        <React.Fragment></React.Fragment>
    )
}