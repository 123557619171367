import { ConceptType, GlobalNotifications } from "../../../models/common/CommonEnums";
import { TagDeleteActionResponse } from "../../../models/communication/actions/TagDeleteAction";
import { TagLoadAction, TagLoadActionResponse } from "../../../models/communication/actions/TagLoadAction";
import { TagUpdateActionResponse } from "../../../models/communication/actions/TagUpdateAction";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { GlobalNotification, GlobalNotificationHub } from "../../GlobalMessageHub";
import { MessageHubOwner } from "../../messaging/MessageHubOwner";
import { MessageService } from "../../messaging/MessageService";
import { IHubSubcription, MessageHubContext, MessageHubHandler } from "../../notification/MessageHubHandler";

export interface TagModel {
    TagID: string;
    TagDescription: string;
    isSelected: boolean;
    isSystemTag: boolean;
}

export interface TagStoreData {
    tagList: TagModel[];
}

export interface ITagStoreNotification {
    defaultLoaded: (defaultTagList: TagModel[]) => void;
}

class TagDefaultLoaderImplementation {

    messageContext?: MessageHubHandler;
    defaultTagList: TagModel[] = [];
    conceptType: ConceptType = ConceptType.Default;

    constructor(conceptType: ConceptType) {
        this.OnTagsLoaded = this.OnTagsLoaded.bind(this);
        this.OnTagUpdate = this.OnTagUpdate.bind(this);
        this.OnTagRename = this.OnTagRename.bind(this);
        this.OnTagDelete = this.OnTagDelete.bind(this);

        MessageHubContext()
            .ListenMessage(MessageHubOwner, { MessageType: MessageType.TagLoaded, OnReceive: this.OnTagsLoaded } as IHubSubcription)
            .ListenGlobalNotificationAsync([GlobalNotifications.AddNewTag], this.OnTagUpdate)
            .ListenGlobalNotificationAsync([GlobalNotifications.TagRenamed], this.OnTagRename)
            .ListenMessage(MessageHubOwner, { MessageType: MessageType.TagDeleted, OnReceive: this.OnTagDelete } as IHubSubcription);


        this.conceptType = conceptType;
    }

    notify: ITagStoreNotification[] = [];

    async Initialize() {
        return MessageService.sendMessage({
            messageType: MessageType.TagLoad,
            requestData: { conceptType: this.conceptType } as TagLoadAction
        } as IServerMessage);
    }

    async LoadTags(tagStore: ITagStoreNotification) {
        if (this.defaultTagList.length > 0) {
            tagStore.defaultLoaded(this.defaultTagList);
        }
        else {
            this.notify.push(tagStore);
            await this.Initialize();

        }
    }

    async OnTagRename(notification: GlobalNotification) {
        const newTagData = notification?.notificationData as TagUpdateActionResponse;

        if (!newTagData?.tag) {
            return;
        }

        const existingTagIndex = this.defaultTagList.findIndex(tag => tag.TagID === newTagData.tag.tagID);
        if (existingTagIndex > -1) {

            const updatingList = this.defaultTagList;
            updatingList[existingTagIndex] = {
                TagID: newTagData.tag.tagID,
                TagDescription: newTagData.tag.tagDescription
            } as TagModel;

            this.defaultTagList = [...updatingList];
        }
    }

    OnTagDelete(deletedNotification: TagDeleteActionResponse) {
        if (!deletedNotification?.deletedTag) {
            return;
        }

        if (this.defaultTagList.length === 0) {
            return;
        }

        const newList = this.defaultTagList.filter(tag => tag.TagID !== deletedNotification.deletedTag.tagID);
        this.defaultTagList = [...newList];
    }

    async OnTagUpdate(notification: GlobalNotification) {
        const newTagData = notification?.notificationData as TagUpdateActionResponse;

        if (!newTagData?.tag) {
            return;
        }

        if (this.defaultTagList.length === 0) {
            // data will be loaded from server when needed
            return;
        }

        switch (this.conceptType) {
            case ConceptType.Insights:
                if (!newTagData.insightSelected) {
                    return;
                }
                break;
            case ConceptType.Problems:
                if (!newTagData.problemSelected) {
                    return;
                }
                break;
            case ConceptType.Solutions:
                if (!newTagData.solutionSelected) {
                    return;
                }
                break;
        }

        const existingTagIndex = this.defaultTagList.findIndex(tag => tag.TagID === newTagData.tag.tagID);
        if (existingTagIndex > -1) {

            const updatingList = this.defaultTagList;
            updatingList[existingTagIndex] = {
                TagID: newTagData.tag.tagID,
                TagDescription: newTagData.tag.tagDescription
            } as TagModel;

            this.defaultTagList = [...updatingList];
        } else {

            const newList = this.defaultTagList;
            newList.push({
                TagID: newTagData.tag.tagID,
                TagDescription: newTagData.tag.tagDescription
            } as TagModel);
            this.defaultTagList = [...newList];
        }

        GlobalNotificationHub.sendMessage(GlobalNotifications.TagStoreUpdated);
    }

    async OnTagsLoaded(response: TagLoadActionResponse) {

        if (response.conceptType !== this.conceptType) {
            return
        }

        this.defaultTagList = response.tagList.map(tag => {
            return {
                TagID: tag.tagID,
                TagDescription: tag.tagDescription,
                isSystemTag: tag.isSystemTag,
                isSelected: false,
            } as TagModel;
        });

        this.notify.forEach(store => store.defaultLoaded(this.defaultTagList));
        this.notify = [];
    }
};

export const TagInsightLoader = new TagDefaultLoaderImplementation(ConceptType.Insights);
export const TagProblemLoader = new TagDefaultLoaderImplementation(ConceptType.Problems);
export const TagSolutionLoader = new TagDefaultLoaderImplementation(ConceptType.Solutions);