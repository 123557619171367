import { TextBoxComponent, TextBoxComponentProps } from "./TextBoxComponent"


export const TextBoxKeywordSearchComponent = (props: TextBoxComponentProps) => {

    return (
        <div className="vc-input-group vc-input-group-right keyword-search">
            <div className="vc-icon">
                <svg className="pe-none w-100"
                    width="14" height="14" role="img" aria-label="Search"><use xlinkHref="#search-icon"></use></svg>
            </div>
            <TextBoxComponent cssClass="w-100" {...props} />
        </div>
    )
}