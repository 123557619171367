import { Subject } from "rxjs";
import { INotificationHub } from "../../models/common/INotificationHub";

class MessageHubOwnerImplemetantion implements INotificationHub {
    conceptEditorSubject = new Subject();

    subscribe(setState: any) {
        return this.conceptEditorSubject.subscribe(setState);
    };

    sendMessage(messageName: any) {
        // Notifies everyone
        this.conceptEditorSubject.next(messageName);
    }
}

export const MessageHubOwner = new MessageHubOwnerImplemetantion();