import { Subject } from "rxjs";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { FeedbackDataModel } from "../../../models/data/FeedbackDataModel";
import { MessageService } from "../../messaging/MessageService";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { FeedbackSettingsSaveAction } from "../../../models/communication/actions/FeedbackSettingsSaveAction";
import { FeedbackSettingsLoadAction, FeedbackSettingsLoadActionResponse } from "../../../models/communication/actions/FeedbackSettingsLoadAction";
import { AttachmentDataModel } from "../../../models/data/AttachmentDataModel";

const defaultState = {
    isEnabled: false
} as FeedbackDataModel;

export class FeedbackStore implements INotificationHub {
    feedbackSubject = new Subject();
    currentStoreState = { ...defaultState };

    subscribe(onMessageReceive: any) {
        return this.feedbackSubject.subscribe(onMessageReceive);
    }

    // initialize() {
    //     this.currentStoreState = { ...defaultState };
    //     this.feedbackSubject.next(this.currentStoreState);
    // }

    ping() {
        this.feedbackSubject.next(this.currentStoreState);
    }

    async loadFeedbackSettings() {
        return MessageService.sendMessage({
            messageType: MessageType.FeedbackSettingsLoad,
            requestData: {} as FeedbackSettingsLoadAction
        } as IServerMessage);
    }

    loadData(message: FeedbackSettingsLoadActionResponse) {
        this.currentStoreState = { ...message.settings };
        this.feedbackSubject.next(this.currentStoreState);
    }

    async switchEnable() {
        this.currentStoreState = { ...this.currentStoreState, isEnabled: !this.currentStoreState.isEnabled };

        const updateData = {
            settings: {
                logoAttachmentID: this.currentStoreState.logoAttachmentID,
                logoFileName: this.currentStoreState.logoFileName,
                logoFileExtension: this.currentStoreState.logoFileExtension,
                isEnabled: this.currentStoreState.isEnabled
            }
        } as FeedbackSettingsSaveAction;

        return MessageService.sendMessage({
            messageType: MessageType.FeedbackSettingsSave,
            requestData: updateData
        } as IServerMessage);
    }

    updateLogo(newAttachment: AttachmentDataModel) {
        const updateData = {
            settings: {
                logoAttachmentID: newAttachment.attachmentID,
                logoFileName: newAttachment.displayName,
                logoFileExtension: newAttachment.fileExtension,
                isEnabled: this.currentStoreState.isEnabled
            }
        } as FeedbackSettingsSaveAction;

        return MessageService.sendMessage({
            messageType: MessageType.FeedbackSettingsSave,
            requestData: updateData
        } as IServerMessage);
    }
}