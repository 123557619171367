import { Subject } from "rxjs";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { AttachmentDataModel, AttachmentType } from "../../../models/data/AttachmentDataModel";
import { MessageService } from "../../messaging/MessageService";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { ConceptAttachmentSaveAction } from "../../../models/communication/actions/ConceptAttachmentSaveAction";
import { ConceptAttachmentDeleteAction } from "../../../models/communication/actions/ConceptAttachmentDeleteAction";
import { ConceptAttachmentNotify } from "../../../models/communication/actions/ConceptAttachmentNotify";
import { ConceptAttachmentUploadCompleteAction } from "../../../models/communication/actions/ConceptAttachmentUploadCompleteAction";
import update from "immutability-helper";


export interface ConceptAttachmentStoreData {
    attachmentList: AttachmentDataModel[];
    deletedAttachmentList: AttachmentDataModel[];
}


const initialState = {
    attachmentList: [] as AttachmentDataModel[],
    deletedAttachmentList: [] as AttachmentDataModel[]
} as ConceptAttachmentStoreData;

export class ConceptAttachmentStore implements INotificationHub {
    conceptAttachmentSubject = new Subject();
    storageState: ConceptAttachmentStoreData = initialState;
    syncWithServer: boolean = false;
    conceptID: string = "";

    subscribe(onMessageReceive: any) {
        return this.conceptAttachmentSubject.subscribe(onMessageReceive);
    }


    initialize() {

        // This component is created on demand, so no need to reset to initial state.
        this.conceptAttachmentSubject.next(this.storageState);
    }

    loadAttachments(conceptID: string, attachmentList: AttachmentDataModel[]) {
        this.conceptID = conceptID;
        this.storageState.attachmentList = [...attachmentList];

        this.conceptAttachmentSubject.next(this.storageState);
    }

    addNewAttachment(newAttachment: AttachmentDataModel) {

        this.storageState = {

            ...this.storageState,
            attachmentList: [...this.storageState.attachmentList, newAttachment]
        };

        this.conceptAttachmentSubject.next(this.storageState);
    }

    addNewAttachmentList(attachmentList: AttachmentDataModel[]) {

        //const newList = [...this.storageState.attachmentList, ...attachmentList];

        this.storageState = {

            ...this.storageState,
            attachmentList: [...this.storageState.attachmentList, ...attachmentList]
        };

        this.conceptAttachmentSubject.next(this.storageState);
    }

    async saveNewAttachement(newAttachment: AttachmentDataModel) {

        if (this.syncWithServer) {

            const request = {
                conceptID: this.conceptID,
                attachment: { ...newAttachment }
            } as ConceptAttachmentSaveAction;

            await MessageService.sendMessage({
                messageType: MessageType.ConceptAttachmentSave,
                requestData: request
            } as IServerMessage);
        }
    }

    async deleteAttachment(attachment: AttachmentDataModel) {

        attachment.shouldDelete = true;

        this.storageState = {
            ...this.storageState,

            attachmentList: [...this.storageState.attachmentList.filter(att => att.attachmentID !== attachment.attachmentID)],
            deletedAttachmentList: [...this.storageState.deletedAttachmentList, attachment]
        }

        if (this.syncWithServer) {
            const request = {
                conceptID: this.conceptID,
                attachment: { ...attachment }
            } as ConceptAttachmentDeleteAction;

            await MessageService.sendMessage({
                messageType: MessageType.ConceptAttachmentDelete,
                requestData: request
            } as IServerMessage);
        }

        this.conceptAttachmentSubject.next(this.storageState);
    }

    async notifyUpload(attachment: AttachmentDataModel) {
        const request = {
            conceptID: this.conceptID,
            attachmentID: attachment.attachmentID,
            fileExtension: attachment.fileExtension,
            fileSize: attachment.fileSize
        } as ConceptAttachmentNotify;

        await MessageService.sendMessage({
            messageType: MessageType.ConceptAttachmentNotify,
            requestData: request
        } as IServerMessage);
    }

    async completeUpload(attachment: AttachmentDataModel) {
        const request = {
            conceptID: this.conceptID,
            attachmentID: attachment.attachmentID,
            fileExtension: attachment.fileExtension,
            attachmentType: attachment.attachmentType
        } as ConceptAttachmentUploadCompleteAction;

        await MessageService.sendMessage({
            messageType: MessageType.ConceptAttachmentUploadComplete,
            requestData: request
        } as IServerMessage);
    }


    countAttachment(attachmentType: AttachmentType) {
        return this.storageState.attachmentList.filter(att => att.attachmentType === attachmentType).length;
    }


    replaceAttachment(oldAttachment: AttachmentDataModel, newAttachment: AttachmentDataModel) {

        const dragIndex = this.storageState.attachmentList.indexOf(oldAttachment);
        const hoverIndex = dragIndex;

        const updatedList = update(this.storageState.attachmentList, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, newAttachment],
            ]
        });

        this.storageState.attachmentList = updatedList;
        //this.conceptAttachmentSubject.next(this.storageState);
    }

    calculateAttachmentOrder(attachment: AttachmentDataModel) {
        if (!attachment.attachmentOrder) {
            const listBasedOnType = this.storageState.attachmentList.filter(att => att.attachmentType === attachment.attachmentType);

            if (listBasedOnType.length > 0) {
                const lastOrder = listBasedOnType[listBasedOnType.length - 1].attachmentOrder;
                attachment.attachmentOrder = (lastOrder + 1);
            }
            else {
                attachment.attachmentOrder = 1;
            }
        }
    }
}