import React, { memo, useEffect, useState } from "react";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { CommentDataModel } from "../../models/data/CommentDataModel";
import { CommentDataStoreEditor } from "../../services/comments/CommentDataStoreEditor";
import { GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { FieldType, TextBoxComponent } from "../elements/TextBoxComponent";
import { AreYouSureLinkComponent } from "../notification/AreYouSureLinkComponent";
import { CommonElements } from "../../models/CommonElements";
import { LogCollector } from "../../services/logger/LogCollector";

interface CommentDisplayComponentProps {
    comment: CommentDataModel;
    currentUserID: string;
}

export const CommentDisplayComponent: React.FunctionComponent<CommentDisplayComponentProps>
    = memo(function CommentDisplay({ comment, currentUserID }) {
        const [isEditing, setIsEditing] = useState(false);
        const isOwnComment = (currentUserID === comment.byAccountID);

        const OnConceptDeleteConfirm = async () => {

            GlobalNotificationHub.sendMessageWithData(GlobalNotifications.DeleteComment, comment);
        }

        const OnEditContent = () => {
            setIsEditing(true);
        }

        const GetOptionalStyles = () => {
            let optionalStyle = isOwnComment ? "comment-display-conceptmod " : "";

            optionalStyle += comment?.savePending ? "comment-display-saving" : "";

            return optionalStyle;
        }

        const RenderContent = () => {

            if (isEditing) {
                return <CommentEditorComponent comment={comment} onCancel={() => {
                    const modal = document.getElementById(CommonElements.ModalElement);
                    if (modal) {
                        modal.focus();
                    }
                    setIsEditing(false);
                }} />
            }

            return (
                <React.Fragment>
                    <div className="pt-1 display-lines">{comment.commentContent}</div>
                    {
                        (isOwnComment) &&
                        <div className="d-flex pt-1">
                            <div className="fw-xsmall-500 concept-link" onClick={OnEditContent}>Edit</div>
                            <div className="ps-3 fw-xsmall-500">
                                <AreYouSureLinkComponent OnConfirmAction={OnConceptDeleteConfirm} />
                            </div>
                        </div>
                    }
                </React.Fragment>
            )
        }

        return (
            <div className={"p-3 my-3 comment-display fw-small-400 " + GetOptionalStyles()}>
                <div className="fw-small-500">{comment.byUserName}<span className="ps-2 fw-secondary fw-small-400">{comment.displayTime}</span></div>
                <RenderContent />
            </div>
        )
    });

interface CommentEditorComponentProps {
    comment: CommentDataModel;
    onCancel: () => void;
}

const CommentEditorComponent = (props: CommentEditorComponentProps) => {
    const [store] = useState(new CommentDataStoreEditor());

    useEffect(() => {
        store.initialize(props.comment);
    }, [props.comment, store])

    const OnCommitMessage = async () => {
        await store.commitChanges();
        props.onCancel();
    }

    LogCollector.LogMessage("Render: CommentEditorComponent");
    return (
        <div className="pt-1">
            {
                (store) &&
                <TextBoxComponent
                    fieldName="commentContent"
                    dataStore={store}
                    maxLength={500}
                    placeholder={props.comment.commentContent}
                    onCommit={OnCommitMessage}
                    onCancel={props.onCancel}
                    autoFocus={true}
                    details={{
                        fieldType: FieldType.Text,
                        numberOfRows: 1
                    }} />
            }
        </div>
    )
}