import { ImageFullscreenViewNotificationModel } from "../../../models/communication/internal/ImageFullscreenViewNotificationModel";
import { ScreenshotFullScreenViewerComponent } from "../../uploader/components/ScreenshotFullScreenViewerComponent";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";

export const ImageFullscreenViewerHander = (notification: ImageFullscreenViewNotificationModel) => {

    return {
        modalSize: ModalSize.Xlarge,
        modalContent: (
            <ScreenshotFullScreenViewerComponent
                currentScreenshot={notification.currentImage} />
        )
    } as ModalDefinitionModel;
}