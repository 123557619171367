
export interface FolderDataModel {
    folderID: string;
    folderName: string;
    folderType: FolderType;
    conceptCount: number;  
    displayOrder: number;  
}

export enum FolderType {
    SystemAllConcepts = 1,
    SystemNotSorted = 2,
    SystemQikFeedbackForm = 3,

    CustomFolder = 10
}