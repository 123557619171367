import { Subject } from "rxjs";
import { ConceptType } from "../../models/common/CommonEnums";
import { INotificationHub } from "../../models/common/INotificationHub";
import { ConceptSearchAction, ConceptSearchActionResponse } from "../../models/communication/actions/ConceptSearchAction";
import { IServerMessage, MessageType } from "../../models/message/IServerMessage";
import { MessageHubOwner } from "../messaging/MessageHubOwner";
import { MessageService } from "../messaging/MessageService";
import { IHubSubcription, MessageHubContext, MessageHubHandler } from "../notification/MessageHubHandler";

class ConceptSearchServiceImplementation implements INotificationHub {
    conceptSearchServiceSubject = new Subject();
    messageHubContext: MessageHubHandler;

    constructor() {
        this.messageHubContext = MessageHubContext()
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.ConceptSearched,
                OnReceive: (messageData: ConceptSearchActionResponse) => {

                    this.conceptSearchServiceSubject.next(messageData.conceptList);
                }
            } as IHubSubcription);
    }

    subscribe(onReceiveAction: any) {
        return this.conceptSearchServiceSubject.subscribe(onReceiveAction);
    }


    async searchConcept(conceptType: ConceptType, keywordSearch: string, forConceptID: string) {
        return MessageService.sendMessage({
            messageType: MessageType.ConceptSearch,
            forConceptID: forConceptID,
            requestData: { conceptType: conceptType, keyword: keywordSearch } as ConceptSearchAction
        } as IServerMessage);
    }

    Dispose() {
        this.messageHubContext?.Dispose();
    }
}

export const ConceptSearchService = new ConceptSearchServiceImplementation();