import { useEffect, useState } from "react";
import { ConceptType } from "../../models/common/CommonEnums";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { TagCreateModel, TagCreateService, TagCreateStore } from "../../services/stores/Tag/TagCreateStore";
import { CheckboxComponent } from "../elements/CheckboxComponent";
import { SaveButtonComponent, TempValidatorType } from "../elements/SaveButtonComponent";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../elements/SectionHeaderComponent";
import { TextBoxKeywordSearchComponent } from "../elements/TextBoxKeywordSearchComponent";

interface TagCreateComponentProps {
    currentValue: string;
    conceptType: ConceptType;
    closeParent: () => void;
    closeCreateTag: () => void;
}

export const TagCreateComponent = (props: TagCreateComponentProps) => {

    const [tagStore] = useState(new TagCreateStore());

    useEffect(() => {
        const hubContext = MessageHubContext()
            .Subscribe(tagStore, (newTagModel: TagCreateModel) => {
                if (new TagCreateService().alreadyExists(props.conceptType, newTagModel)) {
                    window.togglePopover("#tagSearchGroup", "Tag already exists.", true);
                }
                else {
                    window.togglePopover("#tagSearchGroup", "Tag already exists.", false);
                }
            });

        tagStore.initialize({
            tag: {
                TagDescription: props.currentValue,
            },
            insightSelected: props.conceptType === ConceptType.Insights,
            problemSelected: props.conceptType === ConceptType.Problems,
            solutionSelected: props.conceptType === ConceptType.Solutions
        } as TagCreateModel);


        return () => hubContext.Dispose();
    }, [tagStore])

    const OnSaveTag = async () => {
        await
            new TagCreateService().saveTag(tagStore).then(() => {
                props.closeCreateTag();
            })
    }

    return (
        <div>
            <div className="bg-header text-white p-3 ps-3 rounded-top d-flex">
                <div className="flex-fill">
                    <SectionHeaderComponent iconName="#tags-icon"
                        header="Create new tag"
                        iconSize={SectionHeaderIconSize.Medium} headerStyle="icon-bright" />
                </div>
                <div><button type="button" className="btn-close btn-close-white" onClick={props.closeParent}></button></div>
            </div>
            <div className="vc-editor vc-list-group p-3">
                <div className="d-flex">
                    <div className="flex-fill" id="tagSearchGroup" data-bs-container="body" data-bs-trigger="manual" data-bs-toggle="popover" data-bs-placement="bottom" data-bs-content="Bottom popover">
                        <TextBoxKeywordSearchComponent dataStore={tagStore} fieldName="tag.TagDescription" maxLength={50} placeholder="New tag" />
                    </div>
                </div>
                <div className="mt-4 vc-list-group" style={{ height: "215px" }}>
                    <div className="fw-reg-500">Add this tag to</div>
                    <CheckboxComponent fieldPath="insightSelected" description="Insights" dataStore={tagStore} />
                    <CheckboxComponent fieldPath="problemSelected" description="Problems" dataStore={tagStore} />
                    <CheckboxComponent fieldPath="solutionSelected" description="Solutions" dataStore={tagStore} />
                </div>
            </div>
            <div className="vc-editor d-flex justify-content-between p-3">
                <div className="flex-fill m-2">
                    <SaveButtonComponent
                        ButtonClick={OnSaveTag}
                        notificationHub={tagStore}
                        validatorType={TempValidatorType.Tag}
                        className="w-100"
                        disableAutoClose={true}
                        conceptType={props.conceptType}

                    />
                </div>
                <div className="flex-fill m-2">
                    <button className="btn btn-outline-primary btn-concept-outline w-100" onClick={props.closeCreateTag} >Cancel</button>
                </div>
            </div>
        </div>


    )

}