import React, { useEffect, useRef, useState } from "react";
import FeedbackSample from "../../../assets/images/feedback/feedback-background.png";
import FeedbackContentSample from "../../../assets/images/feedback/feedback-content-sample.png";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../elements/SectionHeaderComponent";
import { UploadStore } from "../../services/upload/UploadStore";
import { FailureReasons, UploadService } from "../../services/upload/UploadService";
import { ConceptAttachmentStore } from "../../services/stores/ConceptAttachment/ConceptAttachmentStore";
import { AttachmentDataModel, AttachmentType } from "../../models/data/AttachmentDataModel";
import { AttachmentHelperExtension } from "../../services/helpers/AttachmentHelperExtension";
import { GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { LogCollector } from "../../services/logger/LogCollector";
import { ErrorNotificationModel } from "../../models/communication/internal/ErrorNotificationModel";
import { ErrorDisplayComponent } from "../notification/ErrorDisplayComponent";
import { FeedbackStore } from "../../services/stores/Feedback/FeedbackStore";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { FeedbackDataModel } from "../../models/data/FeedbackDataModel";
import { ContentUploadProgress } from "../uploader/components/ContentUploadProgress";

interface FeedbackEditorComponentProps {
    feedbackStore: FeedbackStore;
}

const feedbackStorage = process.env.REACT_APP_FEEDBACKSTORAGE;

export const FeedbackEditorComponent = (props: FeedbackEditorComponentProps) => {
    const [uploadStore] = useState(new UploadStore());
    const [previewImg, setPreviewImage] = useState("");
    const [uploadingFile, setUploadingFile] = useState<File>();
    const previewRef = useRef<HTMLDivElement>(null);
    const [inputKey, setInputKey] = useState(Math.random().toString(36));
    const [errorComponentKey] = useState(Math.random().toString(36));
    const uploadingRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (previewRef.current) {
            //previewRef.current.style.background = "no-repeat";
            previewRef.current.style.backgroundImage = "url('" + FeedbackSample + "')";
            previewRef.current.style.backgroundSize = "contain";
            previewRef.current.style.backgroundRepeat = "no-repeat";
        }

    }, [previewRef.current]);

    useEffect(() => {

        const hubContext = MessageHubContext()
            .Subscribe(props.feedbackStore, (feedbackSettings: FeedbackDataModel) => {
                if (feedbackSettings.logoUrl) {
                    setPreviewImage(feedbackStorage + feedbackSettings.logoUrl);
                }

            });

        props.feedbackStore.ping();

        return () => hubContext.Dispose();
    }, []);

    const onFileChange = (event: any) => {

        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertClose, { errorComponentKey: errorComponentKey } as ErrorNotificationModel);

        if (!event.target.files[0]) {
            return;
        }

        var reader = new FileReader();
        reader.onload = function (e: any) {
            setPreviewImage(e.target.result);
            setUploadingFile(event.target.files[0]);
        }

        reader.readAsDataURL(event.target.files[0]);
    }

    const OnSaving = () => {
        if (!uploadingFile) {
            return;
        }

        uploadStore.updateProgress(1);
        if (uploadingRef.current) {
            uploadingRef.current.style.display = "";
        }
        const uploadUID = crypto.randomUUID();

        const newAttachment = {
            attachmentID: uploadUID,

            attachmentType: AttachmentType.FeedbackLogo,

            displayName: uploadingFile.name,
            fileName: uploadingFile.name,
            fileContentType: uploadingFile.type,
            fileSize: (uploadingFile.size / 1024 / 1024),
            fileExtension: AttachmentHelperExtension.GetFileExtension(uploadingFile.name),
            uploadFileName: AttachmentHelperExtension.GetFileName(uploadingFile.name, uploadUID),


            isNew: true

        } as AttachmentDataModel;

        const store = new ConceptAttachmentStore();
        store.conceptID = "00000000-0000-0000-0000-000000000000";
        store.syncWithServer = false;

        const uploadService = new UploadService({
            uploadStore: uploadStore,
            attachmentStore: store,
            fileTypesAllowed: "image/",
            maxFileSize: 1
        });

        uploadService.uploadAttachment(newAttachment, uploadingFile)
            .then(async () => {
                await props.feedbackStore.updateLogo(newAttachment);
            })
            .catch((reason: FailureReasons) => {
                switch (reason) {
                    case FailureReasons.FileTooBig:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "The file must be smaller than 1MB." } as ErrorNotificationModel);
                        break;
                    case FailureReasons.NoneSpecified:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "Could not upload the file. Try again later." } as ErrorNotificationModel);
                        break;
                    case FailureReasons.FileMustBeImage:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "You must upload an image." } as ErrorNotificationModel);
                        break;
                    case FailureReasons.NotEnoughSpaceToUpload:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "Total file storage reached for the account.", errorDetails: ["Max file storage size: 1TB", "You can delete old/unused attachments to release the space"] } as ErrorNotificationModel);
                        break;
                }
            })
            .finally(() => {
                LogCollector.LogMessage("...................... upload completed");
                uploadService.dispose();

                if (uploadingRef.current) {
                    uploadingRef.current.style.display = "none";
                }

                uploadStore.updateProgress(0);
                setUploadingFile(undefined);
                setInputKey(Math.random().toString(36));
            });
    }

    return (
        <React.Fragment>
            <div className="modal-header bg-header text-white">
                <SectionHeaderComponent iconName="#customize-icon" header="Personalize your form by adding a logo" iconSize={SectionHeaderIconSize.Medium} headerStyle="icon-bright" />
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body p-0 pb-3">
                <div ref={uploadingRef} className="processing-overlay" style={{ display: "none" }}>
                    <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                        <div className="w-50">
                            <ContentUploadProgress uploadStore={uploadStore} />
                        </div>
                    </div>
                </div>
                <div className="row px-3">
                    <div className="col-3">
                        <div className="pt-3 px-3">
                            <div>
                                <button type="button" className="btn btn-light btn-concept-lighter btn-xsmall text-nowrap"
                                    onClick={() => {
                                        document.getElementById("fileSelection")?.click();
                                    }}
                                >{previewImg ? "Change logo" : "Choose a logo"}</button>
                                <input type="file" onChange={onFileChange} id="fileSelection" style={{ display: "none" }} key={inputKey || ''} accept="image/*" />
                            </div>
                            <div className="fw-small-400 pt-1 px-1">Maximum file size of 1MB</div>
                        </div>
                        <div className="p-3 pt-2 fw-small-400">
                            <ErrorDisplayComponent errorComponentKey={errorComponentKey} />
                        </div>
                        {
                            (uploadingFile) &&
                            <div className="pt-3 px-3">
                                <button type="button" className="btn btn-primary btn-concept btn-medium text-nowrap" onClick={OnSaving}>Save</button>
                            </div>
                        }
                    </div>
                    <div className="col-6">
                        <div className="d-flex flex-column align-items-center">
                            <div className="fw-small-500 pt-5">
                                Preview of your form
                            </div>
                            <div ref={previewRef} className="mt-3" style={{ height: "900px", width: "437px", overflow: "hidden" }}>
                                {/* <img src={FeedbackSample} height={700} alt="Default icon" /> */}
                                <div className="p-5 mt-5">
                                    {
                                        (previewImg) &&
                                        <div className="pt-3 text-center">
                                            <img src={previewImg} style={{ maxWidth: "100%", maxHeight: "300px", overflow: "hidden" }} alt="Preview" />
                                        </div>
                                    }
                                    <div className="pt-3 px-1">
                                        <img src={FeedbackContentSample} alt="Sample" width={331} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">

                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}