import csv from "../../../../assets/images/attachments/csv.svg";
import jpg from "../../../../assets/images/attachments/jpg.svg";
import avi from "../../../../assets/images/attachments/avi.svg";
import doc from "../../../../assets/images/attachments/doc.svg";
import docx from "../../../../assets/images/attachments/docx.svg";
import flv from "../../../../assets/images/attachments/flv.svg";
import giff from "../../../../assets/images/attachments/giff.svg";
import html from "../../../../assets/images/attachments/html.svg";
import mid from "../../../../assets/images/attachments/mid.svg";
import mov from "../../../../assets/images/attachments/mov.svg";
import mp3 from "../../../../assets/images/attachments/mp3.svg";
import mp4 from "../../../../assets/images/attachments/mp4.svg";
import mpeg from "../../../../assets/images/attachments/mpeg.svg";
import pdf from "../../../../assets/images/attachments/pdf.svg";
import png from "../../../../assets/images/attachments/png.svg";
import ppt from "../../../../assets/images/attachments/ppt.svg";
import pub from "../../../../assets/images/attachments/pub.svg";
import rar from "../../../../assets/images/attachments/rar.svg";
import svg from "../../../../assets/images/attachments/svg.svg";
import txt from "../../../../assets/images/attachments/txt.svg";
import wav from "../../../../assets/images/attachments/wav.svg";
import wma from "../../../../assets/images/attachments/wma.svg";
import xml from "../../../../assets/images/attachments/xml.svg";
import xls from "../../../../assets/images/attachments/xls.svg";
import xlsx from "../../../../assets/images/attachments/xlsx.svg";
import zip from "../../../../assets/images/attachments/zip.svg";
import generic from "../../../../assets/images/attachments/generic.svg";


interface AttachmentIconComponentProps {
    fileExtension: string;
}

const imageMapping = new Map<string, string>([
    ["csv", csv],
    ["jpg", jpg],
    ["avi", avi],
    ["doc", doc],
    ["docx", docx],
    ["flv", flv],
    ["giff", giff],
    ["html", html],
    ["mid", mid],
    ["mov", mov],
    ["mp3", mp3],
    ["mp4", mp4],
    ["mpeg", mpeg],
    ["pdf", pdf],
    ["png", png],
    ["ppt", ppt],
    ["pub", pub],
    ["rar", rar],
    ["svg", svg],
    ["txt", txt],
    ["wav", wav],
    ["wma", wma],
    ["xml", xml],
    ["xls", xls],
    ["zip", zip],
    ["xlsx", xlsx]
]);

export const AttachmentIconComponent = (props: AttachmentIconComponentProps) => {

    const mapping = imageMapping.get(props.fileExtension.toLowerCase());

    if (!mapping) {
        return (
            <img src={generic} alt="Default icon" />
        )
    }


    return (
        <img src={mapping} alt="Attachment icon display" />
    )

}