import React, { useEffect, useState } from "react";
import { ConceptType } from "../../../models/common/CommonEnums";
import { HelperExtension } from "../../../services/HelperExtension";
import { ConceptLinkStore, ConceptLinkStoreData } from "../../../services/stores/ConceptLink/ConceptLinkStore";
import { ConceptLinkComponent } from "../../conceptlink/ConceptLinkComponent";
import { ConceptLinkViewerComponent } from "../../conceptlink/ConceptLinkViewerComponent";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../../elements/SectionHeaderComponent";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";

interface ConceptLinkSectionProps {
    conceptLinkStore: ConceptLinkStore;

    sourceConceptType: ConceptType;
    targetConceptType: ConceptType;
    isEditing: boolean;

    customization?: ConceptLinkSectionCustomization;
}

interface ConceptLinkSectionCustomization {
    singleItemHeaderText: string;
    manyItemsHeaderText: string;

    buttonIcon: string;
    buttonText: string;
    useNormalSizeCard: boolean;

    displayAttachMessage?: boolean;
    customContent?: JSX.Element
}

export const ConceptLinkSection = (props: ConceptLinkSectionProps) => {

    const GetDefaultHeaderText = () => {
        return HelperExtension.GetConceptNamePlural(props.targetConceptType) + " linked to this " + HelperExtension.GetConceptNameSingular(props.sourceConceptType)?.toLowerCase();
    }

    const GetConceptTheme = () => {
        switch (props.targetConceptType) {
            case ConceptType.Problems:
                return "problem";
            case ConceptType.Solutions:
                return "solution";
            default:
                return "insight";
        }
    }

    return (
        <React.Fragment>
            <div className="d-flex">
                <div className="me-auto">
                    <ConceptLinkHeaderSection
                        conceptLinkStore={props.conceptLinkStore}
                        conceptType={props.targetConceptType}
                        defaultHeaderText={GetDefaultHeaderText()}

                        singleItemHeaderText={props.customization?.singleItemHeaderText}
                        manyItemsHeaderText={props.customization?.manyItemsHeaderText}
                    />
                </div>
                <div>
                    <ConceptLinkComponent conceptLinkStore={props.conceptLinkStore} conceptType={props.targetConceptType}
                        buttonIcon={props.customization?.buttonIcon}
                        buttonText={props.customization?.buttonText} />
                </div>
            </div>
            {props.customization?.customContent}
            <div data-theme={GetConceptTheme()}>
                <ConceptLinkViewerComponent
                    conceptLinkStore={props.conceptLinkStore}
                    conceptType={props.targetConceptType}
                    isEditing={props.isEditing}
                    useNormalCard={props.customization?.useNormalSizeCard}
                    displayAttachedMessage={props.customization?.displayAttachMessage}
                />
            </div>
        </React.Fragment>

    );
}

export const ConceptLinkSectionButtonOnly = (props: ConceptLinkSectionProps) => {

    const GetConceptTheme = () => {
        switch (props.targetConceptType) {
            case ConceptType.Problems:
                return "problem";
            case ConceptType.Solutions:
                return "solution";
            default:
                return "insight";
        }
    }

    return (
        <React.Fragment>
            <div className="my-3">
                <div className="d-flex">
                    {
                        (props.isEditing) &&
                        <div>
                            <ConceptLinkComponent conceptLinkStore={props.conceptLinkStore} conceptType={props.targetConceptType} buttonText="Link Insight" />
                        </div>
                    }
                </div>
                <div data-theme={GetConceptTheme()}>
                    <ConceptLinkViewerComponent conceptLinkStore={props.conceptLinkStore} conceptType={props.targetConceptType} isEditing={props.isEditing} />
                </div>
            </div>
        </React.Fragment>

    );
}


interface ConceptLinkHeaderSectionProps {
    conceptLinkStore: ConceptLinkStore;
    conceptType: ConceptType;

    defaultHeaderText: string;

    singleItemHeaderText?: string;
    manyItemsHeaderText?: string;
}

const ConceptLinkHeaderSection = (props: ConceptLinkHeaderSectionProps) => {

    const [headerText, setHeaderText] = useState(props.defaultHeaderText);

    useEffect(() => {

        if (!props.singleItemHeaderText || !props.manyItemsHeaderText) {
            return;
        }

        const hubContext = MessageHubContext()
            .Subscribe(props.conceptLinkStore, (data: ConceptLinkStoreData) => {
                const linkCount = data.conceptLinkList.filter(link => link.conceptType === props.conceptType).length;

                if (linkCount > 1) {
                    setHeaderText(props.manyItemsHeaderText ?? "");
                }
                else {
                    setHeaderText(props.singleItemHeaderText ?? "");
                }
            });

        setHeaderText(props.singleItemHeaderText);

        return () => hubContext.Dispose();
    }, []);

    return (
        <SectionHeaderComponent iconName={HelperExtension.GetConceptIcon(props.conceptType)}
            header={headerText}
            iconSize={SectionHeaderIconSize.Medium} />
    )
}