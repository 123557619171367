import { useEffect, useState } from "react";
import { ConceptType } from "../../models/common/CommonEnums";
import { INotificationHub } from "../../models/common/INotificationHub";
import { ConceptDataModel } from "../../models/ConceptDataModel";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { TagCreateService } from "../../services/stores/Tag/TagCreateStore";

interface SaveButtonComponentProps {
    ButtonClick: () => any;
    notificationHub: INotificationHub;
    validatorType: TempValidatorType;
    className?: string;
    disableAutoClose?: boolean;
    conceptType?: ConceptType;
    displayText?: string;
}

export enum TempValidatorType {
    Concept,
    Workspace,
    Folder,
    Problem,
    Solution,
    Tag,
    MemberInvitation
}

export const SaveButtonComponent = (props: SaveButtonComponentProps) => {

    const [isEnabled, setIsEnabled] = useState(false);

    const IsValidString = (value: string) => {
        if (!value) {
            return false;
        }
        return Boolean(value.match(/[A-Z]/i));
    }

    const isValidEmail = (email: string) => {
        //const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]+$/;
        const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return regex.test(email);
    };

    const OnModelChange = (x: any) => {

        if (props.validatorType === TempValidatorType.Concept) {
            if (!IsValidString(x.title)) {
                setIsEnabled(false);
            }
            else {
                setIsEnabled(true);
            }
        }
        else if (props.validatorType === TempValidatorType.Solution) {

            const concept = x as ConceptDataModel;

            const isTitleValid = IsValidString(concept.title);
            if (!isTitleValid) {
                setIsEnabled(false);
                return;
            }

            if (!concept.solutionData) {
                setIsEnabled(false);
                return;
            }

            setIsEnabled(true);
            // if (concept.solutionData.useFreeText) {
            //     setIsEnabled(IsValidString(concept.description));
            // }
            // else {
            //     const isValid =
            //         IsValidString(concept.solutionData.believeText) &&
            //         IsValidString(concept.solutionData.resultText) &&
            //         IsValidString(concept.solutionData.evidenceText);

            //     setIsEnabled(isValid);
            // }
        }
        else if (props.validatorType === TempValidatorType.Problem) {

            const concept = x as ConceptDataModel;
            const isTitleValid = IsValidString(concept.title);

            if (!isTitleValid) {
                setIsEnabled(false);
                return;
            }

            if (!concept.problemData) {
                setIsEnabled(false);
                return;
            }

            setIsEnabled(true);

            // if (concept.problemData.useFreeText) {
            //     setIsEnabled(IsValidString(concept.description));
            // }
            // else {
            //     const isValid =
            //         IsValidString(concept.problemData.whenText) &&
            //         IsValidString(concept.problemData.wantText) &&
            //         IsValidString(concept.problemData.soText) &&
            //         IsValidString(concept.problemData.becauseText);

            //     setIsEnabled(isValid);
            // }
        }
        else if (props.validatorType === TempValidatorType.Workspace) {
            setIsEnabled(IsValidString(x.workspaceName));
        }
        else if (props.validatorType === TempValidatorType.Folder) {
            setIsEnabled(IsValidString(x.folder?.folderName) && (x.insightSelected || x.solutionSelected || x.problemSelected));
        }
        else if (props.validatorType === TempValidatorType.Tag) {
            let initialValidation: boolean = IsValidString(x.tag?.TagDescription) && (x.insightSelected || x.solutionSelected || x.problemSelected);

            if (initialValidation && props.conceptType) {
                if (new TagCreateService().alreadyExists(props.conceptType, x)) {
                    initialValidation = false;
                }
            }

            setIsEnabled(initialValidation);
        }
        else if (props.validatorType === TempValidatorType.MemberInvitation) {

            const isValidEmailResult = isValidEmail(x.emailAddress);
            setIsEnabled(isValidEmailResult);

        }
    }

    useEffect(() => {
        const messageHubContext = MessageHubContext()
            .Subscribe(props.notificationHub, OnModelChange);

        return () => { messageHubContext.Dispose() };
    });

    return (
        <button type="button" className={"btn btn-primary btn-concept btn-medium " + props.className ?? ""} disabled={!isEnabled} onClick={props.ButtonClick} data-bs-dismiss={(props.disableAutoClose) ? undefined : "modal"}>
            {(props.displayText) ? props.displayText : "Save"}
        </button>
    );
}
