import { RecentEventsComments } from "./RecentEventsComments"
import { RecentEventsConcept } from "./RecentEventsConcept"

export const RecentEventsSection = () => {


    return (
        <div className="recent-events-section pt-3">
            <div>
                <div className="fw-large-700">Recently added & edited</div>
                <div className="pt-3">
                    <RecentEventsConcept />
                </div>
            </div>
            <div>
                <div className="fw-large-700">Recent comments</div>
                <div className="pt-3">
                    <RecentEventsComments />
                </div>
            </div>
        </div>
    )
}