import { Subject } from "rxjs";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { ConceptLinkSummaryLoadActionResponse } from "../../../models/communication/actions/ConceptLinkSummaryLoadAction";
import { ConceptLinksSummaryModel } from "../../../models/ConceptDataModel";

const initialState = {
    conceptID: "",
    linkSummary: {} as ConceptLinksSummaryModel
}

export class ConceptLinkSummaryStore implements INotificationHub {
    storageState = initialState;
    conceptLinkSummarySubject = new Subject();

    subscribe(OnMessageReceive: any) {
        return this.conceptLinkSummarySubject.subscribe(OnMessageReceive);
    }

    initialize(conceptID: string, conceptLinkSummary: ConceptLinksSummaryModel) {
        this.storageState = {
            conceptID: conceptID,
            linkSummary: conceptLinkSummary
        };

        this.conceptLinkSummarySubject.next(this.storageState.linkSummary);
    }

    updateData(conceptLinkUpdated: ConceptLinkSummaryLoadActionResponse) {

        if (!conceptLinkUpdated?.conceptLinkSummary) {
            return;
        }

        if (conceptLinkUpdated.conceptID !== this.storageState.conceptID) {
            return;
        }

        this.storageState = {
            ...this.storageState,
            linkSummary: conceptLinkUpdated.conceptLinkSummary
        }

        this.conceptLinkSummarySubject.next(this.storageState.linkSummary);
    }
}