import React, { useEffect, useState } from "react";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../../elements/SectionHeaderComponent";
import { TextDisplayComponent } from "../../elements/TextDisplayComponent";
import { IDataStore } from "../../../models/common/IDataStore";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { ConceptDataModel } from "../../../models/ConceptDataModel";
import { HelperExtension } from "../../../services/HelperExtension";

interface OptionalSectionDisplayProps {
    dataStore: IDataStore;
    fieldPath: string;

    headerText: string;
    headerIcon: string;
}

export const OptionalSectionDisplay = (props: OptionalSectionDisplayProps) => {

    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const hubContext = MessageHubContext()
            .Subscribe(props.dataStore as any as INotificationHub, (conceptModel: ConceptDataModel) => {
                const currentValue = HelperExtension.GetPropertyValue(props.fieldPath, conceptModel);

                if (currentValue) {
                    setIsVisible(true);
                }
                else {
                    setIsVisible(false);
                }

            });

        return () => hubContext.Dispose();
    }, []);


    if (!isVisible) {
        return <React.Fragment></React.Fragment>
    }



    return (
        <React.Fragment>
            <hr className="mx-auto w-100" />
            <div>
                <SectionHeaderComponent iconName={props.headerIcon} header={props.headerText} iconSize={SectionHeaderIconSize.Medium} />
                <div className="pt-2 display-lines">
                    <TextDisplayComponent fieldName={props.fieldPath} dataStore={props.dataStore} />
                </div>
            </div>
        </React.Fragment>
    );
}