import { memo } from "react";
import { useSearchParams } from "react-router-dom";
import { VoyceQueryString } from "../../../models/common/CommonEnums";
import { ConceptService } from "../../../services/ConceptService";
import { FeedbackCommandHandler } from "./FeedbackCommandHandler";

export const ExternalCommandHandler: React.FunctionComponent
    = memo(function TestX() {

        const [queryString] = useSearchParams();

        const isFeedbackAction = queryString.get(VoyceQueryString.SpecialFolder) === VoyceQueryString.FeedbackFolder;

        if (isFeedbackAction) {
            ConceptService.loadOnInitialize = false;
        }

        return (
            <>
                {
                    (isFeedbackAction) && <FeedbackCommandHandler />
                }
            </>
        )
    })

