import { useEffect, useState } from "react";
import { ConceptDataModel } from "../../../models/ConceptDataModel";
import { ConceptEditorService } from "../../../services/concepts/ConceptEditorService";
import { HelperExtension } from "../../../services/HelperExtension";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../../elements/SectionHeaderComponent";

interface ConceptHeaderSectionProps {
    store: ConceptEditorService;
}

export const ConceptHeaderSection = (props: ConceptHeaderSectionProps) => {

    const [headerIcon, setHeaderIcon] = useState("");
    const [headerTitle, setHeaderTitle] = useState("");

    useEffect(() => {
        const hubContext = MessageHubContext()
            .Subscribe(props.store.conceptEditorStore, (conceptModel: ConceptDataModel) => {
                //setHeaderTitle(conceptModel.title);
                setHeaderIcon(HelperExtension.GetConceptIcon(conceptModel.conceptType));
                setHeaderTitle(HelperExtension.GetConceptName(conceptModel.conceptType, 1));
            });

        return () => hubContext.Dispose();
    }, []);

    return (
        <SectionHeaderComponent iconName={headerIcon}
            header={headerTitle}
            iconSize={SectionHeaderIconSize.Medium}
            fontSizeStyle="fw-reg-500"
            headerStyle="icon-bright fw-med-700" />
    )
}