import React, { useEffect, useState } from "react";
import { GlobalNotifications } from "../../../models/common/CommonEnums";
import { ConceptViewerNotificationModel } from "../../../models/communication/internal/ConceptViewerNotificationModel";
import { ConceptDataModel } from "../../../models/ConceptDataModel";
import { ConceptSearchService } from "../../../services/concepts/ConceptSearchService";
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { CardDisplaySmallComponent } from "../../card/CardDisplaySmallComponent";
import { CommonElements } from "../../../models/CommonElements";

interface ConceptKeywordSearchDisplayProps {
    dropdownUID: string;
}

export const ConceptKeywordSearchDisplay = (props: ConceptKeywordSearchDisplayProps) => {
    const [searchResult, setSearchResult] = useState([] as ConceptDataModel[]);

    const OnOpenConceptClick = async (concept: ConceptDataModel) => {
        global.window.closeCustomDropdown(props.dropdownUID);

        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.ViewConcept, { conceptID: concept.conceptID } as ConceptViewerNotificationModel);
        global.window.openModal(CommonElements.ModalElement);
    }

    useEffect(() => {
        const messageHubContext = MessageHubContext()
            .Subscribe(ConceptSearchService, (conceptList: ConceptDataModel[]) => {
                setSearchResult(conceptList);
            });

        return () => {
            messageHubContext.Dispose();
        };
    }, []);


    return (
        <React.Fragment>
            <div className="bg-header text-white p-3 ps-4 rounded-top d-flex align-items-center">
                <div className="fw-large-500">
                    Keyword search
                </div>
                <div className="m-auto"></div>
                <div><button type="button" className="btn-close btn-close-white" onClick={() => { global.window.closeCustomDropdown(props.dropdownUID); }}></button></div>
            </div>
            <div className="vc-editor vc-list-group pt-2" >
                <div className="ps-3 px-2 py-0 overflow-auto vc-custom-scroll drop-body">
                    <div>
                        {
                            searchResult.map((conceptItem) => {
                                return (<CardDisplaySmallComponent
                                    //Removes any caching
                                    key={Math.random().toString(36)}
                                    conceptModel={conceptItem}
                                    onLinkClick={async () => { }}
                                    // Linked cards are not displayed
                                    isLinked={false}
                                    isEditing={false}

                                    enableTitleClick={true}
                                    onTitleClick={OnOpenConceptClick}
                                />);
                            })
                        }
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}