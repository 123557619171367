import React, { useRef, useState } from "react";
import { IDataStore } from "../../models/common/IDataStore"

interface ColorPickerComponentProps {
    dataStore: IDataStore;
    fieldPath: string;
}

export const ColorPickerComponent = (props: ColorPickerComponentProps) => {

    const colorPickerVisibleRef = useRef<HTMLDivElement>(null);
    const [currentColor, setCurrentColor] = useState("#FF0000")

    const OnColourChange = (newColor: string) => {
        props.dataStore.sendMessage(props.fieldPath, newColor);
        if (colorPickerVisibleRef.current) {
            colorPickerVisibleRef.current.style.backgroundColor = newColor;
        }
    }

    return (
        <div ref={colorPickerVisibleRef}
            className="color-picker-component">
            <input type="color" value={currentColor} className="color-picker-input"
                onChange={e => {
                    OnColourChange(e.target.value);
                    setCurrentColor(e.target.value);
                }} />
        </div>

    )
}