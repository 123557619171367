import { useAuth } from "react-oidc-context";
import React, { useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
//#future-reference
//import { HTML5Backend } from "react-dnd-html5-backend";
import { TouchBackend } from "react-dnd-touch-backend";
import { Outlet } from "react-router-dom";
import { ConceptType, PageType } from "../models/common/CommonEnums";
import { LoadingPage } from "../pages/loading/LoadingPage";
import { VoyceContextProvider } from "../services/context/AppContext";
import { MessageService } from "../services/messaging/MessageService";
import { GlobalStore } from "../services/stores/GlobalStore";
import { InitializeTheme } from "../services/ThemeService";
import { HeaderComponent } from "./HeaderComponent";
import MainSidebar from "./MainSidebar";
import { debounce } from "lodash";
import { AuthRedirectCache } from "../services/authentication/AuthRedirectCache";
import { LogCollector } from "../services/logger/LogCollector";
import { MobileComponentDetector } from "../pages/comingsoon/MobilePage";


interface PrivateLayoutProps {
    pageType: PageType
}

const PrivateLayout: React.FunctionComponent<PrivateLayoutProps> = (props) => {

    const auth = useAuth();
    const [isReadyToUse, setIsReadyToUse] = useState(false);

    GlobalStore.loadWorkspace();


    useEffect(() => {
        if (!auth.isLoading && auth.user?.access_token !== null && auth.user?.access_token !== undefined) {
            MessageService.UpdateAcessToken(auth.user?.access_token as string);
            MessageService.connectAsync().then(() => {
                setIsReadyToUse(true);
            });
        }
    }, [auth]);

    const debouncedChangeHandler = useMemo(
        () => debounce(async () => {

            try {

                await auth.querySessionStatus();
                await auth.signinSilent();
            } catch {
                await auth.signinRedirect({
                    state: AuthRedirectCache.create()
                    // extraQueryParams: {
                    //     newParameter: "test"
                    // }
                });
            }
        }, 200)
        , []);

    useEffect(() => {
        return () => debouncedChangeHandler.cancel();
    }, [debouncedChangeHandler]);

    useEffect(() => {
        if (!auth.isAuthenticated) {
            if (!auth.isLoading) {
                debouncedChangeHandler();
            }
        }
    }, [auth.isLoading, debouncedChangeHandler]);


    LogCollector.LogMessage("BaseLayout: render");

    let currentPage = ConceptType.Default;

    switch (props.pageType) {
        case PageType.Insights:
            currentPage = ConceptType.Insights;
            break;
        case PageType.Problems:
            currentPage = ConceptType.Problems;
            break;
        case PageType.Solutions:
            currentPage = ConceptType.Solutions;
            break;
    }

    InitializeTheme(currentPage);


    const RenderMainContent = () => {
        if (!isReadyToUse) {
            LogCollector.LogMessage("Still loading!!!");
            return (
                <LoadingPage />
            );
        }

        const touchOptions = { enableMouseEvents: true };

        return (
            <React.Fragment>
                <DndProvider backend={TouchBackend} options={touchOptions}>
                    <VoyceContextProvider conceptType={currentPage}>
                        <Outlet />
                    </VoyceContextProvider>
                </DndProvider>
                <MobileComponentDetector />
            </React.Fragment>
        );
    }



    return (
        <div className="d-flex flex-nowrap">
            <MainSidebar pageType={props.pageType} />
            <div className="container-fluid p-0">
                <HeaderComponent currentPage={currentPage} currentPageType={props.pageType} />
                <main className="p-0">
                    <RenderMainContent />
                </main>
            </div>
        </div>
    );
}

export default PrivateLayout;