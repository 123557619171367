

class LogCollectorImplementation {

    LogError(message: any) {
        console.error(message);
    }

    LogWarn(message: any) {
        //console.warn(message);
    }

    LogMessage(message: any) {
        //console.log(message);
    }
}

export const LogCollector = new LogCollectorImplementation();