import React, { memo, useEffect, useState } from "react";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { TagDataModel } from "../../models/data/TagDataModel";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { ConceptTagStore, ConceptTagStoreModel } from "../../services/stores/Tag/ConceptTagStore";
import { TagEditorStore } from "../../services/stores/Tag/TagEditorStore";
import { TagModel, TagStoreData } from "../../services/stores/Tag/TagStore";
import { TextSearchData, TextSearchStore } from "../../services/stores/TextSearchStore";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../elements/SectionHeaderComponent";
import { TextBoxKeywordSearchComponent } from "../elements/TextBoxKeywordSearchComponent";
import { TagCreateComponent } from "./TagCreateComponent";
import { TagSingleItemComponent } from "./TagSingleItemComponent";
import { LogCollector } from "../../services/logger/LogCollector";


interface TagContentComponentProps {
    conceptTagStore: ConceptTagStore;
    dropdownKey: string;
    selectionOnly: boolean;
    disableDelete: boolean;
}

export const TagContentComponent: React.FunctionComponent<TagContentComponentProps>
    = memo(function TagContent({ conceptTagStore, dropdownKey, selectionOnly, disableDelete }) {
        const [searchStore] = useState(new TextSearchStore());
        const [openCreateTagPage, setOpenCreatePage] = useState(false);

        const SwitchTagPage = (openCreateTag: boolean) => {
            setOpenCreatePage(openCreateTag);

        }

        const CloseDropdown = () => {
            global.window.closeCustomDropdown(dropdownKey);
        }

        LogCollector.LogMessage("TagContent:Render")

        if (openCreateTagPage) {
            return (
                <TagCreateComponent
                    currentValue={searchStore.storageState.filterValue}
                    conceptType={conceptTagStore.storageState.conceptType}
                    closeParent={CloseDropdown}
                    closeCreateTag={() => SwitchTagPage(false)}

                />
            )
        }

        return <TagDisplayView
            CloseDropdown={CloseDropdown}
            SwitchTagPage={SwitchTagPage}
            conceptTagStore={conceptTagStore}
            searchStore={searchStore}
            selectionOnly={selectionOnly}
            disableDelete={disableDelete}
        />
    });

interface TagDisplayViewProps {
    conceptTagStore: ConceptTagStore;
    searchStore: TextSearchStore;
    CloseDropdown: () => void;
    SwitchTagPage: (v: boolean) => void;
    selectionOnly: boolean;
    disableDelete: boolean;
}

const TagDisplayView = (props: TagDisplayViewProps) => {

    const [editorStore] = useState(new TagEditorStore());
    const [tagList, setTagList] = useState<TagModel[]>([]);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {

        const hubContext = MessageHubContext()
            .Subscribe(props.conceptTagStore, (c: ConceptTagStoreModel) => {
                editorStore.initialize(c);
            })
            .Subscribe(editorStore, (tags: TagStoreData) => {

                if (!props.conceptTagStore.syncWithServer) {

                    props.conceptTagStore.updateData(tags.tagList.filter(t => t.isSelected).map((m) => {
                        return {
                            tagID: m.TagID,
                            tagDescription: m.TagDescription
                        } as TagDataModel;
                    }));
                }
                setTagList(tags.tagList);
            })
            .Subscribe(props.searchStore, (textSearch: TextSearchData) => {
                setSearchText(textSearch.filterValue ?? "");
            })
            .ListenGlobalNotification([GlobalNotifications.TagStoreUpdated], () => {
                editorStore.reload();
            });

        editorStore.syncWithServer = props.conceptTagStore.syncWithServer;
        props.conceptTagStore.notify();

        return () => hubContext.Dispose();

    }, [props.conceptTagStore, editorStore, props.searchStore]);


    const RenderTags = () => {
        return tagList.filter(tag => {
            return tag.TagDescription.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
        }).sort((t, t1) => t.isSelected < t1.isSelected ? 1 : -1)
            .map((tag) => {
                return <TagSingleItemComponent key={tag.TagID} conceptTag={tag} tagStore={editorStore} disableDelete={props.disableDelete} CloseDropdown={props.CloseDropdown} />
            });
    }

    return (
        <React.Fragment>
            <div className="bg-header text-white p-3 ps-3 rounded-top d-flex">
                <div className="flex-fill">
                    <SectionHeaderComponent iconName="#tags-icon"
                        header={props.selectionOnly ? "Tag filter" : "Add tag"}
                        iconSize={SectionHeaderIconSize.Medium} headerStyle="icon-bright" />
                </div>
                <div><button type="button" className="btn-close btn-close-white" onClick={props.CloseDropdown}></button></div>
            </div>

            <div className="vc-editor vc-list-group">
                <div className="p-3 pb-2 d-flex">
                    <div className="flex-fill">
                        <TextBoxKeywordSearchComponent dataStore={props.searchStore} fieldName="filterValue" maxLength={50} placeholder="Search existing" />
                    </div>
                    {
                        (!props.selectionOnly) &&
                        <div className="ps-2">
                            {/* <CreateNewTagComponent /> */}
                            <button className="btn btn-primary btn-concept-outline btn-mod-createtag"
                                onClick={() => props.SwitchTagPage(true)}
                            >Create tag</button>
                        </div>
                    }
                </div>

                <div className="px-3 overflow-auto vc-custom-scroll" style={{ height: "268px" }}>
                    {RenderTags()}
                </div>
            </div>
            <div className="mx-3 mt-1">
                <button className="btn btn-primary btn-concept w-100" onClick={props.CloseDropdown}>Done</button>
                <div id="fakeDiv" style={{ outline: "0px" }} tabIndex={-1}></div>
            </div>
        </React.Fragment >
    )
}