
export interface SectionHeaderComponentProps {
    iconName: string;
    header: string;
    description?: string;

    iconSize?: SectionHeaderIconSize;
    headerStyle?: string;
    fontSizeStyle?: string;
}

export enum SectionHeaderIconSize {
    Regular,
    Medium
}

export const SectionHeaderComponent = (props: SectionHeaderComponentProps) => {

    const GetIconSize = () => {
        return props.iconSize === SectionHeaderIconSize.Medium
            ? "icon-medium-size"
            : "icon-regular-size";
    }

    const GetColorStyle = () => {
        if (!props.headerStyle) {
            return "fw-concept";
        }

        return props.headerStyle;
    }

    const GetFontSize = () => {

        if (props.fontSizeStyle) {

            return props.fontSizeStyle;;
        }

        return "fw-large-700";
    }

    return (
        <div className={GetFontSize() + " " + GetColorStyle()}>
            <svg className={"p-none " + GetIconSize()} role="img"><use xlinkHref={props.iconName}></use></svg>
            <span className="ps-2 align-middle text-break">
                {props.header}
                {
                    (props.description) && (<span className="ps-1 fw-large-400">{props.description}</span>)
                }
            </span>
        </div>
    );
}