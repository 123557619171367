import React from "react";
import { ConceptType } from "../../models/common/CommonEnums";

interface ImplementationLevelComponentProps {
    currentLevel: number;
    conceptType: ConceptType;
}

const problemDescriptionLookup = ["Not now", "Minor", "Moderate", "Major", "Critical"];
const problemStyleLookup = ["implvl-1", "implvl-2", "implvl-3", "implvl-4", "implvl-5"];

const solutionDescriptionLookup = ["Distraction", "Risky", "Reasonable", "Good value", "Quick win!"];
const solutionStyleLookup = ["implvl-5", "implvl-4", "implvl-3", "implvl-2", "implvl-1"];

export const ImplementationLevelComponent = (props: ImplementationLevelComponentProps) => {

    //const implementationLevel = [1, 2, 3, 4, 5];

    if (!props.currentLevel) {
        return (<React.Fragment></React.Fragment>)
    }

    let message = props.conceptType === ConceptType.Problems
        ? problemDescriptionLookup[props.currentLevel - 1]
        : solutionDescriptionLookup[props.currentLevel - 1]

    let style = props.conceptType === ConceptType.Problems
        ? problemStyleLookup[props.currentLevel - 1]
        : solutionStyleLookup[props.currentLevel - 1]

    return (
        <div className={"implementation-level text-nowrap fw-xsmall-400 " + style}>
            {message}
        </div>
    )
    // return (
    //     <div className="d-flex" style={{ alignItems: "center", marginLeft:"5px" }}>
    //         {implementationLevel.map(level => {
    //             return (<div key={"implementationLevel" + level} className={"rounded-circle" + (level <= props.currentLevel ? " bg-header" : "")} style={{ width: "10px", height: "10px", marginRight: "2px", backgroundColor: "#C4C4C4" }}></div>)
    //         })}


    //     </div>
    // );
}