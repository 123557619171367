import React from "react";
import { CSSProperties } from "react";
import { useDragLayer, XYCoord } from "react-dnd";
import { DraggableElement } from "../../models/common/CommonEnums";
import { DraggableContent } from "../card/CardDisplayDraggableComponent";
import { CardDisplayDraggablePreview } from "../card/CardDisplayDraggablePreview";

export const DraggableLayerComponent = () => {
    const { itemType, isDragging, item, currentOffset } =
        useDragLayer((monitor) => ({
            item: monitor.getItem() as DraggableContent,
            itemType: monitor.getItemType(),
            //initialOffset: monitor.getInitialSourceClientOffset(),
            currentOffset: monitor.getSourceClientOffset(),
            //c: monitor.getInitialClientOffset(),
            //initialCursorOffset: monitor.getInitialClientOffset(),
            //cursorOffset: monitor.getClientOffset(),
            //initialCursorOffset: monitor.getDifferenceFromInitialOffset(),
            isDragging: monitor.isDragging(),
        }));

    function renderItem() {
        switch (itemType) {
            case DraggableElement.Concept:
                return <CardDisplayDraggablePreview conceptModel={item.concept} />
            default:
                return null;
        }
    }

    function getDraggablePosition(currentOffset: XYCoord | null) {
        if (!currentOffset) {
            return {
                display: 'none',
            }
        }

        let { x, y } = currentOffset;

        const transform = `translate(${x}px, ${y}px) rotate(-1deg)`
        return {
            transform,
            WebkitTransform: transform
        } as CSSProperties
    }

    if (!isDragging) {
        return null
    }
    return (
        <div className="voyce-card-draggable-layer">
            <div className="voyce-card-draggable"
                style={getDraggablePosition(currentOffset)} >
                {renderItem()}
            </div>
        </div>
    )
}