import { ConceptType } from "../models/common/CommonEnums";

export function InitializeTheme(voyceTheme: ConceptType) {

    var root = document.getElementById("root");
    if (root != null) {

        if (voyceTheme === ConceptType.Insights) {
            root.setAttribute("data-theme", "insight");
        }
        else if(voyceTheme === ConceptType.Problems) {
            root.setAttribute("data-theme", "problem");
        }
        else if(voyceTheme === ConceptType.Solutions) {
            root.setAttribute("data-theme", "solution");
        }
        else if ( voyceTheme === ConceptType.Default){
            root.setAttribute("data-theme", "workspace");
        }
        else{
            root.removeAttribute("data-theme")
        }
    }
}