import { memo, useEffect, useState } from "react";
import { IDataStore } from "../../models/common/IDataStore";
import { INotificationHub } from "../../models/common/INotificationHub";
import { HelperExtension } from "../../services/HelperExtension";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";

export interface CheckboxComponentProps {
    fieldPath: string;
    description: string;
    dataStore: IDataStore;
}


export const CheckboxComponent: React.FunctionComponent<CheckboxComponentProps>
    = memo(function CheckboxComponentImplementation({ fieldPath, description, dataStore }) {

        const [isSelected, setIsSelected] = useState(false);

        useEffect(() => {
            const notify = dataStore as any as INotificationHub;
            if (!notify) {
                return;
            }

            const hubContext = MessageHubContext()
                .Subscribe(notify, (obj: any) => {

                    const isSelectedValue = HelperExtension.GetPropertyValue(fieldPath, obj);
                    if (isSelectedValue === "true") {
                        setIsSelected(true);
                    }
                    else {
                        setIsSelected(false);
                    }
                });

            return () => hubContext.Dispose();

        }, [dataStore]);

        const OnSelectChange = () => {
            const newSelectedState = !isSelected;

            dataStore.sendMessage(fieldPath, String(newSelectedState));
            setIsSelected(newSelectedState);

        }

        return (
            <div className="d-flex vc-item align-items-center" onClick={OnSelectChange}>
                <input className="form-check-input m-0 me-2" type="checkbox" aria-label="Change Tag" checked={isSelected} onChange={() => { }} />
                <div>
                    {description}
                </div>
            </div>
        );
    })