
export const HelpUsComponent = () => {


    return (
        <div className="alert-help-us">
            <div className="alert-body d-flex align-items-center" role="button" onClick={() => {
                window.open("https://feedback.simplyvoyce.com/ff337d4d02", "_blank");
            }}>
                <div>
                    <svg className="pe-none" style={{paddingTop:"3px"}} width="32" height="32" role="img" aria-label="Link"><use xlinkHref="#voyce-logo-white"></use></svg>
                </div>
                <div className="ps-2">
                    <div className="fw-small-500">
                        Help us improve
                    </div>
                    <div className="fw-xsmall-400">
                        Voyce Feedback
                    </div>
                </div>
            </div>
        </div>

    )
}