import { useState } from "react";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { ErrorNotificationModel } from "../../models/communication/internal/ErrorNotificationModel";
import { AttachmentDataModel, AttachmentType } from "../../models/data/AttachmentDataModel";
import { GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { FailureReasons, UploadService } from "../../services/upload/UploadService";
import { UploadStore } from "../../services/upload/UploadStore";
import { SectionHeaderComponent } from "../elements/SectionHeaderComponent";
import { ErrorDisplayComponent } from "../notification/ErrorDisplayComponent";
import { ContentUploadProgress } from "./components/ContentUploadProgress";
import { ConceptAttachmentStore } from "../../services/stores/ConceptAttachment/ConceptAttachmentStore";
import { AttachmentHelperExtension } from "../../services/helpers/AttachmentHelperExtension";
import { AttachmentDisplayComponent } from "./components/AttachmentDisplayComponent";
import { LogCollector } from "../../services/logger/LogCollector";

export interface ConceptAttachmentComponentProps {
    attachmentStore: ConceptAttachmentStore;
}

export const ConceptAttachmentComponent = (props: ConceptAttachmentComponentProps) => {

    const [uploadStore] = useState(new UploadStore());
    const [isUploading, setIsUploading] = useState(false);
    const [inputKey, setInputKey] = useState(Math.random().toString(36));
    const [errorComponentKey] = useState(Math.random().toString(36));

    const onFileChange = (event: any) => {
        if (!event.target.files[0]) {
            return;
        }

        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertClose, { errorComponentKey: errorComponentKey } as ErrorNotificationModel);
        uploadStore.updateProgress(1);
        setIsUploading(true);

        const uploadUID = crypto.randomUUID();
        const uploadingFile = event.target.files[0];


        const newAttachment = {
            attachmentID: uploadUID,

            attachmentType: AttachmentType.Attachment,

            displayName: uploadingFile.name,
            fileName: uploadingFile.name,
            fileContentType: uploadingFile.type,
            fileSize: (uploadingFile.size / 1024 / 1024),
            fileExtension: AttachmentHelperExtension.GetFileExtension(uploadingFile.name),
            uploadFileName: AttachmentHelperExtension.GetFileName(uploadingFile.name, uploadUID),


            isNew: true

        } as AttachmentDataModel;

        const uploadService = new UploadService({
            uploadStore: uploadStore,
            attachmentStore: props.attachmentStore,
            maxFileSize: 10
        });
        
        uploadService.uploadAttachment(newAttachment, uploadingFile)
            .catch((reason: FailureReasons) => {
                switch (reason) {
                    case FailureReasons.FileTooBig:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "The file must be smaller than 10MB." } as ErrorNotificationModel);
                        break;
                    case FailureReasons.NoneSpecified:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "Could not upload the file. Try again later." } as ErrorNotificationModel);
                        break;
                    case FailureReasons.FileMustBeImage:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "You must upload an image." } as ErrorNotificationModel);
                        break;
                    case FailureReasons.NotEnoughSpaceToUpload:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "Total file storage reached for the account.", errorDetails: ["Max file storage size: 1TB", "You can delete old/unused attachments to release the space"] } as ErrorNotificationModel);
                        break;
                }
            })
            .finally(() => {
                LogCollector.LogMessage("...................... upload completed");
                uploadService.dispose();

                uploadStore.updateProgress(0);
                setIsUploading(false);
                setInputKey(Math.random().toString(36));
            });
    };

    return (
        <div>
            <div className="d-flex">
                <div className="me-auto">
                    <SectionHeaderComponent iconName="#attach-icon" header="File attachments" />
                    <div className="fw-small-400">Maximum file size per file of 10MB</div>
                </div>
                <div>
                    <button type="button" className="btn btn-light btn-concept-lighter btn-xsmall text-nowrap" disabled={isUploading}
                        onClick={() => {
                            document.getElementById("fileSelection")?.click();
                        }}
                    >+ Attach</button>
                    <input type="file" onChange={onFileChange} id="fileSelection" style={{ display: "none" }} key={inputKey || ''} />
                </div>
            </div>
            <div className="mt-2">
                <AttachmentDisplayComponent attachmentStore={props.attachmentStore} />
            </div>
            <div className="mt-3">
                <ErrorDisplayComponent errorComponentKey={errorComponentKey} />
            </div>
            <div>
                <ContentUploadProgress uploadStore={uploadStore} />
            </div>
        </div>
    );
}