import { debounce } from "lodash";
import { PropsWithChildren, useEffect, useMemo } from "react";
import { useAuth } from "react-oidc-context";
import { AuthRedirectCache } from "../../services/authentication/AuthRedirectCache";
import React from "react";
import { useNavigate } from "react-router-dom";

interface AuthLoaderProps {
    extraQueryParamenters: any;
}

export const AuthLoader = (props: PropsWithChildren<AuthLoaderProps>) => {
    const auth = useAuth();
    const navigate = useNavigate();

    const debouncedChangeHandler = useMemo(
        () => debounce(async () => {

            try {
                await auth.querySessionStatus();
                await auth.signinSilent();

                navigate("/workspace/");
            } catch {
                //localStorage.setItem
                await auth.signinRedirect({
                    state: AuthRedirectCache.createWithUrl("/workspace/"),
                    extraQueryParams: props.extraQueryParamenters
                    // extraQueryParams: {
                    //     newParameter: "test"
                    // }
                    ,
                });
            }
        }, 200)
        , []);

    useEffect(() => {
        return () => debouncedChangeHandler.cancel();
    }, [debouncedChangeHandler]);

    useEffect(() => {
        if (!auth.isAuthenticated) {
            if (!auth.isLoading) {
                debouncedChangeHandler();
            }
        }
        else {
            navigate("/workspace/");
        }
    }, [auth.isLoading, debouncedChangeHandler]);

    return (
        <React.Fragment>{props.children}</React.Fragment>
    )
}