import React from "react";
import { memo } from "react";
import { ConceptType } from "../../../models/common/CommonEnums";
import { useAppContext } from "../../../services/context/AppContext";
import { ConceptEditorStore } from "../../../services/stores/ConceptEditorStore";
import { ProblemImpactComponent } from "../problems/elements/ProblemImpactComponent";
import { SolutionImplementationValueComponent } from "../solutions/elements/SolutionImplementationValueComponent";

interface ConceptImpactComponentProp {
    conceptStore: ConceptEditorStore;
}

export const ConceptImpactComponent: React.FunctionComponent<ConceptImpactComponentProp>
    = memo(function ConceptImpact({ conceptStore }) {

        const context = useAppContext();

        if (context.conceptType === ConceptType.Solutions) {
            return <SolutionImplementationValueComponent conceptStore={conceptStore} />
        }

        return <ProblemImpactComponent conceptStore={conceptStore} />
    })