import React, { useEffect, useMemo, useRef, useState } from "react";
import { ConceptDataModel } from "../../models/ConceptDataModel";
import { MessageType } from "../../models/message/IServerMessage";
import { HelperExtension } from "../../services/HelperExtension";
import { MessageHubOwner } from "../../services/messaging/MessageHubOwner";
import { IHubSubcription, MessageHubContext } from "../../services/notification/MessageHubHandler";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { debounce } from "lodash";
import { AlertTimerWarningNotificationModel } from "../../models/communication/internal/AlertTimerNotificationModel";

export const AlertTimerComponent = () => {

    return (
        <React.Fragment>
            <AlertTimerSuccessComponent />
            <AlertTimerWarningComponent />
        </React.Fragment>
    )
}

const AlertTimerSuccessComponent = () => {
    const [alertMessage, setAlertMessage] = useState("Insight saved");

    useEffect(() => {

        const OnDisplayNotification = (message?: string) => {
            if (!message) {
                return;
            }

            if (messageRef.current) {
                messageRef.current.innerText = message;
            }

            global.window.openAlert("bootstrapMainAlert");
            debouncedCloseHandler();
        }

        const messageContext = MessageHubContext()
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.ConceptCreated,
                OnReceive: (concept: ConceptDataModel) => {
                    OnDisplayNotification(HelperExtension.GetConceptSuccessMessage(concept?.conceptType));
                }
            } as IHubSubcription)
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.ConceptDeleted,
                OnReceive: (concept: ConceptDataModel) => {
                    OnDisplayNotification(HelperExtension.GetConceptDeleteMessage(concept?.conceptType))
                }
            } as IHubSubcription)
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.ConceptCommentDeleted,
                OnReceive: () => {
                    OnDisplayNotification(HelperExtension.GetCommentDeleteMessage())
                }
            } as IHubSubcription)
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.FeedbackSettingsSaved,
                OnReceive: () => {
                    OnDisplayNotification("Feedback settings updated")
                }
            } as IHubSubcription)
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.WorkspaceMembersRemoved,
                OnReceive: () => {
                    OnDisplayNotification("Member removed")
                }
            } as IHubSubcription)
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.WorkspaceMembersInvited,
                OnReceive: () => {
                    OnDisplayNotification("Member invited")
                }
            } as IHubSubcription)
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.WorkspaceMemberAlreadyInvited,
                OnReceive: () => {
                    OnDisplayNotification("Member already invited")
                }
            } as IHubSubcription)
            .ListenGlobalNotification([GlobalNotifications.FeedbackUrlCopied], () => {
                OnDisplayNotification("Link copied to clipboard")
            })
            .ListenGlobalNotification([GlobalNotifications.ImageScribbleUpdated], () => {
                OnDisplayNotification("Scribble updated")
            })
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.WorkspaceDeleted,
                OnReceive: () => {
                    OnDisplayNotification("Workspace deleted")
                }
            } as IHubSubcription)

        return () => messageContext.Dispose();
    }, [])


    const debouncedCloseHandler = useMemo(
        () => debounce(async () => {
            global.window.toggleAlert("bootstrapMainAlert");
        }, 3000)
        , []);

    useEffect(() => {
        return () => debouncedCloseHandler.cancel();
    }, [debouncedCloseHandler]);

    const messageRef = useRef<HTMLDivElement>(null);
    return (
        <div className="alert-fixed">
            <div className="d-flex justify-content-center">
                <div id="bootstrapMainAlert" className="alert alert-concept-success d-flex align-items-center fade" role="alert">
                    <svg className="p-none" width="26" height="27" role="img"><use xlinkHref="#alert-concept-success"></use></svg>
                    <div ref={messageRef} className="ps-2 fw-reg-400">
                        {alertMessage}
                    </div>
                </div>
            </div>
        </div>
    );
}

const AlertTimerWarningComponent = () => {
    const [alertMessage, setAlertMessage] = useState("Insight saved");

    useEffect(() => {

        const OnDisplayNotification = (message?: string) => {
            if (!message) {
                return;
            }

            if (messageRef.current) {
                messageRef.current.innerText = message;
            }

            global.window.openAlert("bootstrapMainWarningAlert");
            debouncedCloseHandler();
        }

        const messageContext = MessageHubContext()
            .ListenGlobalNotification([GlobalNotifications.AlertTimerError], (notification) => {
                const warningNotification = notification.notificationData as AlertTimerWarningNotificationModel;
                if (warningNotification?.warningMessage) {
                    OnDisplayNotification(warningNotification?.warningMessage)
                }
            })

        return () => messageContext.Dispose();
    }, [])


    const debouncedCloseHandler = useMemo(
        () => debounce(async () => {
            global.window.toggleAlert("bootstrapMainWarningAlert");
        }, 3000)
        , []);

    useEffect(() => {
        return () => debouncedCloseHandler.cancel();
    }, [debouncedCloseHandler]);

    const messageRef = useRef<HTMLDivElement>(null);
    return (
        <div className="alert-fixed">
            <div className="d-flex justify-content-center">
                <div id="bootstrapMainWarningAlert" className="alert alert-danger alert-concept-error d-flex align-items-center fade" role="alert">
                    <svg className="p-none" width="26" height="27" role="img"><use xlinkHref="#problem-icon"></use></svg>
                    <div ref={messageRef} className="ps-2 fw-reg-400">
                        {alertMessage}
                    </div>
                </div>
            </div>
        </div>
    );
}