import { ImageScribbleNotificationModel } from "../../../models/communication/internal/ImageScribbleNotificationModel";
import { ImageScribbleService } from "../../../services/imagescribble/ImageScribbleService";
import { ImageScribbleComponent } from "../../uploader/components/imagescribble/ImageScribbleComponent";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";

export const ImageScribbleHandler = (notification: ImageScribbleNotificationModel, onCloseModal: () => void) => {

    const scribbleService = new ImageScribbleService(notification.attachmentStore, notification.currentImage);

    return {
        modalSize: ModalSize.Xlarge,
        modalContent: (
            <ImageScribbleComponent
                scribbleService={scribbleService}
                closeModal={onCloseModal} />
        )
    } as ModalDefinitionModel;

}