import { useNavigate } from "react-router-dom";
import { CommonElements } from "../../../models/CommonElements";
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub";
import { GlobalStore } from "../../../services/stores/GlobalStore";
import { PageType, VoyceQueryString } from "../../../models/common/CommonEnums";

export const AddNewConceptSection = () => {
    const navigate = useNavigate();

    const OnAddClick = (pageType: PageType) => {

        if (pageType !== PageType.Feedback) {
            GlobalNotificationHub.queueDelayedAction(() => {
                const createConcept = document.getElementById(CommonElements.CreateConcept);
                if (createConcept) {
                    createConcept.click();
                }
            });
        }

        const workspaceCode = GlobalStore.getWorkspaceCodeIfAvailable();

        switch (pageType) {
            case PageType.Insights:
                navigate("/w/" + workspaceCode + "/insights/");
                break;
            case PageType.Problems:
                navigate("/w/" + workspaceCode + "/problems/");
                break;
            case PageType.Solutions:
                navigate("/w/" + workspaceCode + "/solutions/");
                break;
            case PageType.Feedback:
                navigate("/w/" + workspaceCode + `/insights/?${VoyceQueryString.SpecialFolder}=${VoyceQueryString.FeedbackFolder}`);
                break;
        }
    }

    return (
        <div>
            <div className="text-center">
                What would you like to do?
            </div>
            <div className="add-newconcept-section pt-3">
                <div className="item-section mb-3" onClick={() => OnAddClick(PageType.Feedback)}>
                    <div className="nav-link py-3 concept-link" role="button">
                        <svg className="pe-none w-100" width="36" height="34" role="img" aria-label="Insights">
                            <use xlinkHref="#folder-feedback-icon"></use></svg>
                        <div className="fw-small-600 mt-1 text-center">Collect or review customer insights</div>
                    </div>
                </div>
                <div className="item-section mb-3" onClick={() => OnAddClick(PageType.Insights)}>
                    <div className="nav-link py-3 concept-link insight-icon" role="button">
                        <svg className="pe-none w-100" width="36" height="34" role="img" aria-label="Insights">
                            <use xlinkHref="#insight-icon"></use></svg>
                        <div className="fw-small-600 mt-1 text-center"> Add an Insight</div>
                    </div>
                </div>
                <div className="item-section mb-3" onClick={() => OnAddClick(PageType.Problems)}>
                    <div className="nav-link py-3 concept-link problem-icon" role="button">
                        <svg className="pe-none w-100" width="36" height="34" role="img" aria-label="Insights">
                            <use xlinkHref="#problem-icon"></use></svg>
                        <div className="fw-small-600 mt-1 text-center">Define a problem</div>
                    </div>
                </div>
                <div className="item-section mb-3" onClick={() => OnAddClick(PageType.Solutions)}>
                    <div className="nav-link py-3 concept-link solution-icon" role="button">
                        <svg className="pe-none w-100" width="36" height="34" role="img" aria-label="Insights">
                            <use xlinkHref="#solution-icon"></use></svg>
                        <div className="fw-small-600 mt-1 text-center"> Add an idea or solution</div>
                    </div>
                </div>
                {/* <div>
                    <a className="item-section mt-3 d-flex flex-column align-items-center" role="button">
                        <div className="fw-med-700">🔬</div>
                        <div className="pt-2 px-3 fw-small-400 fw-secondary text-center">Add an new insight</div>
                    </a>
                </div>
                <div>
                    <div>
                        <button type="button" className="btn btn-primary btn-concept btn-medium w-100" onClick={() => OnAddClick(ConceptType.Insights)}>+ Add an insight</button>
                    </div>
                    <div className="pt-2 fw-secondary fw-xsmall-400">
                        Combine your insights with feedback from your customers to gain a 360° view of their needs and wants.
                    </div>
                </div>
                <div data-theme="problem">
                    <div>
                        <button type="button" className="btn btn-primary btn-concept btn-medium w-100" onClick={() => OnAddClick(ConceptType.Problems)}>+ Define a problem</button>
                    </div>
                    <div className="pt-2 fw-secondary fw-xsmall-400">
                        Add concise problem definitions to capture trends and help identify the value of making improvements.
                    </div>
                </div>
                <div data-theme="solution">
                    <div>
                        <button type="button" className="btn btn-primary btn-concept btn-medium w-100" onClick={() => OnAddClick(ConceptType.Solutions)}>+ Add an idea</button>
                    </div>
                    <div className="pt-2 fw-secondary fw-xsmall-400">
                        Generate ideas and prioritize the ones that benefit your customers the most while delivering the best ROI.
                    </div>
                </div> */}
            </div>
        </div>
    )
}