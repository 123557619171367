import React from "react";
import { memo, useState } from "react";
import { ConceptType } from "../../models/common/CommonEnums";
import { ConceptDataModel } from "../../models/ConceptDataModel";
import { ImplementationLevelComponent } from "../elements/ImplementationLevelComponent";

interface CardDisplayComponentProps {
    conceptModel: ConceptDataModel;

    onLinkClick: (concept: ConceptDataModel) => Promise<void>;
    isLinked: boolean;
    isEditing: boolean;

    enableTitleClick?: boolean;
    onTitleClick?: (concept: ConceptDataModel) => Promise<void>;

    //linkDetails: CardDisplayLink;
}

const RenderUnlinkText = () => {
    return (
        <React.Fragment>
            <svg className="pe-none" width="18" height="15" role="img" aria-label="Link"><use xlinkHref="#unlink-icon"></use></svg>
            <span className="ps-1">Unlink</span>
        </React.Fragment>
    );
}

const RenderLinkText = () => {
    return (
        <React.Fragment>
            <svg className="pe-none" width="18" height="15" role="img" aria-label="Link"><use xlinkHref="#link-icon-small"></use></svg>
            <span className="ps-1">Link</span>
        </React.Fragment>
    );
}

export const CardDisplaySmallComponent: React.FunctionComponent<CardDisplayComponentProps>
    = memo(
        function CardDisplaySmall({ conceptModel, onLinkClick, isLinked, isEditing, enableTitleClick, onTitleClick }) {

            const [isCurrentlyLinked, setIsLinked] = useState(isLinked);

            const OnLinkClick = async () => {
                await onLinkClick(conceptModel);
                setIsLinked(!isCurrentlyLinked);
            }

            const RenderTitle = () => {
                if (enableTitleClick && onTitleClick) {
                    return (
                        <div className="concept-link fw-dark" style={{ width: "fit-content" }} onClick={async () => { await onTitleClick(conceptModel); }}>
                            {conceptModel.title}
                        </div >
                    );
                }

                return (
                    <div className="fw-dark">{conceptModel.title}</div>
                )
            }

            return (
                <div className="voyce-card-sm shadow-sm ">
                    <div className="d-flex align-items-start p-1">
                        <div className="d-flex flex-column w-100">
                            <div className="fw-concept fw-small-400 ps-1 pe-2 text-break">
                                <RenderTitle />
                            </div>
                            {
                                (conceptModel.conceptType !== ConceptType.Insights && conceptModel.conceptValue) &&
                                <div className="pt-2" style={{ width: "fit-content" }}>
                                    <ImplementationLevelComponent currentLevel={conceptModel.conceptValue} conceptType={conceptModel.conceptType} />
                                </div>
                            }
                        </div>
                        {(isEditing) &&
                            (
                                <div className="mx-auto">
                                    <button type="button" className="btn btn-light btn-concept-light btn-xsmall text-nowrap" style={{ width: "80px" }} onClick={OnLinkClick}>
                                        {
                                            isCurrentlyLinked
                                                ? <RenderUnlinkText />
                                                : <RenderLinkText />
                                        }
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </div>
            );
        }
    )