import { useEffect, useState } from "react";
import { DashboardStore, DashboardStoreComponent, DashboardStoreState, DashboardStoreType } from "../../../services/stores/Dashboard/DashboardStore";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { RecentCommentDataModel } from "../../../models/communication/actions/RecentLoadAction";
import { LoadingComponent } from "../../loading/LoadingPage";
import { ConceptType, GlobalNotifications } from "../../../models/common/CommonEnums";
import { ConceptDataModel } from "../../../models/ConceptDataModel";
import { CommentDataModel } from "../../../models/data/CommentDataModel";
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub";
import { ConceptViewerNotificationModel } from "../../../models/communication/internal/ConceptViewerNotificationModel";

export const RecentEventsComments = () => {
    const [recentDashboardStore, setRecentDashboardStore] = useState<DashboardStore>();
    const [recentCommentList, setRecentComments] = useState<RecentCommentDataModel[]>();

    useEffect(() => {
        if (!recentDashboardStore) {
            return;
        }

        const hubContext = MessageHubContext()
            .Subscribe(recentDashboardStore, (storeState: DashboardStoreState) => {

                if (!storeState.recentCommentList) {
                    return;
                }

                setRecentComments([...storeState.recentCommentList]);
            });


        return () => hubContext.Dispose();

    }, [recentDashboardStore]);

    return (
        <div>
            {
                (!recentCommentList) &&
                <div className="d-flex w-100 justify-content-center my-3">
                    <LoadingComponent />
                </div>
            }
            {
                (recentCommentList && recentCommentList.length === 0) &&
                <div className="fw-secondary fw-i">
                    When comments are added, we'll email you a notification and display them here.
                </div>
            }
            {
                (recentCommentList) &&
                recentCommentList.map((recentComment: RecentCommentDataModel) => {
                    let theme = "";
                    if (recentComment.concept.conceptType === ConceptType.Problems) {
                        theme = "problem";
                    }
                    else if (recentComment.concept.conceptType === ConceptType.Solutions) {
                        theme = "solution";
                    }
                    return (
                        <div key={recentComment.comment.conceptCommentID} data-theme={theme}>
                            <RecentCommentDisplay
                                conceptModel={recentComment.concept}
                                commentModel={recentComment.comment}
                            />
                        </div>
                    )
                })
            }
            <DashboardStoreComponent storeType={DashboardStoreType.RecentComments} onStoreCreated={(store) => { setRecentDashboardStore(store); }} />
        </div>
    )
}

interface RecentCommentDisplayProps {
    conceptModel: ConceptDataModel;
    commentModel: CommentDataModel;
}

const RecentCommentDisplay = (props: RecentCommentDisplayProps) => {


    return (
        <div className="voyce-card-sm comment-card-override text-break">
            <div className="fw-reg-500 display-lines dashboard-card-override " onClick={() => {
                GlobalNotificationHub.sendMessageWithData(GlobalNotifications.ViewConcept, {
                    conceptID: props.conceptModel.conceptID,
                    conceptType: props.conceptModel.conceptType
                } as ConceptViewerNotificationModel);
            }}>
                <div className="concept-link">
                    {props.commentModel.commentContent}
                </div>
            </div>
            <div className="d-flex pt-2 fw-xsmall-400">
                <div>{props.commentModel.byUserName}</div>
                <div className="ps-1 fw-secondary">{props.commentModel.displayTime}</div>
            </div>
            <div className="concept-link fw-xsmall-500 " onClick={async () => {
                GlobalNotificationHub.sendMessageWithData(GlobalNotifications.ViewConcept, {
                    conceptID: props.conceptModel.conceptID,
                    conceptType: props.conceptModel.conceptType
                } as ConceptViewerNotificationModel);
                //window.open(HelperExtension.GetConceptUrl(props.conceptModel), "_blank")
            }}>
                {props.conceptModel.title}
            </div>
        </div>
    )
}