import { useEffect, useState } from "react";
import { CommonElements } from "../../models/CommonElements";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { FeedbackActionComponent } from "./components/FeedbackActionComponent"
import { FeedbackStore } from "../../services/stores/Feedback/FeedbackStore";
import { IHubSubcription, MessageHubContext } from "../../services/notification/MessageHubHandler";
import { MessageHubOwner } from "../../services/messaging/MessageHubOwner";
import { MessageType } from "../../models/message/IServerMessage";
import { FeedbackSettingsLoadActionResponse } from "../../models/communication/actions/FeedbackSettingsLoadAction";
import { FeedbackEnableComponent } from "./components/FeedbackEnableComponent";
import { FeedbackSettingsNotificationModel } from "../../models/communication/internal/FeedbackSettingsNotificationModel";
import { TextDisplayComponent } from "../elements/TextDisplayComponent";
import { useAuth } from "react-oidc-context";
import { OneOffNotificationComponent } from "../notification/oneoffalert/OneOffNotificationComponent";
import { UserDismissibleType } from "../../services/stores/UserDismissibleStore";

export const FeedbackSettingsComponent = () => {

    const [feedbackStore] = useState(new FeedbackStore());
    const [feedbackEmailTemplate, setFeedbackEmailtemplate] = useState("");
    const auth = useAuth();



    useEffect(() => {

        var hubContext = MessageHubContext()
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.FeedbackSettingsLoaded, OnReceive: (message: FeedbackSettingsLoadActionResponse) => {

                    feedbackStore.loadData(message);

                    let companyName = "[Company Name]";
                    if (auth.user?.profile.preferred_username) {
                        companyName = auth.user?.profile.preferred_username;
                    }

                    const emailTemplate = "Hello there!%0D%0A" +
                        "It's " + auth.user?.profile.given_name + " from " + companyName + ". I'm reaching out for your opinion on how we can improve.%0D%0A%0D%0A" +
                        "I literally have 2 quick questions:%0D%0A" +
                        "1. How are we doing - thumbs up or thumbs down?%0D%0A" +
                        "2. If you had a magic wand to change one thing about our product or service, what would it be?%0D%0A%0D%0A" +
                        "No need to hold back - it's totally anonymous, so feel free to speak your mind!%0D%0A%0D%0A" +
                        "Click on the link below to let us know what you think:%0D%0A" +
                        message.settings.feedbackUrl +
                        "%0D%0A%0D%0A" +
                        "Thanks so much!%0D%0A%0D%0A" +
                        auth.user?.profile.given_name;

                    setFeedbackEmailtemplate(emailTemplate)
                }
            } as IHubSubcription)

        feedbackStore.loadFeedbackSettings();
        return () => hubContext.Dispose();
    }, []);

    const OnCustomize = () => {
        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.FeedbackLogoEditor, { feedbackStore: feedbackStore } as FeedbackSettingsNotificationModel);
        global.window.openModal(CommonElements.ModalElement);
    }


    return (
        <div className="feedback-section voyce-card-size mb-4">
            <div className="feedback-settings-section p-3 mt-3">
                <div className="mx-1">
                    <div className="fw-xlarge-700 fw-concept">
                        Your feedback form
                        {/* <svg className="p-none" width="30" height="30" role="img"><use xlinkHref="#folder-feedback-icon"></use></svg>
                    <div className="ps-2"> Voyce Feedback form</div> */}
                    </div>
                    <div className="pt-3">
                        Get real-time feedback in every workspace. Share your Voyce feedback form to boost reviews, identify areas to improve, and delight your customers.
                    </div>
                    <div className="pt-3">
                        <FeedbackEnableComponent store={feedbackStore} />
                    </div>
                    <div className="feedback-share-container pt-3">
                        <div className="form-control text-break">
                            <TextDisplayComponent dataStore={feedbackStore} fieldName="feedbackUrl" />
                        </div>
                        <div className="ms-1">
                            <button type="button" className="btn btn-primary btn-concept btn-mod-createtag text-nowrap h-100" onClick={() => {
                                if (feedbackStore.currentStoreState.feedbackUrl) {
                                    navigator.clipboard.writeText(feedbackStore.currentStoreState.feedbackUrl);
                                    GlobalNotificationHub.sendMessage(GlobalNotifications.FeedbackUrlCopied);
                                }
                            }}>Copy Link</button>
                        </div>
                    </div>
                </div>
                <div className="d-flex flex-wrap pt-2">
                    <FeedbackActionComponent description="View form" icon="#form-icon" iconWidth="24" iconHeight="28"
                        onClick={() => {
                            window.open(feedbackStore.currentStoreState.feedbackUrl, "_blank");
                        }} />
                    <FeedbackActionComponent description="Email" icon="#email-envelop-icon" iconWidth="32" iconHeight="23"
                        onClick={() => {
                            window.open("mailto:?subject=If you had a magic wand to change one thing about our product or service, what would it be?&body=" + feedbackEmailTemplate);
                        }} />
                    <FeedbackActionComponent description="Share" icon="#facebook-icon" iconWidth="29" iconHeight="28"
                        onClick={() => {
                            window.open("http://www.facebook.com/sharer/sharer.php?u=" + feedbackStore.currentStoreState.feedbackUrl, "_blank");
                        }} />
                    <FeedbackActionComponent description="Share" icon="#x-icon" iconWidth="22" iconHeight="22"
                        onClick={() => {
                            window.open("https://twitter.com/intent/tweet?text=" + feedbackStore.currentStoreState.feedbackUrl, "_black");
                        }} />
                    <FeedbackActionComponent description="Tips" icon="#idea-icon" iconWidth="40" iconHeight="28"
                        onClick={() => {
                            window.open("https://simplyvoyce.com/blog/10-places-to-capture-customer-insights-using-your-voyce-feedback-form", "_black");
                        }} />
                    <FeedbackActionComponent description="Customize" icon="#customize-icon" iconWidth="36" iconHeight="28"
                        onClick={OnCustomize}

                    />
                </div>
            </div>

            <OneOffNotificationComponent alertType={UserDismissibleType.VoyceFeedback} notificationType={GlobalNotifications.VoyceFeedback} />
        </div >
    )
}