import React from "react";
import { ExamplesComponent } from "./ExamplesComponent";
import { TextBoxComponent, TextBoxComponentProps } from "./TextBoxComponent";

export interface TextBoxGroupComponentProps {
    label: string;
    labelDescription?: string;
    textBox: TextBoxComponentProps;
    example?: TextBoxGroupExample;
}

export interface TextBoxGroupExample {
    exampleDescription: string;
    exampleList: string[];
}

export const TextBoxGroupComponent = (props: TextBoxGroupComponentProps) => {

    return (
        <div>
            <div className="input-group">
                <div className="input-group-text">
                    <span className="fw-reg-700">{props.label}</span>
                    {
                        (props.labelDescription) &&
                        <span className="ps-1">{props.labelDescription}</span>
                    }
                </div>
                <TextBoxComponent {...props.textBox} />
            </div>
            {
                (props.example) &&
                <ExamplesComponent
                    exampleDescription={props.example.exampleDescription}
                    exampleList={props.example.exampleList}
                />
            }
        </div>
    )

}