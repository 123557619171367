import { Outlet } from "react-router-dom";
import { CustomModalComponent } from "../../components/modal/CustomModalComponent";
import { ModalComponent } from "../../components/modal/ModalComponent";
import { AlertTimerComponent } from "../../components/notification/AlertTimerComponent";
import { ConceptType } from "../../models/common/CommonEnums";
import { VoyceContextProvider } from "../../services/context/AppContext";
import { InitializeTheme } from "../../services/ThemeService";
import { ConceptViewerHeader } from "./elements/ConceptViewerHeader";
import { HelpUsComponent } from "../../components/notification/HelpUsComponent";
import { KeyboardContext, KeyboardServiceHandler, ModalOpenCloseWatcher } from "../../services/shortcut/KeyboardService";

interface ConceptFullscreenPageProps {
    conceptType: ConceptType;
}

export const ConceptFullscreenPage = (props: ConceptFullscreenPageProps) => {

    InitializeTheme(props.conceptType);

    return (
        <VoyceContextProvider conceptType={props.conceptType}>
            <div className="d-flex flex-nowrap layout-concept-full">
                <div className="container-fluid p-0">
                    <div className="voyce-header-full">
                        <ConceptViewerHeader conceptType={props.conceptType} />
                    </div>
                    <main className="p-0">
                        <div className="p-0">
                            <div className="main-content">
                                <Outlet />
                            </div>
                        </div>
                        <ModalComponent />
                        <AlertTimerComponent />
                        <CustomModalComponent />
                        <KeyboardServiceHandler />
                        <HelpUsComponent />

                        <ModalOpenCloseWatcher keyboardContext={KeyboardContext.ConceptFullscreen} />
                    </main>
                </div>
            </div>
        </VoyceContextProvider>
    );
}