import { debounce } from "lodash";
import React, { memo, useEffect, useMemo, useState } from "react";
import { ConceptType } from "../../models/common/CommonEnums";
import { ConceptDataModel } from "../../models/ConceptDataModel";
import { ConceptSearchService } from "../../services/concepts/ConceptSearchService";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { ConceptLinkStore } from "../../services/stores/ConceptLink/ConceptLinkStore";
import { TextSearchData, TextSearchStore } from "../../services/stores/TextSearchStore";
import { CardDisplaySmallComponent } from "../card/CardDisplaySmallComponent";
import { TextBoxKeywordSearchComponent } from "../elements/TextBoxKeywordSearchComponent";
import { LoadingComponent } from "../../pages/loading/LoadingPage";

export interface ConceptSearchComponentProps {
    conceptLinkStore: ConceptLinkStore;
    conceptType: ConceptType;
}

export const ConceptSearchComponent: React.FunctionComponent<ConceptSearchComponentProps>
    = memo(
        function ConceptSearchImplementation({ conceptLinkStore, conceptType }) {
            const [searchStore, setSearchStore] = useState<TextSearchStore>();
            const [searchResult, setSearchResult] = useState<ConceptDataModel[]>();
            const [refresh, forceRefresh] = useState(0);
            //const [conceptSearchService, setConceptSearchService] = useState(new ConceptSearchService());

            useEffect(() => {
                const searchStoreValue = new TextSearchStore();
                //const conceptSearchServiceValue = new ConceptSearchService();

                const messageHubContext = MessageHubContext()
                    .Subscribe(searchStoreValue, debouncedChangeHandler)
                    .Subscribe(ConceptSearchService, (conceptList: ConceptDataModel[]) => {
                        setSearchResult(conceptList);
                    })
                    .Subscribe(conceptLinkStore, (conceptList: ConceptDataModel[]) => {

                        forceRefresh(n => n + 1);
                    });

                setSearchStore(searchStoreValue);


                // setSearchResult([
                //     { conceptID: "1", title: "Hard to find the different categories to find productsHard to find the different categories to find productsHard to find the different categories to find productsHard to find the different categories to find productsHard to find the different categories to find products" } as ConceptDataModel,
                //     { conceptID: "2", title: "Hard to find the different categories to find products" } as ConceptDataModel,
                //     { conceptID: "3", title: "Hard to find the different categories to find products" } as ConceptDataModel,
                //     { conceptID: "4", title: "Hard to find the different categories to find products" } as ConceptDataModel,
                // ]);


                return () => {
                    messageHubContext.Dispose();
                };
            }, []);

            const debouncedChangeHandler = useMemo(
                () => debounce(async (data: TextSearchData) => {
                    await ConceptSearchService.searchConcept(conceptType, data.filterValue, "");
                }, 300)
                , [conceptType]);

            useEffect(() => {
                return () => debouncedChangeHandler.cancel();
            }, [debouncedChangeHandler]);

            // useEffect(() => {
            //     return () => conceptSearchService.Dispose();
            // }, [conceptSearchService])

            const GetConceptTheme = () => {
                switch (conceptType) {
                    case ConceptType.Problems:
                        return "problem";
                    case ConceptType.Solutions:
                        return "solution";
                    default:
                        return "insight";
                }
            }

            if (!searchStore) {
                return <React.Fragment></React.Fragment>
            }

            return (
                <div >
                    <div className="p-3">
                        <TextBoxKeywordSearchComponent dataStore={searchStore} fieldName="filterValue" maxLength={100} placeholder="Keyword search" />
                    </div>

                    <div className="p-3 py-0 overflow-auto vc-custom-scroll drop-body">
                        <div data-theme={GetConceptTheme()}>
                            {
                                (searchResult) &&
                                searchResult.filter(c => !conceptLinkStore.checkLink(c)).map((conceptItem) => {
                                    return (<CardDisplaySmallComponent
                                        //Removes any caching
                                        key={Math.random().toString(36)}
                                        conceptModel={conceptItem}
                                        onLinkClick={async (concept: ConceptDataModel) => {

                                            await conceptLinkStore.toggleLink(concept);
                                        }}
                                        // Linked cards are not displayed
                                        isLinked={false}
                                        isEditing={true}


                                    />);
                                })
                            }
                        </div>
                        {
                            !(searchResult) &&
                            <div className="w-100 d-flex justify-content-center" style={{ height: "100%", marginTop: "-2rem" }}>
                                <LoadingComponent />
                            </div>
                        }
                    </div>
                </div>
            );
        }
    )