import React, { useEffect, useState } from "react"
import { useAuth } from "react-oidc-context";

interface SecureDownloaderProps {
    partialUrl: string;

    OnDocumentLoaded: (blobUrl: string) => void;
}

const serverURL = process.env.REACT_APP_INTEGRATION_URL;


export class SecureDownloaderHandler {

    list = [] as string[];

    async fetchWithAuthentication(url: string, authToken: string) {
        const headers = new Headers();
        headers.set("Authorization", `Bearer ${authToken}`);
        return fetch(url, { headers });
    }


    async secureDownload(partialUrl: string, authToken: string) {
        const downloadUrl = serverURL + "/secure/storage/?attachmentID=" + partialUrl;

        const response = await this.fetchWithAuthentication(downloadUrl, authToken);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        this.list.push(blobUrl);
        return blobUrl;
    }

    async secureDownloadV2(partialUrl: string, authToken: string, onImageConvertComplete: (imageDataText: string, imageWidth: number, imageHeight: number) => void) {
        const downloadUrl = serverURL + "/secure/storage/?attachmentID=" + partialUrl;

        const response = await this.fetchWithAuthentication(downloadUrl, authToken);
        const downloadedBlob = await response.blob();

        const reader = new FileReader();
        reader.onloadend = async () => {
            const imageBitmap = await createImageBitmap(downloadedBlob);
            onImageConvertComplete(reader.result as string, imageBitmap.width, imageBitmap.height);
        };

        reader.readAsDataURL(downloadedBlob);
    }

    dispose() {

        this.list.forEach(b => {
            URL.revokeObjectURL(b);
        });

        this.list = [];

    }
}

export const SecureDownloader = (props: SecureDownloaderProps) => {

    const auth = useAuth();
    const [downloadHandler] = useState(new SecureDownloaderHandler());

    useEffect(() => {

        let objectUrl: string;

        const displayProtectedImage = async () => {

            objectUrl = await downloadHandler.secureDownload(props.partialUrl, auth.user?.access_token ?? "");
            // Update the source of the image.
            props.OnDocumentLoaded(objectUrl);
        }

        displayProtectedImage();


        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
            }
        }
    }, [props.partialUrl, downloadHandler]);

    return <React.Fragment></React.Fragment>
}

interface SecureDownloaderAsDataUrlProps {
    partialUrl: string;

    OnDocumentLoaded: (blobUrl: string, imageWidth: number, imageHeight: number) => void;
}

export const SecureDownloaderAsDataUrl = (props: SecureDownloaderAsDataUrlProps) => {
    const auth = useAuth();
    const [downloadHandler] = useState(new SecureDownloaderHandler());

    useEffect(() => {

        let objectUrl: string;

        const displayProtectedImage = async () => {

            await downloadHandler.secureDownloadV2(props.partialUrl, auth.user?.access_token ?? "", (imageDataText: string, imageWidth: number, imageHeight: number) => {
                if (imageDataText) {
                    props.OnDocumentLoaded(imageDataText, imageWidth, imageHeight);
                }
            });
            // Update the source of the image.

        }

        displayProtectedImage();


        return () => {
            if (objectUrl) {
                URL.revokeObjectURL(objectUrl);
            }
        }
    }, [props.partialUrl, downloadHandler]);

    return <React.Fragment></React.Fragment>
}