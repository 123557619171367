import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { DndProvider } from "react-dnd";
import { TouchBackend } from "react-dnd-touch-backend";
import { useAuth } from "react-oidc-context";
import { Outlet } from "react-router-dom";
import { AuthRedirectCache } from "../services/authentication/AuthRedirectCache";
import { MessageService } from "../services/messaging/MessageService";
import { LoadingPage } from "./loading/LoadingPage";
import { LogCollector } from "../services/logger/LogCollector";

export const AuthenticationLoaderComponent = () => {
    const auth = useAuth();
    const [isReadyToUse, setIsReadyToUse] = useState(false);

    useEffect(() => {
        if (!auth.isLoading && auth.user?.access_token !== null && auth.user?.access_token !== undefined) {

            MessageService.UpdateAcessToken(auth.user?.access_token as string);

            MessageService.connectAsync().then(() => {
                setIsReadyToUse(true);
            });
        }
    }, [auth]);

    const debouncedChangeHandler = useMemo(
        () => debounce(async () => {

            try {

                await auth.querySessionStatus();
                await auth.signinSilent();
            } catch {
                //localStorage.setItem
                await auth.signinRedirect({
                    state: AuthRedirectCache.create()
                    // extraQueryParams: {
                    //     newParameter: "test"
                    // }
                    ,
                });
            }
        }, 200)
        , []);

    useEffect(() => {
        return () => debouncedChangeHandler.cancel();
    }, [debouncedChangeHandler]);

    useEffect(() => {
        if (!auth.isAuthenticated) {
            if (!auth.isLoading) {
                debouncedChangeHandler();
            }
        }
    }, [auth.isLoading, debouncedChangeHandler]);




    LogCollector.LogMessage("AuthenticationLoaderComponent: render");

    if (!isReadyToUse) {
        LogCollector.LogMessage("AuthenticationLoaderComponent: Still loading!!!");
        return (
            <LoadingPage />
        );
    }

    const touchOptions = { enableMouseEvents: true };

    return (
        <DndProvider backend={TouchBackend} options={touchOptions}>
            <Outlet />
        </DndProvider>
    );
}