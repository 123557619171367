import { Subject } from "rxjs";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { INotificationInitializer, InitializerHelper } from "../Feedback/FeedbackStatisticStore";
import { ConceptDataModel } from "../../../models/ConceptDataModel";
import { MessageService } from "../../messaging/MessageService";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { IHubSubcription, MessageHubContext } from "../../notification/MessageHubHandler";
import { MessageHubOwner } from "../../messaging/MessageHubOwner";
import { RecentCommentDataModel, RecentLoadAction, RecentLoadActionResponse } from "../../../models/communication/actions/RecentLoadAction";


export enum DashboardStoreType {
    RecentFeedback = 1,
    RecentConcepts = 2,
    RecentComments = 3
}
export interface DashboardStoreState {
    storeType: DashboardStoreType;

    recentFeedbackList: ConceptDataModel[];
    recentConceptList: ConceptDataModel[];
    recentCommentList: RecentCommentDataModel[];
}

const defaultRecentComments = {
    storeType: DashboardStoreType.RecentComments
} as DashboardStoreState;

const defaultRecentConcepts = {
    storeType: DashboardStoreType.RecentConcepts
} as DashboardStoreState;

const defaultRecentFeedback = {
    storeType: DashboardStoreType.RecentFeedback,
    recentFeedbackList: [] as ConceptDataModel[]
} as DashboardStoreState;

export class DashboardStore implements INotificationHub, INotificationInitializer {
    storeState = { storeType: DashboardStoreType.RecentFeedback } as DashboardStoreState;
    dashboardSubject = new Subject();

    constructor(storeType: DashboardStoreType) {
        switch (storeType) {
            case DashboardStoreType.RecentComments:
                this.storeState = { ...defaultRecentComments };
                break;
            case DashboardStoreType.RecentConcepts:
                this.storeState = { ...defaultRecentConcepts };
                break;
            case DashboardStoreType.RecentFeedback:
                this.storeState = { ...defaultRecentFeedback };
                break;
        }
    }

    subscribe(onMessageReceive: any) {
        return this.dashboardSubject.subscribe(onMessageReceive);
    }

    initialize() {

        let messageType = MessageType.RecentFeedbackLoad;
        let maxResult = 3;

        if (this.storeState.storeType === DashboardStoreType.RecentComments) {
            messageType = MessageType.RecentCommentLoad;
            maxResult = 20;
        }
        else if (this.storeState.storeType === DashboardStoreType.RecentConcepts) {
            messageType = MessageType.RecentConceptLoad;
            maxResult = 20;
        }

        MessageService.sendMessage({
            messageType: messageType,
            requestData: { maxResult: maxResult } as RecentLoadAction
        } as IServerMessage);
    }

    loadData(response: RecentLoadActionResponse) {
        this.storeState = { ...this.storeState, ...response };
        this.dashboardSubject.next(this.storeState);
    }
}

interface DashboardStoreComponentProps {
    storeType: DashboardStoreType;
    onStoreCreated: (store: DashboardStore) => void;
}

export const DashboardStoreComponent = (props: DashboardStoreComponentProps) => {

    const [store] = useState(new DashboardStore(props.storeType));


    useEffect(() => {
        if (!store) {
            return;
        }

        const hubContext = MessageHubContext()
            .ListenMessage(MessageHubOwner,
                {
                    MessageType: MessageType.RecentFeedbackLoaded,
                    OnReceive: (response: RecentLoadActionResponse) => {
                        store.loadData(response);
                    }
                } as IHubSubcription)
            .ListenMessage(MessageHubOwner,
                {
                    MessageType: MessageType.RecentConceptLoaded,
                    OnReceive: (response: RecentLoadActionResponse) => {
                        store.loadData(response);
                    }
                } as IHubSubcription)
            .ListenMessage(MessageHubOwner,
                {
                    MessageType: MessageType.RecentCommentLoaded,
                    OnReceive: (response: RecentLoadActionResponse) => {
                        store.loadData(response);
                    }
                } as IHubSubcription)

            //Reload messages
            .ListenMessage(MessageHubOwner,
                {
                    MessageType: MessageType.ConceptLastUpdateSaved,
                    OnReceive: () => {
                        store.initialize();
                    }
                } as IHubSubcription)
            .ListenMessage(MessageHubOwner,
                {
                    MessageType: MessageType.ConceptCommentUpdated,
                    OnReceive: () => {
                        store.initialize();
                    }
                } as IHubSubcription);

        return () => hubContext.Dispose();

    }, [store]);


    const debouncedInitializerHandler = useMemo(
        () => debounce(() => {
            props.onStoreCreated(store);
        }, 100)
        , []);

    useEffect(() => {
        debouncedInitializerHandler();

        return () => debouncedInitializerHandler.cancel();
    }, [debouncedInitializerHandler]);



    return (
        <>
            <InitializerHelper hub={store} />
        </>
    )
}