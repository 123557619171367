
export interface IMessageData {

}

export enum MessageType {
    Ping = "server.ping",
    Pong = "server.pong",

    ConceptCreate = "concept.create",
    ConceptCreated = "concept.created",

    ConceptDelete = "concept.delete",
    ConceptDeleted = "concept.deleted",

    ConceptTagAdd = "concept.tag.add",
    ConceptTagDelete = "concept.tag.delete",

    //ConceptTagLoad = "concept.tag.load",
    ConceptTagLoaded = "concept.tag.loaded",

    ConceptLoad = "concept.load",
    ConceptLoaded = "concept.loaded",
    ConceptSearch = "concept.search",
    ConceptSearched = "concept.searched",
    ConceptDetailLoad = "concept.detail.load",
    ConceptDetailLoaded = "concept.detail.loaded",

    ConceptLinkAdd = "concept.link.add",
    ConceptLinkRemove = "concept.link.remove",

    ConceptLinkSummaryLoad = "concept.link.summary.load",
    ConceptLinkSummaryLoaded = "concept.link.summary.loaded",

    ConceptValueUpdate = "concept.value.update",

    TagLoad = "tag.load",
    TagLoaded = "tag.loaded",

    TagUpdate = "tag.update",
    TagUpdated = "tag.updated",

    TagRename = "tag.rename",
    TagRenamed = "tag.renamed",

    TagDelete = "tag.delete",
    TagDeleted = "tag.deleted",

    WorkspaceLoad = "workspace.load",
    WorkspaceLoaded = "workspace.loaded",

    WorkspaceSave = "workspace.save",
    WorkspaceSaved = "workspace.saved",

    WorkspaceUpdate = "workspace.update",

    WorkspaceDelete = "workspace.delete",
    WorkspaceDeleted = "workspace.deleted",

    WorkspaceGenerateDefault = "workspace.generate.default",

    WorkspaceMembersLoad = "workspace.members.load",
    WorkspaceMembersLoaded = "workspace.members.loaded",
    WorkspaceMembersRemove = "workspace.members.remove",
    WorkspaceMembersRemoved = "workspace.members.removed",

    WorkspaceMembersInvite = "workspace.members.invite",
    WorkspaceMembersInvited = "workspace.members.invited",
    WorkspaceMemberAlreadyInvited = "workspace.member.already.invited",

    FolderLoad = "folder.load",
    FolderLoaded = "folder.loaded",

    FolderSave = "folder.save",
    FolderSaved = "folder.saved",

    FolderRename = "folder.rename",
    FolderRenamed = "folder.renamed",

    FolderDelete = "folder.delete",
    FolderDeleted = "folder.deleted",

    ConceptFolderMove = "concept.folder.move",


    ConceptDataUpdated = "concept.data.updated",

    ConceptCommentLoad = "concept.comment.load",
    ConceptCommentLoaded = "concept.comment.loaded",
    ConceptCommentUpdate = "concept.comment.update",
    ConceptCommentUpdated = "concept.comment.updated",
    ConceptCommentDelete = "concept.comment.delete",
    ConceptCommentDeleted = "concept.comment.deleted",

    ConceptAttachmentSave = "concept.attachment.save",
    ConceptAttachmentSaved = "concept.attachment.saved",
    ConceptAttachmentDelete = "concept.attachment.delete",
    ConceptAttachmentDeleted = "concept.attachment.deleted",

    ConceptAttachmentNotify = "concept.attachment.notify",
    ConceptAttachmentNotified = "concept.attachment.notified",

    ConceptAttachmentUploadComplete = "concept.attachment.upload.complete",
    ConceptAttachmentUploadCompleted = "concept.attachment.upload.completed",

    ConceptSortSave = "concept.sort.save",


    FeedbackSettingsLoad = "feedback.settings.load",
    FeedbackSettingsLoaded = "feedback.settings.loaded",
    FeedbackSettingsSave = "feedback.settings.save",
    FeedbackSettingsSaved = "feedback.settings.saved",
    FeedbackStatisticsLoad = "feedback.statistics.load",
    FeedbackStatisticsLoaded = "feedback.statistics.loaded",

    //Recent queries
    RecentFeedbackLoad = "recent.feedback.load",
    RecentFeedbackLoaded = "recent.feedback.loaded",

    RecentConceptLoad = "recent.concept.load",
    RecentConceptLoaded = "recent.concept.loaded",

    RecentCommentLoad = "recent.comment.load",
    RecentCommentLoaded = "recent.comment.loaded",

    // Receives when a change happen to the concept
    ConceptLastUpdateSaved = "concept.lastupdate.saved"
}

export const MessageTypeEnumDescriptor = new Map<MessageType, string>([
    [MessageType.Ping, "server.ping"],
    [MessageType.Pong, "server.pong"],
    [MessageType.ConceptCreate, "concept.create"],
    [MessageType.ConceptCreated, "concept.created"],

    [MessageType.ConceptDelete, "concept.delete"],
    [MessageType.ConceptDeleted, "concept.deleted"],

    [MessageType.ConceptTagAdd, "concept.tag.add"],
    [MessageType.ConceptTagDelete, "concept.tag.delete"],
    [MessageType.ConceptTagLoaded, "concept.tag.loaded"],

    [MessageType.ConceptLoad, "concept.load"],
    [MessageType.ConceptLoaded, "concept.loaded"],
    [MessageType.ConceptSearch, "concept.search"],
    [MessageType.ConceptSearched, "concept.searched"],
    [MessageType.ConceptDetailLoad, "concept.detail.load"],
    [MessageType.ConceptDetailLoaded, "concept.detail.loaded"],

    [MessageType.ConceptValueUpdate, "concept.value.update"],

    [MessageType.ConceptLinkAdd, "concept.link.add"],
    [MessageType.ConceptLinkRemove, "concept.link.remove"],
    [MessageType.ConceptLinkSummaryLoad, "concept.link.summary.load"],
    [MessageType.ConceptLinkSummaryLoaded, "concept.link.summary.loaded"],

    [MessageType.TagLoad, "tag.load"],
    [MessageType.TagLoaded, "tag.loaded"],

    [MessageType.TagUpdate, "tag.update"],
    [MessageType.TagUpdated, "tag.updated"],

    [MessageType.TagRename, "tag.rename"],
    [MessageType.TagRenamed, "tag.renamed"],

    [MessageType.TagDelete, "tag.delete"],
    [MessageType.TagDeleted, "tag.deleted"],


    [MessageType.WorkspaceLoad, "workspace.load"],
    [MessageType.WorkspaceLoaded, "workspace.loaded"],

    [MessageType.WorkspaceMembersLoad, "workspace.members.load"],
    [MessageType.WorkspaceMembersLoaded, "workspace.members.loaded"],
    [MessageType.WorkspaceMembersRemove, "workspace.members.remove"],
    [MessageType.WorkspaceMembersRemoved, "workspace.members.removed"],

    [MessageType.WorkspaceMembersInvite, "workspace.members.invite"],
    [MessageType.WorkspaceMembersInvited, "workspace.members.invited"],
    [MessageType.WorkspaceMemberAlreadyInvited, "workspace.member.already.invited"],

    [MessageType.WorkspaceSave, "workspace.save"],
    [MessageType.WorkspaceSaved, "workspace.saved"],
    [MessageType.WorkspaceUpdate, "workspace.update"],
    [MessageType.WorkspaceDelete, "workspace.delete"],
    [MessageType.WorkspaceDeleted, "workspace.deleted"],

    [MessageType.WorkspaceGenerateDefault, "workspace.generate.default"],

    [MessageType.FolderLoad, "folder.load"],
    [MessageType.FolderLoaded, "folder.loaded"],

    [MessageType.FolderSave, "folder.save"],
    [MessageType.FolderSaved, "folder.saved"],

    [MessageType.FolderRename, "folder.rename"],
    [MessageType.FolderRenamed, "folder.renamed"],

    [MessageType.FolderDelete, "folder.delete"],
    [MessageType.FolderDeleted, "folder.deleted"],

    [MessageType.ConceptFolderMove, "concept.folder.move"],

    [MessageType.ConceptCommentLoad, "concept.comment.load"],
    [MessageType.ConceptCommentLoaded, "concept.comment.loaded"],
    [MessageType.ConceptCommentUpdate, "concept.comment.update"],
    [MessageType.ConceptCommentUpdated, "concept.comment.updated"],
    [MessageType.ConceptCommentDelete, "concept.comment.delete"],
    [MessageType.ConceptCommentDeleted, "concept.comment.deleted"],

    [MessageType.ConceptAttachmentSave, "concept.attachment.save"],
    [MessageType.ConceptAttachmentSaved, "concept.attachment.saved"],
    [MessageType.ConceptAttachmentDelete, "concept.attachment.delete"],
    [MessageType.ConceptAttachmentDeleted, "concept.attachment.deleted"],

    [MessageType.ConceptAttachmentNotify, "concept.attachment.notify"],
    [MessageType.ConceptAttachmentNotified, "concept.attachment.notified"],

    [MessageType.ConceptAttachmentUploadComplete, "concept.attachment.upload.complete"],
    [MessageType.ConceptAttachmentUploadCompleted, "concept.attachment.upload.completed"],

    [MessageType.ConceptSortSave, "concept.sort.save"],

    [MessageType.FeedbackSettingsLoad, "feedback.settings.load"],
    [MessageType.FeedbackSettingsLoaded, "feedback.settings.loaded"],
    [MessageType.FeedbackSettingsSave, "feedback.settings.save"],
    [MessageType.FeedbackSettingsSaved, "feedback.settings.saved"],

    [MessageType.FeedbackStatisticsLoad, "feedback.statistics.load"],
    [MessageType.FeedbackStatisticsLoaded, "feedback.statistics.loaded"],

    //Recent queries
    [MessageType.RecentFeedbackLoad, "recent.feedback.load"],
    [MessageType.RecentFeedbackLoaded, "recent.feedback.loaded"],

    [MessageType.RecentConceptLoad, "recent.concept.load"],
    [MessageType.RecentConceptLoaded, "recent.concept.loaded"],

    [MessageType.RecentCommentLoad, "recent.comment.load"],
    [MessageType.RecentCommentLoaded, "recent.comment.loaded"],

    [MessageType.ConceptLastUpdateSaved, "concept.lastupdate.saved"]
]);

export interface IServerMessage {
    messageType: MessageType;

    requestData: IMessageData;
}