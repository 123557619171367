import React from "react"
import { useEffect, useState } from "react"
import { IDataStore } from "../../models/common/IDataStore"
import { INotificationHub } from "../../models/common/INotificationHub"
import { ConceptDataModel } from "../../models/ConceptDataModel"
import { HelperExtension } from "../../services/HelperExtension"
import { MessageHubContext } from "../../services/notification/MessageHubHandler"

interface TextDisplayComponentProps {
    fieldName: string;
    dataStore: IDataStore | INotificationHub;

    label?: TextDisplayLabelProps;
}

interface TextDisplayLabelProps {
    text: string;
    groupStyle: string;
}



export const TextDisplayComponent = (props: TextDisplayComponentProps) => {

    const defaultValue = HelperExtension.GetPropertyValue(props.fieldName, props.dataStore);
    const [currentValue, setCurrentValue] = useState(defaultValue);

    useEffect(() => {

        const hubContext = MessageHubContext()
            .Subscribe(props.dataStore as INotificationHub, (conceptModel: ConceptDataModel) => {

                setCurrentValue(HelperExtension.GetPropertyValue(props.fieldName, conceptModel));
            });


        return () => hubContext.Dispose();

    }, [props.fieldName, props.dataStore])

    if (props.label) {
        return (
            <React.Fragment>
                {
                    (currentValue) &&
                    (
                        <div className={"d-flex " + props.label.groupStyle}>
                            <div className="fw-reg-500 display-label">{props.label.text}</div>
                            <div>{currentValue}</div>
                        </div>
                    )
                }
            </React.Fragment>
        )
    }

    return (
        <div>{currentValue}</div>
    )

}