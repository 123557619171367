import React, { useEffect } from "react";
import { ConceptEditorService } from "../../../services/concepts/ConceptEditorService";
import { AppContextData } from "../../../services/context/AppContext";
import { ConceptEditorComponent } from "../../concept/ConceptEditorComponent";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";

interface ConceptAddHandlerProps {
    context: AppContextData;
}

export const ConceptAddHandler = (props: ConceptAddHandlerProps) => {

    const conceptService = new ConceptEditorService();
    //conceptService.initialize(props.context);

    return {
        modalSize: ModalSize.XLargeScrollable,
        modalContent: (
            <React.Fragment>
                <ConceptEditorComponent conceptEditorService={conceptService} isEditing={false} />
                <ConceptInitializerComponent context={props.context} conceptService={conceptService} />
            </React.Fragment>
        )
    } as ModalDefinitionModel;
}

interface InitializerTestProps {
    context: AppContextData;
    conceptService: ConceptEditorService;
}

const ConceptInitializerComponent = (props: InitializerTestProps) => {

    useEffect(() => {
        props.conceptService.initialize(props.context);
    }, []);

    return (
        <React.Fragment></React.Fragment>
    )

}