import React, { memo, useEffect, useState } from "react";
import { ConceptType, GlobalNotifications, VoyceQueryString } from "../../models/common/CommonEnums";
import { FolderDataModel, FolderType } from "../../models/data/FolderDataModel";
import { GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { FolderStore } from "../../services/stores/Folder/FolderStore";
import { FolderItemComponent } from "./FolderItemComponent"
import { SystemFolderComponent } from "./SystemFolderComponent";
import { CommonElements } from "../../models/CommonElements";
import { useAppContext } from "../../services/context/AppContext";
import { useSearchParams } from "react-router-dom";

interface FolderComponentProps {
    conceptType: ConceptType;
}

export const FolderComponent: React.FunctionComponent<FolderComponentProps>
    = memo(function FolderComponentImp({ conceptType }) {

        const [folderStore] = useState(new FolderStore());
        const [folderList, setFolderList] = useState([] as FolderDataModel[]);
        const context = useAppContext();
        const [queryString] = useSearchParams();
        const isFeedbackActive = (queryString.get(VoyceQueryString.SpecialFolder) === VoyceQueryString.FeedbackFolder);

        useEffect(() => {
            const hubContext = MessageHubContext()
                .Subscribe(folderStore, OnFoldersLoaded);

            folderStore.initialize(conceptType);

            return () => hubContext.Dispose();
        }, [conceptType]);

        const OnFoldersLoaded = (folderListUpdated: FolderDataModel[]) => {
            folderListUpdated.sort((f, f1) => f.displayOrder > f1.displayOrder ? 1 : -1);
            setFolderList(folderListUpdated);

            if (!context.state.selectedFolder.folderID) {
                const systemAllFolder = folderListUpdated.find(folder => folder.folderType === FolderType.SystemAllConcepts);
                if (systemAllFolder) {
                    GlobalNotificationHub.sendMessageWithData(GlobalNotifications.FolderDefaultSelection, systemAllFolder);
                }
            }
        }

        const OnAddNewFoler = () => {
            GlobalNotificationHub.sendMessage(GlobalNotifications.AddNewFolder);
        }

        let folderSearch = folderList.filter(folder => folder.folderType === FolderType.SystemAllConcepts);
        const systemAllConcepts = folderSearch.length > 0 ? folderSearch[0] : { folderType: FolderType.SystemAllConcepts, conceptCount: 0 } as FolderDataModel;

        folderSearch = folderList.filter(folder => folder.folderType === FolderType.SystemNotSorted);
        const systemNotInFolder = folderSearch.length > 0 ? folderSearch[0] : { folderType: FolderType.SystemNotSorted, conceptCount: 0 } as FolderDataModel;

        folderSearch = folderList.filter(folder => folder.folderType === FolderType.SystemQikFeedbackForm);
        const systemFeedback = folderSearch.length > 0 ? folderSearch[0] : { folderType: FolderType.SystemQikFeedbackForm, conceptCount: 0 } as FolderDataModel;

        const customFolders = folderList.filter(folder => folder.folderType === FolderType.CustomFolder);

        return (
            <React.Fragment>
                <div className="vc-list-group">
                    <SystemFolderComponent conceptType={conceptType} systemFolder={systemAllConcepts} defaultSelected={!isFeedbackActive} />
                    <SystemFolderComponent conceptType={conceptType} systemFolder={systemNotInFolder} />
                    {
                        (systemFeedback.folderID) && (
                            <SystemFolderComponent conceptType={conceptType} systemFolder={systemFeedback} defaultSelected={isFeedbackActive} />
                        )}
                </div>
                <hr className="pb-1" />
                <div className="vc-list-group">
                    {
                        customFolders.map((folder) => {
                            return <FolderItemComponent key={folder.folderID} folderStore={folderStore} folderData={folder} />
                        })
                    }
                    <div className="vc-item align-items-center p-2" onClick={OnAddNewFoler} role="button" data-bs-toggle="modal" data-bs-target={CommonElements.ModalElementTarget}>
                        <svg className="p-none" width="18" height="17" role="img" aria-label="Add Folder"><use xlinkHref="#folder-new-icon"></use></svg>
                        <span className="fw-small-500">Add new</span>
                    </div>
                </div>
            </React.Fragment>
        );

    });