import { memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ConceptHeaderSection } from "../../../components/concept/sections/ConceptHeaderSection";
import { ConceptOrganizeComponent } from "../../../components/concept/sections/ConceptOrganizeComponent";
import { ConceptViewerContent } from "../../../components/concept/sections/ConceptViewerContent";
import { ConceptDataModel } from "../../../models/ConceptDataModel";
import { MessageType } from "../../../models/message/IServerMessage";
import { ConceptEditorService } from "../../../services/concepts/ConceptEditorService";
import { MessageHubOwner } from "../../../services/messaging/MessageHubOwner";
import { IHubSubcription, MessageHubContext } from "../../../services/notification/MessageHubHandler";

export const ConceptFullscreenContent: React.FunctionComponent
    = memo(function ConceptFullscreen() {
        const { cardid } = useParams();
        const conceptID = cardid;
        const [conceptService, setConceptService] = useState<ConceptEditorService>();

        useEffect(() => {
            if (!conceptID) {
                return;
            }

            const conceptEditorService = new ConceptEditorService();

            conceptEditorService.loadConcept(conceptID, true);
            setConceptService(conceptEditorService);
            conceptEditorService.conceptLinkStore.syncWithServer = true;

        }, [conceptID]);

        useEffect(() => {

            const context = MessageHubContext()
                .ListenMessage(MessageHubOwner, {
                    MessageType: MessageType.ConceptCreated, OnReceive: (concept: ConceptDataModel) => {
                        if (conceptID && conceptService) {
                            if (conceptID === concept?.conceptID) {
                                conceptService.loadConcept(conceptID, false);
                            }
                        }
                    }
                } as IHubSubcription);

            return () => context.Dispose();

        }, [conceptService, conceptID]);

        if (!conceptID || !conceptService) {
            return (
                <div></div>
            )
        }

        return (
            <div className="modal-dialog modal-xl modal-content vc-editor concept-fullscreen-display">
                <div className="bg-header p-3 concept-display-header">
                    <div className="d-flex w-100 px-1">
                        <div className="flex-fill ps-1">
                            <ConceptHeaderSection store={conceptService} />
                            {/* <div className="pt-1 d-flex fw-small-400">
                                <div className="me-1">Added</div>
                                <TextDisplayComponent fieldName="creationDateDisplay" dataStore={conceptService.conceptEditorStore} />                                
                            </div> */}
                        </div>
                    </div>
                </div>
                <div className="p-3 concept-bg-lighter">
                    <ConceptOrganizeComponent conceptService={conceptService} />
                </div>
                <div className="concept-bg-lighter concept-viewer-container">
                    <ConceptViewerContent
                        conceptID={conceptID} conceptService={conceptService}
                        isFullscreen={true}
                    />
                </div>
            </div>
        )
    })