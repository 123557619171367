import { memo, useEffect } from "react";
import { ConceptTagLoadActionResponse } from "../../../models/communication/actions/ConceptTagLoadAction";
import { MessageType } from "../../../models/message/IServerMessage";
import { ConceptEditorService } from "../../../services/concepts/ConceptEditorService";
import { MessageHubOwner } from "../../../services/messaging/MessageHubOwner";
import { IHubSubcription, MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { TagRenameHandler } from "../../../services/stores/Tag/TagRenameHandler";
import { FolderSelectionComponent } from "../../folders/FolderSelectionComponent";
import { TagComponent } from "../../tags/TagComponent";

interface ConceptOrganizeComponentProps {
    conceptService: ConceptEditorService;
}

export const ConceptOrganizeComponent: React.FunctionComponent<ConceptOrganizeComponentProps>
    = memo(function ConceptOrganizeComponentImp({ conceptService }) {


        useEffect(() => {
            const hubContext = MessageHubContext()
                .ListenMessage(MessageHubOwner, {
                    MessageType: MessageType.ConceptTagLoaded, OnReceive: (conceptTagUpdated: ConceptTagLoadActionResponse) => {

                        if (conceptTagUpdated.conceptID === conceptService.conceptEditorStore.storageState.conceptID) {
                            conceptService.tagStore.updateData(conceptTagUpdated.tagList);
                        }
                    }
                } as IHubSubcription);


            return () => hubContext.Dispose();
        }, []);

        return (
            <div className="concept-organize-container ps-2">
                <div><FolderSelectionComponent dataStore={conceptService.conceptEditorStore} /></div>
                {/* <div className="ps-3"><TagComponent tagStore={conceptService.tagStore} /></div> */}
                <div>
                    <TagComponent conceptTagStore={conceptService.tagStore} disableDelete={true} />
                    <TagRenameHandler tagStore={conceptService.tagStore} />
                </div>
            </div>
        )
    })