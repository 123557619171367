import { useAuth } from "react-oidc-context";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function HomePage() {

    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!auth.isLoading && auth.user?.access_token !== null) {
            navigate("/workspace/")
        }
    }, [auth, navigate]);

    return (
        <div></div>
    )
}

export default HomePage;