import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { IDataStore } from "../../../models/common/IDataStore";
import { memo, useEffect, useState } from "react";
import { RichTextBoxTheme } from "./theme/RichTextBoxTheme";

import { HeadingNode, QuoteNode } from "@lexical/rich-text";
import { AutoLinkNode, LinkNode } from "@lexical/link";
import { ListItemNode, ListNode } from "@lexical/list";
import { CodeNode } from "@lexical/code";
import React from "react";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { ConceptDataModel } from "../../../models/ConceptDataModel";
import { HelperExtension } from "../../../services/HelperExtension";
import { CheckListPlugin } from "@lexical/react/LexicalCheckListPlugin";

interface TextBoxRichTextReadOnlyComponentProps {
    fieldName: string;
    dataStore: IDataStore;
}

export const TextBoxRichTextReadOnlyComponent: React.FunctionComponent<TextBoxRichTextReadOnlyComponentProps>
    = memo(function TextBoxRichTextReadOnlyComponent({ fieldName, dataStore }) {

        const [jsonText, setjsonText] = useState<string>();

        useEffect(() => {

            const hubContext = MessageHubContext()
                .Subscribe((dataStore as any) as INotificationHub, (concept: ConceptDataModel) => {

                    const editorStateJSONString = HelperExtension.GetPropertyValue(fieldName, concept);
                    hubContext.Dispose();

                    if (editorStateJSONString) {
                        setjsonText(editorStateJSONString);
                    }
                });

            return () => hubContext.Dispose();
        }, []);


        if (!jsonText) {
            return <React.Fragment></React.Fragment>
        }

        //for testing
        try {
            JSON.parse(jsonText);
        } catch (e) {
            return <React.Fragment>{jsonText}</React.Fragment>
        }


        const onError = (error: any) => {
            console.error(error);
        }

        const initialConfig = {
            namespace: "VoyceEditor",
            theme: RichTextBoxTheme,
            editable: false,
            onError,

            editorState: jsonText,

            nodes: [
                HeadingNode,
                ListNode,
                ListItemNode,

                QuoteNode,
                CodeNode,
                LinkNode,
                AutoLinkNode
            ]
        };

        return (
            <LexicalComposer initialConfig={initialConfig}>
                <div className="editor-default viewer-default">
                    <RichTextPlugin
                        contentEditable={<ContentEditable />}
                        placeholder={<div></div>}
                        ErrorBoundary={LexicalErrorBoundary}
                    />

                    {/* <OnChangePlugin onChange={onChange} /> */}
                    {/* <HistoryPlugin />
                    <TreeViewPlugin /> */}
                    <ListPlugin />
                    <LinkPlugin />
                    <CheckListPlugin />
                </div>

            </LexicalComposer>

        )
    });