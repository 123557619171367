import { ConfirmNotificationModel } from "../../../models/communication/internal/ConfirmNotificationModel";
import { ConfirmModalComponent } from "../ConfirmModalComponent";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";

interface ConfirmHandlerProps {
    notification: ConfirmNotificationModel;
}

export const ConfirmHandler = (props: ConfirmHandlerProps) => {
    const confirmService = props.notification.confirmService;

    return {
        modalSize: ModalSize.Small,
        modalContent: (
            <ConfirmModalComponent confirmService={confirmService} />
        )
    } as ModalDefinitionModel;
}