import { Subject } from "rxjs";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { ConceptLinkAction } from "../../../models/communication/actions/ConceptLinkAction";
import { ConceptDataModel } from "../../../models/ConceptDataModel";
import { ConceptLinkDataModel } from "../../../models/data/ConceptLinkDataModel";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { MessageService } from "../../messaging/MessageService";

export interface ConceptLinkStoreData {
    //conceptLinkList: ConceptLinkDataModel[];
    conceptLinkList: ConceptDataModel[];
}

const initialState = {
    //conceptLinkList: [] as ConceptLinkDataModel[]
    conceptLinkList: [] as ConceptDataModel[]
} as ConceptLinkStoreData;

export class ConceptLinkStore implements INotificationHub {
    conceptLinkSubject = new Subject();
    storageState: ConceptLinkStoreData = initialState;
    syncWithServer: boolean = false;
    conceptID: string = "";

    subscribe(onMessageReceive: any) {
        return this.conceptLinkSubject.subscribe(onMessageReceive);
    }

    initialize() {

        // This component is created on demand, so no need to reset to initial state.
        this.conceptLinkSubject.next(this.storageState);
    }

    loadLinks(conceptID: string, conceptLinks: ConceptLinkDataModel[]) {

        this.conceptID = conceptID;

        this.storageState = {
            ...this.storageState, conceptLinkList: conceptLinks.map(c => {
                return { conceptID: c.toConceptID, conceptType: c.toConceptType, title: c.toConceptTitle, conceptValue: c.toConceptValue } as ConceptDataModel
            })
        };

        this.conceptLinkSubject.next(this.storageState);
    }

    async toggleLink(concept: ConceptDataModel) {

        //const existingIndex = this.storageState.conceptLinkList.findIndex(link => link.conceptID === concept.conceptID);
        let newList = [...this.storageState.conceptLinkList];

        const shouldRemoveLink = this.checkLink(concept);
        if (shouldRemoveLink) {
            newList = newList.filter(link => link.conceptID !== concept.conceptID);
        } else {
            newList.push(concept);
        }

        if (this.syncWithServer) {
            await MessageService.sendMessage({
                messageType: shouldRemoveLink ? MessageType.ConceptLinkRemove : MessageType.ConceptLinkAdd,
                requestData: {
                    fromConceptID: this.conceptID,
                    toConceptID: concept.conceptID,
                    toConceptType: concept.conceptType
                } as ConceptLinkAction
            } as IServerMessage);
        }

        this.storageState = { ...this.storageState, conceptLinkList: newList };

        //this.storageState.conceptLinkList.push(concept);        
        this.conceptLinkSubject.next(this.storageState);
    }

    checkLink(concept: ConceptDataModel) {
        const existingIndex = this.storageState.conceptLinkList.findIndex(link => link.conceptID === concept.conceptID);

        return (existingIndex > -1);
    }
}