import { Outlet } from "react-router-dom"


export const AuthLayout = () => {

    return (
        <div className="container-fluid fw-reg-400 layout-auth p-0">
            <div className="row m-0">
                <aside className="layout-main-sidebar col-md-5 col-lg-4 d-none d-md-flex">
                    <div className="d-flex w-100">
                        <div className="d-flex justify-content-center w-100 align-self-center">
                            <div className="d-flex flex-column mb-3">
                                <div className="px-2">
                                    <svg className="pe-none" width="7rem" height="5.5rem" role="img" aria-label="Home"><use xlinkHref="#voyce-logo-white"></use></svg>
                                </div>
                                <h3 className="px-2 text-center text-white fw-bolder">voyce</h3>
                            </div>
                        </div>
                    </div>
                </aside>
                <div className="col-md-7 col-lg-8 p-0">
                    <main role="main" className="d-flex justify-content-center align-items-center h-100">
                        <Outlet />
                    </main>
                </div>
            </div>
        </div>
    )
}