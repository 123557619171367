import { AuthProvider, useAuth } from "react-oidc-context";
import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { LoadingPage } from "../pages/loading/LoadingPage";
import { AuthenticationConfig } from "../services/AuthenticationService";
import { AuthRedirectCache } from "../services/authentication/AuthRedirectCache";
import { LogCollector } from "../services/logger/LogCollector";

export function PrivateLayoutBase() {
    return (
        <AuthProvider {...AuthenticationConfig}>
            <Outlet />
        </AuthProvider>
    );
}
export function AuthenticationCallback() {
    const auth = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (auth.isLoading) {
            LogCollector.LogMessage("AuthenticationCallback: Loading");
        }
        else {
            if (auth.user?.profile.updated_at) {
                navigate("/get-started/");
                return;
            }

            const goTo = AuthRedirectCache.getRedirectionURL(auth.user?.state);
            navigate(goTo);
        }

    }, [auth, navigate]);

    if (!auth.isLoading && auth.user?.access_token === null) {
        return <div></div>
    }
    return (
        <LoadingPage />
    );
}

export function SignoutCallback() {
    const navigate = useNavigate();

    useEffect(() => {

        navigate("/sign-in/");

    }, []);

    return (
        <React.Fragment></React.Fragment>
    )
}