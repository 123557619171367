import { GlobalNotifications } from "../../../models/common/CommonEnums";
import { ConceptLinkSummaryLoadAction } from "../../../models/communication/actions/ConceptLinkSummaryLoadAction";
import { ConceptViewerNotificationModel } from "../../../models/communication/internal/ConceptViewerNotificationModel";
import { LinkToNewConceptNotificationModel } from "../../../models/communication/internal/LinkToNewConceptNotificationModel";
import { ConceptLinkDataModel } from "../../../models/data/ConceptLinkDataModel";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { ConceptEditorService } from "../../../services/concepts/ConceptEditorService";
import { VoyceContextProvider } from "../../../services/context/AppContext";
import { VoyceContextExtension } from "../../../services/context/VoyceContextExtension";
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub";
import { HelperExtension } from "../../../services/HelperExtension";
import { MessageService } from "../../../services/messaging/MessageService";
import { ConceptEditorComponent } from "../../concept/ConceptEditorComponent";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";

interface LinkConceptHandlerProps {
    notification: LinkToNewConceptNotificationModel;
}

export const ConceptLinkHandler = (props: LinkConceptHandlerProps) => {

    const linkToNewConceptService = new ConceptEditorService();

    const temporaryContext = VoyceContextExtension({ conceptType: props.notification.targetConceptType });
    const linkToConcept = props.notification.linkToConcept;
    linkToNewConceptService.initialize(temporaryContext);

    linkToNewConceptService.conceptLinkStore.loadLinks(
        linkToConcept.conceptID,
        [{ toConceptID: linkToConcept.conceptID, toConceptTitle: linkToConcept.title, toConceptType: linkToConcept.conceptType, toConceptValue: linkToConcept.conceptValue } as ConceptLinkDataModel]);

    const OnModalClosingRedirectToConceptView = () => {

        MessageService.sendMessage({
            messageType: MessageType.ConceptLinkSummaryLoad,
            requestData: { conceptID: props.notification.linkToConcept.conceptID, delay: 500 } as ConceptLinkSummaryLoadAction
        } as IServerMessage);


        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.ViewConcept, { conceptID: props.notification.linkToConcept.conceptID } as ConceptViewerNotificationModel);
    }

    return {
        OnModalClosing: !props.notification.closeOnComplete ? OnModalClosingRedirectToConceptView : undefined,
        overrideConceptTheme: HelperExtension.GetConceptNameSingular(props.notification.targetConceptType)?.toLocaleLowerCase(),
        modalSize: ModalSize.XLargeScrollable,
        modalContent: (
            <VoyceContextProvider conceptType={props.notification.targetConceptType}>
                <ConceptEditorComponent conceptEditorService={linkToNewConceptService} isEditing={false} />
            </VoyceContextProvider>
        )
    } as ModalDefinitionModel;
}