import React, { useEffect } from "react"
import { useAppContext } from "../../services/context/AppContext"
import { FolderEditorStore } from "../../services/stores/Folder/FolderEditorStore";
import { CheckboxComponent } from "../elements/CheckboxComponent";
import { SaveButtonComponent, TempValidatorType } from "../elements/SaveButtonComponent";
import { TextBoxComponent } from "../elements/TextBoxComponent";


export interface FolderEditorComponentProps {
    folderStore: FolderEditorStore;
}

export const FolderEditorComponent = (props: FolderEditorComponentProps) => {

    const context = useAppContext();

    useEffect(() => {
        props.folderStore.notify();
    }, [props.folderStore])

    const SaveConcept = async () => {
        await props.folderStore.saveFolder(context.conceptType);
    }

    return (
        <React.Fragment>
            <div className="modal-header bg-header text-white">
                <div className="modal-title fw-large-500 ps-2">Create new folder</div>
                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body bg-white vc-editor m-2 mb-0">
                <div>
                    <div className="fw-reg-500 mb-1">Name of folder</div>
                    <TextBoxComponent fieldName="folder.folderName" dataStore={props.folderStore} maxLength={3500} autoFocus={true} autoFocusDelayed={true} />
                    <div className="fw-small-400 fw-secondary pt-1">Tip: Copy & paste <a className="fw-secondary" href="https://emojipedia.org/" rel="noreferrer" target="_blank">emojis</a> in your tag to help findability!</div>
                </div>
                <div className="mt-4 vc-list-group">
                    <div className="fw-reg-500">Add this folder to</div>
                    <CheckboxComponent fieldPath="insightSelected" description="Insights" dataStore={props.folderStore} />
                    <CheckboxComponent fieldPath="problemSelected" description="Problems" dataStore={props.folderStore} />
                    <CheckboxComponent fieldPath="solutionSelected" description="Solutions" dataStore={props.folderStore} />
                </div>
            </div>
            <div className="modal-footer d-flex justify-content-between pt-0 px-3" style={{ border: "0px" }}>
                <SaveButtonComponent validatorType={TempValidatorType.Folder} notificationHub={props.folderStore} ButtonClick={SaveConcept} />
                <button type="button" className="btn btn-outline-primary btn-concept-outline btn-medium" data-bs-dismiss="modal">Cancel</button>
            </div>
        </React.Fragment>
    )
}