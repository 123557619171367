import React from "react";
import { memo } from "react";
import { ConceptType, GlobalNotifications } from "../../../models/common/CommonEnums";
import { useAppContext } from "../../../services/context/AppContext";
import { HelperExtension } from "../../../services/HelperExtension";
import { SaveButtonComponent, TempValidatorType } from "../../elements/SaveButtonComponent";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../../elements/SectionHeaderComponent";
import { FieldType, TextBoxComponent } from "../../elements/TextBoxComponent";
import { ConceptAttachmentComponent } from "../../uploader/ConceptAttachmentComponent";
import { ConceptImageAttachmentComponent } from "../../uploader/ConceptImageAttachmentComponent";
import { ConceptHandlerComponentV2Props } from "../ConceptEditorComponent";
import { ConceptLinkSection } from "../sections/ConceptLinkSection";
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub";
import { TextBoxRichTextComponent } from "../../elements/RichTextBox/TextBoxRichTextComponent";

export const InsightEditorComponent: React.FunctionComponent<ConceptHandlerComponentV2Props>
    = memo(
        function InsightEditor({ conceptEditorService, isEditing }) {
            const context = useAppContext();
            const conceptEditorStore = conceptEditorService.conceptEditorStore;

            const SaveConcept = async () => {
                GlobalNotificationHub.sendMessage(GlobalNotifications.ModalLoading);

                await conceptEditorService.saveConcept(context);
            }

            return (
                <React.Fragment>
                    <div className="modal-header bg-header text-white">
                        <SectionHeaderComponent iconName={context.conceptIcon} header={HelperExtension.GetConceptHeaderText(context.conceptType, isEditing)} iconSize={SectionHeaderIconSize.Medium} headerStyle="icon-bright" />
                        <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body bg-white vc-editor concept-editor ms-2 vc-custom-scroll">
                        <div className="mb-3 pt-3">
                            <SectionHeaderComponent iconName="#edit-icon" header={context.conceptTypeDescription + " title *"} />
                            <div className="mt-2 mb-1 fw-small-400">Summarise as a single sentence. You can add more details to this below.</div>
                            <TextBoxComponent fieldName="title" dataStore={conceptEditorStore} maxLength={300} autoFocus={true} autoFocusDelayed={true} />
                        </div>
                        <hr className="my-4 mx-auto" />
                        <div className="my-3">
                            <SectionHeaderComponent iconName="#edit-icon" header="Description" />
                            {/* <div className="mt-2">
                                <TextBoxComponent fieldName="description" dataStore={conceptEditorStore} details={{ fieldType: FieldType.Text, numberOfRows: 5 }} maxLength={4000} />
                            </div> */}
                            <div className="mt-2">
                                <TextBoxRichTextComponent fieldName="description" dataStore={conceptEditorStore} maxLength={3500} />
                            </div>
                        </div>
                        <hr className="my-4 mx-auto" />
                        <div className="my-3">
                            <ConceptImageAttachmentComponent attachmentStore={conceptEditorService.conceptAttachmentStore} />
                        </div>

                        {/* {
                            (!isEditing) &&
                            <React.Fragment>
                                <hr className="my-4 mx-auto" />
                                <div className="my-3">
                                    <SectionHeaderComponent iconName="#edit-icon" header={"Organize this " + context.conceptTypeDescription.toLowerCase()} />
                                    <div className="my-2">
                                        <FolderSelectionComponent dataStore={conceptEditorStore} />
                                    </div>
                                    <TagComponent conceptTagStore={conceptEditorService.tagStore} disableDelete={true} />
                                </div>
                            </React.Fragment>
                        } */}

                        <hr className="my-4 mx-auto" />
                        <div className="py-1 fw-reg-500">
                            <SectionHeaderComponent iconName="#customer-icon" header="Customer details" />
                            <div className="mt-1 row g-3">
                                <div className="col-md-6">
                                    <div className="mb-1">Name</div>
                                    <TextBoxComponent fieldName="insightData.customerName" dataStore={conceptEditorStore} maxLength={50} />
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-1">Email</div>
                                    <TextBoxComponent fieldName="insightData.customerEmail" dataStore={conceptEditorStore} details={{ fieldType: FieldType.Email, numberOfRows: 1 }} maxLength={50} />
                                </div>
                            </div>
                            <div className="row pt-3 g-3">
                                <div className="col-md-6">
                                    <div className="mb-1">Phone</div>
                                    <TextBoxComponent fieldName="insightData.customerPhone" dataStore={conceptEditorStore} maxLength={50} />
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-1">User ID</div>
                                    <TextBoxComponent fieldName="insightData.customerUserID" dataStore={conceptEditorStore} maxLength={50} />
                                </div>
                            </div>
                        </div>
                        <hr className="my-4 mx-auto" />
                        <div className="my-3">
                            <ConceptAttachmentComponent attachmentStore={conceptEditorService.conceptAttachmentStore} />
                        </div>

                        <hr className="my-4 mx-auto" />
                        <ConceptLinkSection conceptLinkStore={conceptEditorService.conceptLinkStore} sourceConceptType={context.conceptType} targetConceptType={ConceptType.Problems} isEditing={true} />

                        <hr className="my-4 mx-auto" />
                        <ConceptLinkSection conceptLinkStore={conceptEditorService.conceptLinkStore} sourceConceptType={context.conceptType} targetConceptType={ConceptType.Solutions} isEditing={true} />

                    </div>
                    <div className="modal-footer d-flex justify-content-between">
                        <div className="mx-3">
                            <SaveButtonComponent
                                validatorType={TempValidatorType.Concept}
                                notificationHub={conceptEditorStore}
                                disableAutoClose={true}
                                ButtonClick={SaveConcept} />
                        </div>
                        <div className="mx-3">
                            <button type="button" className="btn btn-outline-primary btn-concept-outline btn-medium" data-bs-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    )