import { useEffect, useMemo, useState } from "react"
import { CustomModalComponent } from "../../components/modal/CustomModalComponent"
import { ModalComponent } from "../../components/modal/ModalComponent"
import { AlertTimerComponent } from "../../components/notification/AlertTimerComponent"
import { HelpUsComponent } from "../../components/notification/HelpUsComponent"
import { ConceptType, GlobalNotifications } from "../../models/common/CommonEnums"
import { VoyceContextProvider } from "../../services/context/AppContext"
import { KeyboardServiceHandler } from "../../services/shortcut/KeyboardService"
import { ExternalCommandHandler } from "../search/commands/ExternalCommandHandler"
import { AddNewConceptSection } from "./elements/AddNewConceptSection"
import { HeaderDisplay } from "./elements/HeaderDisplay"
import { RecentEventsSection } from "./elements/RecentEventsSection"
import { RecentFeedbackSection } from "./elements/RecentFeedbackSection"
import { MessageHubContext } from "../../services/notification/MessageHubHandler"
import { ConceptViewerNotificationModel } from "../../models/communication/internal/ConceptViewerNotificationModel"
import { debounce } from "lodash"
import { CommonElements } from "../../models/CommonElements"

export const DashboardPage = () => {

    return (
        <div className="layout-main-content">
            <div className="dashboard-container pt-4  main-content vc-custom-scroll-dark">
                <div className="dashboard-section">
                    <div className="pt-3">
                        <HeaderDisplay />
                    </div>
                    <div className="pt-3 mt-2">
                        <AddNewConceptSection />
                    </div>
                    <div className="pt-3">
                        <RecentFeedbackSection />
                    </div>
                    <div className="pt-3">
                        <RecentEventsSection />
                    </div>
                </div>
            </div>


            <Test />
            <AlertTimerComponent />
            <CustomModalComponent />
            <KeyboardServiceHandler />
            <HelpUsComponent />
            <ExternalCommandHandler />
        </div>
    )
}

const Test = () => {

    const [conceptType, setConceptType] = useState(ConceptType.Insights);

    const debouncedOpenModalHandler = useMemo(
        () => debounce(async () => {
            global.window.openModal(CommonElements.ModalElement);
        }, 50)
        , []);

    useEffect(() => {
        return () => debouncedOpenModalHandler.cancel();
    }, [debouncedOpenModalHandler]);

    useEffect(() => {
        const hubContext = MessageHubContext()
            .ListenGlobalNotification([GlobalNotifications.ViewConcept], (notification) => {
                const x = notification.notificationData as ConceptViewerNotificationModel;
                if (x.conceptType) {
                    setConceptType(x.conceptType);
                    debouncedOpenModalHandler();
                }
            });
        return () => hubContext.Dispose();
    }, [debouncedOpenModalHandler]);

    let themeType = "";
    switch (conceptType) {
        case ConceptType.Problems:
            themeType = "problem";
            break;
        case ConceptType.Solutions:
            themeType = "solution";
            break;
    }

    // const x = document.getElementById("root");
    // if (x)
    //     x.setAttribute("data-theme", themeType)

    return (
        <div data-theme={themeType}>
            <VoyceContextProvider conceptType={conceptType}>
                <ModalComponent />
            </VoyceContextProvider>
        </div>
    )
}
