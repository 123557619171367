import React from "react";
import { Link, Outlet } from "react-router-dom";
import { ConceptType } from "../models/common/CommonEnums";
import { InitializeTheme } from "../services/ThemeService";
import { LogCollector } from "../services/logger/LogCollector";
import { ApplicationSignOut } from "../services/AuthenticationService";
import { GlobalStore } from "../services/stores/GlobalStore";

interface WorkspaceLayoutProps {
    displayTeamMemberSection?: boolean;
}

export const WorkspaceLayout = (props: WorkspaceLayoutProps) => {

    LogCollector.LogMessage("WorkspaceLayout: render");

    InitializeTheme(ConceptType.Default);

    const workspaceName = props.displayTeamMemberSection ? GlobalStore.getWorkspaceNameIfAvailable() : "";
    const workspaceCode = props.displayTeamMemberSection ? GlobalStore.getWorkspaceCodeIfAvailable() : "";

    return (
        <div className="d-flex flex-nowrap">
            <aside className="layout-main-sidebar d-none d-md-block">
                <div className="d-flex flex-nowrap h-100">
                    <div className="d-flex flex-column flex-shrink-0 text-center main-sidebar" style={{ width: "120px" }}>
                        <Link to="/" className="d-block p-3 link-dark text-decoration-none voyce-logo" style={{ height: "5.5em" }}>
                            <svg className="pe-none" width="56" height="46" role="img" aria-label="Home"><use xlinkHref="#voyce-logo"></use></svg>
                            <svg className="pe-none" width="49" height="12" role="img" aria-label="Home"><use xlinkHref="#voyce-word"></use></svg>
                        </Link>
                        <hr className="my-0 mx-auto w-75" />
                        <ul className="nav nav-pills nav-flush flex-column mb-auto text-center pt-3">

                        </ul>
                        <div className="dropdown pb-3">

                            {/* <li><a className="dropdown-item" href="/#">New project...</a></li>
                                <li><a className="dropdown-item" href="/#">Settings</a></li>
                                <li><a className="dropdown-item" href="/#">Profile</a></li> */}
                            {/* <li><button className="dropdown-item" onClick={SwitchTheme}>Switch Theme</button></li>
                                <li><hr className="dropdown-divider" /></li> */}
                            <ApplicationSignOut />
                        </div>
                    </div>
                </div>
            </aside>
            <div className="container-fluid p-0">
                <header className={"navbar navbar-expand-md navbar-dark sticky-top p-0 bg-header-home"} style={{ height: "5.5em" }}>
                    <nav className="flex-nowrap flex-lg-nowrap w-100" aria-label="Main navigation">
                        {
                            (props.displayTeamMemberSection && workspaceName) &&
                            <div className="d-flex ps-4 text-center text-md-start align-items-center">
                                <div className="fw-xlarge-700 fw-fade">
                                    <Link className="fw-xlarge-700 workspace-link fw-fade" to={"/w/" + workspaceCode + "/"} id="workspace-name-element">
                                        {workspaceName}
                                    </Link>
                                </div>
                                <div className="ps-3">
                                    <svg className="pe-none" width="20" height="20" role="img"><use xlinkHref="#arrow-icon"></use></svg>
                                </div>
                                <div className="fw-xlarge-700 fw-color-light ps-3">Team Members</div>
                            </div>
                        }
                        <div className="d-flex justify-content-center w-100">
                            <div className="d-flex flex-column d-md-none">
                                <svg className="pe-none" width="56" height="36" role="img" aria-label="Home"><use xlinkHref="#voyce-logo-white"></use></svg>
                                <svg className="pe-none" width="56" height="36" role="img" aria-label="Home" fill="white"><use xlinkHref="#voyce-word-white"></use></svg>
                            </div>
                        </div>

                    </nav>
                </header>
                <main className="container-fluid p-0">
                    <div className="layout-main-content">
                        <div className="main-content vc-custom-scroll-dark">
                            <Outlet />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}