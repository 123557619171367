
interface AuthRedirectCacheState {
    state: string;
    redirectURL: string;
}

class AuthRedirectCacheImplementation {

    getRedirectionURL(stateKey: any | null) {

        if (!stateKey) {
            return "/workspace/";
        }

        const stateCacheJson = localStorage.getItem("rCache");
        if (stateCacheJson) {
            const redirectionState = JSON.parse(stateCacheJson) as AuthRedirectCacheState;
            if (redirectionState?.state === stateKey) {
                localStorage.removeItem("rCache");
                return redirectionState.redirectURL;
            }
        }

        return "/workspace/";
    }

    create() {
        return this.createWithUrl(window.location.pathname);
    }

    createWithUrl(redirectUrl: string) {
        const redirectionState = {
            state: crypto.randomUUID(),
            redirectURL: redirectUrl
        } as AuthRedirectCacheState;

        localStorage.setItem("rCache", JSON.stringify(redirectionState));

        return redirectionState.state;
    }
}

export const AuthRedirectCache = new AuthRedirectCacheImplementation();