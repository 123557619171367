import { memo } from "react";
import { ConceptDataModel } from "../../models/ConceptDataModel";
import CardDisplayComponent from "./CardDisplayComponent";
import { LogCollector } from "../../services/logger/LogCollector";

export interface CardDisplayDraggablePreviewProps {
    conceptModel: ConceptDataModel;
}

export const CardDisplayDraggablePreview: React.FunctionComponent<CardDisplayDraggablePreviewProps>
    = memo(
        function CardDisplaySmall({ conceptModel }) {
            // const styles: CSSProperties = {
            //     display: 'inline-block',
            //     transform: 'rotate(-2deg)',
            //     WebkitTransform: 'rotate(-2deg)',
            //   }

            LogCollector.LogMessage("CardDisplayDraggablePreview");
            return (
                <CardDisplayComponent conceptModel={conceptModel} />
            );
        });