
interface DismissibleNotification {
    preRelease: boolean;
    voyceFeedback: boolean;
}

export enum UserDismissibleType {
    PreRelease = 1,
    VoyceFeedback = 2
}

class UserDismissibleStoreImplementation {

    currentState: DismissibleNotification;


    constructor() {

        this.currentState = {
            preRelease: true,
            voyceFeedback: true
        } as DismissibleNotification;

        var currentValue = localStorage.getItem("user.custom.notification");
        if (currentValue) {
            const x = JSON.parse(currentValue) as DismissibleNotification;
            if (x) {
                this.currentState = { ...this.currentState, ...x };
            }
        }
    }

    shouldDisplay(noticationKey: UserDismissibleType) {

        switch (noticationKey) {
            case UserDismissibleType.PreRelease:
                return this.currentState.preRelease ?? false;
            case UserDismissibleType.VoyceFeedback:
                return this.currentState.voyceFeedback ?? false;
            default:
                return false;
        }
    }

    dismiss(noticationKey: UserDismissibleType) {

        switch (noticationKey) {
            case UserDismissibleType.PreRelease:
                this.currentState.preRelease = false;
                break;
            case UserDismissibleType.VoyceFeedback:
                this.currentState.voyceFeedback = false;
                break;
        }

        const newJson = JSON.stringify(this.currentState);
        localStorage.setItem("user.custom.notification", newJson);
    }
}

export const UserDismissibleStore = new UserDismissibleStoreImplementation();