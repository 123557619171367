import { Subject } from "rxjs";
import { ConceptType } from "../../../models/common/CommonEnums";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { ConceptDataModel } from "../../../models/ConceptDataModel";
import { TagDataModel } from "../../../models/data/TagDataModel";

export interface ConceptTagStoreModel {
    conceptID: string;
    conceptType: ConceptType;

    selectedTags: TagDataModel[];
}

export class ConceptTagStore implements INotificationHub {
    tagStoreSubject = new Subject();
    storageState = { conceptID: "", conceptType: ConceptType.Default, selectedTags: [] } as ConceptTagStoreModel;
    syncWithServer: boolean = true;

    subscribe(OnReceiveMessage: any) {
        return this.tagStoreSubject.subscribe(OnReceiveMessage);
    }

    initNew(conceptType: ConceptType) {
        this.storageState = {
            ...this.storageState,

            conceptID: "...",
            conceptType: conceptType,
            selectedTags: []
        }
        this.tagStoreSubject.next(this.storageState);
    }

    initialize(conceptModel: ConceptDataModel) {
        this.storageState = {
            conceptID: conceptModel.conceptID,
            conceptType: conceptModel.conceptType,
            selectedTags: [
                ...(conceptModel.tagList ?? [])
            ]
        } as ConceptTagStoreModel;

        this.tagStoreSubject.next(this.storageState);
    }

    notify() {
        this.tagStoreSubject.next(this.storageState);
    }

    updateData(tagList: TagDataModel[]) {

        const newList = [...tagList]

        this.storageState = {
            ...this.storageState,
            selectedTags: [
                ...newList
            ]
        }

        this.tagStoreSubject.next(this.storageState);
    }

    resetSelection() {
        this.updateData([]);
    }

    processRename(tag: TagDataModel) {
        const existingTag = this.storageState.selectedTags.find(t => t.tagID === tag.tagID);
        if (!existingTag) {
            return;
        }

        existingTag.tagDescription = tag.tagDescription;
        this.storageState.selectedTags = [...this.storageState.selectedTags];

        this.tagStoreSubject.next(this.storageState);
    }

    processDelete(deletedTag: TagDataModel) {

        const newList = this.storageState.selectedTags.filter(tag => tag.tagID !== deletedTag.tagID);
        this.storageState.selectedTags = [...newList];

        this.tagStoreSubject.next(this.storageState);
    }
}