import { useEffect, useState } from "react"
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { WorkspaceDataModel } from "../models/data/WorkspaceDataModel";
import { MessageHubContext } from "../services/notification/MessageHubHandler";
import { GlobalStore } from "../services/stores/GlobalStore";
import { LoadingPage } from "./loading/LoadingPage"

//const workspaceLoaderStore = new WorkspaceStore();

export const WorkspaceLoadPage = () => {

    const { id } = useParams();
    const navigate = useNavigate();
    const workspaceLoaderStore = GlobalStore.workspaceLoaderStore;

    const [workspaceList, setWorkspaceList] = useState<WorkspaceDataModel[]>();
    const [hasAccess, setHasAccess] = useState(false);

    useEffect(() => {

        const hubContext = MessageHubContext()
            .Subscribe(workspaceLoaderStore, (workspaceListResponse: WorkspaceDataModel[]) => {
                setWorkspaceList(workspaceListResponse);
            });


        workspaceLoaderStore.initialize();

        return () => hubContext.Dispose();

    }, [workspaceLoaderStore])


    useEffect(() => {
        if (!workspaceList) {
            return;
        }

        const workspace = workspaceList.find(w => w.workspaceCode === id);
        if (workspace) {
            GlobalStore.setCurrentWorkspace(workspace);
            setHasAccess(true);
        }
        else {
            navigate("/no-auth/");
        }

    }, [id, workspaceList, navigate]);

    if (hasAccess) {
        return (
            <Outlet />
        )
    }

    return (
        <LoadingPage />
    )
}