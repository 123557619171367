import React from "react";
import { useEffect, useState } from "react";
import { ConceptType } from "../../../../models/common/CommonEnums";
import { MessageHubContext } from "../../../../services/notification/MessageHubHandler";
import { ConceptLinkStore, ConceptLinkStoreData } from "../../../../services/stores/ConceptLink/ConceptLinkStore"
import { ConceptLinkComponent } from "../../../conceptlink/ConceptLinkComponent"

interface SolutionCustomLinkComponentProps {
    conceptLinkStore: ConceptLinkStore;
}

export const SolutionCustomLinkComponent = (props: SolutionCustomLinkComponentProps) => {

    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        const messageHubContext = MessageHubContext()
            .Subscribe(props.conceptLinkStore, (conceptLink: ConceptLinkStoreData) => {
                setIsVisible(conceptLink.conceptLinkList.length === 0);
            });


        return () => messageHubContext.Dispose();
    }, [props.conceptLinkStore]);

    if (!isVisible) {
        return (<React.Fragment></React.Fragment>);
    }

    return (
        <div data-theme="problem">
            <ConceptLinkComponent conceptLinkStore={props.conceptLinkStore} conceptType={ConceptType.Problems}
                buttonIcon="#link-icon"
                buttonText="Link to an existing problem"
                buttonStyle="btn btn-light btn-concept-lighter btn-small text-nowrap" />
        </div>
    )
}
