import { ConceptType } from "../../models/common/CommonEnums";
import { useAppContext } from "../../services/context/AppContext";
import { HelperExtension } from "../../services/HelperExtension";
import { ConceptLinkStore } from "../../services/stores/ConceptLink/ConceptLinkStore";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../elements/SectionHeaderComponent";
import { ConceptSearchComponent } from "./ConceptSearchComponent";

interface ConceptLinkContentComponentProps {
    conceptLinkStore: ConceptLinkStore;
    conceptType: ConceptType;

    dropdownAligment: string;
    dropdownUID: string;
}

export const ConceptLinkContentComponent = (props: ConceptLinkContentComponentProps) => {

    const context = useAppContext();
    //const dropdownUID = Math.random().toString(36);

    return (
        <div className={"dropdown-menu shadow drop-container drop-container-link " + props.dropdownAligment}>
            <div className="text-white p-3 ps-4 rounded-top d-flex align-items-center concept-bg">                
                <div>
                    <SectionHeaderComponent iconName="#link-icon"
                        header={"Link a " + HelperExtension.GetConceptName(props.conceptType, 1).toLowerCase() + " to this " + context.conceptTypeDescription.toLowerCase()}
                        iconSize={SectionHeaderIconSize.Medium} headerStyle="icon-bright" />
                </div>
                <div className="m-auto"></div>
                <div><button type="button" className="btn-close btn-close-white" onClick={() => { global.window.closeCustomDropdown(props.dropdownUID); }}></button></div>
            </div>
            <div className="vc-editor vc-list-group" >
                {
                    <ConceptSearchComponent conceptLinkStore={props.conceptLinkStore} conceptType={props.conceptType} />
                }
            </div>
            <div className="m-3 mt-2">
                <button className="btn btn-primary btn-concept w-100" onClick={() => { global.window.closeCustomDropdown(props.dropdownUID); }}>Done</button>
            </div>
        </div>
    )
}