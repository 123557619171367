import React from "react";
import { Link, Outlet } from "react-router-dom";
import { ConceptType } from "../models/common/CommonEnums";
import { InitializeTheme } from "../services/ThemeService";
import { LogCollector } from "../services/logger/LogCollector";

export const PublicLayout = () => {

    LogCollector.LogMessage("PublicLayout: render");

    InitializeTheme(ConceptType.Default);

    return (
        <div className="d-flex flex-nowrap">
            <aside className="layout-main-sidebar d-none d-md-block">
                <div className="d-flex flex-nowrap h-100">
                    <div className="d-flex flex-column flex-shrink-0 text-center main-sidebar" style={{ width: "120px" }}>
                        <Link to="/" className="d-block p-3 link-dark text-decoration-none voyce-logo" style={{ height: "5.5em" }}>
                            <svg className="pe-none" width="56" height="46" role="img" aria-label="Home"><use xlinkHref="#voyce-logo"></use></svg>
                            <svg className="pe-none" width="49" height="12" role="img" aria-label="Home"><use xlinkHref="#voyce-word"></use></svg>
                        </Link>
                        <hr className="my-0 mx-auto w-75" />
                    </div>
                </div>
            </aside>
            <div className="container-fluid p-0">
                <header className={"navbar navbar-expand-md navbar-dark sticky-top p-0 bg-header-home"} style={{ height: "5.5em" }}>
                    <nav className="container-fluid flex-nowrap flex-lg-nowrap" aria-label="Main navigation">

                    </nav>
                </header>
                <main className="container-fluid p-0">
                    <div className="layout-main-content">
                        <div className="main-content">
                            <Outlet />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
}