import { GlobalNotifications } from "../../../models/common/CommonEnums";
import { ConceptEditNotificationModel } from "../../../models/communication/internal/ConceptEditNotificationModel";
import { ConceptViewerNotificationModel } from "../../../models/communication/internal/ConceptViewerNotificationModel";
import { ConceptEditorService } from "../../../services/concepts/ConceptEditorService";
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub";
import { ConceptEditorComponent } from "../../concept/ConceptEditorComponent";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";

interface EditConceptHandlerProps {
    notification: ConceptEditNotificationModel;
}

export const ConceptEditHandler = (props: EditConceptHandlerProps) => {

    const conceptService = new ConceptEditorService();
    conceptService.loadConcept(props.notification.conceptID, false);

    const OnClosingRedirectToViewConcept = () => {
        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.ViewConcept, { conceptID: props.notification.conceptID } as ConceptViewerNotificationModel);
    }

    return {
        OnModalClosing: !props.notification.closeOnComplete ? OnClosingRedirectToViewConcept : undefined,
        modalSize: ModalSize.XLargeScrollable,
        modalContent: (
            <ConceptEditorComponent conceptEditorService={conceptService} isEditing={true} />
        )
    } as ModalDefinitionModel;
}