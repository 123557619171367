import { Subject } from "rxjs";
import { IDataStore } from "../../models/common/IDataStore";
import { INotificationHub } from "../../models/common/INotificationHub";

export interface UploadStoreData {
    isUploading: boolean;
    uploadPercentage: number;
}

const defaultUploadStoreState = {
    isUploading: false,
    uploadPercentage: 0
} as UploadStoreData;

export class UploadStore implements IDataStore, INotificationHub {
    uploadStoreSubject = new Subject();
    storageState = defaultUploadStoreState;


    initialze() {
        this.storageState = { ...defaultUploadStoreState };
    }


    subscribe(onMessageReceived: any) {
        return this.uploadStoreSubject.subscribe(onMessageReceived);
    }

    sendMessage(fieldName: string, fieldValue: string) {
        throw new Error("Method not implemented.");
    }

    updateProgress(progressPercentage: number) {

        this.storageState = { ...this.storageState, uploadPercentage: progressPercentage };
        this.uploadStoreSubject.next(this.storageState);
    }

}