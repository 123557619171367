import { Subject } from "rxjs";
import { IDataStore } from "../../../models/common/IDataStore";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { WorkspaceLoadAction, WorkspaceLoadActionResponse } from "../../../models/communication/actions/WorkspaceLoadAction";
import { WorkspaceDataModel } from "../../../models/data/WorkspaceDataModel";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { MessageHubOwner } from "../../messaging/MessageHubOwner";
import { MessageService } from "../../messaging/MessageService";
import { IHubSubcription, MessageHubContext, MessageHubHandler } from "../../notification/MessageHubHandler";
import { WorkspaceSaveAction } from "../../../models/communication/actions/WorkspaceSaveAction";


interface WorkspaceStoreData {
    workspaceList: WorkspaceDataModel[];
}

export class WorkspaceStore implements IDataStore, INotificationHub {

    searchSubject = new Subject();
    storageState: WorkspaceStoreData = { workspaceList: [] } as WorkspaceStoreData;
    hubContext: MessageHubHandler;

    constructor() {
        this.OnWorkspaceLoaded = this.OnWorkspaceLoaded.bind(this);

        this.hubContext = MessageHubContext()
            .ListenMessage(MessageHubOwner, { MessageType: MessageType.WorkspaceLoaded, OnReceive: this.OnWorkspaceLoaded } as IHubSubcription);
    }


    initialize() {

        if (this.storageState.workspaceList.length > 0) {
            this.searchSubject.next(this.storageState.workspaceList);
            return;
        }

        MessageService.sendMessage({
            messageType: MessageType.WorkspaceLoad,
            requestData: {} as WorkspaceLoadAction
        } as IServerMessage);
    }

    forceReload() {
        this.storageState = { workspaceList: [] } as WorkspaceStoreData;
    }

    async OnWorkspaceLoaded(response: WorkspaceLoadActionResponse) {
        const copy = response.workspaceList.map((tag) => {
            return { ...tag };
        });

        this.storageState = { workspaceList: copy };
        this.searchSubject.next(this.storageState.workspaceList);
    }

    sendMessage(fieldName: string, fieldValue: string) {
    }

    subscribe(onMessageReceived: any) {
        return this.searchSubject.subscribe(onMessageReceived);
    }

    addNotSaved(workspace: WorkspaceDataModel) {
        this.storageState.workspaceList.push(workspace);

        this.searchSubject.next(this.storageState.workspaceList);
    }

    updateWorkspaceName(workspaceID: string, workspaceName: string) {

        const foundWorkspaces = this.storageState.workspaceList.filter(workspace => workspace.workspaceID === workspaceID);
        if (foundWorkspaces.length > 0) {
            foundWorkspaces[0].workspaceName = workspaceName;
        }

        this.searchSubject.next(this.storageState.workspaceList);
    }

    async deleteWorkspace(workspaceID: string) {

        return MessageService.sendMessage({
            messageType: MessageType.WorkspaceDelete,
            requestData: {
                workspaceData: {
                    workspaceID: workspaceID
                }
            } as WorkspaceSaveAction
        } as IServerMessage)
            .then(() => {
                const newWorkspaceList = this.storageState.workspaceList.filter(workspace => workspace.workspaceID !== workspaceID);
                this.storageState.workspaceList = [...newWorkspaceList];

                this.searchSubject.next(this.storageState.workspaceList);
            });
    }

    dispose() {
        this.hubContext.Dispose();
    }
}