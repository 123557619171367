import { ConceptType } from "../../../models/common/CommonEnums"
import { InsightEmptyContent } from "./InsightEmptyContent";
import { ProblemEmptyContent } from "./ProblemEmptyContent";
import { SolutionEmptyContent } from "./SolutionEmptyContent";

interface ConceptEmptyContentProps {
    conceptType: ConceptType;
}

export const ConceptEmptyContent = (props: ConceptEmptyContentProps) => {

    switch (props.conceptType) {
        case ConceptType.Problems:
            return <ProblemEmptyContent />
        case ConceptType.Solutions:
            return <SolutionEmptyContent />
        default:
            return <InsightEmptyContent />
    }

}