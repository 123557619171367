import { Subscription } from "rxjs";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { INotificationHub } from "../../models/common/INotificationHub";
import { IMessageData, IServerMessage, MessageType, MessageTypeEnumDescriptor } from "../../models/message/IServerMessage";
import { GlobalNotification, GlobalNotificationHub } from "../GlobalMessageHub";
import { LogCollector } from "../logger/LogCollector";

export interface IHubSubcription {
    MessageType: MessageType;
    OnReceive: (messageData: IMessageData) => any;
}

export class MessageHubHandler {
    componentSubscriptionList: Subscription[] = [];


    Subscribe(notificationHub: INotificationHub, messageReceiveAction: any) {
        var subscription = notificationHub.subscribe(messageReceiveAction);

        this.componentSubscriptionList.push(subscription);
        LogCollector.LogMessage("Subscribe");
        return this;
    };

    ListenGlobalNotification(notificationsTypeList: GlobalNotifications[], onNotificationReceive: (notification: GlobalNotification) => void) {

        return this.ListenGlobalNotificationAsync(notificationsTypeList, async (notificationReceived: GlobalNotification) => {
            onNotificationReceive(notificationReceived);
        });
    }

    ListenGlobalNotificationAsync(notificationsTypeList: GlobalNotifications[], onNotificationReceive: (notification: GlobalNotification) => Promise<void>) {

        const subscription = GlobalNotificationHub.subscribe(async (notification: GlobalNotification) => {

            if (notificationsTypeList.indexOf(notification.notificationType) > - 1) {
                await onNotificationReceive(notification);
            }

        });

        this.componentSubscriptionList.push(subscription);

        return this;
    }

    ListenMessage(notificationHub: INotificationHub, hubSubscription: IHubSubcription) {

        const subscription = notificationHub.subscribe((responseObject: any) => {

            const responseMessage = responseObject as IServerMessage;
            if (responseMessage === null) {
                return;
            }

            const responseMessageType = MessageTypeEnumDescriptor.get(responseMessage.messageType);
            const subscriptionMessageType = MessageTypeEnumDescriptor.get(hubSubscription.MessageType);
            if (responseMessageType === subscriptionMessageType) {
                hubSubscription.OnReceive(responseMessage.requestData);
            }
        });

        this.componentSubscriptionList.push(subscription);
        LogCollector.LogMessage("ListenMessage");
        return this;
    }

    Dispose() {
        this.componentSubscriptionList.map((sub) => {
            LogCollector.LogMessage("unsubscribe");
            sub.unsubscribe();
            return -1;
        });

        this.componentSubscriptionList = [];
    }
}

export const MessageHubContext = () => {
    return new MessageHubHandler();
}