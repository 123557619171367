import { useEffect, useState } from "react";
import { ConceptType, GlobalNotifications } from "../../models/common/CommonEnums";
import { FolderDataModel, FolderType } from "../../models/data/FolderDataModel"
import { HelperExtension } from "../../services/HelperExtension";
import { useAppContext } from "../../services/context/AppContext";
import { GlobalNotification, GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";

export interface SystemFolderComponentProps {
    systemFolder: FolderDataModel;
    conceptType: ConceptType;
    defaultSelected?: boolean;
}

export const SystemFolderComponent = (props: SystemFolderComponentProps) => {

    const [isSelected, setIsSelected] = useState(props.defaultSelected ?? false);
    const context = useAppContext();

    useEffect(() => {
        const messageHubContext = MessageHubContext()
            .ListenGlobalNotification([GlobalNotifications.FolderSelectChange], OnFolderSelectChangeNotification);

        if (props.defaultSelected && isSelected) {
            GlobalNotificationHub.sendMessageWithData(GlobalNotifications.FolderDefaultSelection, props.systemFolder);
        }

        return () => messageHubContext.Dispose();
    });

    const OnFolderSelectChangeNotification = (notification: GlobalNotification) => {
        const selectedFolder = notification.notificationData as FolderDataModel;
        setIsSelected(props.systemFolder.folderID === selectedFolder.folderID);
    }

    const OnFolderChange = (folder: FolderDataModel) => {
        
        window.history.pushState({}, "", window.location.pathname);
        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.FolderSelectChange, folder);
    }

    const GetSystemFolderDescription = () => {
        return HelperExtension.GetSystemFolderDescription(props.systemFolder, context.conceptTypeDescription);
    }

    const GetSystemFolderIcon = () => {

        switch (props.systemFolder.folderType) {
            case FolderType.SystemAllConcepts:
                return context.conceptIcon;
            case FolderType.SystemNotSorted:
                return "#folder-notin-icon";
            case FolderType.SystemQikFeedbackForm:
                return "#folder-feedback-icon";
        }

    }

    return (
        <div id={"system-folder-" + props.systemFolder.folderType} className={"vc-item align-items-center p-2" + (isSelected ? " active" : "")} onClick={() => OnFolderChange(props.systemFolder)}>
            <svg className="p-none" width="18" height="18" role="img"><use xlinkHref={GetSystemFolderIcon()}></use></svg>
            <span className="me-auto fw-small-500">{GetSystemFolderDescription()}</span>
            <div className="fw-small-400 fw-secondary">{props.systemFolder.conceptCount}</div>
        </div>
    );
}