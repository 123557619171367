import React from "react";
import { useRef } from "react";
import { SecureDownloader } from "./SecureDownloader";

export interface SecureImagePreviewProps {
    imagePartialUrl: string;
    savingFileName: string;
}
//const serverURL = process.env.REACT_APP_INTEGRATION_URL;


export const SecureImagePreview = (props: SecureImagePreviewProps) => {
    const previewImageDisplay = useRef<HTMLDivElement>(null);
    const loadingDisplay = useRef<HTMLDivElement>(null);

    const OnImageDownloaded = (blobUrl: string) => {

        if (loadingDisplay.current) {
            loadingDisplay.current.style.display = "none";
        }

        if (previewImageDisplay.current) {
            previewImageDisplay.current.style.backgroundImage = "url('" + blobUrl + "'";
        }
    }

    return (
        <React.Fragment>
            <div ref={previewImageDisplay} role="button" className="attachment-image-preview">
                <div ref={loadingDisplay} className="spinner-border image-loading"></div>
            </div>
            <SecureDownloader partialUrl={props.imagePartialUrl} OnDocumentLoaded={OnImageDownloaded} />
        </React.Fragment>
    )
}