import React from "react";
import { memo } from "react";
import { ConceptType } from "../../../models/common/CommonEnums";
import { ConceptEditorService } from "../../../services/concepts/ConceptEditorService";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../../elements/SectionHeaderComponent";
import { TextDisplayComponent } from "../../elements/TextDisplayComponent";
import { ConceptAttachmentComponent } from "../../uploader/ConceptAttachmentComponent";
import { ConceptLinkSection } from "../sections/ConceptLinkSection";
import { ConceptImageAttachmentComponent } from "../../uploader/ConceptImageAttachmentComponent";
import { ProblemDefinitionDisplayComponent } from "./elements/ProblemDefinitionDisplayComponent";
import { TextBoxRichTextReadOnlyComponent } from "../../elements/RichTextBox/TextBoxRichTextReadOnlyComponent";

interface ProblemViewerComponentProps {
    conceptService: ConceptEditorService;
}

export const ProblemViewerComponent: React.FunctionComponent<ProblemViewerComponentProps>
    = memo(
        function ProblemViewer({ conceptService }) {

            const dataStore = conceptService.conceptEditorStore;

            return (
                <React.Fragment>
                    <div>
                        <SectionHeaderComponent iconName="#ray-icon" header="Problem statement" iconSize={SectionHeaderIconSize.Medium} />
                        <div className="mt-2 display-lines">
                            <ProblemDefinitionDisplayComponent dataStore={dataStore} />
                        </div>
                    </div>

                    <hr className="mx-auto w-100" />
                    <ConceptLinkSection
                        conceptLinkStore={conceptService.conceptLinkStore}
                        sourceConceptType={ConceptType.Problems}
                        targetConceptType={ConceptType.Insights}
                        isEditing={false}
                        customization={{
                            buttonIcon: "",
                            buttonText: "+ Link",
                            singleItemHeaderText: "We know this is a problem because...",
                            manyItemsHeaderText: "We know this is a problem because...",
                            useNormalSizeCard: false,
                            displayAttachMessage: true,

                            customContent: (
                                <div className="py-2 display-lines">
                                    <TextDisplayComponent fieldName="problemData.problemCause" dataStore={dataStore} />
                                </div>
                            )
                        }} />

                    <hr className="mx-auto w-100" />
                    <div>
                        <SectionHeaderComponent iconName="#edit-icon" header="Problem details" iconSize={SectionHeaderIconSize.Medium} />
                        <div className="pt-2 display-lines">
                            <TextBoxRichTextReadOnlyComponent fieldName="problemData.problemDetails" dataStore={dataStore} />
                        </div>
                    </div>

                    <hr className="mx-auto" />
                    <ConceptImageAttachmentComponent attachmentStore={conceptService.conceptAttachmentStore} />

                    <hr className="mx-auto" />
                    <div>
                        <ConceptAttachmentComponent attachmentStore={conceptService.conceptAttachmentStore} />
                    </div>

                    <hr className="mx-auto" />
                    <ConceptLinkSection conceptLinkStore={conceptService.conceptLinkStore} sourceConceptType={ConceptType.Problems} targetConceptType={ConceptType.Solutions} isEditing={false} />
                </React.Fragment>
            )
        }
    )