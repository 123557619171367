import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND, INSERT_CHECK_LIST_COMMAND } from "@lexical/list";
interface ToolbarListComponentProps {
    blockType: string;
    itemKey: string;
    itemStyle: string;
}

export const ToolbarListComponent = (props: ToolbarListComponentProps) => {
    const [editor] = useLexicalComposerContext();

    const formatSelection = () => {
        if (props.blockType !== props.itemKey) {
            if (props.itemKey === "bullet") {
                editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
            }
            else if (props.itemKey === "number") {
                editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
            }
            else if (props.itemKey === "check") {
                editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);

            }
        } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    }

    const isActive = props.blockType === props.itemKey;

    return (
        <button tabIndex={-1} className={"toolbar-item spaced " + (isActive ? "active" : "")} onClick={formatSelection}>
            <i className={"format " + props.itemStyle} />
        </button>
    )
}