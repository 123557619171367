import { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";
import { IServerMessage, MessageTypeEnumDescriptor } from "../../models/message/IServerMessage";
import { GlobalStore } from "../stores/GlobalStore";
import { MessageHubOwner } from "./MessageHubOwner";
import { LogCollector } from "../logger/LogCollector";

const ServerConfig = {
    serverUrl: process.env.REACT_APP_VOYCE_SERVER
};

class MessageServiceImplementation {
    connection: HubConnection | undefined;
    identifier: string = crypto.randomUUID();
    accessToken: string = "";
    counter: number = 0;

    sleep(time: any) {
        return new Promise((resolve) => setTimeout(resolve, time));
    }

    UpdateAcessToken(newAccessToken: string) {
        if (this.accessToken === newAccessToken) {
            return false;
        }

        this.accessToken = newAccessToken;

        const oldConnection = this.connection;
        this.connection = undefined;

        const timeout = setTimeout(() => {
            LogCollector.LogWarn("MessageService: disconnecting");
            oldConnection?.stop();

            clearTimeout(timeout);
        }, 10000);

        return true;
    }

    async startInternalAsync() {

        let waitCounter = 0;
        while (waitCounter < 10) {
            if (this.accessToken !== "") {
                LogCollector.LogWarn("access token found!");
                break;
            }

            LogCollector.LogWarn("waiting 500");
            waitCounter++;
            await this.sleep(1000);
        }

        if (this.connection != null) {
            LogCollector.LogWarn("already started");
            return this.connection;
        }


        const serverConnection = new HubConnectionBuilder()
            .withUrl(ServerConfig.serverUrl as string, {
                accessTokenFactory: () => {
                    this.counter++;
                    return this.accessToken;
                }
            })
            .configureLogging(LogLevel.Warning)
            .withAutomaticReconnect()
            .build();

        await serverConnection.start()
            .then(() => {
                LogCollector.LogMessage('Connected!');
                LogCollector.LogMessage(serverConnection?.connectionId);

                if (serverConnection == null) {
                    return;
                }

                serverConnection.on("messagereceived", message => {
                    LogCollector.LogMessage('SignalRTest-received!');
                    LogCollector.LogMessage(message);
                });

                serverConnection.on("sendresponse", message => {
                    LogCollector.LogMessage('SignalRTest!');
                    LogCollector.LogMessage(message);

                    if (message.clientIdentifier === this.identifier) {
                        LogCollector.LogMessage("own message");

                        const response = {
                            messageType: message.messageType,
                            requestData: message.responseData
                        } as IServerMessage;

                        LogCollector.LogMessage(response);
                        MessageHubOwner.sendMessage(response);
                        return;
                    }
                });
            });

        if (this.connection == null) {
            this.connection = serverConnection;
        }
        else {
            LogCollector.LogMessage("stop!!!!");
            serverConnection.stop();
        }

        return this.connection;
    }

    async connectAsync() {
        return new Promise<HubConnection>((resolve) => {

            LogCollector.LogMessage("connectAsync");

            const currentConnection = this.connection;
            if (currentConnection == null || currentConnection === undefined) {
                LogCollector.LogMessage("connectAsync: creating connection");
                return resolve(new Promise<HubConnection>(async (connectResolver) => {
                    LogCollector.LogMessage("connectAsync2");
                    const currentConnection = await this.startInternalAsync();
                    LogCollector.LogMessage("connectAsync2.1");

                    return connectResolver(currentConnection);
                }));
            }

            return resolve(currentConnection);
        });
    }

    async sendMessage(message: IServerMessage) {
        return new Promise(async (resolve) => {
            return resolve(this.connectAsync()
                .then(async (serverConnection) => {

                    LogCollector.LogWarn("Send!!!!!!!!!");
                    const serverRequest = {
                        clientIdentifier: this.identifier,
                        workspaceID: GlobalStore.getWorkspaceIdIfAvailable(),
                        messageType: MessageTypeEnumDescriptor.get(message.messageType),
                        requestData: message.requestData
                    };

                    if (serverConnection == null) {
                        return;
                    }

                    try {
                        await serverConnection.send("Send", serverRequest);
                    }
                    catch (e) {
                        LogCollector.LogError(e);
                    }
                }));
        });
    }
}

export const MessageService = new MessageServiceImplementation();