import React, { memo, useEffect, useState } from "react";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { ConceptDataModel } from "../../../models/ConceptDataModel";
import { ConceptEditorService } from "../../../services/concepts/ConceptEditorService";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { SectionHeaderComponent } from "../../elements/SectionHeaderComponent";
import { TextDisplayComponent } from "../../elements/TextDisplayComponent";

export interface InsightViewSectionProps {
    conceptEditorService: ConceptEditorService;
}

export const InsightViewSection: React.FunctionComponent<InsightViewSectionProps>
    = memo(
        function InsightView({ conceptEditorService }) {

            const [isVisible, setIsVisible] = useState(true);

            useEffect(() => {

                const hubContext = MessageHubContext()
                    .Subscribe(conceptEditorService.conceptEditorStore as INotificationHub, (conceptModel: ConceptDataModel) => {

                        const insightData = conceptModel.insightData;
                        if (!insightData) {
                            setIsVisible(false);
                        }

                        const isEmpty = !insightData || Object.values(insightData).every(x => { return x === null || x === "" });
                        setIsVisible(!isEmpty);
                    });

                return () => hubContext.Dispose();

            }, [conceptEditorService.conceptEditorStore])


            if (!isVisible) {
                return <React.Fragment></React.Fragment>
            }

            return (
                <React.Fragment>
                    <hr className="my-3 mx-auto w-100" />
                    <div className="py-2 fw-reg-400">
                        <SectionHeaderComponent iconName="#customer-icon" header="Customer details" />
                        <div className="d-flex flex-column g-2">
                            <TextDisplayComponent fieldName="insightData.customerName" dataStore={conceptEditorService.conceptEditorStore} label={{ text: "Name", groupStyle: "text-display-group" }} />
                            <TextDisplayComponent fieldName="insightData.customerEmail" dataStore={conceptEditorService.conceptEditorStore} label={{ text: "Email", groupStyle: "text-display-group" }} />
                            <TextDisplayComponent fieldName="insightData.customerPhone" dataStore={conceptEditorService.conceptEditorStore} label={{ text: "Phone", groupStyle: "text-display-group" }} />
                            <TextDisplayComponent fieldName="insightData.customerUserID" dataStore={conceptEditorService.conceptEditorStore} label={{ text: "User ID", groupStyle: "text-display-group" }} />
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    )