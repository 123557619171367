import React, { useEffect, useRef, useState } from "react";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { ErrorNotificationModel } from "../../models/communication/internal/ErrorNotificationModel";
import { AttachmentDataModel, AttachmentType } from "../../models/data/AttachmentDataModel";
import { GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { ConceptAttachmentStore, ConceptAttachmentStoreData } from "../../services/stores/ConceptAttachment/ConceptAttachmentStore";
import { FailureReasons, UploadService } from "../../services/upload/UploadService";
import { UploadStore } from "../../services/upload/UploadStore";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../elements/SectionHeaderComponent";
import { ErrorDisplayComponent } from "../notification/ErrorDisplayComponent";
import { ContentUploadProgress } from "./components/ContentUploadProgress";
import { ScreenshotDisplayComponent } from "./components/ScreenshotDisplayComponent";
import { AttachmentHelperExtension } from "../../services/helpers/AttachmentHelperExtension";
import { UploadBulkService, UploadBulkServiceProps } from "../../services/upload/UploadBulkService";
import { ImagePasteUploadComponent } from "./components/ImagePasteUploadComponent";
import { LogCollector } from "../../services/logger/LogCollector";

interface ConceptImageAttachmentComponentProps {
    attachmentStore: ConceptAttachmentStore;
}

export const ConceptImageAttachmentComponent = (props: ConceptImageAttachmentComponentProps) => {

    const [uploadStore] = useState(new UploadStore());
    const [isUploading, setIsUploading] = useState(false);
    const [inputKey, setInputKey] = useState(Math.random().toString(36));
    const [errorComponentKey] = useState(Math.random().toString(36));
    const [attachmentList, setAttachmentList] = useState([] as AttachmentDataModel[]);
    const dropImageRef = useRef<HTMLDivElement>(null);

    const maxNumberOfScreenshots = 5;


    useEffect(() => {

        const hubContext = MessageHubContext()
            .Subscribe(props.attachmentStore, (attachmentData: ConceptAttachmentStoreData) => {
                const attList = attachmentData.attachmentList.filter(a => a.attachmentType === AttachmentType.Screenshot);
                setAttachmentList([...attList]);
            });

        return () => hubContext.Dispose();

    }, [props.attachmentStore]);

    const UploadFile = (uploadingFile: File) => {
        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertClose, { errorComponentKey: errorComponentKey } as ErrorNotificationModel);
        uploadStore.updateProgress(1);
        setIsUploading(true);

        const uploadUID = crypto.randomUUID();

        const newAttachment = {
            attachmentID: uploadUID,

            attachmentType: AttachmentType.Screenshot,

            displayName: uploadingFile.name,
            fileName: uploadingFile.name,
            fileContentType: uploadingFile.type,
            fileSize: (uploadingFile.size / 1024 / 1024),
            fileExtension: AttachmentHelperExtension.GetFileExtension(uploadingFile.name),
            uploadFileName: AttachmentHelperExtension.GetFileName(uploadingFile.name, uploadUID),


            isNew: true

        } as AttachmentDataModel;

        LogCollector.LogMessage(uploadingFile);

        const uploadService = new UploadService({
            uploadStore: uploadStore,
            attachmentStore: props.attachmentStore,
            fileTypesAllowed: "image/",
            maxFileSize: 5
        });

        uploadService.uploadAttachment(newAttachment, uploadingFile)
            .catch((reason: FailureReasons) => {
                switch (reason) {
                    case FailureReasons.FileTooBig:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "The file must be smaller than 5MB." } as ErrorNotificationModel);
                        break;
                    case FailureReasons.NoneSpecified:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "Could not upload the file. Try again later." } as ErrorNotificationModel);
                        break;
                    case FailureReasons.FileMustBeImage:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "You must upload an image." } as ErrorNotificationModel);
                        break;
                    case FailureReasons.NotEnoughSpaceToUpload:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "Total file storage reached for the account.", errorDetails: ["Max file storage size: 1TB", "You can delete old/unused attachments to release the space"] } as ErrorNotificationModel);
                        break;
                }
            })
            .finally(() => {
                LogCollector.LogMessage("...................... upload completed");
                uploadService.dispose();

                uploadStore.updateProgress(0);
                setIsUploading(false);
                setInputKey(Math.random().toString(36));
            });
    }

    const OnFileChange = (event: any) => {
        if (!event.target.files[0]) {
            return;
        }

        if (attachmentList.length >= maxNumberOfScreenshots) {
            GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError, { errorComponentKey: errorComponentKey, errorMessage: "You've hit the max number of screenshots you can add here." } as ErrorNotificationModel);
            return;
        }

        UploadFile(event.target.files[0]);
    };

    const OnFileDrop = (uploadList: File[]) => {

        if (uploadList) {
            if (uploadList.length > 0) {
                const uploaderParameters = {
                    uploadStore: uploadStore,
                    attachmentStore: props.attachmentStore,
                    fileTypesAllowed: "image/",
                    filesToBeUploaded: uploadList,
                    availableSlots: (maxNumberOfScreenshots - props.attachmentStore.countAttachment(AttachmentType.Screenshot)),
                    maxFileSize: 5
                } as UploadBulkServiceProps;

                GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertClose, { errorComponentKey: errorComponentKey } as ErrorNotificationModel);
                uploadStore.updateProgress(1);
                setIsUploading(true);

                const uploadServiceV3 = new UploadBulkService(uploaderParameters);

                uploadServiceV3.uploadAttachmentList()
                    .catch((reason: FailureReasons[]) => {

                        let errorList: string[] = [];

                        if (reason.filter(err => err === FailureReasons.FileMustBeImage).length > 0) {
                            errorList.push("Some of the files were not images");
                        }

                        if (reason.filter(err => err === FailureReasons.FileTooBig).length > 0) {
                            errorList.push("Each uploaded file must be smaller than 5MB");
                        }

                        if (reason.filter(err => err === FailureReasons.NoneSpecified).length > 0) {
                            errorList.push("Some of the files failed to upload");
                        }

                        if (reason.filter(err => err === FailureReasons.MaxUploadReached).length > 0) {
                            errorList.push("Max number of screenshot reached");
                        }

                        if (reason.filter(err => err === FailureReasons.NotEnoughSpaceToUpload).length > 0) {
                            errorList.push("Total file storage reached for the account.");
                        }

                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertError,
                            {
                                errorComponentKey: errorComponentKey,
                                errorMessage: "Could not upload all files.",

                                errorDetails: errorList
                            } as ErrorNotificationModel);
                    })
                    .finally(() => {
                        LogCollector.LogMessage("...................... upload completed batch");
                        uploadServiceV3.dispose();

                        uploadStore.updateProgress(0);
                        setIsUploading(false);
                        setInputKey(Math.random().toString(36));
                    });
            }
        }
    }

    const UploadFileList = (uploadingImages: File[]) => {
        OnFileDrop(uploadingImages);
    }

    return (
        <div>
            <div className="d-flex">
                <div className="me-auto">
                    <SectionHeaderComponent iconName="#image-scribble-icon" header="Image scribble" iconSize={SectionHeaderIconSize.Medium} />
                    <div className="fw-small-400">Maximum file size per file of 5MB</div>
                </div>
                <div>
                    <button type="button" className="btn btn-light btn-concept-lighter btn-xsmall text-nowrap" disabled={isUploading}
                        onClick={() => {
                            document.getElementById("screenshotSelection")?.click();
                        }}
                    >+ Attach</button>
                    <input type="file" onChange={OnFileChange} id="screenshotSelection" style={{ display: "none" }} key={inputKey || ''} accept="image/*" />
                </div>
            </div>
            <div className="mt-2">
                <ScreenshotDisplayComponent attachmentStore={props.attachmentStore} attachmentList={attachmentList} maxCount={maxNumberOfScreenshots} />
            </div>
            <div className="mt-3">
                <ErrorDisplayComponent errorComponentKey={errorComponentKey} />
            </div>
            {
                (props.attachmentStore.countAttachment(AttachmentType.Screenshot) < maxNumberOfScreenshots) &&
                <div
                    ref={dropImageRef}
                    className={"screenshot-upload-section" + (isUploading ? " screenshot-upload-section-uploading" : "")}
                    onDragEnter={(event) => { event.preventDefault(); event.stopPropagation(); event.currentTarget.classList.add("screenshot-upload-section-dragover"); }}
                    onDragOver={(event) => { event.preventDefault(); event.stopPropagation(); }}
                    onDragLeave={(event) => { event.preventDefault(); event.stopPropagation(); event.currentTarget.classList.remove("screenshot-upload-section-dragover") }}
                    onDrop={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        event.currentTarget.classList.remove("screenshot-upload-section-dragover")

                        if (event.dataTransfer.files) {
                            const uploadingFiles = Array.from(event.dataTransfer.files)
                            OnFileDrop(uploadingFiles);
                        }
                    }}>
                    <div className="fw-small-400">{uploadStore.storageState.uploadPercentage > 0 ? "Uploading..." : "Drag & drop or copy & paste images directly from your clipboard 🤩"}</div>
                    <div className="px-3 w-100">
                        <ContentUploadProgress uploadStore={uploadStore} />
                        <ImagePasteUploadComponent OnPaste={UploadFileList} />
                    </div>
                </div>
            }
        </div>
    );
}