import { Link } from "react-router-dom";


export const NotAuthorizedPage = () => {


    return (
        <div className="d-flex w-100 pt-4 justify-content-center">
            <div className="w-50 pt-3 text-center">
                <div className="fw-xxlarge-900 fw-concept">
                    Sorry, you don't have access to this page
                </div>
                <div className="pt-3 px-5 mx-5">
                    <div className="pt-3 fw-reg-500">
                        <div>Ask the person who shared this page with you to invite you to be a team member of this workspace.</div>
                    </div>
                    <div className="pt-3 fw-reg-400">
                        <div>If they have invited you but you haven't received an invite to join this workspace, try checking your emails junk folder, or check if they typed in your email correctly.</div>
                    </div>
                </div>
                <div className="pt-4">
                    <Link to="/workspace/" className="btn btn-primary btn-concept" >Home</Link>

                </div>
            </div>
        </div>
    );
}