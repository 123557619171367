import { Subject } from "rxjs";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { memo, useEffect, useMemo, useState } from "react";
import { debounce } from "lodash";
import { MessageService } from "../../messaging/MessageService";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { IHubSubcription, MessageHubContext } from "../../notification/MessageHubHandler";
import { MessageHubOwner } from "../../messaging/MessageHubOwner";
import { FeedbackStatisticsLoadAction, FeedbackStatisticsLoadActionResponse } from "../../../models/communication/actions/FeedbackStatisticsLoadAction";
import { FeedbackStatisticsDataModel } from "../../../models/data/FeedbackStatisticsDataModel";


export interface FeedbackStatisticsState {
    counter: FeedbackStatisticsDataModel;
    dayCount: number;
}

export class FeedbackStatisticStore implements INotificationHub, INotificationInitializer {
    feedbackStatisticSubject = new Subject();
    state: FeedbackStatisticsState = {
        dayCount: 90,
        counter: {
            totalCount: 0,
            positiveCount: 0,
            negativeCount: 0,
        },
    }

    subscribe(onMessageReceiveAction: any) {
        return this.feedbackStatisticSubject.subscribe(onMessageReceiveAction);
    }

    initialize() {
        MessageService.sendMessage({
            messageType: MessageType.FeedbackStatisticsLoad,
            requestData: {
                numberOfDays: 90
            } as FeedbackStatisticsLoadAction
        } as IServerMessage);
    }

    load(statistics: FeedbackStatisticsDataModel) {

        const updateData = {
            totalCount: statistics.totalCount ?? 0,
            positiveCount: statistics.positiveCount ?? 0,
            negativeCount: statistics.negativeCount ?? 0

        } as FeedbackStatisticsDataModel;

        this.state = {
            ...this.state,
            counter: { ...updateData }
        };

        this.feedbackStatisticSubject.next(this.state);
    }
}

interface FeedbackStatisticStoreComponentProps {
    onStoreCreated: (store: FeedbackStatisticStore) => void;
}

export const FeedbackStatisticStoreComponent = (props: FeedbackStatisticStoreComponentProps) => {
    const [feedbackStatisticStore] = useState(new FeedbackStatisticStore());

    useEffect(() => {
        const hubContext = MessageHubContext()
            .ListenMessage(MessageHubOwner,
                {
                    MessageType: MessageType.FeedbackStatisticsLoaded,
                    OnReceive: (response: FeedbackStatisticsLoadActionResponse) => {
                        feedbackStatisticStore.load(response.statistics);
                    }
                } as IHubSubcription);

        return () => hubContext.Dispose();

    }, [props.onStoreCreated, feedbackStatisticStore]);


    const debouncedInitializerHandler = useMemo(
        () => debounce(() => {
            props.onStoreCreated(feedbackStatisticStore);
        }, 100)
        , []);

    useEffect(() => {
        debouncedInitializerHandler();

        return () => debouncedInitializerHandler.cancel();
    }, [debouncedInitializerHandler]);

    return (
        <>
            <InitializerHelper hub={feedbackStatisticStore} />
        </>
    )
}


export interface INotificationInitializer {
    initialize: () => void;
}

interface InitializerHelperProps {
    hub: INotificationInitializer;
}




export const InitializerHelper: React.FunctionComponent<InitializerHelperProps>
    = memo(function InitializeLazy({ hub }) {

        const debouncedInitializerHandler = useMemo(
            () => debounce(() => {
                hub.initialize();
            }, 100)
            , [hub]);

        useEffect(() => {
            debouncedInitializerHandler();

            return () => debouncedInitializerHandler.cancel();
        }, [debouncedInitializerHandler]);

        return <></>
    })