import { useEffect, useRef, useState } from "react";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../../../elements/SectionHeaderComponent";
import { DrawingComponent } from "./DrawingComponent";
import { KeyboardContext, ModalOpenCloseWatcher } from "../../../../services/shortcut/KeyboardService";
import { ColorPickerComponent } from "../../../elements/ColorPickerComponent";
import { ContentUploadProgress } from "../ContentUploadProgress";
import { ImageScribbleService } from "../../../../services/imagescribble/ImageScribbleService";

interface ImageScribbleComponentProps {
    scribbleService: ImageScribbleService;
    closeModal: () => void;
}

export const ImageScribbleComponent = (props: ImageScribbleComponentProps) => {

    const [scribbleVersion, setScribbleVersion] = useState(crypto.randomUUID());
    const processingOverlay = useRef<HTMLDivElement>(null);

    useEffect(() => {

        props.scribbleService.onScribbledSaveFailure = RemoveOverlay;

        return () => {
            props.scribbleService.onScribbledSaveFailure = () => { }
        };
    }, [props.scribbleService, processingOverlay.current]);

    const RemoveOverlay = () => {
        if (processingOverlay.current) {
            processingOverlay.current.classList.add("d-none");
        }
    };


    return (
        <div className={"modal-dialog modal-fullscreen"}>
            <div className="modal-content modal-border">
                <div className="modal-header bg-header text-white border-0 p-3">
                    <div className="d-flex w-100 px-1">
                        <div className="flex-fill ps-1 pt-1">

                            <SectionHeaderComponent iconName={"#image-scribble-icon"}
                                header={"Scribble on this"}
                                iconSize={SectionHeaderIconSize.Medium}
                                fontSizeStyle="fw-reg-500"
                                headerStyle="icon-bright fw-med-700" />
                        </div>
                        <div className="me-3 pt-1">
                            <ColorPickerComponent dataStore={props.scribbleService} fieldPath="lineColour" />
                        </div>
                        <div className="me-1">
                            <button className="btn btn-primary btn-concept btn-mod-border btn-small" onClick={() => {
                                setScribbleVersion(crypto.randomUUID());
                            }}>Restore</button>
                        </div>
                        <div className="me-3">
                            <button className="btn btn-concept-outline btn-small ms-2" onClick={() => {
                                props.scribbleService.triggerSave();
                                if (processingOverlay.current) {
                                    processingOverlay.current.classList.remove("d-none");
                                }
                            }}>Save</button>
                        </div>
                        <div className="pt-2 ms-2 d-flex">
                            <button type="button" className="btn-close btn-close-white" aria-label="Close" onClick={props.closeModal}></button>
                        </div>
                    </div>
                </div>
                <div className="modal-body p-0 bg-light-grey">
                    <div className="d-flex align-items-center justify-content-center h-100 w-100 p-5">

                        <DrawingComponent key={scribbleVersion}
                            scribbleService={props.scribbleService}
                            imagePartialUrl={props.scribbleService.attachmentModel.uploadFileName} />


                    </div>
                </div>
                <div ref={processingOverlay} className="processing-overlay d-none">
                    <div className="d-flex align-items-center justify-content-center w-100 h-100">
                        <div style={{ width: "500px" }}>
                            <ContentUploadProgress uploadStore={props.scribbleService.uploadStore} />
                        </div>
                    </div>
                </div>
            </div>
            <ModalOpenCloseWatcher keyboardContext={KeyboardContext.ImageScribbleViewer} />
        </div >
    )
}