import React from "react";
import { memo, useEffect, useState } from "react";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { CommentLoadActionResponse } from "../../models/communication/actions/CommentLoadAction";
import { CommentUpdateActionResponse } from "../../models/communication/actions/CommentUpdateAction";
import { CommentDataModel } from "../../models/data/CommentDataModel";
import { MessageType } from "../../models/message/IServerMessage";
import { GlobalNotification } from "../GlobalMessageHub";
import { MessageHubOwner } from "../messaging/MessageHubOwner";
import { IHubSubcription, MessageHubContext } from "../notification/MessageHubHandler";
import { CommentsDataStore } from "./CommentsDataStore";

interface CommentDataStoreComponentProps {
    conceptID: string;
    setConceptStore: (commentStore: CommentsDataStore) => void;
}

export const CommentDataStoreComponent: React.FunctionComponent<CommentDataStoreComponentProps>
    = memo(function CommentDataStoreImp({ conceptID, setConceptStore }) {

        const [CommentsStore] = useState(new CommentsDataStore());

        useEffect(() => {

            const hubContext = MessageHubContext()
                .ListenMessage(MessageHubOwner, {
                    MessageType: MessageType.ConceptCommentLoaded, OnReceive: (commentResponse: CommentLoadActionResponse) => {
                        if (commentResponse?.conceptID === conceptID) {
                            CommentsStore.load(commentResponse.commentList);
                        }
                    }
                } as IHubSubcription)
                .ListenMessage(MessageHubOwner, {
                    MessageType: MessageType.ConceptCommentUpdated, OnReceive: (commentUpdateResponse: CommentUpdateActionResponse) => {
                        if (commentUpdateResponse?.commentData) {
                            CommentsStore.updateComment(commentUpdateResponse.commentData);
                        }
                    }
                } as IHubSubcription)
                .ListenGlobalNotification([GlobalNotifications.DeleteComment], async (notification: GlobalNotification) => {

                    const comment = notification.notificationData as CommentDataModel;
                    if (comment) {
                        await CommentsStore.deleteComment(comment);
                    }

                });


            CommentsStore.initialize(conceptID);
            setConceptStore(CommentsStore);

            return () => hubContext.Dispose();

        }, []);

        return <React.Fragment></React.Fragment>
    });