import React, { useRef, useState } from "react"
import { GlobalNotifications } from "../../../models/common/CommonEnums";
import { AttachmentDataModel } from "../../../models/data/AttachmentDataModel";
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub";
import { ConceptAttachmentStore } from "../../../services/stores/ConceptAttachment/ConceptAttachmentStore";
import { AreYouSureLinkComponent } from "../../notification/AreYouSureLinkComponent";
import { SecureImageDisplay } from "./SecureImageDisplay";
import { ImageScribbleNotificationModel } from "../../../models/communication/internal/ImageScribbleNotificationModel";
import { ImageFullscreenViewNotificationModel } from "../../../models/communication/internal/ImageFullscreenViewNotificationModel";
import { CommonElements } from "../../../models/CommonElements";

interface ScreenshotDisplayComponentProps {
    attachmentStore: ConceptAttachmentStore;
    attachmentList: AttachmentDataModel[];
    maxCount: number;
}

export const ScreenshotDisplayComponent = (props: ScreenshotDisplayComponentProps) => {

    const [selectedIndex, setSelectedIndex] = useState(0);
    const imageRef = useRef<HTMLImageElement>(null);

    if (props.attachmentList.length === 0) {
        return (
            <React.Fragment></React.Fragment>
        )
    }

    const OpenPictureFullscreen = (attachment: AttachmentDataModel) => {
        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.DisplayScreenshotFullscreen, { currentImage: attachment } as ImageFullscreenViewNotificationModel);
    }

    const OpenImageScribble = (attachment: AttachmentDataModel) => {
        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.DisplayImageScribble, { currentImage: attachment, attachmentStore: props.attachmentStore } as ImageScribbleNotificationModel);
    }

    const SelectAttachment = (attachment: AttachmentDataModel) => {
        const displayIndex = props.attachmentList.findIndex(att => att.attachmentID === attachment.attachmentID);

        if (displayIndex === selectedIndex) {
            return;
        }

        if (imageRef.current) {
            imageRef.current.src = "";
        }

        if (displayIndex > -1) {
            setSelectedIndex(displayIndex);
        }
        else {
            setSelectedIndex(0);
        }
    }

    const OnDeleteAttachment = async (attachment: AttachmentDataModel) => {
        await props.attachmentStore.deleteAttachment(attachment);
    }

    const selAttachment = selectedIndex < props.attachmentList.length
        ? props.attachmentList[selectedIndex]
        : props.attachmentList[0];

    return (
        <div className="screenshot-section">
            <div style={{ position: "relative" }}>
                <div className="d-flex align-items-center justify-content-center screenshot-selection-display" >
                    {/* <img ref={imageRef} src={"https://voyceuploadertemp.blob.core.windows.net/temp-upload/" + selAttachment.uploadFileName} onClick={() => OpenPictureFullscreen(selAttachment)} /> */}
                    <div onClick={() => OpenPictureFullscreen(selAttachment)}>
                        <SecureImageDisplay imagePartialUrl={selAttachment.uploadFileName} />
                    </div>
                </div>
                <div className="m-2" style={{
                    position: "absolute",
                    left: "0",
                    bottom: "0"
                }}>
                    <button type="button" className="p-2 btn btn-primary btn-concept btn-xsmall" onClick={() => {
                        document.getElementById(CommonElements.ModalElement)?.focus();
                        OpenImageScribble(selAttachment);
                    }}>
                        <svg className="pe-none" width="18" height="18" role="img" aria-label="Link"><use xlinkHref="#image-scribble-icon"></use></svg>
                        <span className="px-2">
                            Scribble on this
                        </span>
                    </button>
                </div>
                <div className="m-2" style={{
                    position: "absolute",
                    right: "0",
                    bottom: "0"
                }}>
                    <AreYouSureLinkComponent
                        OnConfirmAction={async () => { await OnDeleteAttachment(selAttachment); }}

                        customDisplay={
                            <button type="button" className="p-2 btn btn-light btn-concept-lighter btn-xsmall">
                                <svg className="pe-none" width="16" height="19" role="img" aria-label="Link"><use xlinkHref="#delete-icon"></use></svg>
                            </button>
                        }

                    />
                </div>
            </div>
            <div className="d-flex pt-3 screenshot-list justify-content-center flex-wrap">
                {
                    props.attachmentList.map(att => {
                        return (
                            <div key={att.attachmentID}
                                className={
                                    "d-flex align-items-center justify-content-center screenshot-selection-item"
                                    +
                                    ((att.attachmentID === selAttachment?.attachmentID) ? " screenshot-selection-item-active" : "")
                                }
                                onClick={() => { SelectAttachment(att); }}>
                                {/* <SecureImageDisplay imageUrl={"https://localhost:7269/secure/storage/" + att.uploadFileName} /> */}
                                {/* <SecureImageDisplay imageUrl={"https://localhost:7144/secure/storage/" + att.uploadFileName} /> */}
                                <SecureImageDisplay imagePartialUrl={att.uploadFileName} useSmallLoader={true} />
                                {/* <img src={"https://localhost:7269/secure/storage/" + att.uploadFileName} /> */}
                            </div>
                        )
                    })
                }


                {/* <div className="d-flex align-items-center justify-content-center " style={{
                    height: "107px",
                    width: "107px",
                    background: "#EEEEEE"
                }}>
                    <img src="https://voyceuploadertemp.blob.core.windows.net/temp-upload/552c8715-dce2-4701-a253-097ba0037ffd" style={{
                        maxHeight: "107px",
                        maxWidth: "107px",
                        width: "auto",
                        height: "auto"
                    }} />
                </div>
                <div className="d-flex align-items-center justify-content-center ms-3" style={{
                    height: "107px",
                    width: "107px",
                    background: "#EEEEEE"
                }}>
                    <img src="https://voyceuploadertemp.blob.core.windows.net/temp-upload/442a382f-f261-4e5d-8487-657ea138ae02" style={{
                        maxHeight: "107px",
                        maxWidth: "107px",
                        width: "auto",
                        height: "auto"
                    }} />
                </div>
                <div className="d-flex align-items-center justify-content-center ms-3" style={{
                    height: "107px",
                    width: "107px",
                    background: "#EEEEEE",
                    border: "2px solid #078FCA",
                    overflow: "hidden"
                }}>
                    <img src="https://voyceuploadertemp.blob.core.windows.net/temp-upload/0def8b4b-5e3f-4b94-bd72-4f65603a4357" style={{
                        maxHeight: "107px",
                        maxWidth: "107px",
                        width: "auto",
                        height: "auto"
                    }} />
                </div> */}
            </div>
            {
                (props.attachmentList.length >= props.maxCount) &&
                <div className="pt-3 text-center fw-small-400">
                    You've hit the max number of screenshots you can add here
                </div>
            }

            {/* <div className="screenshot-fullscreen-overlay"></div>
            <div className="screenshot-fullscreen">
                <div className="d-flex align-items-center justify-content-center text-white h-100">
                    <div>
                        <img src="https://voyceuploadertemp.blob.core.windows.net/temp-upload/0def8b4b-5e3f-4b94-bd72-4f65603a4357" style={{
                            width: "auto",
                            height: "auto"
                        }} />
                    </div>
                </div>
            </div> */}
        </div>
    )
}