import React from "react";
import { TagDataModel } from "../../models/data/TagDataModel";

interface TagGroupComponentProps {
    selectedTags: TagDataModel[];
    maxItems: number;
}

export const TagGroupComponent = (props: TagGroupComponentProps) => {


    if (props.selectedTags.length <= props.maxItems) {
        return <React.Fragment></React.Fragment>
    }

    const displayTagsInDropDown = props.selectedTags.slice(props.maxItems);

    return (
        <React.Fragment>
            <button type="button" className="btn btn-light btn-xsmall btn-mod-tag" data-bs-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false">+{props.selectedTags.length - props.maxItems}</button>
            <div className="dropdown-menu shadow modal-border mx-2">
                <div className="vc-list-group">
                    {
                        displayTagsInDropDown.map(tag => {
                            return (
                                <div key={tag.tagID} className="vc-item align-items-center p-2">
                                    <span className="me-auto">{tag.tagDescription}</span>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </React.Fragment>
    )
}