import { Subject } from "rxjs";
import { ConceptDataModel } from "../../../models/ConceptDataModel";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { ConceptService, ConceptServiceState } from "../../ConceptService";
import { MessageHubContext, MessageHubHandler } from "../../notification/MessageHubHandler";
import { ConceptType } from "../../../models/common/CommonEnums";
import update from "immutability-helper";
import { MessageService } from "../../messaging/MessageService";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { ConceptSortSaveAction } from "../../../models/communication/actions/ConceptSortSaveAction";
import { LogCollector } from "../../logger/LogCollector";

export interface ConceptDisplayItem {
    concept: ConceptDataModel;
    displayIndex: number;
}

export interface ConceptDisplay {
    displayItems: ConceptDisplayItem[];
}

const defaultStorageValue = {
    displayItems: [] as ConceptDisplayItem[]
} as ConceptDisplay;

export class ConceptDisplayStore implements INotificationHub {
    displaySubject = new Subject();
    storageState = defaultStorageValue;
    hubContext?: MessageHubHandler;

    constructor() {
        this.commitPositionChange = this.commitPositionChange.bind(this);
    }

    subscribe(onChange: any) {
        return this.displaySubject.subscribe(onChange);
    }
    
    initialize(conceptType: ConceptType) {
        this.storageState = defaultStorageValue;

        this.hubContext = MessageHubContext()
            .Subscribe(ConceptService, (message: ConceptServiceState) => {
                this.storageState = {
                    ...this.storageState,
                    displayItems: message.data.map((conceptData, i) => {

                        if (!conceptData.sortData) {
                            LogCollector.LogError("Missing sort data: " + conceptData.conceptID);
                        }

                        return {
                            concept: conceptData,
                            displayIndex: conceptData.sortData?.sortIndex ?? 0
                        } as ConceptDisplayItem;
                    }).sort((a, b) => {
                        if (a.displayIndex < b.displayIndex) {
                            return Math.abs(a.displayIndex);
                        }
                        return -1;
                    })
                };

                this.displaySubject.next(this.storageState);
            });


        ConceptService.initialize(conceptType);
    }

    handleIndexUpdate(dragIndex: number, hoverIndex: number) {
        const x = update(this.storageState.displayItems, {
            $splice: [
                [dragIndex, 1],
                [hoverIndex, 0, this.storageState.displayItems[dragIndex] as ConceptDisplayItem],
            ]
        });

        this.storageState.displayItems = x;
        this.displaySubject.next(this.storageState);
    }

    commitPositionChange(concept: ConceptDataModel, index: number) {
        const storeConcept = this.storageState.displayItems[index];

        if (storeConcept.concept.conceptID === concept.conceptID) {
            if (index === 0) {
                storeConcept.displayIndex = this.storageState.displayItems.length > 1
                    ? Math.round(this.storageState.displayItems[1].displayIndex) + 100
                    : 0;
            }
            else if (index === (this.storageState.displayItems.length - 1)) {
                storeConcept.displayIndex = this.storageState.displayItems.length > 1
                    ? Math.round(this.storageState.displayItems[index - 1].displayIndex) - 100
                    : 0;
            }
            else {
                const afterItem = this.storageState.displayItems[index - 1];
                const beforeItem = this.storageState.displayItems[index + 1];

                const diff = (afterItem.displayIndex - beforeItem.displayIndex) / 2.0;
                storeConcept.displayIndex = Number((beforeItem.displayIndex + diff).toFixed(10));
            }

            MessageService.sendMessage({
                messageType: MessageType.ConceptSortSave,
                requestData: {
                    folderID: storeConcept.concept.sortData.folderID,
                    conceptType: storeConcept.concept.conceptType,
                    conceptID: storeConcept.concept.conceptID,
                    sortIndex: storeConcept.displayIndex
                } as ConceptSortSaveAction
            } as IServerMessage)
        }
    }

    Dispose() {
        this.hubContext?.Dispose();
    }
}