import { useEffect, useState } from "react"
import { CardDisplaySmallComponent } from "../../../components/card/CardDisplaySmallComponent"
import { ConceptDataModel } from "../../../models/ConceptDataModel"
import { GlobalNotifications } from "../../../models/common/CommonEnums"
import { DashboardStore, DashboardStoreComponent, DashboardStoreState, DashboardStoreType } from "../../../services/stores/Dashboard/DashboardStore"
import { MessageHubContext } from "../../../services/notification/MessageHubHandler"
import { LoadingComponent } from "../../loading/LoadingPage"
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub"
import { ConceptViewerNotificationModel } from "../../../models/communication/internal/ConceptViewerNotificationModel"

export const RecentFeedbackDisplay = () => {
    const [dashboardStore, setDashboardStore] = useState<DashboardStore>();
    const [recentFeedbackList, setRecentFeedbackList] = useState<ConceptDataModel[]>();

    useEffect(() => {
        if (!dashboardStore) {
            return;
        }

        const hubContext = MessageHubContext()
            .Subscribe(dashboardStore, (storeState: DashboardStoreState) => {
                if (storeState.recentFeedbackList) {
                    setRecentFeedbackList([...storeState.recentFeedbackList]);
                }
            });


        return () => hubContext.Dispose();

    }, [dashboardStore]);


    return (
        <div>

            {
                (!recentFeedbackList) &&
                <div className="d-flex w-100 justify-content-center my-3">
                    <LoadingComponent />
                </div>
            }
            {
                (recentFeedbackList) &&
                recentFeedbackList.map((feedback: ConceptDataModel) => {
                    return (
                        <CardDisplaySmallComponent
                            key={feedback.conceptID}
                            conceptModel={feedback}
                            onLinkClick={async () => { }}
                            isLinked={false}
                            isEditing={false}
                            enableTitleClick={true}
                            onTitleClick={async (conceptData: ConceptDataModel) => {
                                //window.open(HelperExtension.GetConceptUrl(conceptData), "_blank")

                                GlobalNotificationHub.sendMessageWithData(GlobalNotifications.ViewConcept, {
                                    conceptID: conceptData.conceptID,
                                    conceptType: conceptData.conceptType
                                } as ConceptViewerNotificationModel);

                                //global.window.openModal(CommonElements.ModalElement);
                            }}
                        />
                    )
                })
            }
            {/* <CardDisplaySmallComponent conceptModel={{ title: "New onboarding flow optimised for mobileNew onboarding flow optimised for mobileNew onboarding flow optimised for mobile", conceptType: ConceptType.Insights } as ConceptDataModel}
                onLinkClick={async () => { }}
                isLinked={false}
                isEditing={false}
            />
            <CardDisplaySmallComponent conceptModel={{ title: "New onboarding flow optimised for mobileNew onboarding flow optimised for mobileNew onboarding flow optimised for mobile", conceptType: ConceptType.Insights } as ConceptDataModel}
                onLinkClick={async () => { }}
                isLinked={false}
                isEditing={false}
            />
            <CardDisplaySmallComponent conceptModel={{ title: "New onboarding flow optimised for mobileNew onboarding flow optimised for mobileNew onboarding flow optimised for mobile", conceptType: ConceptType.Insights } as ConceptDataModel}
                onLinkClick={async () => { }}
                isLinked={false}
                isEditing={false}
            /> */}

            <DashboardStoreComponent storeType={DashboardStoreType.RecentFeedback} onStoreCreated={(store) => { setDashboardStore(store) }} />
        </div>
    )
}