import { TagDeleteAction } from "../../models/communication/actions/TagDeleteAction";
import { IConfirmationService } from "../../models/communication/internal/ConfirmNotificationModel";
import { IServerMessage, MessageType } from "../../models/message/IServerMessage";
import { MessageService } from "../messaging/MessageService";
import { TagModel } from "../stores/Tag/TagStore";

export class TagDeleteConfirmService implements IConfirmationService {

    tagToBeDelete: TagModel;

    constructor(tag: TagModel) {
        this.tagToBeDelete = tag;
    }

    GetConfirmHeader() {
        return "Are you sure?";
    }

    GetDescription() {
        return this.tagToBeDelete.TagDescription;        
    }

    GetDescriptionDetail() {
        return "Please confirm you want to delete this tag.";
    }
    GetMessage() {        
        return "";
    }

    async CompleteChanges() {
        await MessageService.sendMessage({
            messageType: MessageType.TagDelete,
            requestData: {
                tagToBeDeleted: {
                    tagID: this.tagToBeDelete.TagID
                }
            } as TagDeleteAction
        } as IServerMessage);
    }
}