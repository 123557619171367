import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { CardDisplayDraggableComponent } from "../../components/card/CardDisplayDraggableComponent";
import { DraggableLayerComponent } from "../../components/draggable/DraggableLayerComponent";
import { ConceptType, GlobalNotifications } from "../../models/common/CommonEnums";
import { ConceptService } from "../../services/ConceptService";
import { IHubSubcription, MessageHubContext } from "../../services/notification/MessageHubHandler";
import { SearchSidebar } from "../../layout/SearchSidebar";
import { ModalComponent } from "../../components/modal/ModalComponent";
import { CustomModalComponent } from "../../components/modal/CustomModalComponent";
import { ConceptFilterComponentV2 } from "../../components/search/Filter/ConceptFilterComponent";
import { HelperExtension } from "../../services/HelperExtension";
import { AlertTimerComponent } from "../../components/notification/AlertTimerComponent";
import { MessageType } from "../../models/message/IServerMessage";
import { MessageHubOwner } from "../../services/messaging/MessageHubOwner";
import { ConceptDataUpdatedActionResponse } from "../../models/communication/actions/ConceptDataUpdatedAction";
import { GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { ConceptEmptyContent } from "./content/ConceptEmptyContent";
import { ConceptTagStore, ConceptTagStoreModel } from "../../services/stores/Tag/ConceptTagStore";
import { TagDataModel } from "../../models/data/TagDataModel";
import { useAppContext } from "../../services/context/AppContext";
import { FolderType } from "../../models/data/FolderDataModel";
import { KeyboardServiceHandler } from "../../services/shortcut/KeyboardService";
import { CommonElements } from "../../models/CommonElements";
import { HelpUsComponent } from "../../components/notification/HelpUsComponent";
import { LogCollector } from "../../services/logger/LogCollector";
import { ConceptDisplay, ConceptDisplayItem, ConceptDisplayStore } from "../../services/stores/Concept/ConceptDisplayStore";
import { FeedbackSettingsComponent } from "../../components/feedback/FeedbackSettingsComponent";
import { LoadingPage } from "../loading/LoadingPage";
import { debounce } from "lodash";
import { ExternalCommandHandler } from "./commands/ExternalCommandHandler";
import { FeedbackSummaryComponent } from "../../components/feedback/FeedbackSummaryComponent";

interface ConceptPageProps {
    conceptType: ConceptType;
}
// interface BaseSearchPageState {
//     conceptList: ConceptDataModel[];
//     index: number;
// }

export const ConceptPage: React.FunctionComponent<ConceptPageProps>
    = memo(function ConceptPageImp({ conceptType }) {

        const [conceptDisplayStore] = useState(new ConceptDisplayStore());

        const initializeDebounceHandler = useMemo(
            () => debounce(async () => {
                conceptDisplayStore.initialize(conceptType)
            }, 100)
            , []);

        useEffect(() => {
            initializeDebounceHandler();

            return () => initializeDebounceHandler.cancel();
        }, [initializeDebounceHandler]);

        useEffect(() => {

            const hubContext = MessageHubContext()
                .ListenMessage(MessageHubOwner, {
                    MessageType: MessageType.ConceptLoaded,
                    OnReceive: () => {
                        if (contentRef.current) {
                            contentRef.current.classList.remove("d-none");
                        }

                        if (loadingRef.current) {
                            loadingRef.current.classList.add("d-none");
                        }
                    }
                } as IHubSubcription)
                .ListenGlobalNotification([GlobalNotifications.FolderSelectChange], () => {
                    if (contentRef.current) {
                        contentRef.current.classList.add("d-none");
                    }

                    if (loadingRef.current) {
                        loadingRef.current.classList.remove("d-none");
                    }
                    //debouncedFolderChangeHandler();
                });

            //conceptDisplayStore.initialize(conceptType);

            return () => {
                hubContext.Dispose();
                //messageHubContext.Dispose();
                conceptDisplayStore.Dispose();
            }
        }, [conceptType]);

        LogCollector.LogMessage("ConceptPageRender");

        const contentRef = useRef<HTMLDivElement>(null);
        const loadingRef = useRef<HTMLDivElement>(null);
        return (
            <div className="layout-main-content d-flex flex-column flex-md-row">
                <SearchSidebar conceptType={conceptType} />
                <div className="d-none d-lg-flex m-4"></div>
                <div className="main-content vc-custom-scroll-dark">
                    <div ref={contentRef} className="p-3 pt-4 d-none">
                        <BaseSearchPage conceptType={conceptType} conceptDisplayStore={conceptDisplayStore} />
                    </div>
                    <div ref={loadingRef} className="">
                        <LoadingPage />
                    </div>
                </div>
            </div>
        )
    });

interface BaseSearchPageProps {
    conceptType: ConceptType;
    conceptDisplayStore: ConceptDisplayStore;
}

const BaseSearchPage: React.FunctionComponent<BaseSearchPageProps>
    = memo(function BaseSearchPageImp({ conceptType, conceptDisplayStore }) {

        //const [conceptDisplayStore] = useState(new ConceptDisplayStore());
        const [displayList, setDisplayList] = useState([] as ConceptDisplayItem[]);
        //const [isLoading, setIsLoading] = useState(false);

        const [searchTagStore] = useState(new ConceptTagStore());
        const [filterTags, setFilterTags] = useState([] as TagDataModel[]);
        const context = useAppContext();

        const disableSort = false;

        useEffect(() => {

            const messageHubContext = MessageHubContext()
                .Subscribe(
                    conceptDisplayStore,
                    (displayData: ConceptDisplay) => {
                        setDisplayList(displayData.displayItems);
                    }
                )
                .Subscribe(searchTagStore, (updatedTags: ConceptTagStoreModel) => {
                    setFilterTags(updatedTags.selectedTags);
                }).ListenGlobalNotification([GlobalNotifications.FolderSelectChange], () => {
                    //TODO: loading after changing the tag filter
                    // setDisplayList([]);

                    searchTagStore.resetSelection();
                });

            searchTagStore.initNew(conceptType);
            searchTagStore.syncWithServer = false;

            return () => {
                messageHubContext.Dispose();
                //conceptDisplayStore.Dispose();
            }
        }, [conceptType, searchTagStore]);

        const OnConceptConceptValueUpdate = useCallback((conceptValueUpdated: ConceptDataUpdatedActionResponse) => {

            if (!conceptValueUpdated?.partialConceptData) {
                return;
            }

            ConceptService.processPartialUpdate(conceptValueUpdated.partialConceptData, true);
        }, []);

        useEffect(() => {

            const messageHubContext = MessageHubContext()
                .ListenMessage(MessageHubOwner, { MessageType: MessageType.ConceptDataUpdated, OnReceive: OnConceptConceptValueUpdate } as IHubSubcription);

            return () => messageHubContext.Dispose();
        }, [])



        const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
            conceptDisplayStore.handleIndexUpdate(dragIndex, hoverIndex);
        }, []);

        const OnConceptAddClick = () => {
            GlobalNotificationHub.sendMessage(GlobalNotifications.AddNewConcept);
        }

        const filteredList = filterTags.length === 0
            ? displayList
            : displayList.filter(displayItem => displayItem.concept.tagList.filter(tl => filterTags.filter(ft1 => ft1.tagID === tl.tagID).length > 0).length === filterTags.length);

        const RenderDisplayListContent = () => {
            if (displayList.length === 0) {
                return (
                    <div className="voyce-card-size">
                        <ConceptEmptyContent conceptType={conceptType} />
                    </div>
                );
            }

            const canMove = (!disableSort && filterTags.length === 0);

            return filteredList.map(
                (displayItem, index) => {
                    LogCollector.LogMessage("BaseSearchPage: cardmap");
                    return (<CardDisplayDraggableComponent
                        key={displayItem.concept.conceptID}
                        conceptStore={conceptDisplayStore}
                        conceptModel={displayItem.concept}
                        conceptIndex={index} moveCard={(canMove ? moveCard : undefined)} />);
                });
        }

        const isFeedbackFolder = context.state?.selectedFolder?.folderType === FolderType.SystemQikFeedbackForm;

        return (
            <React.Fragment>


                {
                    (isFeedbackFolder) &&
                    <FeedbackSettingsComponent />
                }

                <div className="fw-large-700">
                    {
                        (isFeedbackFolder) &&
                        <>
                            <FeedbackSummaryComponent feedbackCounter={filteredList.length} />                            
                        </>
                    }
                    {
                        (!isFeedbackFolder) &&
                        <>{filteredList.length} {HelperExtension.GetConceptName(conceptType, filteredList.length)}</>
                    }
                </div>
                <div className="d-flex flex-row pt-2">
                    <ConceptFilterComponentV2 searchTagStore={searchTagStore} />
                </div>
                {/* {
                            (!disableSort) &&
                            <div className="fw-small-400 pt-3">Sort by newest first</div>
                        } */}
                {
                    (!isFeedbackFolder) &&
                    <div className="btn-add-concept voyce-card-size fw-reg-500 fw-concept text-center my-3 p-2" role="button"
                        data-bs-toggle="modal" data-bs-target={CommonElements.ModalElementTarget} onClick={OnConceptAddClick}>
                        <svg className="pe-none" width="17" height="16" role="img" aria-label="Concept"><use xlinkHref="#add-concept-icon"></use></svg>
                        <span className="ps-2 align-middle">{HelperExtension.GetAddConceptText(conceptType)}</span>
                    </div>
                }

                {RenderDisplayListContent()}


                {/* Global components */}

                <ModalComponent />
                <DraggableLayerComponent />
                <AlertTimerComponent />
                <CustomModalComponent />
                <KeyboardServiceHandler />
                <HelpUsComponent />
                <ExternalCommandHandler />
            </React.Fragment>
        );
    });

export default BaseSearchPage;