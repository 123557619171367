import React, { useEffect, useState } from "react";
import { memo } from "react";
import { ConceptEditorService } from "../../services/concepts/ConceptEditorService";
import { useAppContext } from "../../services/context/AppContext";
import { ConceptHeaderSection } from "./sections/ConceptHeaderSection";
import { ConceptOrganizeComponent } from "./sections/ConceptOrganizeComponent";
import { ConceptViewerContent } from "./sections/ConceptViewerContent";

export interface ConceptViewerComponentProps {
    conceptID: string;
}

export const ConceptViewerComponent: React.FunctionComponent<ConceptViewerComponentProps>
    = memo(
        function ConceptViewer({ conceptID }) {

            const context = useAppContext();
            const [conceptService, setConceptService] = useState<ConceptEditorService>();


            useEffect(() => {
                const conceptEditorService = new ConceptEditorService();

                conceptEditorService.loadConcept(conceptID, true)

                setConceptService(conceptEditorService);

                conceptEditorService.conceptLinkStore.syncWithServer = true;
                //conceptService.loadConcept(context, conceptID)

                return () => { conceptEditorService.Dispose() };
            }, [context, conceptID]);

            if (!conceptService) {
                return (<div></div>);
            }

            return (
                <React.Fragment>
                    <div className="modal-header bg-header text-white border-0">
                        <div className="d-flex w-100 px-1">
                            <div className="flex-fill ps-1">
                                <ConceptHeaderSection store={conceptService} />
                                {/* <div className="pt-1 d-flex fw-small-400">
                                    <div className="me-1">Added</div>
                                    <TextDisplayComponent fieldName="creationDateDisplay" dataStore={conceptService.conceptEditorStore} />                                    
                                </div> */}
                            </div>
                            <div className="pt-1 ms-2 d-flex">
                                <button type="button" className="btn-close btn-close-white" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                        </div>
                    </div>
                    <div className="modal-body p-0">
                        <div className="p-3 concept-bg-lighter">
                            <ConceptOrganizeComponent conceptService={conceptService} />
                        </div>
                        <div className="concept-bg-lighter vc-editor concept-viewer-container">
                            <ConceptViewerContent conceptID={conceptID} conceptService={conceptService} />
                        </div>
                    </div>
                </React.Fragment>

            )
        }
    )