import React, { useEffect } from "react"
import { TagDeleteActionResponse } from "../../../models/communication/actions/TagDeleteAction";
import { TagUpdateActionResponse } from "../../../models/communication/actions/TagUpdateAction";
import { MessageType } from "../../../models/message/IServerMessage";
import { MessageHubOwner } from "../../messaging/MessageHubOwner";
import { IHubSubcription, MessageHubContext } from "../../notification/MessageHubHandler";
import { ConceptTagStore } from "./ConceptTagStore"

interface TagRenameHandlerProps {
    tagStore: ConceptTagStore;
}

export const TagRenameHandler = (props: TagRenameHandlerProps) => {

    useEffect(() => {

        const hubContext = MessageHubContext()
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.TagRenamed,
                OnReceive: (tagRenameResponse: TagUpdateActionResponse) => {
                    if (tagRenameResponse?.tag) {
                        props.tagStore.processRename(tagRenameResponse.tag);
                    }
                }
            } as IHubSubcription)
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.TagDeleted,
                OnReceive: (tagDeleteResponse: TagDeleteActionResponse) => {
                    if (tagDeleteResponse?.deletedTag) {
                        props.tagStore.processDelete(tagDeleteResponse.deletedTag);
                    }
                }
            } as IHubSubcription);


        return () => hubContext.Dispose();
    }, [])


    return <React.Fragment></React.Fragment>
}