import React, { memo } from "react";
import { ConceptType } from "../../../models/common/CommonEnums";
import { ConceptEditorService } from "../../../services/concepts/ConceptEditorService";
import { SectionHeaderComponent } from "../../elements/SectionHeaderComponent";
import { ConceptAttachmentComponent } from "../../uploader/ConceptAttachmentComponent";
import { ConceptImageAttachmentComponent } from "../../uploader/ConceptImageAttachmentComponent";
import { ConceptLinkSection } from "../sections/ConceptLinkSection";
import { InsightViewSection } from "../sections/InsightViewSection";
import { TextBoxRichTextReadOnlyComponent } from "../../elements/RichTextBox/TextBoxRichTextReadOnlyComponent";

interface InsightViewerComponentProps {
    conceptService: ConceptEditorService;

}

export const InsightViewerComponent: React.FunctionComponent<InsightViewerComponentProps>
    = memo(function InsightViewer({ conceptService }) {

        const dataStore = conceptService.conceptEditorStore;

        return (
            <React.Fragment>
                <SectionHeaderComponent iconName="#edit-icon" header="Description" />
                {/* <div className="mt-2 display-lines">
                    <TextDisplayComponent fieldName="description" dataStore={dataStore} />                    
                </div> */}
                <div className="mt-2 display-lines">
                    <TextBoxRichTextReadOnlyComponent fieldName="description" dataStore={dataStore} />
                </div>

                <hr className="mx-auto" />
                <ConceptImageAttachmentComponent attachmentStore={conceptService.conceptAttachmentStore} />

                <InsightViewSection conceptEditorService={conceptService} />

                <hr className="mx-auto" />
                <div className="my-3">
                    <ConceptAttachmentComponent attachmentStore={conceptService.conceptAttachmentStore} />
                </div>

                <hr className="mx-auto" />
                <ConceptLinkSection conceptLinkStore={conceptService.conceptLinkStore} sourceConceptType={ConceptType.Insights} targetConceptType={ConceptType.Problems} isEditing={false} />

                <hr className="mx-auto" />
                <ConceptLinkSection conceptLinkStore={conceptService.conceptLinkStore} sourceConceptType={ConceptType.Insights} targetConceptType={ConceptType.Solutions} isEditing={false} />

            </React.Fragment>
        )
    })
