import { WorkspaceEditorNotificationModel } from "../../../models/communication/internal/WorkspaceEditorNotificationModel";
import { WorkspaceEditor } from "../../workspace/WorkspaceEditor";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";

interface WorkspaceEditorHandlerProps {
    notification: WorkspaceEditorNotificationModel;
}

export const WorkspaceEditorHandler = (props: WorkspaceEditorHandlerProps) => {

    return {
        modalSize: ModalSize.Small,
        modalContent: (
            <WorkspaceEditor workspaceStore={props.notification.workspaceStore} />
        )
    } as ModalDefinitionModel;

}