import { Subject } from "rxjs";
import { IDataStore } from "../../../models/common/IDataStore";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { HelperExtension } from "../../HelperExtension";
import { MessageService } from "../../messaging/MessageService";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { WorkspaceTeamInviteAction } from "../../../models/communication/actions/WorkspaceTeamInviteAction";
import { GlobalNotificationHub } from "../../GlobalMessageHub";
import { GlobalNotifications } from "../../../models/common/CommonEnums";

export interface WorkspaceInvite {
    teamMemberID: string;
    emailAddress: string;
}

export class WorkspaceTeamInviteStore implements IDataStore, INotificationHub {
    storageState: WorkspaceInvite = { teamMemberID: crypto.randomUUID(), emailAddress: "" };
    inviteSubject = new Subject();

    subscribe(onReceiveAction: any) {
        return this.inviteSubject.subscribe(onReceiveAction);
    }

    sendMessage(fieldName: string, fieldValue: string) {
        //this.storageState = {...this.storageState,}
        const newObject = HelperExtension.SetPropertyValueObject(this.storageState, fieldName, fieldValue);
        this.storageState = { ...newObject };


        this.inviteSubject.next(this.storageState);
    }


    initialize() {
        this.storageState = { teamMemberID: crypto.randomUUID(), emailAddress: "" };
        this.inviteSubject.next(this.storageState);
    }

    async sendInvitation() {
        return MessageService.sendMessage({
            messageType: MessageType.WorkspaceMembersInvite,
            requestData: {
                teamMemberID: this.storageState.teamMemberID,
                emailAddress: this.storageState.emailAddress
            } as WorkspaceTeamInviteAction
        } as IServerMessage)
            .then(() => {
                GlobalNotificationHub.sendMessageWithData(GlobalNotifications.WorspaceMemberInvited, this.storageState);
            });
    }
}