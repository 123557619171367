//import { WebStorageStateStore } from "oidc-client-ts";
import React from "react";
import { AuthProviderProps, useAuth } from "react-oidc-context";
import { Link } from "react-router-dom";

export const AuthenticationConfig = {
    authority: process.env.REACT_APP_AUTH_SERVER,
    response_type: "code",
    client_id: process.env.REACT_APP_AUTH_CLIENT,
    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT,
    silent_redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_SILENT,
    automaticSilentRenew: true,
    scope: "profile openid E2AC24C7FB7A",
    loadUserInfo: true,

    //userStore: new WebStorageStateStore({ store: window.localStorage })
} as AuthProviderProps;

export const ApplicationSignOut = () => {
    const auth = useAuth();

    const signoutFunction = () => {
        auth.signoutRedirect({ post_logout_redirect_uri: process.env.REACT_APP_AUTH_LOGOUT });
    }

    const RenderIcon = () => {
        if (auth.user?.profile.picture) {
            return (
                <div>
                    <div className="m-auto" style={{ borderRadius: "15px", overflow: "hidden", width: "fit-content" }}>
                        <img src={auth.user?.profile.picture} width="40" height="40" alt="Profile" />
                    </div>
                </div>
            )
        }
        return (
            <svg className="pe-none w-100" width="32" height="32" role="img" aria-label="Settings"><use xlinkHref="#settings-icon"></use></svg>
        )
    }

    const showItem
        = auth.user && auth.isAuthenticated && !auth.isLoading && !auth.user.expired;

    if (!auth.user || !showItem) {
        return (<React.Fragment></React.Fragment>);
    }

    return (
        <React.Fragment>
            <a href="/#" className="concept-link fw-small-400 fw-secondary" data-bs-toggle="dropdown" aria-expanded="false">
                <RenderIcon />
                Settings
            </a>
            <ul className="dropdown-menu text-small shadow mx-1 mb-2">
                <li><div className="dropdown-item disabled" onClick={signoutFunction}>{auth.user.profile.name}</div></li>
                <li><hr className="dropdown-divider" /></li>
                <li><Link to="/workspace/" role="button" className="dropdown-item">View all workspaces</Link></li>
                <li><button className="dropdown-item" onClick={signoutFunction}>Sign out</button></li>
            </ul>
        </React.Fragment>
    );
}

export const UserDetailsComponent = () => {

    const auth = useAuth();

    if (auth.user?.access_token == null) {
        return (<React.Fragment></React.Fragment>)
    }

    return (
        <div className="mb-3">
            <div className="fw-reg-400">{auth.user.profile.name}</div>
            <div role="button" className="fw-small-500">Sign out</div>
        </div>
    );
}