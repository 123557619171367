import solutions from "../../../../assets/images/app/solutions.svg"

export const SolutionEmptyContent = () => {

    return (
        <div className="text-center w-100 pt-4 px-5">
            <div style={{ height: "280px" }}>
                <img src={solutions} alt="Add a new solution" />
            </div>
            <div className="pt-4 px-5">
                While you can add an solution at any time, you'll get the most out of Voyce by defining a problem first, then creating an idea for it.
            </div>
        </div>

    )
}