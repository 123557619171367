import { useEffect, useState } from "react";
import { ConceptType } from "../../models/common/CommonEnums";
import { ConceptImpactUpdateAction } from "../../models/communication/actions/ConceptImpactUpdateAction";
import { ConceptDataModel, ProblemDataModel, SolutionDataModel } from "../../models/ConceptDataModel";
import { IServerMessage, MessageType } from "../../models/message/IServerMessage";
import { HelperExtension } from "../../services/HelperExtension";
import { MessageService } from "../../services/messaging/MessageService";
import { ConceptEditorStore } from "../../services/stores/ConceptEditorStore";
import { ConceptComplexityService } from "./ComplexityViewerComponent";

export interface SelectorItem {
    selectorID: number;
    selectorText: string;
}

interface SelectorComponentProps {
    conceptStore: ConceptEditorStore;
    fieldName: string;
    description: string;

    currentValue?: number;
    selectionOptions: SelectorItem[];
}

export const SelectorComponent = (props: SelectorComponentProps) => {

    const [selectedItem, setSelectedItem] = useState<SelectorItem>();

    useEffect(() => {

        const selectingItem = props.selectionOptions.find(item => item.selectorID === props.currentValue);
        if (selectingItem) {
            setSelectedItem(selectingItem);
        }

    }, [props.currentValue, props.selectionOptions]);

    const OnSelectionChange = async (newSelectedItem: SelectorItem) => {

        //props.conceptStore.sendMessageCustom(props.fieldName, newSelectedItem.selectorID);
        //const concept = props.conceptStore.storageState;

        // Sets the property on a temporary object
        const concept = HelperExtension.SetPropertyValueObject(props.conceptStore.storageState, props.fieldName, newSelectedItem.selectorID);

        const updateAction = {
            conceptID: concept.conceptID,

            concept: {
                conceptID: concept.conceptID,
                conceptType: concept.conceptType,

                problemData: concept.problemData != null ? {
                    ...concept.problemData
                } : null,
                solutionData: concept.solutionData != null ? {
                    ...concept.solutionData
                } : null
            } as ConceptDataModel

        } as ConceptImpactUpdateAction;

        await MessageService
            .sendMessage({
                messageType: MessageType.ConceptValueUpdate,
                requestData: updateAction
            } as IServerMessage).then(() => {
                setSelectedItem(newSelectedItem);
            }).then(() => {

                if (concept.conceptType === ConceptType.Problems) {
                    const conceptValue = new ConceptComplexityService(ConceptType.Problems).calculateConceptValue(concept.problemData.conceptSeverity, concept.problemData.conceptPeopleAffected);

                    props.conceptStore.processPartialUpdate({
                        conceptID: concept.conceptID,
                        conceptValue: conceptValue,

                        problemData: {
                            conceptSeverity: concept.problemData.conceptSeverity,
                            conceptPeopleAffected: concept.problemData.conceptPeopleAffected
                        } as ProblemDataModel

                    } as ConceptDataModel);

                } else if (concept.conceptType === ConceptType.Solutions) {
                    const conceptValue = new ConceptComplexityService(ConceptType.Solutions).calculateConceptValue(concept.solutionData.conceptImpact, concept.solutionData.conceptEffort);

                    props.conceptStore.processPartialUpdate({
                        conceptID: concept.conceptID,
                        conceptValue: conceptValue,

                        solutionData: {
                            conceptImpact: concept.solutionData.conceptImpact,
                            conceptEffort: concept.solutionData.conceptEffort
                        } as SolutionDataModel

                    } as ConceptDataModel);
                }


            });
        //setSelectedItem(newSelectedItem);
    }

    const description = props.description + (selectedItem?.selectorText ?? "");

    return (
        <div>
            <div className="form-control d-flex" data-bs-toggle="dropdown" aria-expanded="false" role="button">
                <div className="flex-fill">
                    {description}
                </div>
                <div className="dropdown-toggle"></div>
            </div>
            <ul className="dropdown-menu selector-mod">
                {
                    (props.selectionOptions) &&
                    props.selectionOptions.map(op => {
                        if (op.selectorID === props.currentValue) {
                            return (
                                <li key={op.selectorID}><span className="dropdown-item active">{op.selectorText}</span></li>
                            )
                        }
                        return (
                            <li key={op.selectorID}><span role="button" className="dropdown-item" onClick={() => OnSelectionChange(op)}>{op.selectorText}</span></li>
                        )
                    })
                }
            </ul>
        </div>
    )
}