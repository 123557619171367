import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";

export const MobilePage = () => {


    return (
        <div className="d-flex flex-column mx-5">
            <div className="pt-4 fw-xlarge-700 text-center fw-concept">Thanks for joining!</div>
            <div className="pt-3 text-center">
                Until we finish our mobile version of Voyce (it's underway!) you'll need to use <span className="fw-reg-700">Voyce on a desktop or tablet.</span>
            </div>
            <div className="pt-3 text-center">
                Sorry... we know it's a pain!
            </div>
        </div>
    )
}

export const MobileComponentDetector = () => {

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const navigate = useNavigate();

    const handleWindowSizeChange = useCallback(() => {
        setIsMobile(window.innerWidth < 768);
    }, []);


    useEffect(() => {
        if (isMobile) {
            navigate("/mobile/");
        }
    }, [isMobile])



    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    return (<React.Fragment></React.Fragment>)
}