import { memo, useEffect, useRef, useState } from "react";
import { GlobalNotifications } from "../../models/common/CommonEnums";
import { IDataStore } from "../../models/common/IDataStore";
import { ConfirmNotificationModel } from "../../models/communication/internal/ConfirmNotificationModel";
import { GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { TagCreateModel, TagCreateService, TagCreateStore } from "../../services/stores/Tag/TagCreateStore";
import { TagModel } from "../../services/stores/Tag/TagStore";
import { TagDeleteConfirmService } from "../../services/tag/TagDeleteConfirmService";
import { TextBoxComponent } from "../elements/TextBoxComponent";
import { CommonElements } from "../../models/CommonElements";

interface ConceptTagItemProps {
    conceptTag: TagModel;
    tagStore: IDataStore;
    disableDelete: boolean;
    CloseDropdown: () => void;
}

export const TagSingleItemComponent: React.FunctionComponent<ConceptTagItemProps>
    = memo(function TagSingleItem({ conceptTag, tagStore, disableDelete, CloseDropdown }) {

        const [displayConceptTag, setConceptTag] = useState(conceptTag);
        const [isEditing, setIsEditing] = useState(false);
        const selectionRef = useRef<HTMLDivElement>(null);
        const dropRef = useRef<HTMLDivElement>(null);

        const InvertSelection = async () => {

            const newState = !displayConceptTag.isSelected;

            await tagStore.sendMessage(displayConceptTag.TagID, String(newState)).then(() => {
                setConceptTag((prevCards: TagModel) => ({
                    ...prevCards,
                    isSelected: newState
                }));
            });
        }

        const OnOpeningDrop = () => {
            selectionRef.current?.classList.add("line-selection-focus");
        }

        const OnClosingDrop = (event: any) => {
            selectionRef.current?.classList.remove("line-selection-focus");
            event.stopPropagation();
        }

        const OnRename = () => {
            setIsEditing(true);
            dropRef.current?.click();
        }

        const OnDelete = () => {
            CloseDropdown();

            GlobalNotificationHub.sendMessageWithData(GlobalNotifications.ConfirmNotification, {
                confirmService: new TagDeleteConfirmService(conceptTag)
            } as ConfirmNotificationModel);
        }


        useEffect(() => {
            dropRef.current?.addEventListener("shown.bs.dropdown", OnOpeningDrop);
            dropRef.current?.addEventListener("hide.bs.dropdown", OnClosingDrop);

            return () => {
                dropRef.current?.removeEventListener("shown.bs.dropdown", OnOpeningDrop);
                dropRef.current?.removeEventListener("hide.bs.dropdown", OnClosingDrop);
            }
        }, []);


        if (isEditing) {
            return (
                <InLineEditor
                    currentTag={conceptTag}
                    onCommit={(updateTag: TagModel) => {
                        setIsEditing(false);
                        conceptTag.TagDescription = updateTag.TagDescription;
                        document.getElementById("fakeDiv")?.focus();
                    }}
                    onCancel={() => setIsEditing(false)} />
            )
        }

        return (
            <div className="line-selection align-items-center p-2 enable-on-hover btn-group dropend" ref={selectionRef}
                onMouseDown={(event) => { event.preventDefault(); event.stopPropagation(); return false; }}>
                <input className="form-check-input m-0 me-2" type="checkbox" checked={conceptTag.isSelected} aria-label="Change Tag" onChange={InvertSelection} />
                <div onClick={InvertSelection} className="flex-fill">
                    {conceptTag.TagDescription}
                </div>
                {
                    (!conceptTag.isSystemTag) &&
                    <div>
                        <div
                            className="hover-item"
                            role="button"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="outside"
                            aria-expanded="false"
                            ref={dropRef}>
                            <svg className="pe-none" width="18" height="15" role="img" aria-label="Link"><use xlinkHref="#edit-inline-icon"></use></svg>
                        </div>
                        <div className="dropdown-menu shadow modal-border rounded-top" >
                            <li>
                                <div className="dropdown-item dark-icon" onClick={OnRename} >
                                    <svg className="pe-none" width="16" height="17" role="img" aria-label="Link"><use xlinkHref="#t-edit-icon"></use></svg>
                                    <span className="ps-2 fw-small-500">Rename tag</span>
                                </div>
                            </li>
                            {
                                (!disableDelete) &&
                                <li>
                                    <div className="dropdown-item dark-icon" onClick={OnDelete} data-bs-toggle="modal" data-bs-target={CommonElements.ModalElementTarget}>
                                        <svg className="pe-none" width="16" height="19" role="img" aria-label="Link"><use xlinkHref="#delete-icon"></use></svg>
                                        <span className="ps-2 fw-small-500">Delete tag</span>
                                    </div>
                                </li>
                            }
                        </div>
                    </div>
                }
            </div >
        );
    })

interface InLineEditorProps {
    currentTag: TagModel;
    onCommit: (updatedTag: TagModel) => void;
    onCancel: () => void;
}

const InLineEditor = (props: InLineEditorProps) => {
    const [store] = useState(new TagCreateStore());

    useEffect(() => {
        store.initialize({
            tag: { ...props.currentTag }
        } as TagCreateModel)
    }, [props.currentTag])


    return (
        <div className="my-1">
            <TextBoxComponent
                dataStore={store}
                fieldName="tag.TagDescription"
                maxLength={200}
                autoFocus={true}

                onCommit={() => {
                    if (!store.storageState.tag.TagDescription) {
                        return;
                    }

                    new TagCreateService().renameTag(store).then(() => {
                        props.onCommit(store.storageState.tag);
                    });
                }}

                onCancel={() => {
                    props.onCancel();
                }}

                onBlur={() => {
                    props.onCancel();
                }}
            />
        </div>
    )
}