import React, { useEffect } from "react";
import { TagDeleteActionResponse } from "../../../models/communication/actions/TagDeleteAction";
import { TagUpdateActionResponse } from "../../../models/communication/actions/TagUpdateAction";
import { MessageType } from "../../../models/message/IServerMessage";
import { MessageHubOwner } from "../../../services/messaging/MessageHubOwner";
import { IHubSubcription, MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { ConceptTagStore } from "../../../services/stores/Tag/ConceptTagStore";
import { TagComponent } from "../../tags/TagComponent";

interface TagFilterComponentPropsV2 {
    searchTagStore: ConceptTagStore;
}

export const TagFilterComponentV2 = (props: TagFilterComponentPropsV2) => {

    useEffect(() => {

        const hubContext = MessageHubContext()
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.TagRenamed, OnReceive: (tagRenameResponse: TagUpdateActionResponse) => {
                    if (tagRenameResponse?.tag) {
                        props.searchTagStore.processRename(tagRenameResponse.tag);

                    }
                }
            } as IHubSubcription)
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.TagDeleted, OnReceive: (tagDeleteResponse: TagDeleteActionResponse) => {
                    if (tagDeleteResponse?.deletedTag) {
                        props.searchTagStore.processDelete(tagDeleteResponse.deletedTag);

                    }
                }
            } as IHubSubcription)

        return () => hubContext.Dispose();
    }, [])

    return (
        <div>
            <div className="d-flex flex-row ">
                <div className="p-1 ps-0 fw-reg-500">
                    Tagged with
                </div>
                <div className="ms-2">
                    <TagComponent conceptTagStore={props.searchTagStore} selectionOnly={true} />
                </div>
            </div>
        </div>
    );
}