import { ConceptType } from "../../../models/common/CommonEnums";
import { FolderEditorStore } from "../../../services/stores/Folder/FolderEditorStore";
import { FolderEditorComponent } from "../../folders/FolderEditorComponent";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";

interface AddFolderHandlerProps {
    conceptType: ConceptType;
}

export const FolderAddHandler = (props: AddFolderHandlerProps) => {
    const fStore = new FolderEditorStore();
    fStore.initialize(props.conceptType);

    return {
        modalSize: ModalSize.Small,
        modalContent: (
            <FolderEditorComponent folderStore={fStore} />
        )
    } as ModalDefinitionModel;

}