import { memo, useEffect, useState } from "react";
import { ConceptDetailsLoadAction, ConceptDetailsLoadActionResponse } from "../../models/communication/actions/ConceptDetailsLoadAction";
import { ConceptDataModel } from "../../models/ConceptDataModel";
import { IServerMessage, MessageType } from "../../models/message/IServerMessage";
import { MessageHubOwner } from "../../services/messaging/MessageHubOwner";
import { MessageService } from "../../services/messaging/MessageService";
import { IHubSubcription, MessageHubContext } from "../../services/notification/MessageHubHandler";
import CardDisplayComponent from "./CardDisplayComponent";

interface CardDisplayComponentProps {
    conceptPartialModel: ConceptDataModel;
    onLinkClick?: () => void;
    disableDefaultAction?: boolean;
}

export const CardDisplayLoaderComponent: React.FunctionComponent<CardDisplayComponentProps>
    = memo(
        function CardDisplayLoader({ conceptPartialModel, onLinkClick, disableDefaultAction }) {

            const [isLoaded, setIsLoaded] = useState(false);
            const [conceptModel, setConceptModel] = useState(conceptPartialModel);

            useEffect(() => {

                if (!conceptModel) {
                    return;
                }

                if (isLoaded) {
                    return;
                }

                const OnConceptDetailLoaded = (conceptDetailLoadResponse: ConceptDetailsLoadActionResponse) => {
                    if (conceptPartialModel.conceptID === conceptDetailLoadResponse.conceptDetail.conceptID) {
                        setConceptModel(conceptDetailLoadResponse.conceptDetail);
                        setIsLoaded(true);
                    }
                }

                const messageHubContext = MessageHubContext()
                    .ListenMessage(MessageHubOwner, { MessageType: MessageType.ConceptDetailLoaded, OnReceive: OnConceptDetailLoaded } as IHubSubcription)

                MessageService.sendMessage({
                    messageType: MessageType.ConceptDetailLoad,
                    requestData: { conceptID: conceptPartialModel.conceptID } as ConceptDetailsLoadAction
                } as IServerMessage);

                return () => messageHubContext?.Dispose();
            }, []);

            return (
                <CardDisplayComponent conceptModel={conceptModel}
                    disableConceptLink={true}
                    onUnlinkClick={onLinkClick}
                    disableDefaultAction={disableDefaultAction} />
            )
        }
    )