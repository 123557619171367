import { Subject } from "rxjs";
import { ConceptType, GlobalNotifications } from "../../../models/common/CommonEnums";
import { IDataStore } from "../../../models/common/IDataStore";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { TagUpdateAction, TagUpdateActionResponse } from "../../../models/communication/actions/TagUpdateAction";
import { TagDataModel } from "../../../models/data/TagDataModel";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { GlobalNotificationHub } from "../../GlobalMessageHub";
import { HelperExtension } from "../../HelperExtension";
import { MessageService } from "../../messaging/MessageService";
import { TagInsightLoader, TagModel, TagProblemLoader, TagSolutionLoader } from "./TagStore";


export interface TagCreateModel {
    tag: TagModel;

    insightSelected: boolean;
    problemSelected: boolean;
    solutionSelected: boolean;
}

const defaultTagCreateValue = {
    tag: {
        TagDescription: ""
    },
    insightSelected: false,
    problemSelected: false,
    solutionSelected: false
} as TagCreateModel;


export class TagCreateStore implements IDataStore, INotificationHub {
    tagCreateSubject = new Subject();
    storageState: TagCreateModel = { ...defaultTagCreateValue };

    subscribe(messageReceiveAction: any) {
        return this.tagCreateSubject.subscribe(messageReceiveAction);
    }

    sendMessage(fieldName: string, fieldValue: string) {
        if (fieldName.indexOf(".") > -1) {
            this.storageState = HelperExtension.SetPropertyValueObject(this.storageState, fieldName, fieldValue);
        }
        else {
            this.storageState = HelperExtension.SetPropertyValueObject(this.storageState, fieldName, fieldValue === "true");
        }

        this.tagCreateSubject.next(this.storageState);
    }

    initialize(partialTag: TagCreateModel) {
        this.storageState = {
            ...this.storageState, ...partialTag,
            tag: {
                ...this.storageState.tag, ...partialTag.tag
            }
        };

        this.tagCreateSubject.next(this.storageState);
    }
}

export class TagCreateService {
    async saveTag(tagStore: TagCreateStore) {

        const createTag = tagStore.storageState;

        const tagRequest = {
            tag: {
                tagID: createTag.tag.TagID ?? crypto.randomUUID(),
                tagDescription: createTag.tag.TagDescription
            } as TagDataModel,
            insightSelected: createTag.insightSelected,
            problemSelected: createTag.problemSelected,
            solutionSelected: createTag.solutionSelected
        } as TagUpdateAction;

        return MessageService.sendMessage({
            messageType: MessageType.TagUpdate,
            requestData: tagRequest
        } as IServerMessage).then(() => {
            GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AddNewTag, { ...tagRequest, tag: { ...tagRequest.tag } } as TagUpdateActionResponse);
        });
    }

    async renameTag(tagStore: TagCreateStore) {
        const createTag = tagStore.storageState;

        const tagRequest = {
            tag: {
                tagID: createTag.tag.TagID ?? crypto.randomUUID(),
                tagDescription: createTag.tag.TagDescription
            } as TagDataModel
        } as TagUpdateAction;

        return MessageService.sendMessage({
            messageType: MessageType.TagRename,
            requestData: tagRequest
        } as IServerMessage).then(() => {
            GlobalNotificationHub.sendMessageWithData(GlobalNotifications.TagRenamed, { ...tagRequest, tag: { ...tagRequest.tag } } as TagUpdateActionResponse);
        });
    }

    alreadyExists(conceptType: ConceptType, newTagModel: TagCreateModel) {


        if (!newTagModel?.tag?.TagDescription) {
            return false;
        }

        switch (conceptType) {
            case ConceptType.Insights:
                return TagInsightLoader.defaultTagList.findIndex(tag => this.compareString(tag.TagDescription, newTagModel.tag.TagDescription)) > -1;
            case ConceptType.Problems:
                return TagProblemLoader.defaultTagList.findIndex(tag => this.compareString(tag.TagDescription, newTagModel.tag.TagDescription)) > -1;
            case ConceptType.Solutions:
                return TagSolutionLoader.defaultTagList.findIndex(tag => this.compareString(tag.TagDescription, newTagModel.tag.TagDescription)) > -1;
        }

        return false;
    }

    compareString(v: string, v2: string) {
        // const baseRegex = /[\p{Letter}\p{N}]/gu;
        // //const baseRegex = /[\p{Letter}\p{N}\s]/gu


        // const vReady = v.match(baseRegex)?.join("").toLowerCase();
        // const v2Ready = v2.match(baseRegex)?.join("").toLowerCase();
        ///return vReady === v2Ready;

        return v.toLocaleLowerCase() === v2.toLocaleLowerCase();

    }

    clearTags() {
        TagInsightLoader.defaultTagList = [];
        TagProblemLoader.defaultTagList = [];
        TagSolutionLoader.defaultTagList = [];
    }

}