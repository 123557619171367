import React, { useEffect, useState } from "react";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { UploadStore, UploadStoreData } from "../../../services/upload/UploadStore";

export interface ContentUploadProgressProps {
    uploadStore: UploadStore;
}

export const ContentUploadProgress = (props: ContentUploadProgressProps) => {

    const [uploadPercentage, setUploadPercentage] = useState(0);

    useEffect(() => {

        const hubContext = MessageHubContext()
            .Subscribe(props.uploadStore, (uploadState: UploadStoreData) => {
                setUploadPercentage(uploadState.uploadPercentage);
            });


        return () => hubContext.Dispose();
    }, [props.uploadStore]);

    if (uploadPercentage === 0) {
        return <React.Fragment></React.Fragment>
    }
    return (
        <div className="progress">
            <div
                className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-label="Default striped example" style={{ width: uploadPercentage + "%" }}
                aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
        </div>
    );
}