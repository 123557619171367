import { CommonElements } from "../../models/CommonElements";
import { IConfirmationService } from "../../models/communication/internal/ConfirmNotificationModel";

interface ConfirmModalComponentProps {
    confirmService: IConfirmationService;
}

export const ConfirmModalComponent = (props: ConfirmModalComponentProps) => {

    const OnConfirmChanges = async () => {
        await props.confirmService.CompleteChanges();
    }

    return (
        <div className="modal-content modal-border">
            <div className="modal-header bg-header text-white border-0">
                <h5 className="modal-title fw-large-500">{props.confirmService.GetConfirmHeader()}</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body fw-reg-400">
                <div className="fw-med-500">
                    {props.confirmService.GetDescription()}
                </div>
                <div className="pt-1">{props.confirmService.GetDescriptionDetail()}</div>

                {
                    (props.confirmService.GetMessage()) &&
                    <div className="fw-small-400 pt-3">
                        {props.confirmService.GetMessage()}
                    </div>
                }
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary btn-concept" data-bs-target={CommonElements.ModalElementTarget} data-bs-toggle="modal" onClick={OnConfirmChanges}>Confirm</button>
                <button className="btn btn-outline-primary btn-concept-outline" data-bs-target={CommonElements.ModalElementTarget} data-bs-toggle="modal">Cancel</button>
            </div>
        </div>
    )
}