import React from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";

import "../assets/styles/theme-override-default.scss";

import PrivateLayout from "./layout/PrivateLayout";
import { ConceptType, PageType } from "./models/common/CommonEnums";
import HomePage from "./pages/HomePage";
import NotFoundPage from "./pages/NotFoundPage"
import { ConceptPage } from "./pages/search/BaseSearchPage";
import { PrivateLayoutBase, AuthenticationCallback, SignoutCallback } from "./layout/PrivateLayoutBase";
import { PublicLayout } from "./layout/PublicLayout";
import { OnboardingCustomAction, WorkspacePage } from "./pages/WorkspacePage";
import { RenderSidebarIcons } from "./layout/RenderSvgIcons";
import PlaygroundPage from "./pages/PlaygroundPage";
import { WorkspaceLoadPage } from "./pages/WorkspaceLoadPage";
import { NotAuthorizedPage } from "./pages/general/NotAuthorizedPage";
import { AuthenticationLoaderComponent } from "./pages/AuthenticationLoaderComponent";
import { ConceptFullscreenPage } from "./pages/concept/ConceptFullscreenPage";
import { ConceptFullscreenContent } from "./pages/concept/elements/ConceptFullscreenContent";
import { FeedbackPage } from "./pages/comingsoon/FeedbackPage";
import { AuthLayout } from "./layout/AuthLayout";
import { SignInPage } from "./pages/auth/SignInPage";
import { SignUpPage } from "./pages/auth/SignUpPage";
import { WorkspaceLayout } from "./layout/WorkspaceLayout";
import { MobilePage } from "./pages/comingsoon/MobilePage";
import { GetStartedPage } from "./pages/general/GetStartedPage";
import { WorkspaceTeamPage } from "./pages/workspace/WorkspaceTeamPage";
import { DashboardPage } from "./pages/dashboard/DashboardPage";

const VoyceApp: React.FunctionComponent = () => {

    const router = createBrowserRouter(
        createRoutesFromElements(
            <Route>
                <Route element={<PrivateLayoutBase />}>
                    <Route element={<PublicLayout />} >
                        <Route path="/" element={<HomePage />}  ></Route>
                        <Route path="/auth/logout" element={<SignoutCallback />}></Route>


                        <Route path="/feedback/" element={<FeedbackPage />} />
                    </Route>

                    <Route element={<WorkspaceLayout />} >
                        <Route path="/workspace/" element={<WorkspacePage />} />
                        <Route path="/mobile/" element={<MobilePage />} />
                    </Route>
                    <Route element={<WorkspaceLayout displayTeamMemberSection={true} />} >
                        <Route element={<AuthenticationLoaderComponent />}>
                            <Route element={<WorkspaceLoadPage />} >
                                <Route path="/workspace/:id/team" element={<WorkspaceTeamPage />} />
                                <Route path="/w/:id/team" element={<WorkspaceTeamPage />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route element={<PublicLayout />} >
                        <Route path="/onboarding/insights/" element={<WorkspacePage onboarding={{ conceptType: ConceptType.Insights }} />} />
                        <Route path="/onboarding/feedback/" element={<WorkspacePage onboarding={{ conceptType: ConceptType.Insights, customAction: OnboardingCustomAction.Feedback }} />} />

                        <Route path="/onboarding/problems/" element={<WorkspacePage onboarding={{ conceptType: ConceptType.Problems }} />} />
                        <Route path="/onboarding/solutions/" element={<WorkspacePage onboarding={{ conceptType: ConceptType.Solutions }} />} />
                        <Route path="/onboarding/team/" element={<WorkspacePage onboarding={{ conceptType: ConceptType.Default, customAction: OnboardingCustomAction.TeamInvite }} />} />
                    </Route>
                    <Route element={<AuthLayout />}>
                        <Route element={<AuthenticationLoaderComponent />}>
                            <Route path="/get-started/" element={<GetStartedPage />} />
                        </Route>
                        <Route path="/sign-in/" element={<SignInPage />} />
                        <Route path="/sign-up/" element={<SignUpPage />} />
                        <Route path="/auth/callback/" element={<AuthenticationCallback />} />
                    </Route>


                    <Route path="/auth/callback/silent/" element={<HomePage />} />


                    <Route path="/w/" element={<PrivateLayout pageType={PageType.Dashboard} />} >
                        <Route path="/w/:id/" element={<WorkspaceLoadPage />} >
                            <Route index element={<DashboardPage />} />
                            <Route path="/w/:id/dashboard/" element={<DashboardPage />} />
                        </Route>
                    </Route>
                    <Route path="/w/" element={<PrivateLayout pageType={PageType.Insights} />} >
                        <Route path="/w/:id" element={<WorkspaceLoadPage />} >
                            <Route path="/w/:id/insights" element={<ConceptPage conceptType={ConceptType.Insights} />} />
                        </Route>
                    </Route>
                    <Route path="/w/" element={<PrivateLayout pageType={PageType.Problems} />} >
                        <Route path="/w/:id" element={<WorkspaceLoadPage />} >
                            <Route path="/w/:id/problems" element={<ConceptPage conceptType={ConceptType.Problems} />} />
                        </Route>
                    </Route>
                    <Route path="/w/" element={<PrivateLayout pageType={PageType.Solutions} />} >
                        <Route path="/w/:id" element={<WorkspaceLoadPage />} >
                            <Route path="/w/:id/solutions" element={<ConceptPage conceptType={ConceptType.Solutions} />} />
                        </Route>
                    </Route>
                    <Route element={<ConceptFullscreenPage conceptType={ConceptType.Insights} />} >
                        <Route element={<AuthenticationLoaderComponent />}>
                            <Route element={<WorkspaceLoadPage />} >
                                <Route path="/w/:id/insights/:cardid" element={<ConceptFullscreenContent />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route element={<ConceptFullscreenPage conceptType={ConceptType.Problems} />} >
                        <Route element={<AuthenticationLoaderComponent />}>
                            <Route element={<WorkspaceLoadPage />} >
                                <Route path="/w/:id/problems/:cardid" element={<ConceptFullscreenContent />} />
                            </Route>
                        </Route>
                    </Route>
                    <Route element={<ConceptFullscreenPage conceptType={ConceptType.Solutions} />} >
                        <Route element={<AuthenticationLoaderComponent />}>
                            <Route element={<WorkspaceLoadPage />} >
                                <Route path="/w/:id/solutions/:cardid" element={<ConceptFullscreenContent />} />
                            </Route>
                        </Route>
                    </Route>
                    {/* <Route path="/w/">
                        <Route element={<PrivateLayout currentPage={ConceptType.Solutions} />} >
                            <Route path="/w/:id" element={<WorkspaceLoadPage />} >
                                <Route path="/w/:id/solutions/:cardid" element={<ConceptViewerPage />} />
                            </Route>
                        </Route>
                    </Route> */}
                    {/* <Route path="/w/:id/insights" element={<PrivateLayout currentPage={ConceptType.Insights} />} >
                        <Route element={<WorkspaceLoadPage />}>
                            <Route index element={<BaseSearchPage conceptType={ConceptType.Insights} />} />
                        </Route>
                    </Route> */}



                    {/* <Route path="/" element={<PrivateLayout currentPage={ConceptType.Default} />}>
                        <Route path="/dashboard/" element={<HomePage />} />
                    </Route>
                    <Route element={<PrivateLayout currentPage={ConceptType.Insights} />} >
                        <Route path="/insights/" element={<InsightPage />}>
                            <Route index element={<BaseSearchPage conceptType={ConceptType.Insights} />} />
                        </Route>
                    </Route>
                    <Route element={<PrivateLayout currentPage={ConceptType.Problems} />} >
                        <Route path="/problems/" element={<ProblemPage />}>
                            <Route index element={<BaseSearchPage conceptType={ConceptType.Problems} />} />
                        </Route>
                        <Route path="/problems/:id" element={<BaseSearchPage conceptType={ConceptType.Problems} />}>

                        </Route>
                    </Route>
                    <Route element={<PrivateLayout currentPage={ConceptType.Solutions} />} >
                        <Route path="/solutions/" element={<SolutionPage />}>
                            <Route index element={<BaseSearchPage conceptType={ConceptType.Solutions} />} />
                        </Route>
                    </Route> */}
                </Route>
                <Route element={<PublicLayout />}>
                    <Route path="/no-auth/" element={<NotAuthorizedPage />} />
                    <Route path="/hidden/playgroundpage/" element={<PlaygroundPage />}  ></Route>
                    <Route path="*" element={<NotFoundPage />}></Route>
                </Route>
            </Route >
        ));

    return (
        <React.Fragment>
            <RenderSidebarIcons />
            <RouterProvider router={router} />
        </React.Fragment>
    );
}

export default VoyceApp;