import { useRef, useState } from "react";
import ReactPainter from "react-painter";
import { SecureDownloaderAsDataUrl } from "../SecureDownloader";
import { LoadingComponent } from "../../../../pages/loading/LoadingPage";
import { ImageScribbleService } from "../../../../services/imagescribble/ImageScribbleService";
import { CommonElements } from "../../../../models/CommonElements";

interface DrawingComponentProps {
    imagePartialUrl: string;
    scribbleService: ImageScribbleService;
}

interface ImageDetails {
    blobUrl: string;
    imageWidth: number;
    imageHeight: number;
}

export const DrawingComponent = (props: DrawingComponentProps) => {

    const [isReady, setIsReady] = useState(false);
    const [imageUrl, setImageUrl] = useState<ImageDetails>();

    let onCustomSave: () => void;    

    const imageData = isReady ? imageUrl?.blobUrl : "";

    const OnImageDownloaded = (blobUrl: string, imageWidth: number, imageHeight: number) => {
        setIsReady(true);
        setImageUrl({
            blobUrl: blobUrl,
            imageHeight: imageHeight,
            imageWidth: imageWidth
        });
    }

    const containerRef = useRef<HTMLDivElement>(null);
    let imageWidth = undefined;
    let imageHeight = undefined;

    if (isReady && imageUrl && containerRef.current) {

        if (imageUrl.imageWidth > imageUrl.imageHeight) {
            const availableWidth = containerRef.current.offsetWidth - (containerRef.current.offsetWidth * 0.2);

            if (imageUrl.imageWidth < availableWidth) {
                imageWidth = imageUrl.imageWidth;
            }
            else {
                imageWidth = availableWidth;
            }
        }
        else {
            if (imageUrl.imageHeight < containerRef.current.offsetHeight) {
                imageHeight = imageUrl.imageHeight;
            }
            else {
                imageHeight = containerRef.current.offsetHeight;
            }
        }
    }

    const OnSuccessAutoClose = () => {
        setTimeout(() => {
            window.triggerClick(CommonElements.CustomModalElement);
        }, 200);
    }

    return (
        <div ref={containerRef}
            className="d-flex align-items-center justify-content-center m-5" style={{ width: "100%", height: "100%" }}>
            {
                (!isReady) &&
                <LoadingComponent />
            }
            {
                (isReady) &&
                <ReactPainter
                    width={imageWidth}
                    height={imageHeight}
                    image={imageData}
                    initialColor={props.scribbleService.storageState.currentColor}

                    onSave={blob => {
                        props.scribbleService.saveImage(blob);
                    }}
                    render={({ canvas, triggerSave, setColor, imageDownloadUrl, imageCanDownload }) => {

                        onCustomSave = triggerSave;
                        props.scribbleService.overrideDrawingIntegration(triggerSave, setColor, OnSuccessAutoClose);

                        return (
                            <div style={{ cursor: "crosshair" }}
                                onMouseLeave={() => {
                                    if (canvas?.props?.onMouseUp) {
                                        canvas.props.onMouseUp();
                                    }
                                }}>
                                {canvas}
                            </div>
                        )
                        // return (
                        //     <div>
                        //          <input type="color" onChange={e => setColor(e.target.value)} /> 
                        //         <div className="awesomeContainer">{canvas}</div>
                        //          <button onClick={triggerSave}>Save</button> 
                        //     </div>
                        // )
                    }}

                />
            }

            <SecureDownloaderAsDataUrl partialUrl={props.imagePartialUrl} OnDocumentLoaded={OnImageDownloaded} />
        </div >
    )
}