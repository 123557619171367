import { Link, useParams } from "react-router-dom";
import { GlobalStore } from "../../../services/stores/GlobalStore";
import { ConceptType } from "../../../models/common/CommonEnums";

interface ConceptViewerHeaderProps {
    conceptType: ConceptType;
}
export const ConceptViewerHeader = (props: ConceptViewerHeaderProps) => {

    //const { cardid } = useParams();
    //const conceptID = cardid;
    //const workspaceName = GlobalStore.getWorkspaceNameIfAvailable();
    const workspaceCode = GlobalStore.getWorkspaceCodeIfAvailable();

    let workspaceUrl = "/";

    if (workspaceCode) {
        switch (props.conceptType) {
            case ConceptType.Insights:
                workspaceUrl = "/w/" + workspaceCode + "/insights/"
                break;
            case ConceptType.Problems:
                workspaceUrl = "/w/" + workspaceCode + "/problems/"
                break;
            case ConceptType.Solutions:
                workspaceUrl = "/w/" + workspaceCode + "/solutions/"
                break;
        }
    }


    return (
        <header className="navbar navbar-expand-md navbar-dark sticky-top p-0 bg-white shadow-sm" style={{ height: "3rem" }}>
            <div className="d-flex justify-content-center w-100 pt-1">
                {/* <div className="d-flex flex-column flex-shrink-0 bg-white text-center main-sidebar h-100" style={{ width: "120px" }}>
                <Link to="/" className="d-block p-3 voyce-logo" style={{ height: "5.5em" }}>                
                    <svg className="pe-none" width="56" height="46" role="img" aria-label="Home"><use xlinkHref="#voyce-logo"></use></svg>
                    <svg className="pe-none" width="49" height="12" role="img" aria-label="Home"><use xlinkHref="#voyce-word"></use></svg>
                </Link>                
            </div> */}
                {/* <nav className="container-fluid flex-nowrap flex-lg-nowrap" aria-label="Main navigation">
                 <div className="d-flex flex-column ps-3 text-center text-md-start">
                    <div className="fw-small-400 fw-fade ">Workspace: {workspaceName}</div>
                    <div className="fw-xlarge-700 fw-color-light" style={{ marginTop: "-5px" }}>{"Problem #" + conceptID?.substring(0, 8) ?? ""}</div>
                </div> 
            </nav> */}
                <div>
                    <Link to={workspaceUrl}>
                        <svg className="pe-none" width="40" height="30" role="img" aria-label="Home"><use xlinkHref="#voyce-logo"></use></svg>
                        <svg className="pe-none" width="49" height="12" role="img" aria-label="Home"><use xlinkHref="#voyce-word"></use></svg>
                    </Link>
                </div>
            </div>
        </header>
    )
}

export const ConceptViewerHeaderV1 = () => {

    const { cardid } = useParams();
    const conceptID = cardid;
    const workspaceName = GlobalStore.getWorkspaceNameIfAvailable();

    return (
        <header className={"navbar navbar-expand-md navbar-dark sticky-top p-0 bg-header"} style={{ height: "5.5rem" }}>
            <div className="d-flex flex-column flex-shrink-0 bg-white text-center main-sidebar h-100" style={{ width: "120px" }}>
                <Link to="/" className="d-block p-3 voyce-logo" style={{ height: "5.5em" }}>
                    <svg className="pe-none" width="56" height="46" role="img" aria-label="Home"><use xlinkHref="#voyce-logo"></use></svg>
                    <svg className="pe-none" width="49" height="12" role="img" aria-label="Home"><use xlinkHref="#voyce-word"></use></svg>
                </Link>
            </div>
            <nav className="container-fluid flex-nowrap flex-lg-nowrap" aria-label="Main navigation">
                <div className="d-flex flex-column ps-3 text-center text-md-start">
                    <div className="fw-small-400 fw-fade ">Workspace: {workspaceName}</div>
                    <div className="fw-xlarge-700 fw-color-light" style={{ marginTop: "-5px" }}>{"Problem #" + conceptID?.substring(0, 8) ?? ""}</div>
                </div>
            </nav>
        </header>
    )
}