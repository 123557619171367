import React from "react";
import { UserDismissibleStore, UserDismissibleType } from "../../../services/stores/UserDismissibleStore";
import FeedbackSample from "../../../../assets/images/feedback/feedback-sample.png";

export const FeedbackFeatureModalComponent = () => {

    const CloseNotification = () => {
        UserDismissibleStore.dismiss(UserDismissibleType.VoyceFeedback);
    }

    return (
        <React.Fragment>
            <div className="modal-header border-0">

            </div>
            <div className="modal-body p-0 px-3 pb-3">
                <div className="p-3 d-flex">
                    <div className="flex-fill pe-4">
                        <div className="fw-xxlarge-900 pt-5">Let's start collecting meaningful <br />customer feedback.</div>
                        <div className="pt-3">
                            Your Voyce Feedback form is your own private customer feedback form. Add a link to it anywhere you want to collect your customer feedback; end of online checkouts, receipts, emails, social media... anywhere!
                        </div>
                        <div className="pt-3">
                            All feedback arrives neatly into your Voyce Insights  where you can, discuss, organize, & triage.
                        </div>
                        <div className="pt-5">
                            <button type="button" className="btn btn-primary btn-concept btn-medium" data-bs-dismiss="modal" onClick={CloseNotification}>Got it - Let's go!</button>
                        </div>
                    </div>
                    <div>
                        <img src={FeedbackSample} alt="Feedback" />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
