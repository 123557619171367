import { useAuth } from "react-oidc-context";
import { WorkspaceMemberDataModel } from "../../models/data/WorkspaceMemberDataModel"
import { SectionHeaderComponent, SectionHeaderIconSize } from "../elements/SectionHeaderComponent";
import { AreYouSureLinkComponent } from "../notification/AreYouSureLinkComponent";
import React from "react";
import { AccountAccessType } from "../../models/data/WorkspaceDataModel";

interface WorkspaceTeamMemberComponentProps {
    workspaceTeam: WorkspaceMemberDataModel;
    onMemberDelete: (workspaceMember: WorkspaceMemberDataModel) => Promise<void>;
}

export const WorkspaceTeamMemberComponent = (props: WorkspaceTeamMemberComponentProps) => {

    const auth = useAuth();

    const isSignedInUser = props.workspaceTeam.teamMemberID === auth.user?.profile.sub;

    return (
        <div className="d-flex wk-member-display">
            <div>
                <SectionHeaderComponent
                    header={props.workspaceTeam.accountName ?? props.workspaceTeam.emailAddress}
                    iconName={props.workspaceTeam.accountAccessType === AccountAccessType.Invited ? "#email-envelop-icon" : "#user-icon"}
                    headerStyle="fw-dark"
                    fontSizeStyle={props.workspaceTeam.accountAccessType === AccountAccessType.Invited ? "fw-reg-400" : "fw-reg-700"}
                    iconSize={SectionHeaderIconSize.Medium} />
            </div>

            {
                (props.workspaceTeam.accountAccessType === AccountAccessType.Invited) &&
                <div className="ps-1 fw-xsmall-400 ms-auto pe-3">
                    Hasn't accepted invite yet
                </div>
            }

            {
                (props.workspaceTeam.accountAccessType !== AccountAccessType.Invited) &&
                <div className="ps-2 fw-reg-400 flex-fill">
                    {props.workspaceTeam.emailAddress}
                    {(isSignedInUser) ? " (you)" : ""}
                </div>
            }

            <div className="fw-small-700">
                {
                    (props.workspaceTeam.accountAccessType === 1) &&
                    "Owner"
                }
                {
                    (!isSignedInUser && props.workspaceTeam.accountAccessType !== 1) &&
                    <div className="wk-team-remove-link">
                        <AreYouSureLinkComponent
                            OnConfirmAction={async () => await props.onMemberDelete(props.workspaceTeam)} customDisplay={<React.Fragment>Remove</React.Fragment>} />
                    </div>
                }
                {
                    (isSignedInUser && props.workspaceTeam.accountAccessType !== 1) &&
                    <div className="wk-team-remove-link">
                        <AreYouSureLinkComponent
                            OnConfirmAction={async () => await props.onMemberDelete(props.workspaceTeam)} customDisplay={<React.Fragment>Leave</React.Fragment>} />
                    </div>
                }
            </div>
        </div>
    )
}