import { memo, useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { DraggableElement, GlobalNotifications } from "../../models/common/CommonEnums";
import { ConfirmNotificationModel } from "../../models/communication/internal/ConfirmNotificationModel";
import { FolderDataModel, FolderType } from "../../models/data/FolderDataModel";
import { useAppContext } from "../../services/context/AppContext";
import { GlobalNotification, GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { FolderDeleteConfirmService } from "../../services/stores/Folder/FolderDeleteConfirmService";
import { FolderEditorStore } from "../../services/stores/Folder/FolderEditorStore";
import { FolderStore } from "../../services/stores/Folder/FolderStore";
import { DraggableContent } from "../card/CardDisplayDraggableComponent";
import { TextBoxComponent } from "../elements/TextBoxComponent";
import { CommonElements } from "../../models/CommonElements";
import { LogCollector } from "../../services/logger/LogCollector";

interface FolderItemComponentProps {
    folderData: FolderDataModel;
    folderStore: FolderStore;
}

export const FolderItemComponent: React.FunctionComponent<FolderItemComponentProps>
    = memo(function FolderItem({ folderData, folderStore }) {

        const [forceReloadValue, forceReload] = useState(0);
        const [isSelected, setIsSelected] = useState(false);
        const [isEditing, setIsEditing] = useState(false);
        const context = useAppContext();
        const contextMenuRef = useRef<HTMLDivElement>(null);

        useEffect(() => {
            const messageHubContext = MessageHubContext()
                .ListenGlobalNotification([GlobalNotifications.FolderSelectChange], OnFolderSelectionChangeNotification)


            return () => messageHubContext.Dispose();
        });

        const OnFolderSelectionChangeNotification = (notification: GlobalNotification) => {

            const selectedFolder = notification.notificationData as FolderDataModel;
            setIsSelected(folderData.folderID === selectedFolder.folderID);
        }

        const OnFolderChange = (folder: FolderDataModel) => {
            LogCollector.LogMessage("OnFolderChange");

            window.history.pushState({}, "", window.location.pathname);
            GlobalNotificationHub.sendMessageWithData(GlobalNotifications.FolderSelectChange, folder);
        }


        const OnConceptDrop = async (element: DraggableContent) => {

            await folderStore
                .sendFolderChange(element.concept, folderData.folderID)
                .then(() => {

                    // removing from the selected folder
                    LogCollector.LogMessage("OnConceptDrop");

                    if (context.state.selectedFolder.folderType === FolderType.SystemNotSorted) {
                        // Removes the item from the "not in folder" foler
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.RemoveConceptFromView, element.concept);
                    }
                    else if (element.concept.folderList.filter(f => f.folderType === FolderType.CustomFolder && f.folderID === context.state.selectedFolder.folderID).length > 0) {
                        // Removes the item from the current selected folder
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.RemoveConceptFromView, element.concept);
                    }
                    //#hack no-subfolder!!!
                    const x = { folderID: folderData.folderID } as FolderDataModel;
                    element.concept.folderList = [x] as FolderDataModel[];


                    folderData.conceptCount++;
                    forceReload(n => n + 1);
                });
        }

        const [{ isOver, canDrop }, drop] = useDrop({
            accept: DraggableElement.Concept,
            drop: OnConceptDrop,
            collect: (monitor) => ({
                isOver: monitor.isOver(),
                canDrop: monitor.canDrop(),
            }),
            canDrop: (item: DraggableContent) => {

                if (!item.concept.folderList) {
                    return true;
                }

                return item.concept.folderList.filter(f => f.folderID === folderData.folderID).length === 0;
            }
        });

        useEffect(() => {

            if (isOver && canDrop) {
                document.body.classList.add("dragtest-over");
            }
            else {
                document.body.classList.remove("dragtest-over");
            }

        }, [isOver]);

        const OnDelete = () => {
            if (contextMenuRef.current) {
                contextMenuRef.current.click();
            }

            GlobalNotificationHub.sendMessageWithData(GlobalNotifications.ConfirmNotification, {
                confirmService: new FolderDeleteConfirmService(folderData, folderStore, context.conceptType)
            } as ConfirmNotificationModel);
        }

        const isActive = isOver && canDrop
        let cssClass = isSelected ? " active" : "";
        if (isActive) {
            cssClass += " drag-receive active";

        } else if (canDrop) {
            cssClass += " drag-receive";
        }

        if (isEditing) {
            return (
                <InLineFolderEditor
                    currentFolder={folderData}
                    onCancel={() => setIsEditing(false)}
                    onCommit={(renamedFolder: FolderDataModel) => {

                        const folder = {
                            ...folderData,
                            folderName: renamedFolder.folderName
                        };

                        folderData.folderName = renamedFolder.folderName;

                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.FolderSelectChange, folder);
                        setIsEditing(false)
                    }}
                />
            )
        }

        return (
            <div className={"vc-item enable-on-hover align-items-center p-2" + cssClass} ref={drop} onClick={() => OnFolderChange(folderData)}>
                <svg className="p-none" width="17" height="19" role="img" aria-label="Insights"><use xlinkHref="#folder-icon"></use></svg>
                <span className="me-auto fw-small-500">{folderData.folderName}</span>
                <div className="fw-small-400 fw-secondary hide-on-hover">{folderData.conceptCount}</div>
                <div className="hover-item"
                    ref={contextMenuRef}
                    role="button"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"
                    onClick={(event) => {
                        event.stopPropagation();
                        event.preventDefault();
                    }}
                >
                    <svg className="pe-none" width="18" height="15" role="img" aria-label="Link"><use xlinkHref="#edit-inline-icon"></use></svg>
                </div>
                <div className="dropdown-menu shadow modal-border rounded-top" >
                    <li>
                        <div className="dropdown-item" onClick={() => {
                            setIsEditing(true);

                        }}>
                            <svg className="pe-none dark-icon" width="16" height="17" role="img" aria-label="Link"><use xlinkHref="#t-edit-icon"></use></svg>
                            <span className="ps-2 fw-small-500">Rename folder</span>
                        </div>
                    </li>
                    <li>
                        <div className="dropdown-item" data-bs-toggle="modal" data-bs-target={CommonElements.ModalElementTarget} onClick={OnDelete}>
                            <svg className="pe-none dark-icon" width="16" height="19" role="img" aria-label="Link"><use xlinkHref="#delete-icon"></use></svg>
                            <span className="ps-2 fw-small-500">Delete folder</span>
                        </div>
                    </li>

                </div>
            </div>
        );
    });


interface InLineFolderEditorProps {
    currentFolder: FolderDataModel;
    onCommit: (renamedFolder: FolderDataModel) => void;
    onCancel: () => void;
}

const InLineFolderEditor = (props: InLineFolderEditorProps) => {
    const [store] = useState(new FolderEditorStore());

    useEffect(() => {
        store.load(props.currentFolder);
    }, [props.currentFolder])

    const OnRenameCommit = async () => {

        if (!store.storageState.folder.folderName) {
            return;
        }

        store.saveRename().then(() => {
            props.onCommit(store.storageState.folder);
        });
    }


    return (
        <div className="my-1">
            <TextBoxComponent
                dataStore={store}
                fieldName="folder.folderName"
                maxLength={200}
                autoFocus={true}

                onCommit={OnRenameCommit}

                onCancel={() => {
                    props.onCancel();
                }}

                onBlur={() => {
                    props.onCancel();
                }}
            />
        </div>
    )
}