import React from "react";
import { memo } from "react"
import { ConceptType } from "../../../models/common/CommonEnums";
import { ConceptEditorService } from "../../../services/concepts/ConceptEditorService";
import { SectionHeaderComponent, SectionHeaderIconSize } from "../../elements/SectionHeaderComponent";
import { TextDisplayComponent } from "../../elements/TextDisplayComponent";
import { ConceptAttachmentComponent } from "../../uploader/ConceptAttachmentComponent";
import { ConceptLinkSection } from "../sections/ConceptLinkSection";
import { OptionalSectionDisplay } from "../sections/OptionalSectionDisplay";
import { ConceptImageAttachmentComponent } from "../../uploader/ConceptImageAttachmentComponent";
import { TextBoxRichTextReadOnlyComponent } from "../../elements/RichTextBox/TextBoxRichTextReadOnlyComponent";

interface SolutionViewerComponentProps {
    conceptService: ConceptEditorService;
}

export const SolutionViewerComponent: React.FunctionComponent<SolutionViewerComponentProps>
    = memo(
        function SolutionViewer({ conceptService }) {

            const dataStore = conceptService.conceptEditorStore;

            return (
                <React.Fragment>
                    <ConceptLinkSection
                        conceptLinkStore={conceptService.conceptLinkStore}
                        sourceConceptType={ConceptType.Solutions}
                        targetConceptType={ConceptType.Problems}
                        isEditing={false}
                        customization={{
                            buttonIcon: "",
                            buttonText: "+ Link",
                            singleItemHeaderText: "This addresses the problem:",
                            manyItemsHeaderText: "This addresses these problems:",
                            useNormalSizeCard: true
                        }} />

                    <hr className="mx-auto w-100" />
                    <div>
                        <SectionHeaderComponent iconName="#beaker-icon" header="Hypothesis" iconSize={SectionHeaderIconSize.Medium} />
                        <div className="mt-2 display-lines">
                            <TextDisplayComponent fieldName="description" dataStore={dataStore} />
                        </div>
                    </div>

                    <hr className="mx-auto w-100" />
                    <div>
                        <SectionHeaderComponent iconName="#edit-icon" header="Solution details" iconSize={SectionHeaderIconSize.Medium} />
                        <div className="mt-2 display-lines">
                            <TextBoxRichTextReadOnlyComponent fieldName="solutionData.solutionDetails" dataStore={dataStore} />
                        </div>
                    </div>

                    <OptionalSectionDisplay
                        dataStore={dataStore}
                        fieldPath="solutionData.effortDetails"
                        headerIcon="#weightbar-icon"
                        headerText="Effort required to implement this solution"
                    />

                    <hr className="mx-auto" />
                    <ConceptImageAttachmentComponent attachmentStore={conceptService.conceptAttachmentStore} />

                    <hr className="mx-auto" />
                    <div>
                        <ConceptAttachmentComponent attachmentStore={conceptService.conceptAttachmentStore} />
                    </div>

                    <hr className="mx-auto w-100" />
                    <ConceptLinkSection
                        conceptLinkStore={conceptService.conceptLinkStore}
                        sourceConceptType={ConceptType.Solutions}
                        targetConceptType={ConceptType.Insights}
                        isEditing={false} />
                </React.Fragment>
            )
        }
    )