import React, { CSSProperties, memo, useEffect, useRef } from "react";
import { useDrag, useDrop, XYCoord } from "react-dnd";
import type { Identifier } from 'dnd-core';
import { getEmptyImage } from "react-dnd-html5-backend";
import { DraggableElement } from "../../models/common/CommonEnums";
import { ConceptDataModel } from "../../models/ConceptDataModel";
import CardDisplayComponent from "./CardDisplayComponent";
import { LogCollector } from "../../services/logger/LogCollector";
import { ConceptDisplayStore } from "../../services/stores/Concept/ConceptDisplayStore";

interface CardDisplayComponentProps {
    conceptModel: ConceptDataModel;
    conceptStore: ConceptDisplayStore;
    conceptIndex: number;
    moveCard?: (dragIndex: number, hoverIndex: number) => void;
}

export interface DraggableContent {
    concept: ConceptDataModel;
    index: number;
}


// interface Item {
//     id: string
//     originalIndex: number
// }

//const isEnabled = false;

export const CardDisplayDraggableComponent: React.FunctionComponent<CardDisplayComponentProps>
    = memo(
        function CardDisplayDraggable({ conceptModel, conceptStore, conceptIndex, moveCard }) {

            const ref = useRef<HTMLDivElement>(null);

            const [{ handlerId }, drop] = useDrop<
                DraggableContent,
                void,
                { handlerId: Identifier | null }>({
                    accept: DraggableElement.Concept,
                    collect(monitor) {
                        return {
                            handlerId: monitor.getHandlerId(),
                        }
                    },
                    hover(item: DraggableContent, monitor) {
                        if (!ref.current) {
                            return
                        }

                        if (!moveCard) {
                            return;
                        }

                        const dragIndex = item.index;
                        const hoverIndex = conceptIndex;

                        // Don't replace items with themselves
                        if (dragIndex === hoverIndex) {
                            return
                        }

                        // Determine rectangle on screen
                        const hoverBoundingRect = ref.current?.getBoundingClientRect()

                        // Get vertical middle
                        const hoverMiddleY =
                            (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

                        // Determine mouse position
                        const clientOffset = monitor.getClientOffset()

                        // Get pixels to the top
                        const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

                        // Only perform the move when the mouse has crossed half of the items height
                        // When dragging downwards, only move when the cursor is below 50%
                        // When dragging upwards, only move when the cursor is above 50%

                        // Dragging downwards
                        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                            return
                        }

                        // Dragging upwards
                        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                            return
                        }

                        // Time to actually perform the action
                        moveCard(dragIndex, hoverIndex)

                        // Note: we're mutating the monitor item here!
                        // Generally it's better to avoid mutations,
                        // but it's good here for the sake of performance
                        // to avoid expensive index searches.
                        item.index = hoverIndex
                    },
                })

            const [{ isDragging }, drag, preview] = useDrag({
                type: DraggableElement.Concept,
                item: () => {


                    return { concept: conceptModel, index: conceptIndex } as DraggableContent;
                },
                collect: (monitor: any) => ({
                    isDragging: monitor.isDragging(),
                }),
                end: (item, monitor) => {
                    // //ConceptService.syncItemPosition(item);
                    // console.error(item.index);
                    // console.error(monitor.didDrop());
                    conceptStore.commitPositionChange(item.concept, item.index)
                }
            })

            useEffect(() => {
                preview(getEmptyImage(), { captureDraggingState: true })
            }, [preview])

            const getStyles = (
                isDragging: boolean,
            ) => {
                //const transform = `translate3d(${50}px, ${50}px, 50px)`
                if (!isDragging) {
                    return undefined;
                }
                //  const transform = "rotateZ(20deg)"
                return {
                    //marginTop: "0.5rem", marginBottom: "1rem",
                    //position: 'absolute',
                    // transform,
                    // WebkitTransform: transform,
                    // IE fallback: hide the real node using CSS when dragging
                    // because IE will ignore our custom "empty image" drag preview.
                    opacity: isDragging ? 0 : 1,
                    //height: isDragging ? 0 : ''                    
                } as CSSProperties
            };

            useEffect(() => {

                if (isDragging) {
                    document.body.classList.add("dragtest");              
                }
                else {
                    document.body.classList.remove("dragtest");
                }

            }, [isDragging])


            LogCollector.LogMessage("CardDisplayDraggableComponent");

            drag(drop(ref));
            return (
                <React.Fragment>
                    <div className="card-display" style={getStyles(isDragging)} ref={ref} data-handler-id={handlerId}>
                        <CardDisplayComponent conceptModel={conceptModel} />
                    </div>
                </React.Fragment>
            );
        }
    )