import { useEffect } from "react";
import { useAuth } from "react-oidc-context"

export const HeaderDisplay = () => {
    const auth = useAuth();

    useEffect(() => {

    }, [auth])

    if (!auth.user?.profile?.name) {
        return <></>;
    }

    const RenderIcon = () => {
        if (auth.user?.profile.picture) {
            return (
                <div className="me-2">
                    <div className="m-auto" style={{ borderRadius: "20px", overflow: "hidden", width: "fit-content" }}>
                        <img src={auth.user?.profile.picture} width="40" height="40" alt="Profile" />
                    </div>
                </div>
            )
        }
        return (
            <></>
        )
    }

    return (
        <div className="display-header">
            <RenderIcon />
            <div className="fw-xxlarge-700">
                Hi {auth.user.profile.given_name ?? auth.user.profile.name}
            </div>
        </div>
    )
}