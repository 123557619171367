
export interface WorkspaceDataModel {
    workspaceID: string;
    workspaceName: string;
    workspaceCode: string;
    accountAccessType: AccountAccessType;
    workspaceTeamSize: number;

    insightCount: number;
    problemCount: number;
    solutionCount: number;
    feedbackCount: number;

    //clientonly
    savePending: boolean;
}

export enum AccountAccessType {
    Owner = 1,
    FullAccess = 2,

    Invited = 100
}