import { debounce } from "lodash";
import React, { useRef } from "react";
import { useEffect, useMemo, useState } from "react";
import { ConceptSearchService } from "../../../services/concepts/ConceptSearchService";
import { useAppContext } from "../../../services/context/AppContext";
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { TextSearchData, TextSearchStore } from "../../../services/stores/TextSearchStore";
import { TextBoxKeywordSearchComponent } from "../../elements/TextBoxKeywordSearchComponent";
import { ConceptKeywordSearchDisplay } from "./ConceptKeywordSearchDisplay";
import { CommonElements } from "../../../models/CommonElements";

export const ConceptKeywordSearchComponent = () => {
    const [searchStore, setSearchStore] = useState<TextSearchStore>();
    const [isSearchOpen, setIsSearchOpen] = useState(false);
    const context = useAppContext();
    const conceptType = context.conceptType;
    const dropRef = useRef<HTMLDivElement>(null);

    const debouncedChangeHandler = useMemo(
        () => debounce(async (data: TextSearchData) => {
            await ConceptSearchService.searchConcept(conceptType, data.filterValue, "");
        }, 300)
        , [conceptType]);

    useEffect(() => {
        return () => debouncedChangeHandler.cancel();
    }, [debouncedChangeHandler]);

    useEffect(() => {
        const searchStoreValue = new TextSearchStore();

        const messageHubContext = MessageHubContext()
            .Subscribe(searchStoreValue, debouncedChangeHandler);

        setSearchStore(searchStoreValue);

        return () => {
            messageHubContext.Dispose();
        };
    }, []);

    const OnSearchClick = async () => {

        if (isSearchOpen) {
            document.getElementById("hackForUnselect")?.focus();
            return;
        }

        await ConceptSearchService.searchConcept(conceptType, searchStore?.storageState.filterValue ?? "", "");

        if (dropRef?.current) {
            dropRef.current.addEventListener("hide.bs.dropdown", OnClosingDrop);
        }

        setIsSearchOpen(true);
    }

    const OnClosingDrop = () => {
        if (dropRef?.current) {
            dropRef.current.removeEventListener("hide.bs.dropdown", OnClosingDrop);
        }

        document.getElementById(CommonElements.ConceptKeywordSearch)?.blur();
        setIsSearchOpen(false);
    }

    if (!searchStore) {
        return <React.Fragment></React.Fragment>
    }

    const dropdownUID = crypto.randomUUID();

    //CommonElements.test;
    return (
        <div ref={dropRef}>
            <div id={dropdownUID} data-bs-toggle="dropdown" data-bs-auto-close="outside" onClick={OnSearchClick}>
                <TextBoxKeywordSearchComponent
                    dataStore={searchStore}
                    fieldName={CommonElements.ConceptKeywordSearch}
                    maxLength={100}
                    placeholder="Keyword" />
            </div>

            <div className={"dropdown-menu shadow drop-container drop-container-keyword-search mt-1"}>
                {
                    (isSearchOpen) &&
                    <ConceptKeywordSearchDisplay dropdownUID={dropdownUID} />
                }
            </div>

            <div id="hackForUnselect" style={{ outline: "0px" }} tabIndex={-1}></div>
        </div>
    )
}