import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { FORMAT_TEXT_COMMAND, TextFormatType } from "lexical";

interface ToolbarItemComponentProps {
    isActive: boolean;
    commandText: string;
}

export const ToolbarItemComponent = (props: ToolbarItemComponentProps) => {
    const [editor] = useLexicalComposerContext();

    return (
        <button tabIndex={-1} className={"toolbar-item spaced " + (props.isActive ? "active" : "")} aria-label={"Format " + props.commandText}
            onClick={() => {
                editor.dispatchCommand(FORMAT_TEXT_COMMAND, props.commandText as TextFormatType);
            }}>
            <i className={"format " + props.commandText} />
        </button>
    )
}