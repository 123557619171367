import { Subject } from "rxjs";
import { IDataStore } from "../../../models/common/IDataStore";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { WorkspaceSaveAction } from "../../../models/communication/actions/WorkspaceSaveAction";
import { AccountAccessType, WorkspaceDataModel } from "../../../models/data/WorkspaceDataModel";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { MessageService } from "../../messaging/MessageService";
import { WorkspaceStore } from "./WorkspaceStore";

const workspaceInitialState = {
    //isConceptSaved: false,
    workspaceName: "",

    accountAccessType: AccountAccessType.Owner,

    insightCount: 0,
    problemCount: 0,
    solutionCount: 0

} as WorkspaceDataModel;

export class WorkspaceEditorStore implements IDataStore, INotificationHub {
    conceptEditorSubject = new Subject();
    storageState = workspaceInitialState;

    initialize() {
        this.storageState = { ...workspaceInitialState };
        this.conceptEditorSubject.next(this.storageState);
    }

    load(workspace: WorkspaceDataModel) {
        this.storageState = {
            ...this.storageState,
            workspaceID: workspace.workspaceID,
            workspaceName: workspace.workspaceName
        }

        this.conceptEditorSubject.next(this.storageState);
    }

    subscribe(onMessageReceived: any) {
        return this.conceptEditorSubject.subscribe(onMessageReceived);
    };

    sendMessage(fieldName: string, fieldValue: string) {

        // Updates the temporary storage
        this.storageState = { ...this.storageState, [fieldName]: fieldValue };

        // Notifies everyone
        this.conceptEditorSubject.next(this.storageState);
    }

    async saveWorkspace(workspaceStore: WorkspaceStore) {

        this.storageState = {
            ...this.storageState,
            workspaceID: crypto.randomUUID(),
            savePending: true
        };

        return MessageService.sendMessage({
            messageType: MessageType.WorkspaceSave,
            requestData: { workspaceData: this.storageState } as WorkspaceSaveAction
        } as IServerMessage).then(() => {

            //TODO:
            workspaceStore.addNotSaved(this.storageState)
        });
    }

    async generateDefaultWorkspace() {
        return MessageService.sendMessage({
            messageType: MessageType.WorkspaceGenerateDefault,
            requestData: {}
        } as IServerMessage);
    }

    async renameWorkspace() {
        return MessageService.sendMessage({
            messageType: MessageType.WorkspaceUpdate,
            requestData: { workspaceData: this.storageState } as WorkspaceSaveAction
        } as IServerMessage);
    }
}