import React, { useEffect, useState } from "react";
import { FeedbackStore } from "../../../services/stores/Feedback/FeedbackStore"
import { MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { FeedbackDataModel } from "../../../models/data/FeedbackDataModel";

interface FeedbackEnableComponentProps {
    store: FeedbackStore;
}

export const FeedbackEnableComponent = (props: FeedbackEnableComponentProps) => {

    const [isEnabled, setIsEnabled] = useState(false);

    useEffect(() => {

        const hubContext = MessageHubContext()
            .Subscribe(props.store, (feedbackSettings: FeedbackDataModel) => {
                hubContext.Dispose();
                setIsEnabled(Boolean(feedbackSettings.isEnabled));
            });

        return () => hubContext.Dispose();

    }, []);

    const OnChangeEnable = async () => {
        return props.store.switchEnable().then(() => {
            setIsEnabled(!isEnabled);
        });
    }

    return (
        <div className="fw-small-400 feedback-toggle">
            <div className="form-check form-switch my-0">
                <input className="form-check-input" type="checkbox" role="switch" id="isVoyceFeedbackEnabled" checked={isEnabled} onChange={OnChangeEnable} />
            </div>
            <div className="fw-small-500">
                <label className="form-check-label" htmlFor="isVoyceFeedbackEnabled">{isEnabled ? "Your form is ON and ready to collect feedback!" : "Your form is OFF"}</label>
            </div>
            <div></div>
            <div className="fw-xsmall-400">
                {
                    isEnabled
                        ? "Anyone with the link to your form can access it. Go share it!"
                        : "No one can access your form, including anyone you've already shared it with."
                }
            </div>
        </div>
    )
}