import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { BLUR_COMMAND, COMMAND_PRIORITY_NORMAL, FOCUS_COMMAND, KEY_ESCAPE_COMMAND } from "lexical";
import { useEffect, useRef, useState } from "react";
import { KeyboardService } from "../../../../services/shortcut/KeyboardService";
import { mergeRegister } from "@lexical/utils";
import { LogCollector } from "../../../../services/logger/LogCollector";

export const FocusWatchPlugin = () => {
    const ref = useRef<HTMLDivElement>(null);

    const [elementKey] = useState(crypto.randomUUID());
    const [editor] = useLexicalComposerContext();

    // const debouncedLostFocusHandler = useMemo(
    //     () => debounce(async () => {
    //         console.warn("lost focus")
    //         //KeyboardService.delayedLostFocus(ref.current);
    //         //KeyboardService.delayedLostFocus(ref.current);
    //         setTimeout(() => {
    //             KeyboardService.lostFocus(ref);
    //         }, 200);

    //         if (editor._rootElement?.parentElement?.parentElement) {
    //             editor._rootElement.parentElement.parentElement.classList.remove("editor-container-focus");
    //         }
    //     }, 50)
    //     , [editor, ref]);

    // useEffect(() => {
    //     return () => debouncedLostFocusHandler.cancel();
    // }, [debouncedLostFocusHandler]);

    // useEffect(() => {

    //      const hubContext = MessageHubContext()
    //     // .ListenGlobalNotification(global)

    // }, []);

    useEffect(() => {

        //editor.registerCommand(FOCUS_COMMAND, () => { return true; }, 0);

        return mergeRegister(
            editor.registerCommand(FOCUS_COMMAND, () => {
                LogCollector.LogWarn("FocusWatchPlugin.FOCUS_COMMAND");
                KeyboardService.hasFocus(elementKey);

                if (editor._rootElement?.parentElement?.parentElement) {
                    editor._rootElement.parentElement.parentElement.classList.add("editor-container-focus");
                }

                return true;
            }, 0),
            editor.registerCommand(KEY_ESCAPE_COMMAND, () => {
                LogCollector.LogWarn("FocusWatchPlugin.KEY_ESCAPE_COMMAND");
                KeyboardService.delayedLostFocus(ref.current);

                return true;
            }, COMMAND_PRIORITY_NORMAL),
            editor.registerCommand(BLUR_COMMAND, () => {
                LogCollector.LogWarn("FocusWatchPlugin.BLUR_COMMAND");

                //debouncedLostFocusHandler();
                //KeyboardService.lostFocus(ref);
                //KeyboardService.delayedLostFocus(ref.current);
                // //KeyboardService.delayedLostFocus(ref.current);

                const currentElementKey = elementKey;
                setTimeout(() => {
                    KeyboardService.lostFocus(currentElementKey);
                }, 50);

                if (editor._rootElement?.parentElement?.parentElement) {
                    editor._rootElement.parentElement.parentElement.classList.remove("editor-container-focus");
                }
                return true;
            }, 0),
        );
    }, [editor]);

    return <div ref={ref}></div>;
}