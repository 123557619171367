declare global {
  interface Window {
    openNotification: (elementName: string) => void;
    closeOfFrame: (elementName: string) => void;

    closeCustomDropdown: (elementName: string) => void;

    toggleAlert: (elementName: string) => void;
    openAlert: (elementName: string) => void;

    triggerClick: (elementName: string) => void;
    openDropDown: (elementName: string) => void;

    openModal: (elementName: string) => void;
    closeModal: (elementName: string) => void;

    togglePopover: (elementName: string, message: string, open: boolean) => void;
  }
}

//window.bootstrap = require("bootstrap/dist/js/bootstrap.bundle.js");


global.window.openNotification = (elementName: string) => {
  const toastLiveExample = document.getElementById(elementName)
  const toast = new bootstrap.Toast(toastLiveExample)
  toast.show()
}

global.window.closeOfFrame = (elementName: string) => {
  const toastLiveExample = document.getElementById(elementName)
  const toast1 = bootstrap.Offcanvas.getInstance(toastLiveExample);
  toast1.hide();
}

global.window.closeCustomDropdown = (elementName: string) => {
  const buttonDropdown = document.getElementById(elementName);
  if (buttonDropdown) {
    buttonDropdown.click();
  }
}

global.window.toggleAlert = (elementName: string) => {
  const element = document.getElementById(elementName);
  if (element) {
    const bsCollapse = new bootstrap.Collapse(element);
    bsCollapse.toggle();
  }
}

global.window.openAlert = (elementName: string) => {
  const element = document.getElementById(elementName);
  if (element) {
    if (!element.classList.contains("show")) {
      const bsCollapse = new bootstrap.Collapse(element);
      bsCollapse.toggle();
    }
    else {
      //closes it
      const bsCollapse = new bootstrap.Collapse(element);
      bsCollapse.toggle();

      //then reopens it
      setTimeout(() => {
        const bsCollapse = new bootstrap.Collapse(element);
        bsCollapse.toggle();
      }, 100);
    }
  }
}
global.window.triggerClick = (elementName: string) => {
  const element = document.getElementById(elementName);
  if (element) {
    element.click();
  }
}

global.window.openDropDown = (elementName: string) => {
  const element = document.getElementById(elementName);
  if (element) {
    element.click();
  }
}

global.window.openModal = (elementName: string) => {
  const element = document.getElementById(elementName);
  if (element) {
    new bootstrap.Modal(element).show();
  }
}

global.window.closeModal = (elementName: string) => {
  const element = document.getElementById(elementName);
  if (element) {
    const x = bootstrap.Modal.getOrCreateInstance(element);
    if (x) {
      x.hide();
    }
  }
}


global.window.togglePopover = (elementName: string, message: string, open: boolean) => {

  let popover = bootstrap.Popover.getInstance(elementName);
  if (!popover) {
    popover = bootstrap.Popover.getOrCreateInstance(elementName, {
      trigger: 'manual',
      container: '.vc-editor',
      placement: "bottom"
    });


    popover.setContent({
      //'.popover-header': 'another title',
      '.popover-body': message
    });
  }

  if (open) {
    if (!popover.isOpen) {
      popover.isOpen = true;
      popover.show();
    }
  }
  else if (popover.isOpen) {
    popover.isOpen = false;
    popover.hide()
  }

}

window.addEventListener("popstate", () => {
  if (document.body.classList.contains("modal-open")) {
    const backdropList = document.getElementsByClassName("modal-backdrop");
    if (backdropList && backdropList.length > 0) {
      if (backdropList && backdropList.length > 0) {
        const parent = backdropList[0].parentNode;
        if (parent) {
          parent.removeChild(backdropList[0]);
          document.body.classList.remove("modal-open");
          document.body.style = "";
        }
      }
    }
  }
});


export { }

