import { Subject } from "rxjs";
import { ConceptType, GlobalNotifications } from "../models/common/CommonEnums";
import { INotificationHub } from "../models/common/INotificationHub";
import { ConceptDeleteAction } from "../models/communication/actions/ConceptDeleteAction";
import { ConceptLoadAction, ConceptLoadActionResponse } from "../models/communication/actions/ConceptLoadAction";
import { ConceptSaveAction } from "../models/communication/actions/ConceptSaveAction";
import { ConceptDataModel } from "../models/ConceptDataModel";
import { FolderDataModel } from "../models/data/FolderDataModel";
import { IServerMessage, MessageType } from "../models/message/IServerMessage";
import { GlobalNotification, GlobalNotificationHub } from "./GlobalMessageHub";
import { HelperExtension } from "./HelperExtension";
import { MessageHubOwner } from "./messaging/MessageHubOwner";
import { MessageService } from "./messaging/MessageService";
import { IHubSubcription, MessageHubContext, MessageHubHandler } from "./notification/MessageHubHandler";
import { LogCollector } from "./logger/LogCollector";

const conceptInitialState = {
    conceptType: ConceptType.Insights,
    data: [] as ConceptDataModel[],
    newDataCount: 0,
} as ConceptServiceState;

export interface ConceptServiceState {
    conceptType: ConceptType;
    data: ConceptDataModel[];
    newDataCount: number;
}

export interface ConceptMessage {
    messageDescriptor: string;
    data: ConceptDataModel[];
}

class ConceptServiceImplementation implements INotificationHub {

    messageHubContext?: MessageHubHandler;

    conceptServiceSubject = new Subject();
    storageState: ConceptServiceState = conceptInitialState;
    conceptType: ConceptType = ConceptType.Default;

    loadOnInitialize: boolean = true;


    constructor() {
        this.load = this.load.bind(this);
        this.OnGlobalNotification = this.OnGlobalNotification.bind(this);
    }

    async initialize(conceptTypeValue: ConceptType) {

        if (this.messageHubContext == null) {
            this.messageHubContext = MessageHubContext()
                .ListenMessage(MessageHubOwner, { MessageType: MessageType.ConceptLoaded, OnReceive: this.load } as IHubSubcription)
                .ListenGlobalNotificationAsync([GlobalNotifications.FolderSelectChange, GlobalNotifications.RemoveConceptFromView], this.OnGlobalNotification);
        }

        this.conceptType = conceptTypeValue;

        this.storageState = { ...conceptInitialState, newDataCount: 0, conceptType: conceptTypeValue };
        this.conceptServiceSubject.next(this.storageState);

        if (!this.loadOnInitialize) {
            this.loadOnInitialize = true;
            return;
        }

        return MessageService.sendMessage({
            messageType: MessageType.ConceptLoad,
            requestData: { conceptType: conceptTypeValue } as ConceptLoadAction
        });
    };

    async OnGlobalNotification(notification: GlobalNotification) {

        switch (notification.notificationType) {
            case GlobalNotifications.FolderSelectChange:
                await this.HandleFolderChange(notification.notificationData as FolderDataModel);
                break;
            case GlobalNotifications.RemoveConceptFromView:
                await this.HandleConceptRemoveFromView(notification.notificationData as ConceptDataModel);
                break;
            default:
                return;
        }
    }

    async HandleFolderChange(selectedFolder: FolderDataModel) {
        if (!selectedFolder) {
            return;
        }

        return MessageService.sendMessage({
            messageType: MessageType.ConceptLoad,
            requestData: {
                conceptType: this.conceptType,
                folderID: selectedFolder.folderID
            } as ConceptLoadAction
        } as IServerMessage);
    }

    async HandleConceptRemoveFromView(concept: ConceptDataModel) {
        LogCollector.LogMessage("ConceptService: HandleConceptRemoveFromView");

        const data = this.storageState.data.filter(c => c.conceptID !== concept.conceptID);

        this.storageState = {
            ...this.storageState,
            data: data,
            newDataCount: data.length
        };

        this.conceptServiceSubject.next(this.storageState);
    }

    async load(conceptLoadResponse: ConceptLoadActionResponse) {
        LogCollector.LogMessage("ConceptService: LoadingResults");

        if (conceptLoadResponse.conceptType !== this.conceptType) {
            return;
        }

        let data = conceptLoadResponse.conceptList;//this.storageState.data.concat(newConcept);

        this.storageState = {
            ...this.storageState,
            data: data,
            newDataCount: data.length
        };

        this.conceptServiceSubject.next(this.storageState);
    }

    subscribe(setState: any) {
        return this.conceptServiceSubject.subscribe(setState);
    };

    async createConcept(newConcept: ConceptDataModel) {

        // var temporaryIndex = this.storageState.data.length > 0
        //     ? Math.round(this.storageState.data[0].sortData.sortIndex) + 100
        //     : 0;

        // //newConcept.folderList[0].
        // const x = {
        //     folderID: "",
        //     sortIndex: temporaryIndex
        // } as SortDataModel;

        //newConcept.clientConceptID = crypto.randomUUID();
        //newConcept.conceptID = crypto.randomUUID();
        //newConcept.conceptType = this.storageState.conceptType;
        // const x = {
        //     folderID: "",
        //     sortedIndex: 0
        // } as SortDataModel;
        //newConcept.sortData = x;

        return MessageService
            .sendMessage({
                messageType: MessageType.ConceptCreate,
                requestData: { concept: newConcept } as ConceptSaveAction
            } as IServerMessage)
            .then(() => {

                if (!newConcept.savePending) {
                    newConcept.savePending = true;
                    //GlobalNotificationHub.sendMessageWithData(GlobalNotifications.EditedConcept, newConcept);
                    //this.conceptServiceSubject.next(this.storageState);

                    // this.storageState.newDataCount--;
                    // var filteredList = this.storageState.data.filter(c => c.conceptID !== newConcept.conceptID);
                    // let data = [newConcept, ...filteredList];
                    GlobalNotificationHub.sendMessageWithData(GlobalNotifications.ConceptSavePending, newConcept);

                    const conceptIndex = this.storageState.data.findIndex(c => c.conceptID === newConcept.conceptID);

                    newConcept.sortData = { ...this.storageState.data[conceptIndex].sortData };

                    this.storageState.data[conceptIndex] = newConcept;

                    //#perf-impact: Forces refresh the whole list                    
                    this.storageState.data = [...this.storageState.data];
                    // update(this.storageState.data, {
                    //     0: {
                    //         $set: newConcept
                    //     }
                    // });

                    this.conceptServiceSubject.next(this.storageState);
                    // this.storageState = {
                    //     ...this.storageState,
                    //     data: data
                    // };

                    // this.conceptServiceSubject.next(this.storageState);
                    return;
                }

                // Only adds the concept if the concept type match. Linking existing concept to new concept type will create different types (ie: link Insight to a New Problem)                
                let data = (newConcept.conceptType === this.conceptType)
                    ? [newConcept, ...this.storageState.data]
                    : this.storageState.data;

                this.storageState = {
                    ...this.storageState,
                    data: data,
                    newDataCount: data.length
                };

                this.conceptServiceSubject.next(this.storageState);
            });
    };

    async deleteConcept(concept: ConceptDataModel) {
        return MessageService
            .sendMessage({
                messageType: MessageType.ConceptDelete,
                requestData: { conceptID: concept.conceptID } as ConceptDeleteAction
            } as IServerMessage)
            .then(() => {

                let data = this.storageState.data.filter(c => c.conceptID !== concept.conceptID);
                this.storageState = {
                    ...this.storageState,
                    data: data,
                    newDataCount: data.length
                };

                this.conceptServiceSubject.next(this.storageState);
            });
    }

    syncQueue = new PromiseQueue();
    //#partial-update
    processPartialUpdate(conceptPartialData: ConceptDataModel, triggerNotification: boolean) {

        //Aborts if we are not showing the same concept type
        if (conceptPartialData.conceptType !== this.conceptType) {
            return;
        }

        this.syncQueue.add(() => {
            const cardIndex = this.storageState.data.findIndex(card => card.conceptID === conceptPartialData.conceptID);
            if (cardIndex > -1) {

                this.storageState.data[cardIndex] = HelperExtension.ProcessConceptPartialUpdate(this.storageState.data[cardIndex], conceptPartialData);
                if (triggerNotification) {
                    this.conceptServiceSubject.next(this.storageState);
                }
            }
        })
    }

};

class PromiseQueue {
    queue = Promise.resolve();

    add(operation: () => void) {
        return new Promise((resolve, reject) => {
            this.queue = this.queue
                .then(operation)
                .then(resolve)
                .catch(reject)
        })
    }
}


export const ConceptService = new ConceptServiceImplementation();