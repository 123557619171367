import _ from "lodash";
import { useEffect, useState } from "react";
import { ConceptDataModel } from "../../../../models/ConceptDataModel";
import { MessageHubContext } from "../../../../services/notification/MessageHubHandler";
import { ConceptEditorStore } from "../../../../services/stores/ConceptEditorStore";

interface SolutionPreviewComponentProps {
    conceptEditorStore: ConceptEditorStore;
}

interface SolutionPreviewState {
    believeText: string;
    resultText: string;
    evidenceText: string;
}

export const SolutionPreviewComponent = (props: SolutionPreviewComponentProps) => {

    const [solutionHypothesis, setSolutionHypothesis] = useState<SolutionPreviewState>();

    useEffect(() => {

        let previousData = {} as SolutionPreviewState;

        const hubContext = MessageHubContext()
            .Subscribe(props.conceptEditorStore, (concept: ConceptDataModel) => {

                if (!concept.solutionData) {
                    return;
                }

                const newValue = {
                    believeText: concept.solutionData.believeText,
                    resultText: concept.solutionData.resultText,
                    evidenceText: concept.solutionData.evidenceText
                } as SolutionPreviewState;

                if (!_.isEqual(previousData, newValue)) {
                    previousData = newValue;
                    setSolutionHypothesis(newValue);
                }
            });


        return () => hubContext.Dispose();

    }, []);

    return (
        <div className="p-3 text-center concept-bg-lighter rounded">
            <div className="fw-small-400">Hypothesis preview</div>
            <div className="fw-med-500 text-wrap text-break">
                <span>We believe that </span>
                <span className="fw-concept">{solutionHypothesis?.believeText ?? "........"}</span>
                <span> will result in </span>
                <span className="fw-concept">{solutionHypothesis?.resultText ?? "........"}</span>                
                <span> measured by  </span>
                <span className="fw-concept">{solutionHypothesis?.evidenceText ?? "........"}.</span>

            </div>
        </div>
    )
}