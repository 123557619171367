
export const AttachmentHelperExtension = {
    GetFileExtension(fileName: string) {
        const c = fileName.lastIndexOf(".");

        if (c > -1) {
            return fileName.substring(c + 1).toLowerCase();
        }

        return "";
    },

    GetFileName(fileName: string, attachmentID: string) {
        const fileExtension = AttachmentHelperExtension.GetFileExtension(fileName);
        return (fileExtension.length > 0
            ? attachmentID + "." + fileExtension
            : attachmentID).toLowerCase();

    }
}