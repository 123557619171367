import React from "react";
import { memo } from "react";
import { ConceptType } from "../../models/common/CommonEnums";
import { ConceptAffect, ConceptComplexity } from "../../models/ConceptDataModel";

interface ComplexityViewerComponentProps {
    conceptType: ConceptType;
    complexity?: ConceptComplexity;
    affect?: ConceptAffect;

    yDef: AxisDefinition;
    xDef: AxisDefinition;
}

export interface AxisDefinition {
    minDescription: string;
    axisDescription: string;
    maxDescription: string;

}

interface WrapLevel {
    impactLevel: number;
    serverity: ConceptComplexity;
    affect: ConceptAffect;
}

const SolutionsMap = [
    { impactLevel: 2, serverity: ConceptComplexity.High, affect: ConceptAffect.All },
    { impactLevel: 3, serverity: ConceptComplexity.MediumHigh, affect: ConceptAffect.All },
    { impactLevel: 4, serverity: ConceptComplexity.Medium, affect: ConceptAffect.All },
    { impactLevel: 4, serverity: ConceptComplexity.LowMedium, affect: ConceptAffect.All },
    { impactLevel: 5, serverity: ConceptComplexity.Low, affect: ConceptAffect.All },

    { impactLevel: 2, serverity: ConceptComplexity.High, affect: ConceptAffect.Most },
    { impactLevel: 3, serverity: ConceptComplexity.MediumHigh, affect: ConceptAffect.Most },
    { impactLevel: 3, serverity: ConceptComplexity.Medium, affect: ConceptAffect.Most },
    { impactLevel: 4, serverity: ConceptComplexity.LowMedium, affect: ConceptAffect.Most },
    { impactLevel: 4, serverity: ConceptComplexity.Low, affect: ConceptAffect.Most },

    { impactLevel: 1, serverity: ConceptComplexity.High, affect: ConceptAffect.Many },
    { impactLevel: 2, serverity: ConceptComplexity.MediumHigh, affect: ConceptAffect.Many },
    { impactLevel: 3, serverity: ConceptComplexity.Medium, affect: ConceptAffect.Many },
    { impactLevel: 3, serverity: ConceptComplexity.LowMedium, affect: ConceptAffect.Many },
    { impactLevel: 4, serverity: ConceptComplexity.Low, affect: ConceptAffect.Many },

    { impactLevel: 1, serverity: ConceptComplexity.High, affect: ConceptAffect.Some },
    { impactLevel: 2, serverity: ConceptComplexity.MediumHigh, affect: ConceptAffect.Some },
    { impactLevel: 2, serverity: ConceptComplexity.Medium, affect: ConceptAffect.Some },
    { impactLevel: 3, serverity: ConceptComplexity.LowMedium, affect: ConceptAffect.Some },
    { impactLevel: 3, serverity: ConceptComplexity.Low, affect: ConceptAffect.Some },

    { impactLevel: 1, serverity: ConceptComplexity.High, affect: ConceptAffect.Few },
    { impactLevel: 1, serverity: ConceptComplexity.MediumHigh, affect: ConceptAffect.Few },
    { impactLevel: 1, serverity: ConceptComplexity.Medium, affect: ConceptAffect.Few },
    { impactLevel: 2, serverity: ConceptComplexity.LowMedium, affect: ConceptAffect.Few },
    { impactLevel: 2, serverity: ConceptComplexity.Low, affect: ConceptAffect.Few },
];

const ProblemsMap = [
    { impactLevel: 2, serverity: ConceptComplexity.High, affect: ConceptAffect.Few },
    { impactLevel: 3, serverity: ConceptComplexity.High, affect: ConceptAffect.Some },
    { impactLevel: 4, serverity: ConceptComplexity.High, affect: ConceptAffect.Many },
    { impactLevel: 4, serverity: ConceptComplexity.High, affect: ConceptAffect.Most },
    { impactLevel: 5, serverity: ConceptComplexity.High, affect: ConceptAffect.All },

    { impactLevel: 2, serverity: ConceptComplexity.MediumHigh, affect: ConceptAffect.Few },
    { impactLevel: 3, serverity: ConceptComplexity.MediumHigh, affect: ConceptAffect.Some },
    { impactLevel: 3, serverity: ConceptComplexity.MediumHigh, affect: ConceptAffect.Many },
    { impactLevel: 4, serverity: ConceptComplexity.MediumHigh, affect: ConceptAffect.Most },
    { impactLevel: 4, serverity: ConceptComplexity.MediumHigh, affect: ConceptAffect.All },

    { impactLevel: 1, serverity: ConceptComplexity.Medium, affect: ConceptAffect.Few },
    { impactLevel: 2, serverity: ConceptComplexity.Medium, affect: ConceptAffect.Some },
    { impactLevel: 3, serverity: ConceptComplexity.Medium, affect: ConceptAffect.Many },
    { impactLevel: 3, serverity: ConceptComplexity.Medium, affect: ConceptAffect.Most },
    { impactLevel: 4, serverity: ConceptComplexity.Medium, affect: ConceptAffect.All },

    { impactLevel: 1, serverity: ConceptComplexity.LowMedium, affect: ConceptAffect.Few },
    { impactLevel: 2, serverity: ConceptComplexity.LowMedium, affect: ConceptAffect.Some },
    { impactLevel: 2, serverity: ConceptComplexity.LowMedium, affect: ConceptAffect.Many },
    { impactLevel: 3, serverity: ConceptComplexity.LowMedium, affect: ConceptAffect.Most },
    { impactLevel: 3, serverity: ConceptComplexity.LowMedium, affect: ConceptAffect.All },

    { impactLevel: 1, serverity: ConceptComplexity.Low, affect: ConceptAffect.Few },
    { impactLevel: 1, serverity: ConceptComplexity.Low, affect: ConceptAffect.Some },
    { impactLevel: 1, serverity: ConceptComplexity.Low, affect: ConceptAffect.Many },
    { impactLevel: 2, serverity: ConceptComplexity.Low, affect: ConceptAffect.Most },
    { impactLevel: 2, serverity: ConceptComplexity.Low, affect: ConceptAffect.All },
];

export class ConceptComplexityService {
    currentMap = ProblemsMap;

    constructor(conceptType: ConceptType) {
        if (conceptType === ConceptType.Solutions) {
            this.currentMap = SolutionsMap;
        }
    }

    calculateConceptValue(xAxis: number, yAxis: number) {
        const foundItem = this.currentMap.find(map => map.serverity === yAxis && map.affect === xAxis);
        if (!foundItem) {
            return 0;
        }

        return foundItem.impactLevel;
    }
}

export const ComplexityViewerComponent: React.FunctionComponent<ComplexityViewerComponentProps>
    = memo(
        function ComplexityViewer({ conceptType, complexity, affect, xDef, yDef }) {

            const RenderViewerItem = (impactLevel: WrapLevel) => {

                const selectedStyle =
                    (complexity === impactLevel.serverity &&
                        affect === impactLevel.affect)
                        ? " item-selected"
                        : "";

                return (
                    <div className={"fw-med-500 viewer-item " + " item-" + impactLevel.impactLevel + selectedStyle}>
                        {impactLevel.impactLevel}
                    </div>
                )

            };

            const mappingList = conceptType === ConceptType.Problems ? ProblemsMap : SolutionsMap;


            return (
                <React.Fragment>
                    <div className="d-flex">
                        <div className="heatmap-chart-y-axis fw-xsmall-400 justify-content-between">
                            <div>{yDef.minDescription}</div>
                            <div className="fw-xsmall-500">{yDef.axisDescription}</div>
                            <div>{yDef.maxDescription}</div>
                        </div>
                        <div style={{ marginRight: "-7px", zIndex: 100 }}>
                            <svg className="pe-none" width="18" height="200" role="img" fill="black"><use xlinkHref="#v-arrow"></use></svg>
                        </div>
                        <div className="heatmap-chart flex-wrap">
                            {
                                mappingList.map(pMap => {
                                    return <RenderViewerItem key={crypto.randomUUID()} impactLevel={pMap.impactLevel} serverity={pMap.serverity} affect={pMap.affect} />
                                })
                            }
                            {/* <RenderViewerItem impactLevel={2} serverity={ConceptComplexity.High} affect={ConceptAffect.Few} />
                            <RenderViewerItem impactLevel={3} serverity={ConceptComplexity.High} affect={ConceptAffect.Some} />
                            <RenderViewerItem impactLevel={4} serverity={ConceptComplexity.High} affect={ConceptAffect.Many} />
                            <RenderViewerItem impactLevel={4} serverity={ConceptComplexity.High} affect={ConceptAffect.Most} />
                            <RenderViewerItem impactLevel={5} serverity={ConceptComplexity.High} affect={ConceptAffect.All} />

                            <RenderViewerItem impactLevel={2} serverity={ConceptComplexity.MediumHigh} affect={ConceptAffect.Few} />
                            <RenderViewerItem impactLevel={3} serverity={ConceptComplexity.MediumHigh} affect={ConceptAffect.Some} />
                            <RenderViewerItem impactLevel={3} serverity={ConceptComplexity.MediumHigh} affect={ConceptAffect.Many} />
                            <RenderViewerItem impactLevel={4} serverity={ConceptComplexity.MediumHigh} affect={ConceptAffect.Most} />
                            <RenderViewerItem impactLevel={4} serverity={ConceptComplexity.MediumHigh} affect={ConceptAffect.All} />

                            <RenderViewerItem impactLevel={1} serverity={ConceptComplexity.Medium} affect={ConceptAffect.Few} />
                            <RenderViewerItem impactLevel={2} serverity={ConceptComplexity.Medium} affect={ConceptAffect.Some} />
                            <RenderViewerItem impactLevel={3} serverity={ConceptComplexity.Medium} affect={ConceptAffect.Many} />
                            <RenderViewerItem impactLevel={3} serverity={ConceptComplexity.Medium} affect={ConceptAffect.Most} />
                            <RenderViewerItem impactLevel={4} serverity={ConceptComplexity.Medium} affect={ConceptAffect.All} />

                            <RenderViewerItem impactLevel={1} serverity={ConceptComplexity.LowMedium} affect={ConceptAffect.Few} />
                            <RenderViewerItem impactLevel={2} serverity={ConceptComplexity.LowMedium} affect={ConceptAffect.Some} />
                            <RenderViewerItem impactLevel={2} serverity={ConceptComplexity.LowMedium} affect={ConceptAffect.Many} />
                            <RenderViewerItem impactLevel={3} serverity={ConceptComplexity.LowMedium} affect={ConceptAffect.Most} />
                            <RenderViewerItem impactLevel={3} serverity={ConceptComplexity.LowMedium} affect={ConceptAffect.All} />

                            <RenderViewerItem impactLevel={1} serverity={ConceptComplexity.Low} affect={ConceptAffect.Few} />
                            <RenderViewerItem impactLevel={1} serverity={ConceptComplexity.Low} affect={ConceptAffect.Some} />
                            <RenderViewerItem impactLevel={1} serverity={ConceptComplexity.Low} affect={ConceptAffect.Many} />
                            <RenderViewerItem impactLevel={2} serverity={ConceptComplexity.Low} affect={ConceptAffect.Most} />
                            <RenderViewerItem impactLevel={2} serverity={ConceptComplexity.Low} affect={ConceptAffect.All} /> */}
                        </div>
                    </div>
                    <div style={{ marginLeft: "11px", marginTop: "-11px" }}>
                        <svg className="pe-none" width="213" height="18" role="img" fill="black"><use xlinkHref="#h-arrow"></use></svg>
                    </div>
                    <div style={{ paddingLeft: "11px" }}
                        className="fw-xsmall-400 d-flex justify-content-between mx-3">
                        <div>{xDef.minDescription}</div>
                        <div className="fw-xsmall-500">{xDef.axisDescription}</div>
                        <div>{xDef.maxDescription}</div>
                    </div>
                </React.Fragment>
            )
        }
    )