import { Subject } from "rxjs";
import { IDataStore } from "../../models/common/IDataStore";
import { INotificationHub } from "../../models/common/INotificationHub";
import { CommentDeleteAction } from "../../models/communication/actions/CommentDeleteAction";
import { CommentLoadAction } from "../../models/communication/actions/CommentLoadAction";
import { CommentUpdateAction } from "../../models/communication/actions/CommentUpdateAction";
import { CommentDataModel } from "../../models/data/CommentDataModel";
import { IServerMessage, MessageType } from "../../models/message/IServerMessage";
import { MessageService } from "../messaging/MessageService";

export class CommentsDataStore implements IDataStore, INotificationHub {

    commentsSubject = new Subject();
    storageState: CommentDataModel[] = [];
    newMessage: string = "";

    subscribe(messageReceiveAction: any) {
        return this.commentsSubject.subscribe(messageReceiveAction);
    }

    sendMessage(fieldName: string, fieldValue: string) {
        this.newMessage = fieldValue;
    }

    async initialize(conceptID: string) {
        return MessageService.sendMessage({
            messageType: MessageType.ConceptCommentLoad,
            requestData: {
                conceptID: conceptID
            } as CommentLoadAction

        } as IServerMessage);
    }

    load(commentList: CommentDataModel[]) {
        this.storageState = [...commentList];

        this.commentsSubject.next(this.storageState);
    }

    updateComment(comment: CommentDataModel) {

        const messageIndex = this.storageState.findIndex(c => c.conceptCommentID === comment.conceptCommentID);

        if (messageIndex > - 1) {

            const updateMessage = {
                ...this.storageState[messageIndex],
                displayTime: comment.displayTime,
                commentContent: comment.commentContent,
                savePending: false
            } as CommentDataModel;

            this.storageState[messageIndex] = updateMessage;
            this.storageState = [...this.storageState];
            this.commentsSubject.next(this.storageState);
        }

    }


    async Commit(conceptID: string, byAccountID: string, byAccountDisplayName: string) {

        if (!this.newMessage) {
            return;
        }

        const newComment = {

            conceptCommentID: crypto.randomUUID(),
            conceptID: conceptID,
            commentContent: this.newMessage,

            byAccountID: byAccountID,
            byUserName: byAccountDisplayName,
            displayTime: "",
            message: this.newMessage,
            savePending: true
        } as CommentDataModel;

        this.newMessage = "";

        return MessageService.sendMessage({
            messageType: MessageType.ConceptCommentUpdate,
            requestData: {
                commentData: newComment
            } as CommentUpdateAction

        } as IServerMessage).then(() => {

            const newList = [newComment, ...this.storageState];

            this.storageState = newList;
            this.commentsSubject.next(this.storageState);
        });
    }

    async deleteComment(commentToDelete: CommentDataModel) {
        return MessageService.sendMessage({
            messageType: MessageType.ConceptCommentDelete,
            requestData: {
                commentToDelete: commentToDelete
            } as CommentDeleteAction

        } as IServerMessage)
            .then(() => {
                const newList = this.storageState.filter(c => c.conceptCommentID !== commentToDelete.conceptCommentID);
                this.storageState = [...newList];
                this.commentsSubject.next(newList);
            })
    }
}