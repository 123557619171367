import _ from "lodash";
import { memo, useEffect, useState } from "react";
import { ConceptType } from "../../../../models/common/CommonEnums";
import { ConceptAffect, ConceptComplexity, ConceptComplexityLabel, ConceptDataModel } from "../../../../models/ConceptDataModel";
import { MessageHubContext } from "../../../../services/notification/MessageHubHandler";
import { ConceptEditorStore } from "../../../../services/stores/ConceptEditorStore";
import { ComplexityViewerComponent } from "../../../elements/ComplexityViewerComponent";
import { ImplementationLevelComponent } from "../../../elements/ImplementationLevelComponent";
import { SelectorComponent } from "../../../elements/SelectorComponent";

interface SolutionImplementationValueComponentProps {
    conceptStore: ConceptEditorStore;
}


interface ImpactState {
    conceptComplexity: ConceptComplexity;
    problemImpact: ConceptComplexity;
    currentAffect: ConceptAffect;
    currentImpact: number;
}

export const SolutionImplementationValueComponent: React.FunctionComponent<SolutionImplementationValueComponentProps>
    = memo(
        function SolutionImplementationValue({ conceptStore }) {

            const [currentImpact, setCurrentImpact] = useState<ImpactState>();

            useEffect(() => {
                const hubContext = MessageHubContext()
                    .Subscribe(conceptStore, (conceptModel: ConceptDataModel) => {

                        if (!conceptModel.solutionData) {
                            return;
                        }

                        const effortLevel = conceptModel.solutionData.conceptEffort;
                        const problemImpact = conceptModel.solutionData.conceptImpact ?? ConceptComplexity.NOTDEFINED;

                        const newValue = {
                            conceptComplexity: effortLevel,
                            problemImpact: problemImpact,
                            currentAffect: (problemImpact as any) as ConceptAffect,
                            currentImpact: conceptModel.conceptValue
                        } as ImpactState;

                        if (_.isEqual(currentImpact, newValue)) {
                            return;
                        }

                        setCurrentImpact(newValue);

                    });


                return () => hubContext.Dispose();
            }, [currentImpact]);

            const RenderImplementationLevel = () => {
                if (!currentImpact || currentImpact.problemImpact === ConceptComplexity.NOTDEFINED) {
                    return (
                        <div className="bg-white m-2 p-3 rounded-2 fw-reg-500 text-center">
                            Link a problem to calculate the implementation value
                        </div>
                    )
                }

                return (
                    <div className="pt-2">
                        <ImplementationLevelComponent currentLevel={currentImpact?.currentImpact ?? 0} conceptType={ConceptType.Solutions} />
                    </div>
                );
            }

            const GetViewerStyle = () => {
                if (!currentImpact || currentImpact.problemImpact === ConceptComplexity.NOTDEFINED) {
                    return "opacity-25"
                }

                return "";
            }


            return (
                <div className="concept-bg-lighter rounded-2">
                    <div className="pt-3 d-flex flex-column align-items-center">
                        <div className="fw-concept fw-med-500">Implementation Value</div>
                        <RenderImplementationLevel />
                    </div>

                    <div className="p-3" data-theme="solution-map">
                        <div className={GetViewerStyle()}>
                            <ComplexityViewerComponent
                                conceptType={ConceptType.Solutions}
                                complexity={currentImpact?.conceptComplexity}
                                affect={currentImpact?.currentAffect}

                                xDef={{
                                    axisDescription: "Effort",
                                    minDescription: "High",
                                    maxDescription: "Low"
                                }}
                                yDef={{
                                    axisDescription: "Impact",
                                    minDescription: "Small",
                                    maxDescription: "Big"
                                }}

                            />
                        </div>

                        <div className="pt-3">
                            <div className="form-control opacity-50" aria-disabled="true">Impact: {(currentImpact ? ConceptComplexityLabel.get(currentImpact.problemImpact) : "")}</div>
                        </div>
                        <div className="pt-3">
                            <SelectorComponent
                                conceptStore={conceptStore}
                                fieldName="solutionData.conceptEffort"
                                description="Effort: "
                                currentValue={currentImpact?.conceptComplexity}
                                selectionOptions={[
                                    { selectorID: ConceptComplexity.Low, selectorText: "Low" },
                                    { selectorID: ConceptComplexity.LowMedium, selectorText: "Medium/Low" },
                                    { selectorID: ConceptComplexity.Medium, selectorText: "Medium" },
                                    { selectorID: ConceptComplexity.MediumHigh, selectorText: "Medium/High" },
                                    { selectorID: ConceptComplexity.High, selectorText: "High" },
                                ]}
                            />
                        </div>
                        <div className="pt-4 fw-xsmall-400">
                            * Impact is automatically inherited from the attached problem. If multiple problems are attached, it will sum the Scale of Problem values.
                        </div>
                    </div>
                </div>
            );
        }
    )