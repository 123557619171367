import { useEffect } from "react";
import { IHubSubcription, MessageHubContext } from "../../../services/notification/MessageHubHandler";
import { MessageHubOwner } from "../../../services/messaging/MessageHubOwner";
import { MessageType } from "../../../models/message/IServerMessage";
import { FolderLoadActionResponse } from "../../../models/communication/actions/FolderLoadAction";
import { FolderType } from "../../../models/data/FolderDataModel";
import { GlobalNotifications } from "../../../models/common/CommonEnums";
import { GlobalNotificationHub } from "../../../services/GlobalMessageHub";

export const FeedbackCommandHandler = () => {


    useEffect(() => {
        const hubContext = MessageHubContext()
            .ListenMessage(MessageHubOwner, {
                MessageType: MessageType.FolderLoaded, OnReceive: (folders: FolderLoadActionResponse) => {                    
                    hubContext.Dispose();

                    const feedbackFolder = folders.folderList.filter(x => x.folderType === FolderType.SystemQikFeedbackForm);
                    if (feedbackFolder.length > 0) {
                        setTimeout(() => {
                            GlobalNotificationHub.sendMessageWithData(GlobalNotifications.FolderSelectChange, feedbackFolder[0]);
                        }, 250)

                    }

                }
            } as IHubSubcription);


        return () => hubContext.Dispose();

    }, []);

    return <></>
}