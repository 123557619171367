import { AttachmentDataModel, AttachmentType } from "../../models/data/AttachmentDataModel";
import { AttachmentHelperExtension } from "../helpers/AttachmentHelperExtension";
import { ConceptAttachmentStore } from "../stores/ConceptAttachment/ConceptAttachmentStore";
import { FailureReasons, UploadService } from "./UploadService";
import { UploadStore } from "./UploadStore";

export interface UploadBulkServiceProps {
    uploadStore: UploadStore;
    attachmentStore: ConceptAttachmentStore;
    fileTypesAllowed?: string;
    filesToBeUploaded: File[];
    availableSlots: number;
    maxFileSize: number;
}

interface UploadResult {
    uploadingFile: File;
    attachment: AttachmentDataModel;
    failureReason?: FailureReasons;
}

export class UploadBulkService {
    props: UploadBulkServiceProps;

    currentUploaders: UploadService[] = [];
    availableSlots: number = 0;
    uploadResult: UploadResult[] = [];

    constructor(props: UploadBulkServiceProps) {
        this.uploadAttachmentList = this.uploadAttachmentList.bind(this);
        this.props = props;
        this.availableSlots = this.props.availableSlots;
    }

    async uploadAttachmentList() {
        const uploaderWorker = new UploadService({ ...this.props, disableNotification: true });

        const modifier = 1.0 / this.props.filesToBeUploaded.length;
        uploaderWorker.uploadModifier = modifier;

        this.currentUploaders.push(uploaderWorker);

        let currentPromise = Promise.resolve().then(() => { });

        this.props.filesToBeUploaded.forEach(uploadingFile => {

            currentPromise = currentPromise.then(() => {

                if (this.availableSlots <= 0) {
                    this.uploadResult.push({
                        uploadingFile: uploadingFile,
                        attachment: {} as AttachmentDataModel,
                        failureReason: FailureReasons.MaxUploadReached
                    });
                    return;
                }


                this.availableSlots--;

                const uploadUID = crypto.randomUUID();

                const newAttachment = {
                    attachmentID: uploadUID,

                    attachmentType: AttachmentType.Screenshot,

                    displayName: uploadingFile.name,
                    fileName: uploadingFile.name,
                    fileContentType: uploadingFile.type,
                    fileSize: (uploadingFile.size / 1024 / 1024),
                    fileExtension: AttachmentHelperExtension.GetFileExtension(uploadingFile.name),
                    uploadFileName: AttachmentHelperExtension.GetFileName(uploadingFile.name, uploadUID),

                    isNew: true

                } as AttachmentDataModel;

                return uploaderWorker.uploadAttachment(newAttachment, uploadingFile)
                    .then(() => {
                        this.uploadResult.push({
                            uploadingFile: uploadingFile,
                            attachment: newAttachment
                        });
                    })
                    .catch((failureReason: FailureReasons) => {
                        this.uploadResult.push({
                            uploadingFile: uploadingFile,
                            attachment: newAttachment,
                            failureReason: failureReason
                        });

                        this.availableSlots++;
                    })
                    .finally(() => {
                        uploaderWorker.uploadModifier += modifier;
                    });
            });
        });

        return currentPromise

            .finally(() => {
                uploaderWorker.dispose();

                return new Promise((resolve, reject) => {
                    const successfulUploads = this.uploadResult.filter(upload => !upload.failureReason);
                    const failedfulUploads = this.uploadResult.filter(upload => upload.failureReason);

                    if (successfulUploads.length > 0) {
                        this.props.attachmentStore.addNewAttachmentList(successfulUploads.map(upload => upload.attachment));
                    }

                    if (failedfulUploads.length > 0) {
                        reject(Array.from(new Set(failedfulUploads.map(u => u.failureReason))));
                        return;
                    }

                    resolve(true);
                });
            });
    }

    dispose() {
        if (this.currentUploaders && this.currentUploaders.length > 0) {
            this.currentUploaders.forEach(uploader => uploader.dispose());
            this.currentUploaders = [];
        }
    }
}