import React, { memo, useEffect, useMemo } from "react"
import { IHubSubcription, MessageHubContext, MessageHubHandler } from "../../notification/MessageHubHandler";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { Subject } from "rxjs";
import { MessageService } from "../../messaging/MessageService";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { debounce } from "lodash";
import { WorkspaceTeamLoadActionResponse } from "../../../models/communication/actions/WorkspaceTeamLoadAction";
import { WorkspaceMemberDataModel } from "../../../models/data/WorkspaceMemberDataModel";
import { MessageHubOwner } from "../../messaging/MessageHubOwner";
import { WorkspaceTeamRemoveAction, WorkspaceTeamRemoveActionResponse } from "../../../models/communication/actions/WorkspaceTeamRemoveAction";
import { GlobalNotifications } from "../../../models/common/CommonEnums";
import { GlobalNotification } from "../../GlobalMessageHub";
import { WorkspaceInvite } from "./WorkspaceTeamInviteStore";
import { AccountAccessType } from "../../../models/data/WorkspaceDataModel";

export interface WorkspaceTeamStoreState {
    members: WorkspaceMemberDataModel[]
}


export class WorkspaceTeamStore implements INotificationHub {
    workspaceTeamSubject = new Subject();
    storeState = { members: [] } as WorkspaceTeamStoreState;

    subscribe(onMessageReceive: any) {
        return this.workspaceTeamSubject.subscribe(onMessageReceive);
    }


    async loadWorkspaceMembers() {
        return MessageService.sendMessage({
            messageType: MessageType.WorkspaceMembersLoad,
            requestData: {}
        } as IServerMessage);
    }

    OnMembersLoaded(memberList: WorkspaceMemberDataModel[]) {

        this.storeState.members = [...memberList];
        this.workspaceTeamSubject.next(this.storeState);
    }

    async removeMember(workspaceMember: WorkspaceMemberDataModel) {
        if (!workspaceMember.teamMemberID) {
            return;
        }

        return MessageService.sendMessage({
            messageType: MessageType.WorkspaceMembersRemove,
            requestData: { teamMemberID: workspaceMember.teamMemberID } as WorkspaceTeamRemoveAction
        } as IServerMessage);
    }

    memberRemoved(accountID: string) {
        if (!accountID) {
            return;
        }

        const filterMembers = this.storeState.members.filter(m => m.teamMemberID !== accountID);
        this.storeState = { ...this.storeState, members: [...filterMembers] };
        this.workspaceTeamSubject.next(this.storeState);
    }

    memberInvite(newInvite: WorkspaceInvite) {

        var alreadyInvited = this.storeState.members.findIndex(c => c.emailAddress.toLowerCase() === newInvite.emailAddress.toLowerCase());
        if (alreadyInvited > -1) {
            return;
        }

        const newInvitation = {
            teamMemberID: newInvite.teamMemberID,
            emailAddress: newInvite.emailAddress,
            accountAccessType: AccountAccessType.Invited
        } as WorkspaceMemberDataModel;

        this.storeState = {
            ...this.storeState,
            members: [...this.storeState.members, newInvitation]
        };

        this.workspaceTeamSubject.next(this.storeState);
    }
}

interface WorkspaceTeamStoreElementProps {
    workspaceTeamStoreLoad: (store: WorkspaceTeamStore, hubContext: MessageHubHandler) => void;
}

export const WorkspaceTeamStoreElement: React.FunctionComponent<WorkspaceTeamStoreElementProps>
    = memo(function WorkspaceTeamStoreElementImp({ workspaceTeamStoreLoad }) {

        const debouncedChangeHandler = useMemo(
            () => debounce(async (store: WorkspaceTeamStore) => {
                await store.loadWorkspaceMembers();
            }, 150)
            , []);

        useEffect(() => {
            return () => debouncedChangeHandler.cancel();
        }, [debouncedChangeHandler]);


        useEffect(() => {
            const store = new WorkspaceTeamStore();

            var hubContext = MessageHubContext()
                .ListenMessage(MessageHubOwner, {
                    MessageType: MessageType.WorkspaceMembersLoaded,
                    OnReceive: (workspaceTeamResponse: WorkspaceTeamLoadActionResponse) => {
                        store.OnMembersLoaded(workspaceTeamResponse.memberList);
                    }
                } as IHubSubcription)
                .ListenMessage(MessageHubOwner, {
                    MessageType: MessageType.WorkspaceMembersRemoved,
                    OnReceive: (workspaceMemberRemoved: WorkspaceTeamRemoveActionResponse) => {
                        store.memberRemoved(workspaceMemberRemoved.teamMemberID);
                    }
                } as IHubSubcription)
                .ListenGlobalNotification([GlobalNotifications.WorspaceMemberInvited], (notification: GlobalNotification) => {

                    const newInvite = notification.notificationData as WorkspaceInvite;

                    if (notification.notificationData) {
                        store.memberInvite(newInvite);
                    }

                });

            ;

            workspaceTeamStoreLoad(store, hubContext);

            debouncedChangeHandler(store);
            return () => hubContext.Dispose();
        }, []);

        return <React.Fragment></React.Fragment>
    })
