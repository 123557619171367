import { ConceptType } from "./common/CommonEnums";
import { AttachmentDataModel } from "./data/AttachmentDataModel";
import { ConceptLinkDataModel } from "./data/ConceptLinkDataModel";
import { FolderDataModel } from "./data/FolderDataModel";
import { TagDataModel } from "./data/TagDataModel";
import { IMessageData } from "./message/IServerMessage";

export class ConceptDataModel implements IMessageData {
  //    clientConceptID: string = "";

  conceptID: string = "";
  conceptType: ConceptType = ConceptType.Error;
  title: string = "";
  description: string = "";

  creationDateDisplay: string = "";

  conceptValue: number = 0;

  insightData: InsightDataModel = {} as InsightDataModel;
  problemData: ProblemDataModel = {} as ProblemDataModel;
  solutionData: SolutionDataModel = {} as SolutionDataModel;
  //["customerDetail.firstName"]: ConceptCustomerDetailModel | undefined;
  // customerName: string = "";
  // customerEmail: string = "";
  // customerPhone: string = "";
  // customerUserID: string = "";

  tagList: TagDataModel[] = [];

  folderList: FolderDataModel[] = [];

  conceptLinkList: ConceptLinkDataModel[] = [];

  conceptLinkSummary: ConceptLinksSummaryModel = {} as ConceptLinksSummaryModel;

  conceptAttachmentList: AttachmentDataModel[] = [];

  sortData: SortDataModel = {} as SortDataModel

  //client only
  savePending: boolean = false;
}

export class InsightDataModel {
  customerName: string = "";
  customerEmail: string = "";
  customerPhone: string = "";
  customerUserID: string = "";
}

export interface ConceptLinksSummaryModel {
  insightsCount: number;
  problemsCount: number;
  solutionsCount: number;
}

export interface ProblemDataModel {
  problemDetails: string;
  useFreeText: boolean;
  whenText: string;
  wantText: string;
  soText: string;
  becauseText: string;

  problemCause: string;

  conceptSeverity: ConceptComplexity;
  conceptPeopleAffected: ConceptAffect;
}

export interface SolutionDataModel {
  conceptEffort: ConceptComplexity;
  conceptImpact: ConceptComplexity;

  useFreeText: boolean;
  believeText: string;
  resultText: string;
  evidenceText: string;

  solutionDetails: string;
  effortDetails: string;
}

export interface SortDataModel {
  folderID: string;
  sortIndex: number;
}

export enum ConceptComplexity {
  NOTDEFINED = 0,
  Low = 1,
  LowMedium = 2,
  Medium = 3,
  MediumHigh = 4,
  High = 5
}

export enum ConceptAffect {
  Few = 1,
  Some = 2,
  Many = 3,
  Most = 4,
  All = 5
}


export const ConceptComplexityLabel = new Map<ConceptComplexity, string>([
  [ConceptComplexity.High, "High"],
  [ConceptComplexity.MediumHigh, "Medium/High"],
  [ConceptComplexity.Medium, "Medium"],
  [ConceptComplexity.LowMedium, "Medium/Low"],
  [ConceptComplexity.Low, "Low"],
  [ConceptComplexity.NOTDEFINED, "n/a"],
]);

export const ConceptAffectLabel = new Map<ConceptAffect, string>([
  [ConceptAffect.All, "All"],
  [ConceptAffect.Most, "Most"],
  [ConceptAffect.Many, "Many"],
  [ConceptAffect.Some, "Some"],
  [ConceptAffect.Few, "Few"],
]);