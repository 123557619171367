import { useState } from "react";

interface ExamplesComponentProps {
    exampleDescription: string;
    exampleList: string[];
}

export const ExamplesComponent = (props: ExamplesComponentProps) => {
    const [displayExample, setDisplayExample] = useState(false);
    const examplesUID = "examples-" + crypto.randomUUID();

    return (
        <div className="fw-small-400 pt-1 d-flex flex-column">
            {
                (props.exampleDescription) &&
                <div>
                    {props.exampleDescription}
                </div>
            }
            <div id={examplesUID} className="collapse">
                <ul className="pt-1 mb-1">
                    {
                        props.exampleList.map(value => {
                            return <li key={value}>{value}</li>
                        })
                    }
                </ul>
            </div>
            <div>
                <div className="concept-link" data-bs-toggle="collapse" data-bs-target={"#" + examplesUID} role="button" aria-expanded="false" aria-controls={examplesUID} onClick={() => setDisplayExample(!displayExample)}>
                    {!displayExample ? "Show examples" : "Hide examples"}
                </div>
            </div>

        </div>
    )
}