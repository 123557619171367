import { GlobalNotifications } from "../../models/common/CommonEnums";
import { IDataStore } from "../../models/common/IDataStore";
import { AlertTimerWarningNotificationModel } from "../../models/communication/internal/AlertTimerNotificationModel";
import { AttachmentDataModel, AttachmentType } from "../../models/data/AttachmentDataModel";
import { GlobalNotificationHub } from "../GlobalMessageHub";
import { AttachmentHelperExtension } from "../helpers/AttachmentHelperExtension";
import { LogCollector } from "../logger/LogCollector";
import { ConceptAttachmentStore } from "../stores/ConceptAttachment/ConceptAttachmentStore";
import { FailureReasons, UploadService } from "../upload/UploadService";
import { UploadStore } from "../upload/UploadStore";


export class ImageScribbleService implements IDataStore {
    //not in use
    storageState = {
        currentColor: "#FF0000"
    };

    onCustomSave: () => void = () => { };
    onChangeColor: (color: string) => void = () => { };
    onScribbledUpdated: () => void = () => { };
    onScribbledSaveFailure: () => void = () => { };

    uploadStore: UploadStore = new UploadStore();
    attachmentStore: ConceptAttachmentStore;
    attachmentModel: AttachmentDataModel;

    overrideDrawingIntegration(triggerSave: () => void, changeColor: (color: string) => void, scribbledUpdated: () => void) {
        this.onCustomSave = triggerSave;
        this.onChangeColor = changeColor;

        this.onScribbledUpdated = scribbledUpdated;
    }

    sendMessage(fieldName: string, fieldValue: string) {
        if (fieldName === "lineColour") {
            this.storageState.currentColor = fieldValue;
            this.onChangeColor(fieldValue);
        }
    }

    triggerSave() {
        this.uploadStore.updateProgress(1);
        this.onCustomSave();
    }

    constructor(attachmentStore: ConceptAttachmentStore, selectedAttachment: AttachmentDataModel) {
        this.attachmentStore = attachmentStore;
        this.attachmentModel = selectedAttachment;
    }

    async saveImage(blob: Blob) {
        const newImageFile = new File([blob], this.attachmentModel.displayName, {
            type: blob.type
        });

        const uploadUID = crypto.randomUUID();
        const uploadingFile = newImageFile;

        const newAttachment = {
            attachmentID: uploadUID,

            attachmentType: AttachmentType.Screenshot,

            displayName: uploadingFile.name,
            fileName: uploadingFile.name,
            fileContentType: uploadingFile.type,
            fileSize: (uploadingFile.size / 1024 / 1024),
            fileExtension: AttachmentHelperExtension.GetFileExtension(uploadingFile.name),
            uploadFileName: AttachmentHelperExtension.GetFileName(uploadingFile.name, uploadUID),

            attachmentOrder: this.attachmentModel.attachmentOrder,

            isNew: true

        } as AttachmentDataModel;

        const uploadService = new UploadService({
            uploadStore: this.uploadStore,
            attachmentStore: this.attachmentStore,
            maxFileSize: 10,
            disableNotification: true
        });

        return uploadService.uploadAttachment(newAttachment, uploadingFile)
            .then(async () => {

                this.attachmentStore.replaceAttachment(this.attachmentModel, newAttachment);
                await this.attachmentStore.deleteAttachment(this.attachmentModel);

                GlobalNotificationHub.sendMessage(GlobalNotifications.ImageScribbleUpdated);
            })
            .then(() => {
                this.onScribbledUpdated();
            }).catch((reason: FailureReasons) => {
                switch (reason) {
                    case FailureReasons.FileTooBig:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertTimerError, { warningMessage: "The file must be smaller than 10MB." } as AlertTimerWarningNotificationModel);
                        break;
                    case FailureReasons.NoneSpecified:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertTimerError, { warningMessage: "Could not upload the file. Try again later." } as AlertTimerWarningNotificationModel);
                        break;
                    case FailureReasons.FileMustBeImage:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertTimerError, { warningMessage: "You must upload an image." } as AlertTimerWarningNotificationModel);
                        break;
                    case FailureReasons.NotEnoughSpaceToUpload:
                        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.AlertTimerError, { warningMessage: "Total file storage reached for the account." } as AlertTimerWarningNotificationModel);
                        break;
                }

                this.onScribbledSaveFailure();
            })
            .finally(() => {
                LogCollector.LogMessage("...................... upload completed");
                uploadService.dispose();

                this.uploadStore.updateProgress(0);
            });
    }
}