import React from "react";
import { ModalDefinitionModel, ModalSize } from "../ModalComponent";
import { PreReleaseModalComponent } from "../../notification/PreReleaseComponent";

export const PreReleaseHandler = () => {

    return {
        modalSize: ModalSize.Xlarge,
        modalPosition: "modal-dialog-centered",
        modalContent: (
            <PreReleaseModalComponent />
        )
    } as ModalDefinitionModel;
}