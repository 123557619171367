import { Subject } from "rxjs";
import { ConceptType } from "../../../models/common/CommonEnums";
import { IDataStore } from "../../../models/common/IDataStore";
import { INotificationHub } from "../../../models/common/INotificationHub";
import { FolderRenameAction } from "../../../models/communication/actions/FolderRenameAction";
import { FolderEditorModel, FolderSaveAction } from "../../../models/communication/actions/FolderSaveAction";
import { FolderDataModel, FolderType } from "../../../models/data/FolderDataModel";
import { IServerMessage, MessageType } from "../../../models/message/IServerMessage";
import { MessageService } from "../../messaging/MessageService";

const initialFolderState = {
    folderName: "",
    folderType: FolderType.CustomFolder
} as FolderDataModel;

const folderInitialState = {
    folder: initialFolderState,

    //conceptTypeAllowed: [] as ConceptType[],
    insightSelected: false,
    problemSelected: false,
    solutionSelected: false,
} as FolderEditorModel;


export class FolderEditorStore implements IDataStore, INotificationHub {
    folderEditorSubject = new Subject();
    storageState: FolderEditorModel = folderInitialState;

    initialize(conceptType: ConceptType) {

        this.storageState = {
            folder: {
                ...initialFolderState,
                folderID: crypto.randomUUID(),
            },

            insightSelected: (conceptType === ConceptType.Insights),
            problemSelected: (conceptType === ConceptType.Problems),
            solutionSelected: (conceptType === ConceptType.Solutions),
        };
        this.folderEditorSubject.next(this.storageState);
    }

    load(currentFolder: FolderDataModel) {
        this.storageState = {
            ...this.storageState,
            folder: {
                ...currentFolder
            }
        }

        this.folderEditorSubject.next(this.storageState);
    }


    subscribe(onMessageReceived: any) {
        return this.folderEditorSubject.subscribe(onMessageReceived);
    }

    notify() {
        this.folderEditorSubject.next(this.storageState);
    }

    sendMessage(fieldName: string, fieldValue: string) {
        const fieldNames = fieldName.split(".");

        if (fieldNames.length === 1) {
            this.storageState = { ...this.storageState, [fieldName]: (fieldValue === "true") };
        }
        else {
            // Updates the temporary storage
            const parent = fieldNames[0];
            const field = fieldNames[1];

            this.storageState = {
                ...this.storageState,
                [parent]: {
                    ...(this.storageState as any)[parent],
                    [field]: fieldValue
                }
            };
        }

        // Notifies everyone
        this.folderEditorSubject.next(this.storageState);
    }

    async saveFolder(currentConceptType: ConceptType) {
        // this.storageState = {
        //     ...this.storageState,
        //     workspaceID: crypto.randomUUID(),
        //     savePending: true
        // };

        return MessageService.sendMessage({
            messageType: MessageType.FolderSave,
            requestData: { request: this.storageState, conceptType: currentConceptType } as FolderSaveAction
        } as IServerMessage).then(() => {

            //TODO:
            //workspaceStore.addNotSaved(this.storageState)
        });
    }

    async saveRename() {
        return MessageService.sendMessage({
            messageType: MessageType.FolderRename,
            requestData: {
                folder: {
                    ...this.storageState.folder
                }
            } as FolderRenameAction
        } as IServerMessage);
    }

}