import { useNavigate } from "react-router-dom"
import { RecentFeedbackDisplay } from "./RecentFeedbackDisplay"
import { GlobalStore } from "../../../services/stores/GlobalStore";
import { VoyceQueryString } from "../../../models/common/CommonEnums";
import { FeedbackGraphComponent } from "../../../components/feedback/components/FeedbackGraphComponent";

export const RecentFeedbackSection = () => {
    const navigate = useNavigate();

    const navigateToFeedback = () => {
        const navigateTo = "/w/" + GlobalStore.getWorkspaceCodeIfAvailable() + "/insights/" + `?${VoyceQueryString.SpecialFolder}=${VoyceQueryString.FeedbackFolder}`
        navigate(navigateTo);
    }

    return (
        <div className="recent-feedback-section p-3 dashboard-card-override">
            <div className="d-flex align-items-top">
                <div className="flex-fill">
                    <div className="fw-dark fw-xlarge-700">
                        Customer Feedback
                    </div>
                    <div className="pt-2 pe-3 fw-small-400 fw-secondary">
                        Real customer feedback on how you can improve your product offering through asking the 'Magic Question'.
                    </div>
                </div>
                <div>
                    <button type="button" className="btn btn-outline-primary btn-concept-outline btn-medium" onClick={navigateToFeedback}>Go to feedback</button>
                </div>
            </div>
            <div className="pt-3">
                <FeedbackGraphComponent />
            </div>
            <div className="pt-3">
                <RecentFeedbackDisplay />
            </div>
            <div className="pt-2">
                <a className="concept-link link-insight"
                    onClick={navigateToFeedback}>View all Customer Feedback</a>
            </div>
        </div>
    )
}