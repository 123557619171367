import { CSSProperties, useEffect, useState } from "react";
import { ConceptDataModel } from "../../models/ConceptDataModel";
import { HelperExtension } from "../../services/HelperExtension";
import { MessageHubContext } from "../../services/notification/MessageHubHandler";
import { ConceptEditorStore } from "../../services/stores/ConceptEditorStore";

interface RangeSelectorComponentProps {
    header: string;

    selectionList: SelectorItem[];
    fieldName: string;
    conceptStore: ConceptEditorStore;
}

interface SelectorItem {
    description: string;
    value: any;
    positionFix?: string;
}

export const RangeSelectorComponent = (props: RangeSelectorComponentProps) => {

    const [currentValue, setCurrentValue] = useState(0);
    const [stepValue] = useState(100 / (props.selectionList.length - 1));

    useEffect(() => {
        const hubContext = MessageHubContext()
            .Subscribe(props.conceptStore, (conceptModel: ConceptDataModel) => {

                hubContext.Dispose();

                const loadedValue = HelperExtension.GetPropertyValueObject(props.fieldName, conceptModel) as number;
                if (!isNaN(loadedValue)) {
                    // The enum starts in 1 but index is zero based that is why we decrease one from the loadedValue
                    setCurrentValue((loadedValue - 1) * stepValue);
                }
            });


        return () => hubContext.Dispose();
    }, [])

    const OnValueChange = (event: any) => {

        const selectedValue = event.target.value;

        const selectionIndex = Math.round(selectedValue / stepValue);
        props.conceptStore.sendMessageCustom(props.fieldName, props.selectionList[selectionIndex].value);

        setCurrentValue(selectedValue);
    }
    //background: linear-gradient(to right, var(--voyce-main-color) 0%, var(--voyce-main-color) 50%, #d3d3d3 50%, #d3d3d3 100%);

    const OnDescriptionClick = (selection: SelectorItem) => {

        props.conceptStore.sendMessageCustom(props.fieldName, selection.value);

        const selectionIndex = props.selectionList.indexOf(selection);
        setCurrentValue(selectionIndex * stepValue);
    }

    const GetBackgroundColor = () => {
        return {
            background: "linear-gradient(to right, var(--voyce-main-color) 0%, var(--voyce-main-color) " + currentValue + "%, #d3d3d3 " + currentValue + "%, #d3d3d3 100%)"
        } as CSSProperties;
    }

    return (
        <div>
            {
                (props.header) &&
                <div className="fw-med-500">{props.header}</div>
            }
            <div className="range-selector mt-2">
                <input type="range"
                    min="0"
                    max="100"
                    step={stepValue}
                    value={currentValue}
                    className="slider"
                    style={GetBackgroundColor()}
                    onChange={OnValueChange} />
                <div className="d-flex flex-wrap justify-content-between fw-small-400 mt-2">
                    {
                        props.selectionList.map(selection => {

                            return (
                                <div key={selection.value} role="button" onClick={() => OnDescriptionClick(selection)} style={{ "marginLeft": selection.positionFix }}>
                                    {selection.description}
                                </div>
                            )
                        })
                    }
                    {/* <div role="button" onClick={() => setCurrentValue(0)}>Low</div>
                    <div role="button" onClick={() => setCurrentValue(25)}>Medium/Low</div>
                    <div role="button" onClick={() => setCurrentValue(50)}>Medium</div>
                    <div role="button" onClick={() => setCurrentValue(75)}>Medium/High</div>
                    <div role="button" onClick={() => setCurrentValue(100)}>High</div> */}
                </div>
            </div>
        </div>
    )
}