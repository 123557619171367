import { Subject } from "rxjs";
import { IDataStore } from "../../models/common/IDataStore";
import { INotificationHub } from "../../models/common/INotificationHub";
import { CommentUpdateAction } from "../../models/communication/actions/CommentUpdateAction";
import { CommentDataModel } from "../../models/data/CommentDataModel";
import { IServerMessage, MessageType } from "../../models/message/IServerMessage";
import { MessageService } from "../messaging/MessageService";

const defaultValue = {
    commentContent: ""
} as CommentDataModel;

export class CommentDataStoreEditor implements IDataStore, INotificationHub {
    editorSubject = new Subject();
    storageState = defaultValue;

    subscribe(messageReceiveAction: any) {
        return this.editorSubject.subscribe(messageReceiveAction);
    }

    sendMessage(fieldName: string, fieldValue: string) {

        this.storageState = {
            ...this.storageState,
            [fieldName]: fieldValue
        }
    }

    initialize(currentValue: CommentDataModel) {

        this.storageState = {
            ...currentValue
        }

        this.editorSubject.next(this.storageState);
    }

    async commitChanges() {
        await MessageService.sendMessage({
            messageType: MessageType.ConceptCommentUpdate,
            requestData: {
                commentData: this.storageState
            } as CommentUpdateAction
        } as IServerMessage);
    }
}