import { useEffect, useState } from "react";
import { CommonElements } from "../../models/CommonElements";
import { GlobalNotifications, PageType } from "../../models/common/CommonEnums";
import { WorkspaceDataModel } from "../../models/data/WorkspaceDataModel";
import { WorkspaceEditorStore } from "../../services/stores/Workspace/WorkspaceEditorStore";
import { TextBoxComponent } from "../elements/TextBoxComponent";
import { WorkspaceStore } from "../../services/stores/Workspace/WorkspaceStore";
import { GlobalNotificationHub } from "../../services/GlobalMessageHub";
import { ConfirmNotificationModel } from "../../models/communication/internal/ConfirmNotificationModel";
import { WorkspaceDeleteService } from "../../services/stores/Workspace/WorkspaceDeleteService";

interface WorkspaceDisplayActionComponentProps {
    workspace: WorkspaceDataModel;
    workspaceStore?: WorkspaceStore;
    onNavigateToWorkspace: (workspace: WorkspaceDataModel, navigateTo: PageType) => void;
    isSharedWorkspace: boolean;
}

export const WorkspaceDisplayActionComponent = (props: WorkspaceDisplayActionComponentProps) => {
    const [isEditing, setIsEditing] = useState(false);

    const OnDelete = () => {
        if (!props.workspaceStore) {
            return;
        }

        GlobalNotificationHub.sendMessageWithData(GlobalNotifications.ConfirmNotification, {
            confirmService: new WorkspaceDeleteService(props.workspaceStore, props.workspace)
        } as ConfirmNotificationModel);
    }

    return (
        <div className="d-flex fw-large-700">
            <div className="flex-fill">
                {
                    (!isEditing) &&
                    <div className="d-flex align-items-center sz-fit display-lines" role="button"
                        onClick={(event) => {
                            if (!isEditing) {
                                props.onNavigateToWorkspace(props.workspace, PageType.Dashboard);
                            }
                            else {
                                event.preventDefault();
                                event.stopPropagation();
                            }
                        }}>
                        <div className="concept-link">
                            {props.workspace.workspaceName}
                        </div>
                        <div style={{ paddingBottom: "3px" }}>
                            <svg className="p-none insight-icon ms-2" width="20" height="20" role="img">
                                <use xlinkHref="#go-to-icon"></use>
                            </svg>
                        </div>
                    </div>
                }
                {
                    (isEditing) &&
                    <InLineWorkspaceEditor
                        currentWorkspace={props.workspace}
                        onCancel={() => { setIsEditing(false) }}
                        onCommit={(updatedWorkspace: WorkspaceDataModel) => {
                            if (props.workspaceStore) {
                                props.workspaceStore.updateWorkspaceName(updatedWorkspace.workspaceID, updatedWorkspace.workspaceName);
                            }
                            setIsEditing(false);
                        }}
                    />
                }
            </div>
            {
                (!props.isSharedWorkspace) &&
                <div>

                    <div
                        className="ms-2 grey-icon"
                        role="button"
                        data-bs-toggle="dropdown"
                        data-bs-auto-close="true"
                        aria-expanded="false"                >
                        <svg className="pe-none mt-1" width="17" height="18" role="img" aria-label="Link"><use xlinkHref="#gear-icon"></use></svg>
                    </div>
                    <div className="dropdown-menu dropdown-menu-end shadow modal-border rounded-top" >
                        <li>
                            <div role="button" className="dropdown-item" onClick={() => {
                                setTimeout(() => {
                                    setIsEditing(true);
                                }, 50);
                            }}>
                                <svg className="pe-none dark-icon" width="16" height="17" role="img" aria-label="Link"><use xlinkHref="#t-edit-icon"></use></svg>
                                <span className="ps-2 fw-small-500">Rename workspace</span>
                            </div>
                        </li>
                        <li>
                            <div role="button" className="dropdown-item" data-bs-toggle="modal" data-bs-target={CommonElements.ModalElementTarget} onClick={OnDelete}>
                                <svg className="pe-none dark-icon" width="16" height="19" role="img" aria-label="Link"><use xlinkHref="#delete-icon"></use></svg>
                                <span className="ps-2 fw-small-500">Delete workspace</span>
                            </div>
                        </li>

                    </div>
                </div>
            }
        </div>
    )
}



interface InLineWorkspaceEditorProps {
    currentWorkspace: WorkspaceDataModel;
    onCommit: (renamedWorkspace: WorkspaceDataModel) => void;
    onCancel: () => void;
}

const InLineWorkspaceEditor = (props: InLineWorkspaceEditorProps) => {
    const [store] = useState(new WorkspaceEditorStore());

    useEffect(() => {
        store.load(props.currentWorkspace);
    }, [props.currentWorkspace])

    const OnRenameCommit = async () => {

        if (!store.storageState.workspaceName) {
            return;
        }


        store.renameWorkspace().then(() => {
            props.onCommit({ ...store.storageState } as WorkspaceDataModel);
        });
    }


    return (
        <div >
            <TextBoxComponent
                dataStore={store}
                fieldName="workspaceName"
                maxLength={200}
                autoFocus={true}

                onCommit={OnRenameCommit}

                onCancel={() => {
                    props.onCancel();
                }}

                onBlur={() => {
                    props.onCancel();
                }}
            />
        </div>
    )
}