import React, { useState } from "react";
import { Link } from "react-router-dom";
import { TextBoxRichTextComponent } from "../components/elements/RichTextBox/TextBoxRichTextComponent";
import { TextSearchStore } from "../services/stores/TextSearchStore";


// interface ImplementationValueItemProps {
//     currentLevel: number;
//  }

// function SidebarImplementationFilter(props: ImplementationValueItemProps) {

//     return (
//         <div className="vc-item p-2" style={{ alignItems: "center" }}>
//             <input className="form-check-input  m-0 me-2" type="checkbox" value="" aria-label="Checkbox for following text input" />
//             <div className="me-auto">
//                 <ImplementationLevelComponent {...props} />
//             </div>
//             <div className="text-right">87</div>
//         </div>
//     );
// }



export const TestComponentX = () => {

    const [currentValue, setCurrentValue] = useState(0);

    const OnValueCHange = (event: any) => {

        setCurrentValue(event.target.value);
    }
    return (
        <div className="slidecontainer" style={{ width: "600px" }}>
            <input type="range"
                min="0"
                max="100"
                step={25}
                value={currentValue}
                className="slider"
                style={{ width: "100%" }}
                id="myRange" onChange={OnValueCHange} />
            <div className="d-flex flex-wrap justify-content-between" style={{ width: "100%" }}>
                <div>Low</div>
                <div>Medium/Low</div>
                <div>Medium</div>
                <div>Medium/High</div>
                <div>High</div>
            </div>
        </div>

    )
}

function PlaygroundPage() {

    //useMount()
    // useEffect(() => {
    //     MessageService.connect();
    // });


    // const test = async () => {
    //     await MessageService.sendMessage({
    //         messageType: MessageType.ConceptCreated,
    //         requestData: {
    //             conceptID: "aaaa",
    //             title: "newtitle",
    //             customerEmail: "xxx.f@fasd.com"
    //         } as ConceptDataModel
    //     } as IServerMessage)

    //     console.log("sent already");
    //}

    const [c, setC] = useState({
        notifications: [] as string[]
    });

    // useEffect(()=>{

    //     const t = setTimeout(() => {
    //         //const x  = x + "test";
    //         const str = new Date();
    //         const x = c;
    //         x.push( (str.toTimeString() + "Hello, world! This is a toast message") )

    //         console.log("here");
    //         console.log(x);
    //         setC(x);
    //         //setC(c+ str.toTimeString() + "Hello, world! This is a toast message");

    //        // return () => clearTimeout(t);
    //     }, 1000);

    // })

    const RenderTest = () => {
        let counter = 1;
        console.log("render");

        //const xxxx1 = 

        return <React.Fragment>
            {c.notifications.map((xxxx) => {
                counter++;
                return (
                    <div key={counter}>{xxxx}</div>
                );
            })}
        </React.Fragment>
    }

    return (
        <div>
            <br />
            <span style={{ fontFamily: "Roboto", fontSize: "16px", fontWeight: "400" }}>This a playground page!</span>
            <br />
            <br />
            <span style={{ fontFamily: "Roboto", fontSize: "16px", fontWeight: "400" }}>Body - Medium (16px</span>
            <br />
            {/* <ContentUploaderComponent /> */}
            <br />
            <Link to="/workspace" className="btn btn-primary mx-2">Sign In</Link>
            <br />
            <br />

            <button type="button" className="btn btn-primary" id="liveToastBtn" onClick={() => {
                console.log("x");

                global.window.openNotification("liveToast");


            }}>Show live toast</button>
            <button type="button" className="btn btn-primary" id="liveToastBtn" onClick={() => {
                console.log("x");

                // const toastLiveExample = document.getElementById("liveToast");
                // if (toastLiveExample) {
                //     toastLiveExample.style.display = "block";
                // }
                const str = new Date();
                const x = c.notifications;
                x.push((str.toLocaleString() + " - Hello, world! This is a toast message"))
                console.log("here");
                //console.log(x);
                setC({
                    notifications: x
                });

                console.log(c);

                //Window.test();
                //global.Window.

                //Window.test();
                //  toast.show()

            }}>Show live toast2</button>


            <div className="toast-container position-fixed bottom-0 end-0 p-3">
                <div id="liveToast" className="toast" role="alert" aria-live="assertive" aria-atomic="true">
                    <div className="toast-header">
                        <strong className="me-auto">Notifications</strong>
                        <small>11 mins ago</small>
                        <button type="button" className="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
                    </div>
                    <div className="toast-body">
                        <RenderTest />
                    </div>
                </div>
            </div>

            <TestComponentX />

            <TextBoxRichTextComponent fieldName="ddd" dataStore={new TextSearchStore()} maxLength={3500} />

        </div>
    )
}

export default PlaygroundPage;