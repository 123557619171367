import { FeedbackGraphComponent } from "./components/FeedbackGraphComponent";

interface FeedbackSummaryComponentProps {
    feedbackCounter: number;
}

export const FeedbackSummaryComponent = (props: FeedbackSummaryComponentProps) => {


    return (
        <div className="voyce-card-size">
            <div className="fw-concept fw-xlarge-700">Customer responses</div>
            <FeedbackGraphComponent />
            <div className="fw-med-700 mt-4">{props.feedbackCounter} written {props.feedbackCounter === 1 ? "response" : "responses"} to the question in your feedback form:</div>
            <div className="fw-reg-400 mb-3" style={{ fontStyle: "italic" }}>"If we could change or improve one thing what would it be?"</div>
        </div>
    )
}