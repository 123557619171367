import React, { memo, useCallback, useEffect, useRef, useState } from "react";
import { KeyboardService } from "../../services/shortcut/KeyboardService";
import { ConceptTagStore } from "../../services/stores/Tag/ConceptTagStore";
import { TagContentComponent } from "./TagContentComponent";

interface TagSearchOrCreateComponentProps {
    tagName: string;
    tagConceptStore: ConceptTagStore;
    selectionOnly: boolean;
    disableDelete: boolean;
}

export const TagSearchOrCreateComponent: React.FunctionComponent<TagSearchOrCreateComponentProps>
    = memo(function TagSearchOrCreate({ tagName, tagConceptStore, selectionOnly, disableDelete }) {

        const [isTagModalOpen, setIsTagModalOpen] = useState(false);
        const dropRef = useRef<HTMLButtonElement>(null);
        const [dropdownUID] = useState(Math.random().toString(36));

        const OnTagSelectionClick = () => {

            if (isTagModalOpen) {
                return;
            }

            setIsTagModalOpen(true);
        }

        useEffect(() => {
            KeyboardService.attachAutoClose(dropRef);

            return () => { KeyboardService.release(dropRef) }
        }, [dropRef]);

        const OnClosingDrop = useCallback(() => {
            setIsTagModalOpen(false);
        }, []);

        const PreventDrag = (event: React.MouseEvent<HTMLDivElement>) => {
            //event.preventDefault();
            event.stopPropagation();
            return false;
        }



        useEffect(() => {
            dropRef.current?.addEventListener("hidden.bs.dropdown", OnClosingDrop);
            return () => {
                dropRef.current?.removeEventListener("hidden.bs.dropdown", OnClosingDrop);
            }
        }, []);


        return (
            <div>
                <button id={dropdownUID} type="button" className="btn btn-light btn-xsmall btn-mod-tag"
                    data-bs-toggle="dropdown"
                    data-bs-auto-close="outside"
                    aria-expanded="false"

                    onClick={OnTagSelectionClick}
                    ref={dropRef}
                >{tagName}</button>
                <div className="dropdown-menu p-0 shadow drop-container modal-border rounded-top"
                    draggable onDragStart={PreventDrag} onMouseDownCapture={PreventDrag}>
                    {
                        isTagModalOpen &&
                        <TagContentComponent conceptTagStore={tagConceptStore} dropdownKey={dropdownUID} selectionOnly={selectionOnly} disableDelete={disableDelete} />
                    }
                    {/* <div className="bg-header text-white p-2 rounded-top d-flex">
                    <div className="m-auto">
                        ADD TAG
                    </div>
                    <button type="button" className="btn-close btn-close-white" onClick={() => { global.window.closeCustomDropdown(dropdownName); }} aria-label="Close"></button>
                </div> */}

                </div>
            </div>
        );
    })