import { Subject } from "rxjs";
import { IDataStore } from "../../models/common/IDataStore";
import { INotificationHub } from "../../models/common/INotificationHub";
import { WorkspaceDataModel } from "../../models/data/WorkspaceDataModel";
import { WorkspaceStore } from "./Workspace/WorkspaceStore";
import { LogCollector } from "../logger/LogCollector";

export interface GlobalStoreData {
    currentWorkspace: WorkspaceDataModel;
}

const defaultState = {

} as GlobalStoreData;

class GlobalStoreImplementation implements IDataStore, INotificationHub {
    globalStoreSubject = new Subject();
    storageState: GlobalStoreData = defaultState;

    // static version for lookups
    workspaceLoaderStore: WorkspaceStore = new WorkspaceStore();


    initialize() {
        this.storageState = { ...defaultState };

        localStorage.removeItem("current_workspace");

        //Notifies everyone
        this.globalStoreSubject.next(this.storageState);
    }

    loadWorkspace() {

        LogCollector.LogMessage("cached: load workspace");
        if (this.storageState?.currentWorkspace != null) {
            return;
        }

        const jsonWorkspace = localStorage.getItem("current_workspace");
        if (jsonWorkspace === null) {
            return;
        }

        this.storageState.currentWorkspace = JSON.parse(jsonWorkspace) as WorkspaceDataModel;
        this.updatePageTitle();
    }

    subscribe(onMessageReceived: any) {
        return this.globalStoreSubject.subscribe(onMessageReceived);
    }

    sendMessage(fieldName: string, fieldValue: string) {
        throw new Error("Method not implemented.");
    }

    updatePageTitle() {
        if (this.storageState.currentWorkspace) {
            //const workspaceName = TruncateText(this.storageState.currentWorkspace.workspaceName, 26);
            const workspaceName = this.storageState.currentWorkspace.workspaceName;
            document.title = workspaceName + " | Voyce";
        }
        else {
            document.title = "Voyce";
        }
    }

    setCurrentWorkspace(workspace: WorkspaceDataModel) {

        this.storageState.currentWorkspace = workspace;
        localStorage.setItem("current_workspace", JSON.stringify(workspace));

        this.updatePageTitle();

        this.globalStoreSubject.next(this.storageState);
    }

    getWorkspaceIdIfAvailable() {
        this.loadWorkspace();

        if (this.storageState?.currentWorkspace?.workspaceID == null) {
            return "";
        }

        return this.storageState.currentWorkspace.workspaceID;
    }

    getWorkspaceNameIfAvailable() {
        this.loadWorkspace();

        if (this.storageState?.currentWorkspace?.workspaceName == null) {
            return "";
        }

        return this.storageState.currentWorkspace.workspaceName;
    }

    getWorkspaceCodeIfAvailable() {
        this.loadWorkspace();

        if (this.storageState?.currentWorkspace?.workspaceCode == null) {
            return "";
        }

        return this.storageState.currentWorkspace.workspaceCode;
    }

}

export const GlobalStore = new GlobalStoreImplementation();